import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TwofaGuard } from 'src/guards/twofa.guard';
import { TournamentResolver } from 'src/resolvers/tournament.resolver';
import { HomePageComponent } from './home-page/home-page.component';
import { TableGameReplayComponent } from './home-page/table-game-replay/table-game-replay.component';
import { LobbyTournamentComponent } from './lobby-tournament/lobby-tournament.component';
import { LoggedComponent } from './logged.component';
import { PlayCashgameComponent } from './play-cashgame/play-cashgame.component';
import { ProfileComponent } from './profile/profile.component';
import { SettingsComponent } from './settings/settings.component';
import { TableGameComponent } from './table-game/table-game.component';
import { TesteComponent } from './teste/teste.component';
import { TournamentInfoComponent } from './tournament-info/tournament-info.component';

const routerConfig: Routes = [
  {
    path: 'pages',
    component: LoggedComponent,
    children: [
      { path: 'home', component: HomePageComponent },
      { path: 'profile', component: ProfileComponent },
      {
        path: 'lobby-tournament/id/:id',
        component: LobbyTournamentComponent,
        resolve: { data: TournamentResolver },
      },
      { path: 'tournament-info/id/:id', component: TournamentInfoComponent },
      { path: 'cashgame/id/:id', component: PlayCashgameComponent },
      { path: 'tablegame/id/:id', component: TableGameComponent },
      { path: 'tablegame/id/:id/:viewer', component: TableGameComponent },
      { path: 'tablegame-replay/id/:tableId/:userId/:round', component: TableGameReplayComponent },
      { path: 'teste', component: TesteComponent },
      { path: 'settings', component: SettingsComponent },
    ],
    canActivate: [TwofaGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routerConfig)],
  exports: [RouterModule],
})
export class LoggedRouteModule { }
