import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserInterface } from 'src/app/dtos/user/user.interface';
import { BaseService } from './base.service';
import { KycRegisterDto } from 'src/app/dtos/kyc/kyc-register.dto';

@Injectable({
    providedIn: 'root',
})
export class AdvertisementService extends BaseService {
    userApi: string = `${this.gameBackoffice}advertisement`;

    constructor(private httpClient: HttpClient) {
        super();
    }

    getAd() {
        return this.httpClient.get(`${this.userApi}`, this.authorizedHeader);
    }

}
