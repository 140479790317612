<div class="container card-login">
    <div (click)="exit()" class="d-flex justify-content-end cursor-pointer mt-3 pe-3">
        <img src="../../../assets/icons/icon-x-black.svg">
      </div>
    <div class="d-flex flex-column align-items-center">
        <img src="../../../assets/icons/icon-pokerfi-grey.svg" class="img-fluid" style="margin-top: 24px !important;">
    </div>
    <div class="d-flex flex-column px-lg-5 mt-2">
        <h3 class="profile-700">Login</h3>
        <p>{{ 'MODAL_LOGIN.REGISTER_USER' | translate }}</p>
        <form class="w-100" [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="d-flex flex-column">
                <strong><label class="label-custom" for="email">E-mail</label></strong>
                <input type="email" formControlName="email" class="form-control input-custom" placeholder="E-mail"
                    id="email" style="border:none;border-bottom: 1px solid #C1D34E; border-radius: 0;">
            </div>
            <div class="d-flex flex-column mt-2">
                <strong><label class="label-custom" for="password">{{ 'MODAL_LOGIN.PASS' | translate }}</label></strong>
                <input type="password" formControlName="password" class="form-control input-custom" placeholder="{{ 'MODAL_LOGIN.PASS' | translate }}"
                    id="password" style="border:none;border-bottom: 1px solid #C1D34E; border-radius: 0;">
            </div>
            <div class="my-3 d-flex align-items-center justify-content-center">
                <a href="https://wallet.pokerfi.app/forgot-password" target="_blank" class="link-400 cursor-pointer">{{ 'MODAL_LOGIN.FORGOT_PASS' | translate }}</a>
            </div>
            <p>{{ 'MODAL_LOGIN.DONT_HAVE_ACCOUNT' | translate }} <a class="link-400 cursor-pointer" (click)="openModalSignUp()">{{ 'MODAL_LOGIN.REGISTER' | translate }}</a></p>
            <!-- <label>
                <div class="mt-3">
                    <div class="d-flex align-items-center pb-2">
                        <div class="checkbox-round d-flex justify-content-center align-items-center cursor-pointer">
                            <input type="checkbox" id="1" value="Poeira Fina" class="checkbox-round-center"
                                formControlName="remember" />
                        </div>
                        <p class="m-0 ps-2">{{ 'MODAL_LOGIN.REMEMBER' | translate }}</p>
                    </div>
                </div>
            </label> -->
            <div class="d-flex flex-column mt-2 align-items-center">
                <button class="btn btn-login" type="submit" [disabled]="!form.valid"
                    style="width: 80%;">{{ 'MODAL_LOGIN.ENTER' | translate }}
                </button>
            </div>
        </form>
    </div>
</div>
