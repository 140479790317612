<section class="container section-auth d-flex align-items-center">
  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
  </ngx-spinner>
  <div class="col-12 col-md-10 col-lg-6 mt-4">
    <h2 class="heading-700-md">{{ 'SIGN-UP.TITLE' | translate }}</h2>
    <p class="subtitle my-3">{{ 'SIGN-UP.SUBTITLE_START' | translate }} <span [routerLink]="'/sign-in'">{{ 'SIGN-UP.SUBTITLE_MID' | translate
        }}</span> {{ 'SIGN-UP.SUBTITLE_END' | translate }}</p>
    <form class="form" [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="row d-flex flex-row mt-4 justify-content-between">
        <div class="col-sm d-flex flex-column">
          <div class="d-flex position-relative">
            <input type="text" formControlName="name" class="form-control input-box text-capitalize"
            placeholder="{{ 'SIGN-UP.FULL_NAME' | translate }}">
            <img src="../../../assets/icons/user-icon.svg" class="icon-input" width="20" height="16" />
          </div>
          <div *ngIf="form.controls['name']?.errors?.['required'] && this.isSubmit" class="d-flex msg-error mt-1">{{'FORM.REQUIRED_FIELD' | translate}}</div>
          <div *ngIf="form.controls['name']?.errors?.['minlength'] && this.isSubmit" class="d-flex msg-error mt-1">{{'FORM.MIN_LENGTH_3' | translate}}</div>
        </div>

        <div class="col-sm mt-3 mt-sm-0 d-flex flex-column">
          <div class="d-flex position-relative">
            <input type="text" formControlName="nickname" (blur)="unblur()" class="form-control input-box"
            placeholder="{{ 'SIGN-UP.NICKNAME' | translate }}">
            <img src="../../../assets/icons/icon-nickname.svg" class="icon-input" width="20" height="16" />
          </div>
          <div *ngIf="form.controls['nickname']?.errors?.['required'] && this.isSubmit" class="d-flex msg-error mt-1">{{'FORM.REQUIRED_FIELD' | translate}}</div>
          <div *ngIf="form.controls['nickname']?.errors?.['minlength'] && this.isSubmit" class="d-flex msg-error mt-1">{{'FORM.MIN_LENGTH_2' | translate}}</div>
        </div>

      </div>
      <div class="row mt-3">
        <div class="col-sm-7 d-flex flex-column">
          <div class="d-flex position-relative">
            <input type="text" formControlName="email" class="form-control input-box"
            placeholder="{{ 'SIGN-UP.EMAIL' | translate }}">
            <img src="../../../assets/icons/email-icon.svg" class="icon-input" width="20" height="16" />
          </div>
          <div *ngIf="form.controls['email']?.errors?.['required'] && this.isSubmit" class="d-flex msg-error mt-1">{{'FORM.REQUIRED_FIELD' | translate}}</div>
          <div *ngIf="form.controls['email']?.errors?.['email'] && this.isSubmit" class="d-flex msg-error mt-1">{{'FORM.INVALID_EMAIL' | translate}}</div>
        </div>

        <div class="col-sm-5 mt-3 mt-sm-0 d-flex flex-column">
          <div class="d-flex position-relative">
            <input type="text" formControlName="birthdate" [mask]="birthdateMask" class="form-control input-box"
            placeholder="{{ 'SIGN-UP.BIRTHDATE' | translate }}">
            <img src="../../../assets/icons/date-icon.svg" class="icon-input" width="20" height="16" />
          </div>
          <div *ngIf="form.controls['birthdate']?.errors?.['required'] && this.isSubmit" class="d-flex msg-error mt-1">{{'FORM.REQUIRED_FIELD' | translate}}</div>
          <div *ngIf="form.controls['birthdate']?.errors?.['minlength'] && this.isSubmit" class="d-flex msg-error mt-1">{{'FORM.INVALID_BIRTHDATE' | translate}}</div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-sm d-flex flex-column">
          <div class="d-flex position-relative">
            <input type="text" formControlName="phone" class="form-control input-box"
            placeholder="+55 11 9999-9999">
            <img src="../../../assets/icons/phone-icon.svg" class="icon-input" width="20" height="16" />
          </div>
          <div *ngIf="form.controls['phone']?.errors?.['required'] && this.isSubmit" class="d-flex msg-error mt-1">{{'FORM.REQUIRED_FIELD' | translate}}</div>
          <div *ngIf="form.controls['phone']?.errors?.['minlength'] && this.isSubmit" class="d-flex msg-error mt-1">{{'FORM.INVALID_PHONE' | translate}}</div>
        </div>
        <div class="col-sm mt-3 mt-sm-0 d-flex flex-column">
          <div class="d-flex position-relative">
            <input type="text" formControlName="country" class="form-control input-box text-capitalize"
            placeholder="{{ 'SIGN-UP.COUNTRY' | translate }}">
            <img src="../../../assets/icons/house-icon.svg" class="icon-input" width="20" height="16" />
          </div>
          <div *ngIf="form.controls['country']?.errors?.['required'] && this.isSubmit" class="d-flex msg-error mt-1">{{'FORM.REQUIRED_FIELD' | translate}}</div>
          <div *ngIf="form.controls['country']?.errors?.['minlength'] && this.isSubmit" class="d-flex msg-error mt-1">{{'FORM.MIN_LENGTH_3' | translate}}</div>
        </div>
      </div>

      <div class="d-flex justify-content-center align-items-center mt-4">
        <button class="btn-continue button-bold">{{ 'SIGN-UP.BUTTON' | translate }}</button>
      </div>

      <div class="d-flex flex-row justify-content-start align-items-center mt-4">
        <input type="checkbox" formControlName="age" [value]="true" />
        <span class="checkbox-label ms-3">{{ 'SIGN-UP.AGE' | translate }}</span>
      </div>
      <div *ngIf="form.controls['age']?.errors?.['required'] && this.isSubmit" class="d-flex msg-error mt-1">{{'FORM.REQUIRED_FIELD' | translate}}</div>

      <div class="d-flex flex-row justify-content-start align-items-center mt-2">
        <input type="checkbox" formControlName="term" [value]="true" />
        <span class="checkbox-label ms-3">{{ 'SIGN-UP.TERM' | translate }}</span>
      </div>
      <div *ngIf="form.controls['term']?.errors?.['required'] && this.isSubmit" class="d-flex msg-error mt-1">{{'FORM.REQUIRED_FIELD' | translate}}</div>
    </form>
  </div>
</section>
