import { environment } from "./../environments/environment";
import { HttpErrorResponse } from "@angular/common/http";
import { ErrorHandler, Injectable, Injector, NgZone } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  private toastr!: ToastrService;
  private spinner!: NgxSpinnerService;

  constructor(
    private injector: Injector,
    private zone: NgZone,
    private translateService: TranslateService) { }

  handleError(error: any) {
    if (!(error instanceof HttpErrorResponse)) {
      error.rejection ? (error = error.rejection) : null;
    }
    this.zone.run(() => {
      this.toastr = this.injector.get(ToastrService);
      this.spinner = this.injector.get(NgxSpinnerService);
      if (environment.production === false) {
        this.handleDev(error);
      } else {
        this.handleProd(error);
      }
      this.spinner.hide();
    });
  }

  private handleDev(error: any) {
    if (error.code !== 0) {
      if (error instanceof HttpErrorResponse) {
        this.toastr.error(this.getDevMsgTemplate(error), undefined, {
          progressBar: true,
        });
      } else {
        if (Object.keys(error).length > 0) {
          this.toastr.error(
            `Um erro ocorreu. Olhe o console -->`,
            // `An error ocurred. Please try again later.`,
            undefined,
            {
              progressBar: true,
            }
          );
        }
      }
      console.error(error);
    }
  }

  private handleProd(error: any) {
    if (error.code !== 0) {
      if (error instanceof HttpErrorResponse) {
        if (error.status === 401) {

          this.translateService.get('TOASTR.AUTHENTICATION_EXPIRED').subscribe({
            next: data => {
              this.toastr.warning(data, undefined, { progressBar: true, });
            }
          });

        } else {
          if (Object.keys(error).length > 0) {
            this.translateService.get('TOASTR.AN_ERROR_OCURRED').subscribe({
              next: data => {
                this.toastr.error(data, undefined, { progressBar: true, });
              }
            });
          }
        }
      } else {
        console.error(error);
      }
    }
  }

  private getDevMsgTemplate(error: HttpErrorResponse) {
    if (error.error.errors) {
      return `Code: ${error.status} | Message: ${error.error.errors.map((el: { message: any; }) => el.message)} | Olhe o console -->`;
    } else {
      return `Code: ${error.status} | Message: ${error.error.message} | Error: ${error.error.error} | Olhe o console -->`;
    }
  }
}
