import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Socket } from 'socket.io-client';
import { ChatMessageDto } from 'src/models/chatMessageDto';
import { ChatSocketService } from 'src/services/sockets/chat.socket.service';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit, OnDestroy {

  chatSocket: Socket | undefined;

  constructor(public _chatGateway: ChatSocketService) { }

  ngOnInit(): void {
    this.connect();
    let tableId = localStorage.getItem('tableId') ?? '';

    this._chatGateway.requestMessageList(tableId);
  }

  connect() {
    this.chatSocket = this._chatGateway.initializeMain();
  }

  sendMessage(sendForm: NgForm) {
    let user = JSON.parse(localStorage.getItem('user') ?? '{}');
    let tableId = localStorage.getItem('tableId') ?? '';
    const chatMessage = new ChatMessageDto(user._id, tableId, sendForm.value.message, user.nickname);
    this._chatGateway.sendMessage(chatMessage);
    this._chatGateway.messages.subscribe({
      next: (response: ChatMessageDto[]) => {
        sendForm.controls['message'].reset();
      }
    })
  }

  handleEnterKey(event: any): void {
    event.stopPropagation();
  }

  ngOnDestroy(): void {
    this._chatGateway.shutDownAll()
  }
}
