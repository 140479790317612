<section class="container section-auth d-flex align-items-center">
  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
  </ngx-spinner>
  <div class="col-12 col-md-10 col-lg-6 mt-5">
    <h2 class="heading-700-md">{{ "RESET_PASSWORD.TITLE" | translate }}</h2>
    <div *ngIf="firstStep">
      <p *ngIf="!showCodeInput" class="subtitle mt-4">
        {{ "RESET_PASSWORD.SUBTITLE_START" | translate }}
      </p>
      <p *ngIf="!showCodeInput" class="subtitle pt-1">{{ "RESET_PASSWORD.SUBTITLE_END" | translate }}</p>

      <p *ngIf="showCodeInput" class="subtitle mt-4">
        {{ "RESET_PASSWORD.VERIFY_SUBTITLE" | translate }}
      </p>
    </div>
    <div *ngIf="!firstStep">
      <p class="subtitle my-3">{{ 'RESET_PASSWORD.SUBTITLE_START2' | translate }}
        <br>
        <span>{{ 'RESET_PASSWORD.SUBTITLE_END2' | translate}}</span>
      </p>
    </div>

    <div *ngIf="firstStep">
      <div class="row mt-4">
        <form [formGroup]="formEmail">
          <div class="col-12 col-sm-8">
            <div *ngIf="!showCodeInput" class="position-relative">
              <input type="email" formControlName="email" class="form-control input-box"
                placeholder="{{ 'SIGN_IN.EMAIL' | translate }}">
              <img src="../../../assets/icons/email-icon.svg" class="icon-input" width="20" height="16" />
            </div>
          </div>
        </form>
      </div>

      <div class="row justify-content-center align-items-center">
        <form [formGroup]="formCode">
          <div *ngIf="showCodeInput">
            <div class="col-12 col-sm-8">
              <input type="text" formControlName="code" maxlength="5" class="form-control input-box text-capitalize"
                placeholder="{{ 'RESET_PASSWORD.CODE' | translate }}">
            </div>
            <div class="d-flex align-items-center col-md mt-2">
              <a *ngIf="!resendButton" class="link-resend text-white me-1">{{timeLeft}}
                {{ "RESET_PASSWORD.VERIFY_TIME_LEFT" | translate }}</a>
              <a *ngIf="resendButton" (click)="formEmail.valid?submitEmail():false" class="link-resend">{{
                'RESET_PASSWORD.RESEND' |
                translate }}</a>
            </div>
          </div>
        </form>
      </div>
      <div class="d-flex col-12 col-sm-6 justify-content-start align-items-center mt-4">
        <button *ngIf="!showCodeInput" [ngClass]="!formEmail.valid?'btn-continue-disable':'btn-continue'"
          class="btn-continue button-bold" (click)="formEmail.valid?submitEmail():false">{{ 'RESET_PASSWORD.BUTTON' |
          translate
          }}</button>
        <button *ngIf="showCodeInput" [ngClass]="!formCode.valid?'btn-continue-disable':'btn-continue'"
          class="btn-continue button-bold" (click)="formCode.valid?submitCode():false">{{ "RESET_PASSWORD.VERIFY_BUTTON"
          | translate }}</button>
      </div>
    </div>

    <form *ngIf="!firstStep" class="form" [formGroup]="formPassword" (ngSubmit)="onSubmit()">
      <div class="row d-flex flex-row mt-3">
        <div class="col-sm position-relative">
          <input [type]="showPassword?'text':'password'" formControlName="password" class="form-control input-box"
            placeholder="{{ 'RESET_PASSWORD.PASSWORD' | translate }}">
          <img src="../../../assets/icons/password-icon.svg" class="icon-input cursor-pointer" width="20" height="16"
            (click)="showPassword=!showPassword" />
        </div>
        <div class="col-sm mt-3 mt-sm-0 position-relative">
          <input [type]="showConfirmPassword?'text':'password'" formControlName="newPassword"
            class="form-control input-box" placeholder="{{ 'RESET_PASSWORD.CONFIRMPASSWORD' | translate }}">
          <img src="../../../assets/icons/password-icon.svg" class="icon-input cursor-pointer" width="20" height="16"
            (click)="showConfirmPassword=!showConfirmPassword" />
        </div>
      </div>
      <div class="d-flex justify-content-center align-items-center mt-4">
        <button [ngClass]="!formPassword.valid?'btn-continue-disable':'btn-continue'" class="button-bold">{{
          'RESET_PASSWORD.BUTTON2' | translate }}</button>
      </div>
    </form>

  </div>
</section>
