import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/services/user.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit {
  form: FormGroup;
  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private toastrService: ToastrService,
    private router: Router,
    private ngxSpinnerService: NgxSpinnerService,
    private translateService: TranslateService
  ) {
    this.form = this.fb.group({
      password: ['', [Validators.required, Validators.minLength(8)]],
      email: ['', [Validators.required, Validators.email]],
    });
  }

  ngOnInit(): void {}

  onSubmit() {
    if (this.form.pristine || !this.form.valid) {
      return;
    }

    this.ngxSpinnerService.show();

    const data = Object.assign({}, this.form.value);
    // this.userService.auth(data).subscribe(
    //   (data) => this.loginSuccess(data),
    //   (error) => this.runError(error)
    // );
  }
  loginSuccess(data: any) {
    let user = JSON.stringify(data);
    localStorage.setItem('user', user);
    this.router.navigate(['/pages/home']);
    this.ngxSpinnerService.hide();
  }

  runError(exception: any): void {
    console.error(exception);
    this.ngxSpinnerService.hide();
    if (exception.error.errors == 'email is invalid!') {
      this.translateService.get('TOASTR.INVALID_EMAIL').subscribe({
        next: data => {
          this.toastrService.error(data, undefined, { progressBar: true, });
        }
      });
    } else if (exception.error.errors == 'Email and/or password are invalid!') {
      this.translateService.get('TOASTR.INVALID_EMAIL_OR_PASSWORD').subscribe({
        next: data => {
          this.toastrService.error(data, undefined, { progressBar: true, });
        }
      });
    } else {
      this.translateService.get('TOASTR.ERROR_LOGIN').subscribe({
        next: data => {
          this.toastrService.error(data, undefined, { progressBar: true, });
        }
      });
    }
  }
}
