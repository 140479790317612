import { Injectable } from "@angular/core";
import { ReplaySubject } from "rxjs";
import { Socket } from "socket.io-client";
import { BaseSocketService } from "./base.socket.service";
import { TableGameJoinResponseDto } from "src/app/dtos/socket/table-game-join-response.dto";
import { SocketDto } from "src/app/dtos/socket/socket.dto";
import { GameGatewayEventsEnum } from "src/app/dtos/enum/game-gateway-events.enum";
import { TableGameJoinRequestDto } from "src/app/dtos/socket/table-game-join-request.dto";
import { ResponseDto } from "src/app/dtos/response.dto";
import { environment } from "src/environments/environment";
import { TableGamePlayerActionRequestDto } from "src/app/dtos/table-game/table-game-player-action-request.dto";
import { TableGameLeaveRequestDto } from "src/app/dtos/table-game/table-game-leave-request.dto";
import { AnimationActionResponseDto } from "../../app/dtos/table-game/animation-action-response.dto";
import { AnimationGiveChipsToWinnersResponseDto } from "src/app/dtos/table-game/animation-give-chips-to-winners-response.dto";
import { AnimationShowCardsAtShowdownDto } from "src/app/dtos/table-game/animation-show-cards-at-showdown.dto";
import { AnimationTimerCurrentPlayerDto } from "src/app/dtos/table-game/animation-timer-current-player.dto";
import { AnimationShowActionsDto } from "src/app/dtos/table-game/animation-show-actions.dto";
import { TableGameBuyCoinRequestDto } from "src/app/dtos/table-game/table-game-buy-coin-request.dto";
import { AnimationShowFullPotDto } from "src/app/dtos/table-game/animation-show-full-pot.dto";
import { SitAtTableGameRequestDto } from "src/app/dtos/table-game/sit-at-table-game-request.dto";
import { TableGamePlayerAbsenceRequestDto } from "src/app/dtos/table-game/table-game-player-absence-request.dto";
import { TableGamePlayerPresenceRequestDto } from "src/app/dtos/table-game/table-game-player-presence-request.dto";
import { TableGamePayBigBlindRequestDto } from "src/app/dtos/table-game/table-game-pay-big-blind-request.dto";
import { AnimationTimeBankCurrentPlayerDto } from "src/app/dtos/table-game/animation-time-bank-current-player.dto";

@Injectable({
  providedIn: "root",
})
export class GameGatewaySocketService extends BaseSocketService {

  tableGameJoinResponse = new ReplaySubject<TableGameJoinResponseDto>();
  tableGameLeaveResponse = new ReplaySubject<ResponseDto>();
  animationActionResponse = new ReplaySubject<AnimationActionResponseDto>();
  animationGiveChipsToWinners = new ReplaySubject<AnimationGiveChipsToWinnersResponseDto>();
  animationSetCardsToPlayers = new ReplaySubject<TableGameJoinResponseDto>();
  animationSetChipsToPot = new ReplaySubject<TableGameJoinResponseDto>();
  animationShowCardsAtShowdown = new ReplaySubject<AnimationShowCardsAtShowdownDto>();
  animationTimerCurrentPlayer = new ReplaySubject<AnimationTimerCurrentPlayerDto>();
  animationShowActions = new ReplaySubject<AnimationShowActionsDto>();
  animationShowFullPot = new ReplaySubject<AnimationShowFullPotDto>();
  tableGameBuyCoinResponse = new ReplaySubject<ResponseDto>();
  animationBankTimeCurrentPlayer = new ReplaySubject<AnimationTimeBankCurrentPlayerDto>();


  public _socket: Socket | undefined;

  constructor(
  ) {
    super(
      new SocketDto(
        environment.apis.gameBackoffice,
        GameGatewayEventsEnum.PATH.toString(),
      ));
  }

  initializeMain(): void {
    this._socket = this.initializeCommonRoutine(this._socket!);
  }

  requestJoin(tableGameId: string) {

    this._socket!.emit(
      GameGatewayEventsEnum.REQUEST_JOIN_TABLE_GAME,
      new TableGameJoinRequestDto(tableGameId),
    );
    this._socket!.on(GameGatewayEventsEnum.RESPONSE_JOIN_TABLE_GAME, (response: ResponseDto) => {
      if (response.success)
        this.tableGameJoinResponse.next(response.data);
    });
    this._socket!.on(GameGatewayEventsEnum.RESPONSE_START_TABLE_GAME, (response: ResponseDto) => {
      if (response.success)
        this.tableGameJoinResponse.next(response.data);
    });
    this._socket!.on(GameGatewayEventsEnum.RESPONSE_PLAYER_ACTION, (response: ResponseDto) => {
      if (response.success)
        this.tableGameJoinResponse.next(response.data);
    });
  }

  requestSitAtTableGame(tableGameId: string, positionInArrayOfPlayers: number, buyIn: number) {
    this._socket!.emit(
      GameGatewayEventsEnum.REQUEST_SIT_AT_TABLE_GAME,
      new SitAtTableGameRequestDto(tableGameId, positionInArrayOfPlayers, buyIn),
    );
    this._socket!.on(GameGatewayEventsEnum.RESPONSE_JOIN_TABLE_GAME, (response: ResponseDto) => {
      if (response.success)
        this.tableGameJoinResponse.next(response.data);
    });
  }

  requestBuyCoin(tableGameId: string, buyCoinValue: number) {
    this._socket!.emit(
      GameGatewayEventsEnum.REQUEST_BUY_COIN,
      new TableGameBuyCoinRequestDto(tableGameId, buyCoinValue),
    );
    this._socket!.on(GameGatewayEventsEnum.RESPONSE_JOIN_TABLE_GAME, (response: ResponseDto) => {
      this.tableGameBuyCoinResponse.next(response);
    });
  }

  requestPlayerPresence(tableGameId: string) {
    this._socket!.emit(
      GameGatewayEventsEnum.REQUEST_PLAYER_PRESENCE,
      new TableGamePlayerPresenceRequestDto(tableGameId),
    );
    this._socket!.on(GameGatewayEventsEnum.RESPONSE_JOIN_TABLE_GAME, (response: ResponseDto) => {
      this.tableGameBuyCoinResponse.next(response);
    });
  }

  requestPayBigBlind(tableGameId: string) {
    this._socket!.emit(
      GameGatewayEventsEnum.REQUEST_PAY_BIG_BLIND,
      new TableGamePayBigBlindRequestDto(tableGameId),
    );
    this._socket!.on(GameGatewayEventsEnum.RESPONSE_JOIN_TABLE_GAME, (response: ResponseDto) => {
      this.tableGameBuyCoinResponse.next(response);
    });
  }

  playerAction(dto: TableGamePlayerActionRequestDto) {

    this._socket!.emit(GameGatewayEventsEnum.REQUEST_PLAYER_ACTION, dto);
    this._socket!.on(GameGatewayEventsEnum.RESPONSE_PLAYER_ACTION, (response: ResponseDto) => {
      if (response.success)
        this.tableGameJoinResponse.next(response.data);
    });


  }

  shutDownAll(): void {
    if (!!this._socket && this._socket.connected) {
      this._socket.offAny()
      this._socket.removeAllListeners();
      this._socket.close();
      this._socket.disconnect();
    }
  }

  leaveGame(dto: TableGameLeaveRequestDto) {
    this._socket!.emit(GameGatewayEventsEnum.REQUEST_LEAVE_GAME, dto);
    this._socket!.on(GameGatewayEventsEnum.RESPONSE_LEAVE_GAME, (response: ResponseDto) => {
      this.tableGameLeaveResponse.next(response);
    });
  }

  requestSittingOut(dto: TableGameLeaveRequestDto) {
    this._socket!.emit(GameGatewayEventsEnum.REQUEST_PLAYER_ABSENCE, dto);
    this._socket!.on(GameGatewayEventsEnum.RESPONSE_PLAYER_ACTION, (response: ResponseDto) => {
      this.tableGameLeaveResponse.next(response);
    });
  }

  subscribeAnimationAction() {
    this._socket!.on(GameGatewayEventsEnum.RESPONSE_ANIMATION_ACTION, (response: ResponseDto) => {
      if (response.success)
        this.animationActionResponse.next(response.data);
    });
  }

  subscribeAnimationGiveChipsToWinners() {
    this._socket!.on(GameGatewayEventsEnum.RESPONSE_ANIMATION_GIVE_CHIPS_TO_WINNERS, (response: ResponseDto) => {
      if (response.success)
        this.animationGiveChipsToWinners.next(response.data);
    });
  }

  subscribeAnimationSetCardsToPlayers() {
    this._socket!.on(GameGatewayEventsEnum.RESPONSE_ANIMATION_SET_CARDS_TO_PLAYERS, (response: ResponseDto) => {
      if (response.success)
        this.animationSetCardsToPlayers.next(response.data);
    });
  }

  subscribeAnimationSetChipsToPot() {
    this._socket!.on(GameGatewayEventsEnum.RESPONSE_ANIMATION_SET_CHIPS_TO_POT, (response: ResponseDto) => {
      if (response.success)
        this.animationSetChipsToPot.next(response.data);
    });
  }

  subscribeAnimationShowCardsAtShowdown() {
    this._socket!.on(GameGatewayEventsEnum.RESPONSE_ANIMATION_SHOW_CARDS_AT_SHOWDOWN, (response: ResponseDto) => {
      if (response.success)
        this.animationShowCardsAtShowdown.next(response.data);
    });
  }

  subscribeAnimationTimerCurrentPlayer() {
    this._socket!.on(GameGatewayEventsEnum.RESPONSE_ANIMATION_TIMER_CURRENT_PLAYER, (response: ResponseDto) => {
      if (response.success)
        this.animationTimerCurrentPlayer.next(response.data);
    });
  }

  subscribeAnimationTimeBankCurrentPlayer() {
    this._socket!.on(GameGatewayEventsEnum.RESPONSE_ANIMATION_TIMEBANK_CURRENT_PLAYER, (response: ResponseDto) => {
      if (response.success)
        this.animationBankTimeCurrentPlayer.next(response.data);
    });
  }

  subscribeAnimationShowActions() {
    this._socket!.on(GameGatewayEventsEnum.RESPONSE_ANIMATION_SHOW_ACTIONS, (response: ResponseDto) => {
      if (response.success)
        this.animationShowActions.next(response.data);
    });
  }

  subscribeAnimationShowFullPot() {
    this._socket!.on(GameGatewayEventsEnum.RESPONSE_ANIMATION_SHOW_FULL_POT, (response: ResponseDto) => {
      if (response.success)
        this.animationShowFullPot.next(response.data);
    });
  }

}
