import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CashGameHandHisotry } from 'src/app/dtos/cash-game-history/cash-game-hand-history.interface';
import { PlayerHandActionEnum } from 'src/app/dtos/enum/player-hand-action.enum';
import { PlayerStatusEnum } from 'src/app/dtos/enum/player-status.enum';
import { GameCoinGetBalanceResponseDto } from 'src/app/dtos/game-coin/game-coin-get-balance-response.dto';
import { ResponseDto } from 'src/app/dtos/response.dto';
import { TableGameGetDetailsResponseDto } from 'src/app/dtos/socket/table-game-get-details-response.dto';
import { AnimationGiveChipsToWinnersResponseDto } from 'src/app/dtos/table-game/animation-give-chips-to-winners-response.dto';
import { AnimationSetChipsToPotResponseDto } from 'src/app/dtos/table-game/animation-set-chips-to-pot-response.dto';
import { AnimationShowFullPotDto } from 'src/app/dtos/table-game/animation-show-full-pot.dto';
import { AnimationTimerCurrentPlayerDto } from 'src/app/dtos/table-game/animation-timer-current-player.dto';
import { PlayerGetResponseDto } from 'src/app/dtos/table-game/player-get-response.dto';
import { TableGameRoundStatusEnum } from 'src/interface/table-game-round-status.enum';
import { TableGameStatusEnum } from 'src/interface/table-game-status.enum';
import { AuthService } from 'src/services/auth.service';
import { CashGameReplayService } from 'src/services/cash-game-replay.service';
import { GameCoinService } from 'src/services/game-coin.service';
import { CommonGatewaySocketService } from 'src/services/sockets/common-gateway.socket.service';
import { GameGatewaySocketService } from 'src/services/sockets/game-gateway.socket.service';
import colors from 'src/util/color.utils';

import { AnimationTimeBankCurrentPlayerDto } from 'src/app/dtos/table-game/animation-time-bank-current-player.dto';
import { TableGamePlayerActionRequestDto } from 'src/app/dtos/table-game/table-game-player-action-request.dto';
import getTipForHand from 'src/util/tip.utils';
import { TableGameService } from '../../../services/table-game.service';
import SoundUtil from '../../../util/sound.util';
import { JwtPayloadDto } from '../../dtos/auth/jwt-payload.dto';
import { AnimationActionResponseDto } from '../../dtos/table-game/animation-action-response.dto';
import { LocalStorageKeysEnum } from 'src/app/dtos/enum/local-storage-keys.enum';


@Component({
  selector: 'app-table-game',
  templateUrl: './table-game.component.html',
  styleUrls: ['./table-game.component.scss']
})
export class TableGameComponent implements OnInit, OnDestroy {

  controlPanel: string = 'chat';
  isCollapsed: boolean = false;
  loggedUser: JwtPayloadDto | undefined
  progressWidth!: number;
  form: FormGroup;
  formBuyIn: FormGroup;
  formBuyCoin: FormGroup;
  formCheckBoxs: FormGroup;
  formWaitBigBlind: FormGroup;
  buyInButtonDisable: boolean = false
  tableGame?: any;
  playerList: PlayerGetResponseDto[] = []
  playersInViewOrder: PlayerGetResponseDto[] = [];
  minRaiseValue = 0;
  joinQueue: boolean = false
  alreadyInQueue: boolean = false
  isUserPlaying: boolean = false
  user: any
  fullRoom: boolean = false
  showActionPlayer0: boolean = false;
  showActionPlayer1: boolean = false;
  showActionPlayer2: boolean = false;
  showActionPlayer3: boolean = false;
  showActionPlayer4: boolean = false;
  showActionPlayer5: boolean = false;
  showActionPlayer6: boolean = false;
  showActionPlayer7: boolean = false;
  showActionPlayer8: boolean = false;

  animationPlayerIndex: number | undefined;
  animationResponse: AnimationActionResponseDto | undefined;
  sitAtTableGameIndex: number = 0;

  buyCoinMin: Number = 0;
  buyCoinMax: Number = 0;

  showFullPot: boolean = false;

  arrayShowdownIndex = [false, false, false, false, false, false, false, false, false,];

  balanceValue?: GameCoinGetBalanceResponseDto;

  showValuesInBigBlindsFormat = false;

  currentTurn: string = '';
  previousRound: string = '';
  previousDealer: string = '';
  visualPots: any[] = [];
  animationGiveChipsToWinners?: AnimationGiveChipsToWinnersResponseDto;
  animationGiveChipsToWinnersPlayerWinner: number | undefined;
  AnimationGiveChipsToWinnersResponseDto?: number;
  modalRebuyIsOpen = false;
  isViewer: boolean = false
  canLeavePokerGame: boolean = true;
  isSelectVisible: boolean = false;
  selectedTab: string = 'chat';
  lang: any;
  tableId: string = ''
  handHistory: CashGameHandHisotry[] = []
  rgbModalStatus: boolean = false
  colorArray = colors
  playerToSetColor: PlayerGetResponseDto | null = null
  selectedColor: string = ''
  tableRound: number = 0
  showActionPlayer = Array(8).fill(false);
  timeCounterInSeconds = -1
  stopTimeBankCount = true
  selectedDeck: 'normal' | 'colored' = 'normal';
  intervalId: any;
  remainingTimeBank = -1
  lastTimeRecorded = 0

  automaticFold: TableGamePlayerActionRequestDto | null = null

  playerTip: string = ''
  playerWaitingListPosition: number = 0
  lastBet = 0
  hasRaised = false 
  playerHand:string[] = []
  timeBankMap: {id: string, value: number}[] = [];
  constructor(
    private formBuilder: FormBuilder,
    private readonly route: ActivatedRoute,
    private readonly authService: AuthService,
    private readonly _gameGateway: GameGatewaySocketService,
    private readonly _commonGateway: CommonGatewaySocketService,
    private toastrService: ToastrService,
    private gameCoinService: GameCoinService,
    private ngxSpinnerService: NgxSpinnerService,
    public translate: TranslateService,
    private readonly router: Router,
    private tableGameService: TableGameService,
    private cashGameReplayService: CashGameReplayService,

  ) {
    window.addEventListener('beforeunload', this.beforeUnloadListener);

    this.form = this.formBuilder.group({
      bet: [''],
      valueRange: [''],
      valueRangeBB: [''],
    });
    this.formBuyIn = this.formBuilder.group({
      buyIn: [''],
      valueRangeBuyIn: [''],
    });
    this.formBuyCoin = this.formBuilder.group({
      buyCoin: [''],
      valueRangeBuyCoin: [''],
    });

    this.formCheckBoxs = this.formBuilder.group({
      leaveGameCheckbox: [''],
      absenceCheckbox: [''],
      automaticFold: [false]
    });
    this.formWaitBigBlind = this.formBuilder.group({
      waitForBigBlind: [''],
    });
    translate.use(localStorage.getItem('lan') || 'en')
  }

  ngOnInit(): void {

    this._commonGateway.initializeMain();
    this._gameGateway.initializeMain();

    if (this.route.snapshot.params['viewer'] === 'viewer') {
      this.isViewer = true
    } else {
      this.isViewer = false
    }
    
    this._gameGateway.requestJoin(this.route.snapshot.params['id']);
    this.user = JSON.parse(localStorage.getItem('user') || '')
    
    this.route.params.subscribe(params => {
      this.tableId = params['id']
    })

    this.sitAtTable()


    this._gameGateway.subscribeAnimationShowCardsAtShowdown()
    this._gameGateway.animationShowCardsAtShowdown.subscribe({
      next: (response: any) => {
        this.arrayShowdownIndex = [];
        let indexInit = this.tableGame?.players.findIndex((a: any) => a?.user?.email == this.authService.getAuthenticatedUser().email);
        if (indexInit !== undefined && indexInit > -1) {
          for (let i = indexInit; i < this.tableGame!.players.length; i++) {
            this.arrayShowdownIndex.push(response[`showdownIndex${i}`]);
          }
          for (let i = 0; i < indexInit; i++) {
            this.arrayShowdownIndex.push(response[`showdownIndex${i}`]);
          }
        } else {
          for (let i = 0; i < this.tableGame!.players.length; i++) {
            this.arrayShowdownIndex.push(response[`showdownIndex${i}`]);
          }
        }
      }
    });

    this._gameGateway.subscribeAnimationSetCardsToPlayers()
    this._gameGateway.animationSetCardsToPlayers.subscribe({
      next: (response: any) => {
        let amimationPlayersInViewOrder = [];
        if (response) {
          let indexInit = response.players.findIndex((a: any) => a?.user?.email == this.authService.getAuthenticatedUser().email);
          if (indexInit > -1) {
            for (let i = indexInit; i < response.players.length; i++) {
              amimationPlayersInViewOrder.push(response.players[i]);
            }
            for (let i = 0; i < indexInit; i++) {
              amimationPlayersInViewOrder.push(response.players[i]);
            }
          } else {
            amimationPlayersInViewOrder = response.players;

          }
        }
        this.animationSetCardsToPlayers(amimationPlayersInViewOrder);
      }
    });

    this._gameGateway.subscribeAnimationAction();
    this._gameGateway.animationActionResponse.subscribe({
      next: (response: AnimationActionResponseDto) => {
        this.animationResponse = response;
   
        this.showPlayerAction();
      }
    });

    this._gameGateway.subscribeAnimationSetChipsToPot()
    this._gameGateway.animationSetChipsToPot.subscribe({
      next: (response: any) => {
        this.animationSetChipsToPot(response);
      }
    });

    this._gameGateway.subscribeAnimationTimerCurrentPlayer()
    this._gameGateway.animationTimerCurrentPlayer.subscribe({
      next: (response: AnimationTimerCurrentPlayerDto) => {
        this.calcHigherBet()
        if (this.automaticFold !== null && this.tableGame?.currentTurn == this.playersInViewOrder[0]?.user?._id && !this.canCheck()) {
          this.fold()
        }

        this.progressWidth = response.timer;
        if (response.timer <= 0) {
          this.progressWidth = 0
        } 

        if (this.progressWidth == 25 && this.tableGame) {
          let indexInit = this.tableGame.players.findIndex((a: any) => a?.user?.email == this.authService.getAuthenticatedUser().email);
          if (this.tableGame.players[indexInit]?.user?._id! == this.tableGame.currentTurn) {
            SoundUtil.play('../../assets/sounds/time-running-out.wav', 1, 0.1);
          }
        }
      }
    });

    this._gameGateway.subscribeAnimationTimeBankCurrentPlayer();

    this._gameGateway.animationBankTimeCurrentPlayer.subscribe({
      next: (response: AnimationTimeBankCurrentPlayerDto) => {
        this.timeBankMap = response.timer 
        let timeValue = response.timer.find((item) => item.id === (this.tableGame._id.toString()+ '/'+this.playersInViewOrder[0]?.user?._id?.toString()!))
        
        this.timeCounterInSeconds = timeValue ? timeValue.value : 0
        


      }
    })

    this._gameGateway.subscribeAnimationShowFullPot()
    this._gameGateway.animationShowFullPot.subscribe({
      next: (response: AnimationShowFullPotDto) => {
        // this.showFullPot = response.showFullPot;
      }
    });

    this._gameGateway.subscribeAnimationGiveChipsToWinners()
    this._gameGateway.animationGiveChipsToWinners.subscribe({
      next: (response: AnimationGiveChipsToWinnersResponseDto) => {
        this.animationGiveChipsToWinners = response;
        this.tableRound = response.tableRound
        this.formCheckBoxs.controls['automaticFold'].setValue(false)
        this.automaticFold = null
        this.hasRaised = false
        this.playerHand = []
        this.showAnimationGiveChipsToWinners(response);
      }
    });


    this.lang = localStorage.getItem('lan')
    window.addEventListener('storage', this.handleStorageEvent.bind(this));

    const savedDeck = localStorage.getItem('selectedDeck');
    if (savedDeck === 'normal' || savedDeck === 'colored') {
      this.selectedDeck = savedDeck;
    }

  }
  handleStorageEvent(event: StorageEvent) {
    if (event.key === 'pokerQueueStatus') {
      const queueStatus = JSON.parse(event.newValue || '');
      if (queueStatus.tableGameId === this.tableGame._id && !queueStatus.inQueue) {
        this.alreadyInQueue = false
      }
    }
  }
  @HostListener('document:keydown.enter', ['$event'])
  handleEnterKey(event: KeyboardEvent) {
    if (this.canRaise() && this.tableGame?.showActions && this.tableGame?.currentTurn == this.playersInViewOrder[0]?.user?._id && this.isMainPlayer()) {
      this.raise();
    } else if (this.canAllIn() && this.tableGame?.showActions && this.tableGame?.currentTurn == this.playersInViewOrder[0]?.user?._id && this.isMainPlayer()) {
      this.allIn();
    }
  }

  beforeUnloadListener = (event: BeforeUnloadEvent) => {
    this.onWindowClose();
    event.preventDefault();
  };

  playTableReplay(event: any) {

    window.open(`/pages/tablegame-replay/id/${this.tableGame._id}/${this.user._id}/${event.target.value}`, '_blank');
  }

  getPlayerBorderColor(player: any):  string | void {

    if (this.user && this.user.notes) {
     const user = JSON.parse(localStorage.getItem(LocalStorageKeysEnum.user) || '') 
      const note = user.notes.find((note: any) => note.user === player.user?._id);
      if (note) {
        return `2px solid ${note.color} `;
      }
    }
    return player.color ? `2px solid ${player.color}` : '';
  }
  // fromViewerToPlayer() {
  //   this.isViewer = false
  //   this._commonGateway.requestTableUpdateView(this.route.snapshot.params['id'], 'left');
  //   //this.sitAtTable()
  //   const currentUrl = this.router.url;
  //   const newUrl = currentUrl.replace('/viewer', '');
  //   this.router.navigateByUrl(newUrl);
  // }

  sitAtTable() {
    this._gameGateway.tableGameJoinResponse.subscribe({
      next: (response: any) => {
        this.tableGame = response;
        if (this.tableGame.timeBank > 0) {
          if (this.timeBankMap.length === 0) {
            this.timeCounterInSeconds = this.tableGame.timeBank          

          } else {
            this.timeCounterInSeconds = this.timeBankMap.find((item) => item.id === `${this.tableGame._id.toString()}/${this.user._id.toString()}`)?.value!
          }
        }

        this.playerList = this.tableGame.players.filter((item: PlayerGetResponseDto) => item.user !== null)
        let emptySeat = 0
        this.tableGame.players.forEach((item: any) => {
          item.user === null ? emptySeat += 1 : emptySeat
        })

        if (emptySeat > 0) {
          this.fullRoom = false
        } else {
          this.fullRoom = true
        }
        this.tableGame.waitingList.forEach((item: any) => {
          if (item?.user._id === this.user._id || item?.user === this.user._id) {

            this.alreadyInQueue = true
          } else {
            this.alreadyInQueue = false
          }
        })
        let found = false
        this.tableGame.players.forEach((item: any) => {
          if (item?.user?._id === this.user._id) {

            found = true
            return this.isUserPlaying = true
          } else {
            if (!found) {

              return this.isUserPlaying = false
            } else {
              return this.isUserPlaying = true
            }
          }
        })

        if (this.previousRound === TableGameRoundStatusEnum.PRE_FLOP && this.tableGame?.round === TableGameRoundStatusEnum.FLOP) {
          this.playerTip = getTipForHand(this.playerHand, [this.tableGame?.flopCards[0], this.tableGame?.flopCards[1], this.tableGame?.flopCards[2]])

          SoundUtil.play('../../assets/sounds/flop.m4a', 2);
        } else if (this.previousRound === TableGameRoundStatusEnum.FLOP && this.tableGame?.round === TableGameRoundStatusEnum.TURN ||
          this.previousRound === TableGameRoundStatusEnum.TURN && this.tableGame?.round === TableGameRoundStatusEnum.RIVER) {
           if(this.tableGame?.flopCards?.length && (this.tableGame?.round == 'TURN' || this.tableGame?.round == 'RIVER'  || this.tableGame?.round == 'SHOWDOWN')) {
            this.playerTip = getTipForHand(this.playerHand, [this.tableGame?.flopCards[0], this.tableGame?.flopCards[1], this.tableGame?.flopCards[2], this.tableGame?.flopCards[3]])

           }
           if(this.tableGame?.flopCards?.length && (this.tableGame?.round == 'RIVER'  || this.tableGame?.round == 'SHOWDOWN')) {
            this.playerTip = getTipForHand(this.playerHand,[this.tableGame?.flopCards[0], this.tableGame?.flopCards[1], this.tableGame?.flopCards[2], this.tableGame?.flopCards[3],this.tableGame?.flopCards[4]])

           }
            
          SoundUtil.play('../../assets/sounds/turn-river.m4a', 2);
        }

        if (this.currentTurn !== this.tableGame?.currentTurn || this.previousRound !== this.tableGame?.round || this.previousDealer !== this.tableGame.dealerTurn) {
          this.currentTurn = this.tableGame?.currentTurn!;
          // this.showIndividualPot = true;
          if (this.tableGame) {
            let indexInit = this.tableGame.players.findIndex((a: any) => a?.user?.email == this.authService.getAuthenticatedUser().email);
            if (this.tableGame?.players[indexInit]?.user?._id! == this.tableGame.currentTurn && !this.tableGame.inResolveGame) {
              SoundUtil.play('../../assets/sounds/your-turn.wav', 1, 0.1);
            }
          }
        }

        this.previousDealer = this.tableGame?.dealerTurn!;
        this.previousRound = this.tableGame?.round!;

        this.playersInViewOrder = [];
        if (this.tableGame) {
          // this.createPots();

          this.visualPots = this.tableGame.visualPots;

          let indexInit = this.tableGame.players.findIndex((a: any) => a?.user?.email == this.authService.getAuthenticatedUser().email);
          if (indexInit > -1) {
            for (let i = indexInit; i < this.tableGame.players.length; i++) {
              this.playersInViewOrder.push(this.tableGame.players[i]);
            }
            for (let i = 0; i < indexInit; i++) {
              this.playersInViewOrder.push(this.tableGame.players[i]);
            }

            this.getMinRaise();
            this.form.patchValue({
              bet: this.minRaiseValue,
              valueRange: this.minRaiseValue,
              valueRangeBB: this.minRaiseValue / this.tableGame.bigBlindValue
            });

          } else {
            this.playersInViewOrder = this.tableGame.players;

          }

          if (this.playersInViewOrder.length > 0 && this.user.notes) {
            this.playersInViewOrder.forEach((jogador) => {
              const index = this.user.notes.findIndex((item: any) => item.user === jogador.user?._id?.toString())
              if (index !== -1) {
                jogador.color = this.user.notes[index].color
              }
            })
          }

          let playerIndex = this.tableGame!.players.findIndex((a: any) => a?.user?.email == this.authService.getAuthenticatedUser().email);

          this.formCheckBoxs.patchValue({
            leaveGameCheckbox: this.tableGame?.players[playerIndex]?.requestLeaveGame,
            absenceCheckbox: this.tableGame?.players[playerIndex]?.requestSittingOut,
          });
          if (this.isViewer === false) {

            if (this.tableGame?.players[playerIndex]?.status == PlayerStatusEnum.rebuy && !this.tableGame?.startGameLoading && this.tableGame?.round == TableGameRoundStatusEnum.SHOWDOWN && this.tableGame?.tableStatus == TableGameStatusEnum.WAITING && !this.modalRebuyIsOpen || this.tableGame?.players[playerIndex]?.status == PlayerStatusEnum.rebuy && !this.tableGame?.startGameLoading && this.tableGame?.round == TableGameRoundStatusEnum.PRE_FLOP && this.tableGame?.tableStatus == TableGameStatusEnum.WAITING && this.tableGame?.players[playerIndex]?.rebuy?.value == 0 && !this.modalRebuyIsOpen) {
              document.getElementById('btn-open-modal-rebuy')?.click();
              this.modalRebuyIsOpen = true;
              setTimeout(() => {
                this.modalRebuyIsOpen = false;
                document.getElementById('cancelBuyCoin')?.click();
              }, 30000);
            } else {
              if (this.tableGame?.startGameLoading && !this.modalRebuyIsOpen) {
                document.getElementById('cancelBuyIn')?.click();
                document.getElementById('cancelBuyCoin')?.click();
              }
            }

          }


        }
      }
    });

  }

  selectPlayerColor(playerPosition: number) {
    this.rgbModalStatus = true;
    this.playerToSetColor = this.playersInViewOrder[playerPosition]
    this.playerToSetColor.color = this.selectedColor
    const selectElement = document.getElementById('option3') as HTMLElement;
    selectElement.click()

  }

  selectColor(hexCode: string) {
    this.rgbModalStatus = false;
    if (this.playerToSetColor) {
      this.playerToSetColor.color = hexCode
    }
    this.playersInViewOrder.forEach((jogador) => {
      if (jogador.user?._id === this.playerToSetColor?.user?._id) {
        jogador.color = hexCode

      }
    })
    this.selectedColor = hexCode

  }

  onWindowClose() {

    if (this.isViewer) {
      this._commonGateway.requestTableUpdateView(this.route.snapshot.params['id'], 'left');
    }
    if (this.alreadyInQueue === true) {
      this._commonGateway.removeFromWaitingList(this.tableGame._id, this.user._id!, 'out', 0);
      this._commonGateway.requestTableGameList()
    }
  }

  ngOnDestroy() {

    window.removeEventListener('beforeunload', this.beforeUnloadListener);
  }

  setLeaveRequestStatus(event: any) {
    const opt = event.target.value

    this._commonGateway.updateLeaveGameOfPlayerByIndex(this.route.snapshot.params['id'], this.user!._id, opt);
  }

  changeShowValuesInBigBlindsFormat() {
    this.showValuesInBigBlindsFormat = !this.showValuesInBigBlindsFormat;
  }

  sitAtTableGame() {
    let valueToFixed = Number(Number(this.formBuyIn.controls['buyIn'].value).toFixed(2));

    if (valueToFixed < this.tableGame?.buyInMinimum!) {
      this.toastrService.error(`Valor mínimo é de ${this.tableGame?.buyInMinimum} ${this.tableGame?.coin}!`, '', { progressBar: true });
      return;
    }
    if (this.tableGame?.coin === 'USDT') {
      if (this.balanceValue?.coinUsdValue! < valueToFixed) {
        this.toastrService.error('Saldo insuficiente!', '', { progressBar: true });
        return;
      }
    }
    if (this.tableGame?.coin === 'POKERFI') {
      if (this.balanceValue?.coinPokerFiValue! < valueToFixed) {
        this.toastrService.error('Saldo insuficiente!', '', { progressBar: true });
        return;
      }
    }
    this._gameGateway.requestSitAtTableGame(this.route.snapshot.params['id'], this.sitAtTableGameIndex, valueToFixed);
    document.getElementById('cancelBuyIn')?.click();
    // retira usuario do viewerlist
    if (this.isViewer) {
      this._commonGateway.requestTableUpdateView(this.route.snapshot.params['id'], 'left');
      this.isViewer = false
    }


  }

  playerInTableGame() {
    if (this.tableGame) {
      let indexInit = this.tableGame.players.findIndex((a: any) => a?.user?.email == this.authService.getAuthenticatedUser().email);
      if (indexInit > -1) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  betByPlayer(index: number) {
    let bet = 0;
    if (this.playersInViewOrder[index]?.playerHand) {
      for (const action of this.playersInViewOrder[index]?.playerHand!.actions) {
        if (this.tableGame?.round == TableGameRoundStatusEnum.PRE_FLOP) {
          if (action.round == TableGameRoundStatusEnum.PRE_FLOP || action.round == TableGameRoundStatusEnum.BLIND) {
            bet += action.bet
          }
        } else {
          if (action.round == this.tableGame?.round) {
            bet += action.bet
          }
        }
      }

    }
    return Number(bet.toFixed(2));
  }

  animacao() {

    //----------------------------------- Animação das cartas indo para os jogadores no inicio da rodada -----------------------------------//

    // let animationDivSetCardsToPlayer0 = document.getElementById('animationDivSetCardsToPlayer0');
    // animationDivSetCardsToPlayer0?.classList.add('startAnimationDivSetCardsToPlayer0');
    // setTimeout(() => {
    //   animationDivSetCardsToPlayer0?.classList.remove('startAnimationDivSetCardsToPlayer0');
    // }, 1000);

    // let animationDivSetCardsToPlayer1 = document.getElementById('animationDivSetCardsToPlayer1');
    // animationDivSetCardsToPlayer1?.classList.add('startAnimationDivSetCardsToPlayer1');
    // setTimeout(() => {
    //   animationDivSetCardsToPlayer1?.classList.remove('startAnimationDivSetCardsToPlayer1');
    // }, 1000);

    // let animationDivSetCardsToPlayer2 = document.getElementById('animationDivSetCardsToPlayer2');
    // animationDivSetCardsToPlayer2?.classList.add('startAnimationDivSetCardsToPlayer2');
    // setTimeout(() => {
    //   animationDivSetCardsToPlayer2?.classList.remove('startAnimationDivSetCardsToPlayer2');
    // }, 1000);

    // let animationDivSetCardsToPlayer3 = document.getElementById('animationDivSetCardsToPlayer3');
    // animationDivSetCardsToPlayer3?.classList.add('startAnimationDivSetCardsToPlayer3');
    // setTimeout(() => {
    //   animationDivSetCardsToPlayer3?.classList.remove('startAnimationDivSetCardsToPlayer3');
    // }, 1000);

    // let animationDivSetCardsToPlayer4 = document.getElementById('animationDivSetCardsToPlayer4');
    // animationDivSetCardsToPlayer4?.classList.add('startAnimationDivSetCardsToPlayer4');
    // setTimeout(() => {
    //   animationDivSetCardsToPlayer4?.classList.remove('startAnimationDivSetCardsToPlayer4');
    // }, 1000);

    // let animationDivSetCardsToPlayer5 = document.getElementById('animationDivSetCardsToPlayer5');
    // animationDivSetCardsToPlayer5?.classList.add('startAnimationDivSetCardsToPlayer5');
    // setTimeout(() => {
    //   animationDivSetCardsToPlayer5?.classList.remove('startAnimationDivSetCardsToPlayer5');
    // }, 1000);

    // let animationDivSetCardsToPlayer6 = document.getElementById('animationDivSetCardsToPlayer6');
    // animationDivSetCardsToPlayer6?.classList.add('startAnimationDivSetCardsToPlayer6');
    // setTimeout(() => {
    //   animationDivSetCardsToPlayer6?.classList.remove('startAnimationDivSetCardsToPlayer6');
    // }, 1000);

    // let animationDivSetCardsToPlayer7 = document.getElementById('animationDivSetCardsToPlayer7');
    // animationDivSetCardsToPlayer7?.classList.add('startAnimationDivSetCardsToPlayer7');
    // setTimeout(() => {
    //   animationDivSetCardsToPlayer7?.classList.remove('startAnimationDivSetCardsToPlayer7');
    // }, 1000);

    // let animationDivSetCardsToPlayer8 = document.getElementById('animationDivSetCardsToPlayer8');
    // animationDivSetCardsToPlayer8?.classList.add('startAnimationDivSetCardsToPlayer8');
    // setTimeout(() => {
    //   animationDivSetCardsToPlayer8?.classList.remove('startAnimationDivSetCardsToPlayer8');
    // }, 1000);


    //----------------------------------- Animação das cartas quando o jogador da FOLD -----------------------------------//


    // let animationDivSetCardsToTable0 = document.getElementById('animationDivSetCardsToTable0');
    // animationDivSetCardsToTable0?.classList.add('startAnimationDivSetCardsToTable0');
    // setTimeout(() => {
    //   animationDivSetCardsToTable0?.classList.remove('startAnimationDivSetCardsToTable0');
    // }, 1000);

    // let animationDivSetCardsToTable1 = document.getElementById('animationDivSetCardsToTable1');
    // animationDivSetCardsToTable1?.classList.add('startAnimationDivSetCardsToTable1');
    // setTimeout(() => {
    //   animationDivSetCardsToTable1?.classList.remove('startAnimationDivSetCardsToTable1');
    // }, 1000);

    // let animationDivSetCardsToTable2 = document.getElementById('animationDivSetCardsToTable2');
    // animationDivSetCardsToTable2?.classList.add('startAnimationDivSetCardsToTable2');
    // setTimeout(() => {
    //   animationDivSetCardsToTable2?.classList.remove('startAnimationDivSetCardsToTable2');
    // }, 1000);

    // let animationDivSetCardsToTable3 = document.getElementById('animationDivSetCardsToTable3');
    // animationDivSetCardsToTable3?.classList.add('startAnimationDivSetCardsToTable3');
    // setTimeout(() => {
    //   animationDivSetCardsToTable3?.classList.remove('startAnimationDivSetCardsToTable3');
    // }, 1000);

    // let animationDivSetCardsToTable4 = document.getElementById('animationDivSetCardsToTable4');
    // animationDivSetCardsToTable4?.classList.add('startAnimationDivSetCardsToTable4');
    // setTimeout(() => {
    //   animationDivSetCardsToTable4?.classList.remove('startAnimationDivSetCardsToTable4');
    // }, 1000);

    // let animationDivSetCardsToTable5 = document.getElementById('animationDivSetCardsToTable5');
    // animationDivSetCardsToTable5?.classList.add('startAnimationDivSetCardsToTable5');
    // setTimeout(() => {
    //   animationDivSetCardsToTable5?.classList.remove('startAnimationDivSetCardsToTable5');
    // }, 1000);

    // let animationDivSetCardsToTable6 = document.getElementById('animationDivSetCardsToTable6');
    // animationDivSetCardsToTable6?.classList.add('startAnimationDivSetCardsToTable6');
    // setTimeout(() => {
    //   animationDivSetCardsToTable6?.classList.remove('startAnimationDivSetCardsToTable6');
    // }, 1000);

    // let animationDivSetCardsToTable7 = document.getElementById('animationDivSetCardsToTable7');
    // animationDivSetCardsToTable7?.classList.add('startAnimationDivSetCardsToTable7');
    // setTimeout(() => {
    //   animationDivSetCardsToTable7?.classList.remove('startAnimationDivSetCardsToTable7');
    // }, 1000);

    // let animationDivSetCardsToTable8 = document.getElementById('animationDivSetCardsToTable8');
    // animationDivSetCardsToTable8?.classList.add('startAnimationDivSetCardsToTable8');
    // setTimeout(() => {
    //   animationDivSetCardsToTable8?.classList.remove('startAnimationDivSetCardsToTable8');
    // }, 1000);

    //----------------------------------- Animação (BET CALL RAISE E ALLIN) das fichas saindo do jogador para a mesa -----------------------------------//

    // let animationChipsFromHandToTableIndex0 = document.getElementById('animationChipsFromHandToTableIndex0');
    // animationChipsFromHandToTableIndex0?.classList.add('startAnimationChipsFromHandToTableIndex0');
    // setTimeout(() => {
    //   animationChipsFromHandToTableIndex0?.classList.remove('startAnimationChipsFromHandToTableIndex0');
    // }, 1000);

    // let animationChipsFromHandToTableIndex1 = document.getElementById('animationChipsFromHandToTableIndex1');
    // animationChipsFromHandToTableIndex1?.classList.add('startAnimationChipsFromHandToTableIndex1');
    // setTimeout(() => {
    //   animationChipsFromHandToTableIndex1?.classList.remove('startAnimationChipsFromHandToTableIndex1');
    // }, 1000);

    // let animationChipsFromHandToTableIndex2 = document.getElementById('animationChipsFromHandToTableIndex2');
    // animationChipsFromHandToTableIndex2?.classList.add('startAnimationChipsFromHandToTableIndex2');
    // setTimeout(() => {
    //   animationChipsFromHandToTableIndex2?.classList.remove('startAnimationChipsFromHandToTableIndex2');
    // }, 1000);

    // let animationChipsFromHandToTableIndex3 = document.getElementById('animationChipsFromHandToTableIndex3');
    // animationChipsFromHandToTableIndex3?.classList.add('startAnimationChipsFromHandToTableIndex3');
    // setTimeout(() => {
    //   animationChipsFromHandToTableIndex3?.classList.remove('startAnimationChipsFromHandToTableIndex3');
    // }, 1000);

    // let animationChipsFromHandToTableIndex4 = document.getElementById('animationChipsFromHandToTableIndex4');
    // animationChipsFromHandToTableIndex4?.classList.add('startAnimationChipsFromHandToTableIndex4');
    // setTimeout(() => {
    //   animationChipsFromHandToTableIndex4?.classList.remove('startAnimationChipsFromHandToTableIndex4');
    // }, 1000);

    // let animationChipsFromHandToTableIndex5 = document.getElementById('animationChipsFromHandToTableIndex5');
    // animationChipsFromHandToTableIndex5?.classList.add('startAnimationChipsFromHandToTableIndex5');
    // setTimeout(() => {
    //   animationChipsFromHandToTableIndex5?.classList.remove('startAnimationChipsFromHandToTableIndex5');
    // }, 1000);

    // let animationChipsFromHandToTableIndex6 = document.getElementById('animationChipsFromHandToTableIndex6');
    // animationChipsFromHandToTableIndex6?.classList.add('startAnimationChipsFromHandToTableIndex6');
    // setTimeout(() => {
    //   animationChipsFromHandToTableIndex6?.classList.remove('startAnimationChipsFromHandToTableIndex6');
    // }, 1000);

    // let animationChipsFromHandToTableIndex7 = document.getElementById('animationChipsFromHandToTableIndex7');
    // animationChipsFromHandToTableIndex7?.classList.add('startAnimationChipsFromHandToTableIndex7');
    // setTimeout(() => {
    //   animationChipsFromHandToTableIndex7?.classList.remove('startAnimationChipsFromHandToTableIndex7');
    // }, 1000);

    // let animationChipsFromHandToTableIndex8 = document.getElementById('animationChipsFromHandToTableIndex8');
    // animationChipsFromHandToTableIndex8?.classList.add('startAnimationChipsFromHandToTableIndex8');
    // setTimeout(() => {
    //   animationChipsFromHandToTableIndex8?.classList.remove('startAnimationChipsFromHandToTableIndex8');
    // }, 1000);

    //----------------------------------- Animação das fichas saindo da frente do jogador para a pot visual 0 -----------------------------------//

    // let animationChipsGoingToVisualPot0Index0 = document.getElementById('animationChipsGoingToVisualPot0Index0');
    // animationChipsGoingToVisualPot0Index0?.classList.add('startAnimationChipsGoingToVisualPot0Index0');
    // setTimeout(() => {
    //   animationChipsGoingToVisualPot0Index0?.classList.remove('startAnimationChipsGoingToVisualPot0Index0');
    // }, 1000);

    // let animationChipsGoingToVisualPot0Index1 = document.getElementById('animationChipsGoingToVisualPot0Index1');
    // animationChipsGoingToVisualPot0Index1?.classList.add('startAnimationChipsGoingToVisualPot0Index1');
    // setTimeout(() => {
    //   animationChipsGoingToVisualPot0Index1?.classList.remove('startAnimationChipsGoingToVisualPot0Index1');
    // }, 1000);

    // let animationChipsGoingToVisualPot0Index2 = document.getElementById('animationChipsGoingToVisualPot0Index2');
    // animationChipsGoingToVisualPot0Index2?.classList.add('startAnimationChipsGoingToVisualPot0Index2');
    // setTimeout(() => {
    //   animationChipsGoingToVisualPot0Index2?.classList.remove('startAnimationChipsGoingToVisualPot0Index2');
    // }, 1000);

    // let animationChipsGoingToVisualPot0Index3 = document.getElementById('animationChipsGoingToVisualPot0Index3');
    // animationChipsGoingToVisualPot0Index3?.classList.add('startAnimationChipsGoingToVisualPot0Index3');
    // setTimeout(() => {
    //   animationChipsGoingToVisualPot0Index3?.classList.remove('startAnimationChipsGoingToVisualPot0Index3');
    // }, 1000);

    // let animationChipsGoingToVisualPot0Index4 = document.getElementById('animationChipsGoingToVisualPot0Index4');
    // animationChipsGoingToVisualPot0Index4?.classList.add('startAnimationChipsGoingToVisualPot0Index4');
    // setTimeout(() => {
    //   animationChipsGoingToVisualPot0Index4?.classList.remove('startAnimationChipsGoingToVisualPot0Index4');
    // }, 1000);

    // let animationChipsGoingToVisualPot0Index5 = document.getElementById('animationChipsGoingToVisualPot0Index5');
    // animationChipsGoingToVisualPot0Index5?.classList.add('startAnimationChipsGoingToVisualPot0Index5');
    // setTimeout(() => {
    //   animationChipsGoingToVisualPot0Index5?.classList.remove('startAnimationChipsGoingToVisualPot0Index5');
    // }, 1000);

    // let animationChipsGoingToVisualPot0Index6 = document.getElementById('animationChipsGoingToVisualPot0Index6');
    // animationChipsGoingToVisualPot0Index6?.classList.add('startAnimationChipsGoingToVisualPot0Index6');
    // setTimeout(() => {
    //   animationChipsGoingToVisualPot0Index6?.classList.remove('startAnimationChipsGoingToVisualPot0Index6');
    // }, 1000);

    // let animationChipsGoingToVisualPot0Index7 = document.getElementById('animationChipsGoingToVisualPot0Index7');
    // animationChipsGoingToVisualPot0Index7?.classList.add('startAnimationChipsGoingToVisualPot0Index7');
    // setTimeout(() => {
    //   animationChipsGoingToVisualPot0Index7?.classList.remove('startAnimationChipsGoingToVisualPot0Index7');
    // }, 1000);

    // let animationChipsGoingToVisualPot0Index8 = document.getElementById('animationChipsGoingToVisualPot0Index8');
    // animationChipsGoingToVisualPot0Index8?.classList.add('startAnimationChipsGoingToVisualPot0Index8');
    // setTimeout(() => {
    //   animationChipsGoingToVisualPot0Index8?.classList.remove('startAnimationChipsGoingToVisualPot0Index8');
    // }, 1000);

    //----------------------------------- Animação das fichas saindo da frente do jogador para a pot visual 1 -----------------------------------//

    // let animationChipsGoingToVisualPot1Index0 = document.getElementById('animationChipsGoingToVisualPot1Index0');
    // animationChipsGoingToVisualPot1Index0?.classList.add('startAnimationChipsGoingToVisualPot1Index0');
    // setTimeout(() => {
    //   animationChipsGoingToVisualPot1Index0?.classList.remove('startAnimationChipsGoingToVisualPot1Index0');
    // }, 1000);

    // let animationChipsGoingToVisualPot1Index1 = document.getElementById('animationChipsGoingToVisualPot1Index1');
    // animationChipsGoingToVisualPot1Index1?.classList.add('startAnimationChipsGoingToVisualPot1Index1');
    // setTimeout(() => {
    //   animationChipsGoingToVisualPot1Index1?.classList.remove('startAnimationChipsGoingToVisualPot1Index1');
    // }, 1000);

    // let animationChipsGoingToVisualPot1Index2 = document.getElementById('animationChipsGoingToVisualPot1Index2');
    // animationChipsGoingToVisualPot1Index2?.classList.add('startAnimationChipsGoingToVisualPot1Index2');
    // setTimeout(() => {
    //   animationChipsGoingToVisualPot1Index2?.classList.remove('startAnimationChipsGoingToVisualPot1Index2');
    // }, 1000);

    // let animationChipsGoingToVisualPot1Index3 = document.getElementById('animationChipsGoingToVisualPot1Index3');
    // animationChipsGoingToVisualPot1Index3?.classList.add('startAnimationChipsGoingToVisualPot1Index3');
    // setTimeout(() => {
    //   animationChipsGoingToVisualPot1Index3?.classList.remove('startAnimationChipsGoingToVisualPot1Index3');
    // }, 1000);

    // let animationChipsGoingToVisualPot1Index4 = document.getElementById('animationChipsGoingToVisualPot1Index4');
    // animationChipsGoingToVisualPot1Index4?.classList.add('startAnimationChipsGoingToVisualPot1Index4');
    // setTimeout(() => {
    //   animationChipsGoingToVisualPot1Index4?.classList.remove('startAnimationChipsGoingToVisualPot1Index4');
    // }, 1000);

    // let animationChipsGoingToVisualPot1Index5 = document.getElementById('animationChipsGoingToVisualPot1Index5');
    // animationChipsGoingToVisualPot1Index5?.classList.add('startAnimationChipsGoingToVisualPot1Index5');
    // setTimeout(() => {
    //   animationChipsGoingToVisualPot1Index5?.classList.remove('startAnimationChipsGoingToVisualPot1Index5');
    // }, 1000);

    // let animationChipsGoingToVisualPot1Index6 = document.getElementById('animationChipsGoingToVisualPot1Index6');
    // animationChipsGoingToVisualPot1Index6?.classList.add('startAnimationChipsGoingToVisualPot1Index6');
    // setTimeout(() => {
    //   animationChipsGoingToVisualPot1Index6?.classList.remove('startAnimationChipsGoingToVisualPot1Index6');
    // }, 1000);

    // let animationChipsGoingToVisualPot1Index7 = document.getElementById('animationChipsGoingToVisualPot1Index7');
    // animationChipsGoingToVisualPot1Index7?.classList.add('startAnimationChipsGoingToVisualPot1Index7');
    // setTimeout(() => {
    //   animationChipsGoingToVisualPot1Index7?.classList.remove('startAnimationChipsGoingToVisualPot1Index7');
    // }, 1000);

    // let animationChipsGoingToVisualPot1Index8 = document.getElementById('animationChipsGoingToVisualPot1Index8');
    // animationChipsGoingToVisualPot1Index8?.classList.add('startAnimationChipsGoingToVisualPot1Index8');
    // setTimeout(() => {
    //   animationChipsGoingToVisualPot1Index8?.classList.remove('startAnimationChipsGoingToVisualPot1Index8');
    // }, 1000);

    //----------------------------------- Animação das fichas saindo da frente do jogador para a pot visual 2 -----------------------------------//

    // let animationChipsGoingToVisualPot2Index0 = document.getElementById('animationChipsGoingToVisualPot2Index0');
    // animationChipsGoingToVisualPot2Index0?.classList.add('startAnimationChipsGoingToVisualPot2Index0');
    // setTimeout(() => {
    //   animationChipsGoingToVisualPot2Index0?.classList.remove('startAnimationChipsGoingToVisualPot2Index0');
    // }, 1000);

    // let animationChipsGoingToVisualPot2Index1 = document.getElementById('animationChipsGoingToVisualPot2Index1');
    // animationChipsGoingToVisualPot2Index1?.classList.add('startAnimationChipsGoingToVisualPot2Index1');
    // setTimeout(() => {
    //   animationChipsGoingToVisualPot2Index1?.classList.remove('startAnimationChipsGoingToVisualPot2Index1');
    // }, 1000);

    // let animationChipsGoingToVisualPot2Index2 = document.getElementById('animationChipsGoingToVisualPot2Index2');
    // animationChipsGoingToVisualPot2Index2?.classList.add('startAnimationChipsGoingToVisualPot2Index2');
    // setTimeout(() => {
    //   animationChipsGoingToVisualPot2Index2?.classList.remove('startAnimationChipsGoingToVisualPot2Index2');
    // }, 1000);

    // let animationChipsGoingToVisualPot2Index3 = document.getElementById('animationChipsGoingToVisualPot2Index3');
    // animationChipsGoingToVisualPot2Index3?.classList.add('startAnimationChipsGoingToVisualPot2Index3');
    // setTimeout(() => {
    //   animationChipsGoingToVisualPot2Index3?.classList.remove('startAnimationChipsGoingToVisualPot2Index3');
    // }, 1000);

    // let animationChipsGoingToVisualPot2Index4 = document.getElementById('animationChipsGoingToVisualPot2Index4');
    // animationChipsGoingToVisualPot2Index4?.classList.add('startAnimationChipsGoingToVisualPot2Index4');
    // setTimeout(() => {
    //   animationChipsGoingToVisualPot2Index4?.classList.remove('startAnimationChipsGoingToVisualPot2Index4');
    // }, 1000);

    // let animationChipsGoingToVisualPot2Index5 = document.getElementById('animationChipsGoingToVisualPot2Index5');
    // animationChipsGoingToVisualPot2Index5?.classList.add('startAnimationChipsGoingToVisualPot2Index5');
    // setTimeout(() => {
    //   animationChipsGoingToVisualPot2Index5?.classList.remove('startAnimationChipsGoingToVisualPot2Index5');
    // }, 1000);

    // let animationChipsGoingToVisualPot2Index6 = document.getElementById('animationChipsGoingToVisualPot2Index6');
    // animationChipsGoingToVisualPot2Index6?.classList.add('startAnimationChipsGoingToVisualPot2Index6');
    // setTimeout(() => {
    //   animationChipsGoingToVisualPot2Index6?.classList.remove('startAnimationChipsGoingToVisualPot2Index6');
    // }, 1000);

    // let animationChipsGoingToVisualPot2Index7 = document.getElementById('animationChipsGoingToVisualPot2Index7');
    // animationChipsGoingToVisualPot2Index7?.classList.add('startAnimationChipsGoingToVisualPot2Index7');
    // setTimeout(() => {
    //   animationChipsGoingToVisualPot2Index7?.classList.remove('startAnimationChipsGoingToVisualPot2Index7');
    // }, 1000);

    // let animationChipsGoingToVisualPot2Index8 = document.getElementById('animationChipsGoingToVisualPot2Index8');
    // animationChipsGoingToVisualPot2Index8?.classList.add('startAnimationChipsGoingToVisualPot2Index8');
    // setTimeout(() => {
    //   animationChipsGoingToVisualPot2Index8?.classList.remove('startAnimationChipsGoingToVisualPot2Index8');
    // }, 1000);

    //----------------------------------- Animação das fichas saindo do pot visual 2 para o vencedor -----------------------------------//

    // let animationChipsFromPot2ToPlayerIndex0 = document.getElementById('animationChipsFromPot2ToPlayerIndex0');
    // animationChipsFromPot2ToPlayerIndex0?.classList.add('startAnimationChipsFromPot2ToPlayerIndex0');
    // setTimeout(() => {
    //   animationChipsFromPot2ToPlayerIndex0?.classList.remove('startAnimationChipsFromPot2ToPlayerIndex0');
    // }, 1000);

    // let animationChipsFromPot2ToPlayerIndex1 = document.getElementById('animationChipsFromPot2ToPlayerIndex1');
    // animationChipsFromPot2ToPlayerIndex1?.classList.add('startAnimationChipsFromPot2ToPlayerIndex1');
    // setTimeout(() => {
    //   animationChipsFromPot2ToPlayerIndex1?.classList.remove('startAnimationChipsFromPot2ToPlayerIndex1');
    // }, 1000);

    // let animationChipsFromPot2ToPlayerIndex2 = document.getElementById('animationChipsFromPot2ToPlayerIndex2');
    // animationChipsFromPot2ToPlayerIndex2?.classList.add('startAnimationChipsFromPot2ToPlayerIndex2');
    // setTimeout(() => {
    //   animationChipsFromPot2ToPlayerIndex2?.classList.remove('startAnimationChipsFromPot2ToPlayerIndex2');
    // }, 1000);

    // let animationChipsFromPot2ToPlayerIndex3 = document.getElementById('animationChipsFromPot2ToPlayerIndex3');
    // animationChipsFromPot2ToPlayerIndex3?.classList.add('startAnimationChipsFromPot2ToPlayerIndex3');
    // setTimeout(() => {
    //   animationChipsFromPot2ToPlayerIndex3?.classList.remove('startAnimationChipsFromPot2ToPlayerIndex3');
    // }, 1000);

    // let animationChipsFromPot2ToPlayerIndex4 = document.getElementById('animationChipsFromPot2ToPlayerIndex4');
    // animationChipsFromPot2ToPlayerIndex4?.classList.add('startAnimationChipsFromPot2ToPlayerIndex4');
    // setTimeout(() => {
    //   animationChipsFromPot2ToPlayerIndex4?.classList.remove('startAnimationChipsFromPot2ToPlayerIndex4');
    // }, 1000);

    // let animationChipsFromPot2ToPlayerIndex5 = document.getElementById('animationChipsFromPot2ToPlayerIndex5');
    // animationChipsFromPot2ToPlayerIndex5?.classList.add('startAnimationChipsFromPot2ToPlayerIndex5');
    // setTimeout(() => {
    //   animationChipsFromPot2ToPlayerIndex5?.classList.remove('startAnimationChipsFromPot2ToPlayerIndex5');
    // }, 1000);

    // let animationChipsFromPot2ToPlayerIndex6 = document.getElementById('animationChipsFromPot2ToPlayerIndex6');
    // animationChipsFromPot2ToPlayerIndex6?.classList.add('startAnimationChipsFromPot2ToPlayerIndex6');
    // setTimeout(() => {
    //   animationChipsFromPot2ToPlayerIndex6?.classList.remove('startAnimationChipsFromPot2ToPlayerIndex6');
    // }, 1000);

    // let animationChipsFromPot2ToPlayerIndex7 = document.getElementById('animationChipsFromPot2ToPlayerIndex7');
    // animationChipsFromPot2ToPlayerIndex7?.classList.add('startAnimationChipsFromPot2ToPlayerIndex7');
    // setTimeout(() => {
    //   animationChipsFromPot2ToPlayerIndex7?.classList.remove('startAnimationChipsFromPot2ToPlayerIndex7');
    // }, 1000);

    // let animationChipsFromPot2ToPlayerIndex8 = document.getElementById('animationChipsFromPot2ToPlayerIndex8');
    // animationChipsFromPot2ToPlayerIndex8?.classList.add('startAnimationChipsFromPot2ToPlayerIndex8');
    // setTimeout(() => {
    //   animationChipsFromPot2ToPlayerIndex8?.classList.remove('startAnimationChipsFromPot2ToPlayerIndex8');
    // }, 1000);

  }

  toggleNotes(event: any) {
    event.stopPropagation();
    this.isSelectVisible = !this.isSelectVisible;

  }


  SetControlPanel(control: string) {
    this.controlPanel = control;
    if (this.controlPanel !== 'nota') {
      this.rgbModalStatus = false
      this.playerToSetColor = null
    } else {
      this.isSelectVisible = true
    }
    if (this.isCollapsed == true) {
      this.isCollapsed = false
    }
  }
  onSelectChange(event: Event) {
    const selectElement = event.target as HTMLSelectElement;

    this.isSelectVisible = false;
  }

  toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }
  togglePlayerAction(index: number) {
    this.showActionPlayer = this.showActionPlayer.map((_, i) => i === index);

    setTimeout(() => {
      this.showActionPlayer[index] = false;
    }, 3000);
  }


  showPlayerAction() {
    this.animationPlayerIndex = this.playersInViewOrder.findIndex((a: any) => a?.user?._id?.toString() === this.animationResponse?.playerId.toString());

    if (this.animationResponse?.action == PlayerHandActionEnum.FOLD) {
      this.showAnimationFoldAction();
    } else if (this.animationResponse?.action == PlayerHandActionEnum.CALL || this.animationResponse?.action == PlayerHandActionEnum.RAISE ||
      this.animationResponse?.action == PlayerHandActionEnum.ALLIN || this.animationResponse?.action == PlayerHandActionEnum.BET) {
      this.showAnimationBetAction();
    }

    this.playSound();

    switch (this.animationPlayerIndex) {
      case 0:

        this.showActionPlayer0 = true;
        this.showActionPlayer1 = false;
        this.showActionPlayer2 = false;
        this.showActionPlayer3 = false;
        this.showActionPlayer4 = false;
        this.showActionPlayer5 = false;
        this.showActionPlayer6 = false;
        this.showActionPlayer7 = false;
        this.showActionPlayer8 = false;

        setTimeout(() => {
          this.showActionPlayer0 = false;
        }, 3000);

        break;
      case 1:
        //this.togglePlayerAction(1);
        this.showActionPlayer0 = false;
        this.showActionPlayer1 = true;
        this.showActionPlayer2 = false;
        this.showActionPlayer3 = false;
        this.showActionPlayer4 = false;
        this.showActionPlayer5 = false;
        this.showActionPlayer6 = false;
        this.showActionPlayer7 = false;
        this.showActionPlayer8 = false;

        setTimeout(() => {
          this.showActionPlayer1 = false;
        }, 3000);

        break;
      case 2:
        //  this.togglePlayerAction(2);
        this.showActionPlayer0 = false;
        this.showActionPlayer1 = false;
        this.showActionPlayer2 = true;
        this.showActionPlayer3 = false;
        this.showActionPlayer4 = false;
        this.showActionPlayer5 = false;
        this.showActionPlayer6 = false;
        this.showActionPlayer7 = false;
        this.showActionPlayer8 = false;

        setTimeout(() => {
          this.showActionPlayer2 = false;
        }, 3000);

        break;
      case 3:
        //  this.togglePlayerAction(3);
        this.showActionPlayer0 = false;
        this.showActionPlayer1 = false;
        this.showActionPlayer2 = false;
        this.showActionPlayer3 = true;
        this.showActionPlayer4 = false;
        this.showActionPlayer5 = false;
        this.showActionPlayer6 = false;
        this.showActionPlayer7 = false;
        this.showActionPlayer8 = false;

        setTimeout(() => {
          this.showActionPlayer3 = false;
        }, 3000);

        break;
      case 4:
        //  this.togglePlayerAction(4);
        this.showActionPlayer0 = false;
        this.showActionPlayer1 = false;
        this.showActionPlayer2 = false;
        this.showActionPlayer3 = false;
        this.showActionPlayer4 = true;
        this.showActionPlayer5 = false;
        this.showActionPlayer6 = false;
        this.showActionPlayer7 = false;
        this.showActionPlayer8 = false;

        setTimeout(() => {
          this.showActionPlayer4 = false;
        }, 3000);

        break;
      case 5:
        //   this.togglePlayerAction(5);
        this.showActionPlayer0 = false;
        this.showActionPlayer1 = false;
        this.showActionPlayer2 = false;
        this.showActionPlayer3 = false;
        this.showActionPlayer4 = false;
        this.showActionPlayer5 = true;
        this.showActionPlayer6 = false;
        this.showActionPlayer7 = false;
        this.showActionPlayer8 = false;

        setTimeout(() => {
          this.showActionPlayer5 = false;
        }, 3000);

        break;
      case 6:
        //    this.togglePlayerAction(6);
        this.showActionPlayer0 = false;
        this.showActionPlayer1 = false;
        this.showActionPlayer2 = false;
        this.showActionPlayer3 = false;
        this.showActionPlayer4 = false;
        this.showActionPlayer5 = false;
        this.showActionPlayer6 = true;
        this.showActionPlayer7 = false;
        this.showActionPlayer8 = false;

        setTimeout(() => {
          this.showActionPlayer6 = false;
        }, 3000);

        break;
      case 7:
        //    this.togglePlayerAction(7);
        this.showActionPlayer0 = false;
        this.showActionPlayer1 = false;
        this.showActionPlayer2 = false;
        this.showActionPlayer3 = false;
        this.showActionPlayer4 = false;
        this.showActionPlayer5 = false;
        this.showActionPlayer6 = false;
        this.showActionPlayer7 = true;
        this.showActionPlayer8 = false;

        setTimeout(() => {
          this.showActionPlayer7 = false;
        }, 3000);

        break;
      case 8:
        //  this.togglePlayerAction(8);
        this.showActionPlayer0 = false;
        this.showActionPlayer1 = false;
        this.showActionPlayer2 = false;
        this.showActionPlayer3 = false;
        this.showActionPlayer4 = false;
        this.showActionPlayer5 = false;
        this.showActionPlayer6 = false;
        this.showActionPlayer7 = false;
        this.showActionPlayer8 = true;

        setTimeout(() => {
          this.showActionPlayer8 = false;
        }, 3000);

        break;
    }

  }

  showAnimationFoldAction() {
    switch (this.animationPlayerIndex) {
      case 0:
        let animationDivSetCardsToTable0 = document.getElementById('animationDivSetCardsToTable0');
        animationDivSetCardsToTable0?.classList.add('startAnimationDivSetCardsToTable0');
        setTimeout(() => {
          animationDivSetCardsToTable0?.classList.remove('startAnimationDivSetCardsToTable0');
        }, 1000);
        break;

      case 1:
        let animationDivSetCardsToTable1 = document.getElementById('animationDivSetCardsToTable1');
        animationDivSetCardsToTable1?.classList.add('startAnimationDivSetCardsToTable1');
        setTimeout(() => {
          animationDivSetCardsToTable1?.classList.remove('startAnimationDivSetCardsToTable1');
        }, 1000);
        break;

      case 2:
        let animationDivSetCardsToTable2 = document.getElementById('animationDivSetCardsToTable2');
        animationDivSetCardsToTable2?.classList.add('startAnimationDivSetCardsToTable2');
        setTimeout(() => {
          animationDivSetCardsToTable2?.classList.remove('startAnimationDivSetCardsToTable2');
        }, 1000);
        break;

      case 3:
        let animationDivSetCardsToTable3 = document.getElementById('animationDivSetCardsToTable3');
        animationDivSetCardsToTable3?.classList.add('startAnimationDivSetCardsToTable3');
        setTimeout(() => {
          animationDivSetCardsToTable3?.classList.remove('startAnimationDivSetCardsToTable3');
        }, 1000);
        break;

      case 4:
        let animationDivSetCardsToTable4 = document.getElementById('animationDivSetCardsToTable4');
        animationDivSetCardsToTable4?.classList.add('startAnimationDivSetCardsToTable4');
        setTimeout(() => {
          animationDivSetCardsToTable4?.classList.remove('startAnimationDivSetCardsToTable4');
        }, 1000);
        break;

      case 5:
        let animationDivSetCardsToTable5 = document.getElementById('animationDivSetCardsToTable5');
        animationDivSetCardsToTable5?.classList.add('startAnimationDivSetCardsToTable5');
        setTimeout(() => {
          animationDivSetCardsToTable5?.classList.remove('startAnimationDivSetCardsToTable5');
        }, 1000);
        break;

      case 6:
        let animationDivSetCardsToTable6 = document.getElementById('animationDivSetCardsToTable6');
        animationDivSetCardsToTable6?.classList.add('startAnimationDivSetCardsToTable6');
        setTimeout(() => {
          animationDivSetCardsToTable6?.classList.remove('startAnimationDivSetCardsToTable6');
        }, 1000);
        break;

      case 7:
        let animationDivSetCardsToTable7 = document.getElementById('animationDivSetCardsToTable7');
        animationDivSetCardsToTable7?.classList.add('startAnimationDivSetCardsToTable7');
        setTimeout(() => {
          animationDivSetCardsToTable7?.classList.remove('startAnimationDivSetCardsToTable7');
        }, 1000);
        break;

      case 8:
        let animationDivSetCardsToTable8 = document.getElementById('animationDivSetCardsToTable8');
        animationDivSetCardsToTable8?.classList.add('startAnimationDivSetCardsToTable8');
        setTimeout(() => {
          animationDivSetCardsToTable8?.classList.remove('startAnimationDivSetCardsToTable8');
        }, 1000);
        break;
    }
  }

  showAnimationBetAction() {

    switch (this.animationPlayerIndex) {
      case 0:
        let animationChipsFromHandToTableIndex0 = document.getElementById('animationChipsFromHandToTableIndex0');
        animationChipsFromHandToTableIndex0?.classList.add('startAnimationChipsFromHandToTableIndex0');
        setTimeout(() => {
          animationChipsFromHandToTableIndex0?.classList.remove('startAnimationChipsFromHandToTableIndex0');
        }, 1000);
        break;

      case 1:
        let animationChipsFromHandToTableIndex1 = document.getElementById('animationChipsFromHandToTableIndex1');
        animationChipsFromHandToTableIndex1?.classList.add('startAnimationChipsFromHandToTableIndex1');
        setTimeout(() => {
          animationChipsFromHandToTableIndex1?.classList.remove('startAnimationChipsFromHandToTableIndex1');
        }, 1000);
        break;

      case 2:
        let animationChipsFromHandToTableIndex2 = document.getElementById('animationChipsFromHandToTableIndex2');
        animationChipsFromHandToTableIndex2?.classList.add('startAnimationChipsFromHandToTableIndex2');
        setTimeout(() => {
          animationChipsFromHandToTableIndex2?.classList.remove('startAnimationChipsFromHandToTableIndex2');
        }, 1000);
        break;

      case 3:
        let animationChipsFromHandToTableIndex3 = document.getElementById('animationChipsFromHandToTableIndex3');
        animationChipsFromHandToTableIndex3?.classList.add('startAnimationChipsFromHandToTableIndex3');
        setTimeout(() => {
          animationChipsFromHandToTableIndex3?.classList.remove('startAnimationChipsFromHandToTableIndex3');
        }, 1000);
        break;

      case 4:
        let animationChipsFromHandToTableIndex4 = document.getElementById('animationChipsFromHandToTableIndex4');
        animationChipsFromHandToTableIndex4?.classList.add('startAnimationChipsFromHandToTableIndex4');
        setTimeout(() => {
          animationChipsFromHandToTableIndex4?.classList.remove('startAnimationChipsFromHandToTableIndex4');
        }, 1000);
        break;

      case 5:
        let animationChipsFromHandToTableIndex5 = document.getElementById('animationChipsFromHandToTableIndex5');
        animationChipsFromHandToTableIndex5?.classList.add('startAnimationChipsFromHandToTableIndex5');
        setTimeout(() => {
          animationChipsFromHandToTableIndex5?.classList.remove('startAnimationChipsFromHandToTableIndex5');
        }, 1000);
        break;

      case 6:
        let animationChipsFromHandToTableIndex6 = document.getElementById('animationChipsFromHandToTableIndex6');
        animationChipsFromHandToTableIndex6?.classList.add('startAnimationChipsFromHandToTableIndex6');
        setTimeout(() => {
          animationChipsFromHandToTableIndex6?.classList.remove('startAnimationChipsFromHandToTableIndex6');
        }, 1000);
        break;

      case 7:
        let animationChipsFromHandToTableIndex7 = document.getElementById('animationChipsFromHandToTableIndex7');
        animationChipsFromHandToTableIndex7?.classList.add('startAnimationChipsFromHandToTableIndex7');
        setTimeout(() => {
          animationChipsFromHandToTableIndex7?.classList.remove('startAnimationChipsFromHandToTableIndex7');
        }, 1000);
        break;

      case 8:
        let animationChipsFromHandToTableIndex8 = document.getElementById('animationChipsFromHandToTableIndex8');
        animationChipsFromHandToTableIndex8?.classList.add('startAnimationChipsFromHandToTableIndex8');
        setTimeout(() => {
          animationChipsFromHandToTableIndex8?.classList.remove('startAnimationChipsFromHandToTableIndex8');
        }, 1000);
        break;
    }
  }

  async animationSetCardsToPlayers(amimationPlayersInViewOrder: PlayerGetResponseDto[]) {

    if (amimationPlayersInViewOrder[0] && amimationPlayersInViewOrder[0].status == PlayerStatusEnum.playing) {
      let animationDivSetCardsToPlayer0 = document.getElementById('animationDivSetCardsToPlayer0');
      animationDivSetCardsToPlayer0?.classList.add('startAnimationDivSetCardsToPlayer0');
      SoundUtil.play('../../assets/sounds/flop.m4a', 1);
      setTimeout(() => {
        animationDivSetCardsToPlayer0?.classList.remove('startAnimationDivSetCardsToPlayer0');
      }, 1000);
      if (amimationPlayersInViewOrder[0].playerHand !== undefined && amimationPlayersInViewOrder[0].playerHand.cards.length > 0) {
        this.playerTip = getTipForHand(amimationPlayersInViewOrder[0].playerHand.cards)
        this.playerHand = amimationPlayersInViewOrder[0].playerHand.cards
      }
    }

    if (amimationPlayersInViewOrder[1] && amimationPlayersInViewOrder[1].status == PlayerStatusEnum.playing) {
      let animationDivSetCardsToPlayer1 = document.getElementById('animationDivSetCardsToPlayer1');
      animationDivSetCardsToPlayer1?.classList.add('startAnimationDivSetCardsToPlayer1');
      setTimeout(() => {
        animationDivSetCardsToPlayer1?.classList.remove('startAnimationDivSetCardsToPlayer1');
      }, 1000);
    }

    if (amimationPlayersInViewOrder[2] && amimationPlayersInViewOrder[2].status == PlayerStatusEnum.playing) {
      let animationDivSetCardsToPlayer2 = document.getElementById('animationDivSetCardsToPlayer2');
      animationDivSetCardsToPlayer2?.classList.add('startAnimationDivSetCardsToPlayer2');
      setTimeout(() => {
        animationDivSetCardsToPlayer2?.classList.remove('startAnimationDivSetCardsToPlayer2');
      }, 1000);
    }

    if (amimationPlayersInViewOrder[3] && amimationPlayersInViewOrder[3].status == PlayerStatusEnum.playing) {
      let animationDivSetCardsToPlayer3 = document.getElementById('animationDivSetCardsToPlayer3');
      animationDivSetCardsToPlayer3?.classList.add('startAnimationDivSetCardsToPlayer3');
      setTimeout(() => {
        animationDivSetCardsToPlayer3?.classList.remove('startAnimationDivSetCardsToPlayer3');
      }, 1000);
    }

    if (amimationPlayersInViewOrder[4] && amimationPlayersInViewOrder[4].status == PlayerStatusEnum.playing) {
      let animationDivSetCardsToPlayer4 = document.getElementById('animationDivSetCardsToPlayer4');
      animationDivSetCardsToPlayer4?.classList.add('startAnimationDivSetCardsToPlayer4');
      setTimeout(() => {
        animationDivSetCardsToPlayer4?.classList.remove('startAnimationDivSetCardsToPlayer4');
      }, 1000);
    }

    if (amimationPlayersInViewOrder[5] && amimationPlayersInViewOrder[5].status == PlayerStatusEnum.playing) {
      let animationDivSetCardsToPlayer5 = document.getElementById('animationDivSetCardsToPlayer5');
      animationDivSetCardsToPlayer5?.classList.add('startAnimationDivSetCardsToPlayer5');
      setTimeout(() => {
        animationDivSetCardsToPlayer5?.classList.remove('startAnimationDivSetCardsToPlayer5');
      }, 1000);
    }

    if (amimationPlayersInViewOrder[6] && amimationPlayersInViewOrder[6].status == PlayerStatusEnum.playing) {
      let animationDivSetCardsToPlayer6 = document.getElementById('animationDivSetCardsToPlayer6');
      animationDivSetCardsToPlayer6?.classList.add('startAnimationDivSetCardsToPlayer6');
      setTimeout(() => {
        animationDivSetCardsToPlayer6?.classList.remove('startAnimationDivSetCardsToPlayer6');
      }, 1000);
    }

    if (amimationPlayersInViewOrder[7] && amimationPlayersInViewOrder[7].status == PlayerStatusEnum.playing) {
      let animationDivSetCardsToPlayer7 = document.getElementById('animationDivSetCardsToPlayer7');
      animationDivSetCardsToPlayer7?.classList.add('startAnimationDivSetCardsToPlayer7');
      setTimeout(() => {
        animationDivSetCardsToPlayer7?.classList.remove('startAnimationDivSetCardsToPlayer7');
      }, 1000);
    }

    if (amimationPlayersInViewOrder[8] && amimationPlayersInViewOrder[8].status == PlayerStatusEnum.playing) {
      let animationDivSetCardsToPlayer8 = document.getElementById('animationDivSetCardsToPlayer8');
      animationDivSetCardsToPlayer8?.classList.add('startAnimationDivSetCardsToPlayer8');
      setTimeout(() => {
        animationDivSetCardsToPlayer8?.classList.remove('startAnimationDivSetCardsToPlayer8');
      }, 1000);
    }

  }

  playSound() {
    if (this.animationResponse?.action === PlayerHandActionEnum.FOLD) {
      SoundUtil.play('../../assets/sounds/action-fold.m4a', 2);
    } else if (this.animationResponse?.action === PlayerHandActionEnum.CHECK) {
      SoundUtil.play('../../assets/sounds/action-check.wav', 1, 1);
    } else if (this.animationResponse?.action === PlayerHandActionEnum.RAISE ||
      this.animationResponse?.action === PlayerHandActionEnum.CALL ||
      this.animationResponse?.action === PlayerHandActionEnum.ALLIN ||
      this.animationResponse?.action === PlayerHandActionEnum.BET) {
      SoundUtil.play('../../assets/sounds/action-bet-raise-call-allin.m4a', 2);
    }
  }

  listenedRange(event: any, type: string) {
    if (type === 'range') {
      const rangeValue = event.target.value;
      let newValueBB = Number((rangeValue / this.tableGame?.bigBlindValue!).toFixed(2)).toString().replace(",", ".");
      if (Number(rangeValue) > this.getMaxAmount() + this.betByPlayer(0)) {
        this.form.patchValue({
          bet: +Number((this.getMaxAmount() + this.betByPlayer(0)).toFixed(2)),
          valueRange: +Number((this.getMaxAmount() + this.betByPlayer(0)).toFixed(2)),
          valueRangeBB: +Number((Number((this.getMaxAmount() + this.betByPlayer(0)).toFixed(2)) / this.tableGame?.bigBlindValue!).toFixed(2))
        })
      } else {
        this.form.patchValue({
          valueRange: Number(rangeValue),
          bet: Number(rangeValue),
          valueRangeBB: Number(newValueBB)
        })
      }
    } else if (type === 'inputBB') {
      const inputBBValue = event.target.value
      this.form.patchValue({
        valueRange: +Number(inputBBValue) * +Number(this.tableGame?.bigBlindValue!),
        bet: +Number(inputBBValue) * +Number(this.tableGame?.bigBlindValue!)
      });

      if (inputBBValue > this.getMaxAmount() / this.tableGame?.bigBlindValue!) {
        this.form.patchValue({
          bet: +Number(this.getMaxAmount() + this.betByPlayer(0)),
          valueRange: +Number(this.getMaxAmount() + this.betByPlayer(0)),
          valueRangeBB: +Number((this.getMaxAmount() + this.betByPlayer(0)) / this.tableGame?.bigBlindValue!)
        })
      }
    } else {
      const inputValue = +Number(event.target.value);

      this.form.patchValue({
        bet: Number((inputValue).toFixed(2).toString().replace(",", "."))
      });

      if (inputValue > this.getMaxAmount() + this.betByPlayer(0)) {
        this.form.patchValue({
          bet: Number(this.getMaxAmount() + this.betByPlayer(0)),
          valueRange: Number(this.getMaxAmount() + this.betByPlayer(0)),
          valueRangeBB: Number((this.getMaxAmount() + this.betByPlayer(0))) / this.tableGame?.bigBlindValue!
        })
      }
    }
  }

  getMaxAmount() {
    if (this.tableGame?.players) {
      let playerIndex = this.tableGame!.players.findIndex((a: any) => a?.user?.email == this.authService.getAuthenticatedUser().email);
      if (playerIndex > -1) {
        return this.tableGame.players[playerIndex].coinAmount!;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }

  canCheck() {
    if (this.tableGame?.players) {
      const topBetRound = this.getTopBetRound();
      const topBetPlayer = this.getTopBetPlayer();

      if (topBetRound == topBetPlayer) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  canCall() {
    if (this.tableGame?.players) {
      const topBetRound = this.getTopBetRound();
      const topBetPlayer = this.getTopBetPlayer();
      let playerIndex = this.tableGame!.players.findIndex((a: any) => a?.user?.email == this.authService.getAuthenticatedUser().email);
      if (topBetPlayer + this.tableGame?.players[playerIndex]?.coinAmount! >= topBetRound) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  calcHigherBet() {
    if (this.tableGame?.players) {
      let raiseArray: number[] = [];
      for (const player of this.tableGame.players) {
        // if (player.playerHand?.lastAction == PlayerHandActionEnum.RAISE || player.playerHand?.lastAction == PlayerHandActionEnum.BET) {
          let playerActions;
          if (this.tableGame.round == TableGameRoundStatusEnum.PRE_FLOP) {
            playerActions = player?.playerHand?.actions.map((a: any) => a).filter((b: any) => b.round == TableGameRoundStatusEnum.PRE_FLOP || b.round == TableGameRoundStatusEnum.BLIND);
          } else {
            playerActions = player?.playerHand?.actions.map((a: any) => a).filter((b: any) => b.round == this.tableGame?.round);

          }
          const bets = playerActions?.reduce((max: any, curr: any) => ( max + curr.bet), 0)



          raiseArray.push(bets);
          
       // }
      }

      if (raiseArray.length > 0) {

        this.hasRaised = true
         //const highestBet = Math.max(...raiseArray);
          const highestBet = raiseArray.reduce((max, current) => (current > max ? current : max), raiseArray[0]);

        return highestBet;
      }
    }
    return 0;
  }

  canRaise() {
    if (this.tableGame?.players) {

      let sumBetPlayerWithoutBlinds = this.getSumBetPlayerWithoutBlinds(this.tableGame?.currentTurn);
      let raiseArray: number[] = [];
      for (const player of this.tableGame.players) {
        if (player.playerHand?.lastAction == PlayerHandActionEnum.RAISE || player.playerHand?.lastAction == PlayerHandActionEnum.BET) {
          let playerActions;
          if (this.tableGame.round == TableGameRoundStatusEnum.PRE_FLOP) {
            playerActions = player?.playerHand?.actions.map((a: any) => a).filter((b: any) => b.round == TableGameRoundStatusEnum.PRE_FLOP || b.round == TableGameRoundStatusEnum.BLIND);
          } else {
            playerActions = player?.playerHand?.actions.map((a: any) => a).filter((b: any) => b.round == this.tableGame?.round);
          }
          const bets = playerActions?.map((a: any) => a.bet);
          const sumBet = bets?.reduce((sum: any, current: any) => sum + current, 0);
          raiseArray.push(sumBet);
          this.lastBet = sumBet
        }
      }

      let biggerRaiseRound = Math.max(...raiseArray.filter(a => a !== undefined));

      if (this.tableGame?.allinLessThanMinRaise && sumBetPlayerWithoutBlinds > 0 && sumBetPlayerWithoutBlinds >= biggerRaiseRound) {
        return false;
      }

      let playerIndex = this.tableGame!.players.findIndex((a: any) => a?.user?.email == this.authService.getAuthenticatedUser().email);

      if (this.tableGame?.players[playerIndex]?.coinAmount! >= this.minRaiseValue && this.form.controls['bet'].value < Number((this.tableGame?.players[playerIndex]?.coinAmount! + this.betByPlayer(0)).toFixed(2))) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  canAllIn() {

    if (this.tableGame?.players) {

      let sumBetPlayerWithoutBlinds = this.getSumBetPlayerWithoutBlinds(this.tableGame?.currentTurn);
      let raiseArray: number[] = [];
      for (const player of this.tableGame.players) {
        if (player.playerHand?.lastAction == PlayerHandActionEnum.RAISE || player.playerHand?.lastAction == PlayerHandActionEnum.BET) {
          let playerActions;
          if (this.tableGame.round == TableGameRoundStatusEnum.PRE_FLOP) {
            playerActions = player?.playerHand?.actions.map((a: any) => a).filter((b: any) => b.round == TableGameRoundStatusEnum.PRE_FLOP || b.round == TableGameRoundStatusEnum.BLIND);
          } else {
            playerActions = player?.playerHand?.actions.map((a: any) => a).filter((b: any) => b.round == this.tableGame?.round);
          }
          const bets = playerActions?.map((a: any) => a.bet);
          const sumBet = bets?.reduce((sum: any, current: any) => sum + current, 0);
          raiseArray.push(sumBet);
        }
      }
      let biggerRaiseRound = Math.max(...raiseArray.filter(a => a !== undefined));

      if (this.tableGame?.allinLessThanMinRaise && sumBetPlayerWithoutBlinds > 0 && this.canCall() && sumBetPlayerWithoutBlinds >= biggerRaiseRound) {
        return false;
      } else {
        let playerIndex = this.playersInViewOrder.findIndex((a: any) => a?.user?.email == this.authService.getAuthenticatedUser().email);
        if (playerIndex > -1 && this.playersInViewOrder[playerIndex].coinAmount! <= this.minRaiseValue) {
          this.form.patchValue({
            bet: Number((this.playersInViewOrder[playerIndex].coinAmount! + this.betByPlayer(0)).toFixed(2)),
            valueRange: Number((this.playersInViewOrder[playerIndex].coinAmount! + this.betByPlayer(0)).toFixed(2)),
            valueRangeBB: Number(((this.playersInViewOrder[playerIndex].coinAmount! + this.betByPlayer(0)) / this.tableGame?.bigBlindValue!).toFixed(2))
          });
        }
        if (this.form.controls['bet'].value == Number((this.playersInViewOrder[playerIndex]?.coinAmount! + this.betByPlayer(0)).toFixed(2))) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      return false;
    }
  }

  isMainPlayer() {
    if (this.tableGame) {
      let indexInit = this.playersInViewOrder.findIndex((a: any) => a?.user?.email == this.authService.getAuthenticatedUser().email);
      return indexInit > -1 && indexInit == 0;
    }
    return false;
  }

  isFold(i: number) {

    if (this.playersInViewOrder[i]?.playerHand?.lastAction) {
      if (this.playersInViewOrder[i]?.playerHand?.lastAction == PlayerHandActionEnum.FOLD) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }

  }

  fold() {
 
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    this.tableGame!.showActions = false;
    let dto = {
      tableGameId: this.tableGame!._id,
      action: PlayerHandActionEnum.FOLD,
      bet: 0
    }
    this._gameGateway.playerAction(dto);
    this.automaticFold = null
  }

  autoFold() {
    if (!this.formCheckBoxs.controls['automaticFold'].value) {

      this.automaticFold = null
    } else {

      let dto = {
        tableGameId: this.tableGame!._id,
        action: PlayerHandActionEnum.FOLD,
        bet: 0
      }

      this.automaticFold = dto
    }


  }

  call() {

    this.tableGame!.showActions = false;
    const topBetRound = this.getTopBetRound();
    const topBetPlayer = this._getTopBetCurrentTurnPlayer();
    let index = this.playersInViewOrder.findIndex((a: any) => a?.user?._id == this.tableGame?.currentTurn);
    const bet = topBetRound - topBetPlayer;
    if (this.playersInViewOrder[index]?.coinAmount! - bet == 0) {
      let dto = {
        tableGameId: this.tableGame!._id,
        action: PlayerHandActionEnum.ALLIN,
        bet: this.playersInViewOrder[index]?.coinAmount!
      }
      this._gameGateway.playerAction(dto);
    } else {
      let dto = {
        tableGameId: this.tableGame!._id,
        action: PlayerHandActionEnum.CALL,
        bet: 0
      }
      this._gameGateway.playerAction(dto);
    }
  }

  check() {
    this.tableGame!.showActions = false;
    let dto = {
      tableGameId: this.tableGame!._id,
      action: PlayerHandActionEnum.CHECK,
      bet: 0
    }
    this._gameGateway.playerAction(dto);

  }

  raise() {

    if (this.form.controls['bet'].value < this.minRaiseValue) {
      this.form.patchValue({
        bet: this.minRaiseValue,
        valueRange: this.minRaiseValue,
        valueRangeBB: this.minRaiseValue / this.tableGame?.bigBlindValue!
      });
      return;
    }
    this.tableGame!.showActions = false;

    let playerIndex = this.playersInViewOrder.findIndex((a: any) => a?.user?.email == this.authService.getAuthenticatedUser().email);

    let sumBet = this.betByPlayer(playerIndex);

    let betValue = +Number(Number(this.form.controls['bet'].value) - sumBet).toFixed(2);

    if (betValue == this.playersInViewOrder[playerIndex]?.playerHand?.coinAmount) {
      let dto = {
        tableGameId: this.tableGame!._id,
        action: PlayerHandActionEnum.ALLIN,
        bet: betValue
      }
      this._gameGateway.playerAction(dto);
    } else {
      let dto = {
        tableGameId: this.tableGame!._id,
        action: PlayerHandActionEnum.RAISE,
        bet: betValue
      }
      this._gameGateway.playerAction(dto);
    }

  }

  allIn() {
   
    this.tableGame!.showActions = false;
    let playerIndex = this.tableGame!.players.findIndex((a: any) => a?.user?.email == this.authService.getAuthenticatedUser().email);
    let dto = {
      tableGameId: this.tableGame!._id,
      action: PlayerHandActionEnum.ALLIN,
      bet: this.tableGame!.players[playerIndex]!.coinAmount as number
    }
    this._gameGateway.playerAction(dto);

  }

  changeAbsence() {
    this.requestSittingOut();
  }

  changeLeaveGame() {
    this.leavePokerGame();
  }

  presence() {
    this.playersInViewOrder[0].status = PlayerStatusEnum.presence;
    this._gameGateway.requestPlayerPresence(this.tableGame!._id);
  }

  payBigBlind() {
    this._gameGateway.requestPayBigBlind(this.tableGame!._id);
  }

  getMinRaise() {
    let minRaise = 0;
    let biggerRaiseRound = this._getBiggerRaiseRound();
    let countRaise = this._getCountRaise();


    if (this.tableGame?.round == TableGameRoundStatusEnum.PRE_FLOP) {
      if (countRaise == 0) {
        minRaise = this.tableGame.bigBlindValue * 2;
      } else {
        let secondBiggerRaiseRound = this._getSecondBiggerRaiseRound();
        minRaise = biggerRaiseRound + biggerRaiseRound - secondBiggerRaiseRound;
      }
    } else {
      if (countRaise == 0) {
        minRaise = this.tableGame!.bigBlindValue;
      } else if (countRaise == 1) {
        minRaise = biggerRaiseRound * 2;
      } else {
        let secondBiggerRaiseRound = this._getSecondBiggerRaiseRound();
        minRaise = biggerRaiseRound + biggerRaiseRound - secondBiggerRaiseRound;
      }
    }


    this.minRaiseValue = Number(minRaise.toFixed(2));
    this.form.patchValue({
      bet: this.minRaiseValue,
      valueRange: this.minRaiseValue,
      valueRangeBB: this.minRaiseValue / this.tableGame?.bigBlindValue!
    })
  }

  getSumBetPlayerWithoutBlinds(id: string) {
    let bet = 0;
    if (this.tableGame?.players) {
      for (let i = 0; i < this.tableGame.players.length; i++) {
        if (this.tableGame?.players[i]?.user?._id == id) {
          for (const action of this.tableGame?.players[i]?.playerHand?.actions!) {
            if (this.tableGame?.round == TableGameRoundStatusEnum.PRE_FLOP) {
              if (action?.round !== TableGameRoundStatusEnum.BLIND) {
                bet += action.bet;
              }
            } else {
              if (action.round == this.tableGame?.round) {
                bet += action.bet;
              }
            }
          }
        }
      }
    }
    return Number(bet.toFixed(2));
  }

  private _getBiggerRaiseRound() {
    let raiseArray: number[] = [];
    if (this.tableGame?.players) {
      for (const player of this.tableGame.players) {
        let playerActions;
        if (this.tableGame.round == TableGameRoundStatusEnum.PRE_FLOP) {
          playerActions = player?.playerHand?.actions.map((a: any) => a).filter((b: any) => b.round == TableGameRoundStatusEnum.PRE_FLOP || b.round == TableGameRoundStatusEnum.BLIND);
        } else {
          playerActions = player?.playerHand?.actions.map((a: any) => a).filter((b: any) => b.round == this.tableGame?.round);
        }
        const bets = playerActions?.map((a: any) => a.bet);
        const sumBet = bets?.reduce((sum: any, current: any) => sum + current, 0);
        raiseArray.push(sumBet);
      }
      return Math.max(...raiseArray.filter(a => a !== undefined));
    }
    return 0;
  }

  private _getSecondBiggerRaiseRound() {
    let raiseArray: number[] = [];
    if (this.tableGame?.players) {
      for (const player of this.tableGame.players) {
        let playerActions;
        if (this.tableGame.round == TableGameRoundStatusEnum.PRE_FLOP) {
          playerActions = player?.playerHand?.actions.filter((a: any) => a.round == TableGameRoundStatusEnum.PRE_FLOP || a.round == TableGameRoundStatusEnum.BLIND);
        } else {
          playerActions = player?.playerHand?.actions.filter((a: any) => a.round == this.tableGame?.round);
        }
        const bets = playerActions?.map((a: any) => a.bet);
        const sumBet = bets?.reduce((sum: any, current: any) => sum + current, 0);
        raiseArray.push(sumBet);
      }
      raiseArray.sort((a, b) => b - a);
      let arrayFilter = raiseArray.filter(a => a !== undefined);
      let filter = arrayFilter.filter((a: any) => a !== Math.max(...arrayFilter));
      return Math.max(...filter);
    }
    return 0;
  }

  getTopBetPlayer(): number {
    if (this.tableGame?.players) {
      let playerIndex = this.tableGame!.players.findIndex((a: any) => a?.user?.email == this.authService.getAuthenticatedUser().email);
      let result: number;
      if (this.tableGame.round == TableGameRoundStatusEnum.PRE_FLOP) {
        result = this.tableGame?.players[playerIndex]?.playerHand?.actions.filter((a: any) => a.round == TableGameRoundStatusEnum.PRE_FLOP || a.round == TableGameRoundStatusEnum.BLIND).map((a: any) => a.bet).reduce((sum: any, current: any) => sum + current, 0);
      } else {
        result = this.tableGame?.players[playerIndex]?.playerHand?.actions.filter((a: any) => a.round === this.tableGame?.round).map((a: any) => a.bet).reduce((sum: any, current: any) => sum + current, 0);
      }

      return result;
    } else {
      return 0
    }
  }

  private _getCountRaise() {
    let count = 0;
    if (this.tableGame?.players) {
      for (const player of this.tableGame.players) {
        const arrayActions = player.playerHand?.actions.filter((a: any) => a);
        if (arrayActions) {
          for (const action of arrayActions!) {
            if (action.round == this.tableGame.round && (action.action == PlayerHandActionEnum.RAISE || action.action == PlayerHandActionEnum.ALLIN)) {
              if (action.action == PlayerHandActionEnum.ALLIN) {
                if (action.bet > 0) {
                  count++;
                }
              } else {
                count++;
              }
            }
          }
        }
      }
    }
    return count;
  }

  getTopBetRound(): number {
    if (this.tableGame?.players) {
      const greaterBets: any[] = [];

      this.tableGame.players.forEach((player: any) => {
        let betValues = [];
        if (this.tableGame?.round == TableGameRoundStatusEnum.PRE_FLOP) {
          betValues = player?.playerHand?.actions.filter((a: any) => a?.round == TableGameRoundStatusEnum.PRE_FLOP || a?.round == TableGameRoundStatusEnum.BLIND).map((a: any) => a.bet);
        } else {
          betValues = player?.playerHand?.actions.filter((a: any) => a?.round === this.tableGame?.round).map((a: any) => a.bet);
        }
        const betSum = betValues?.reduce((sum: any, current: any) => sum + current, 0);
        greaterBets.push({
          playerId: player._id,
          lastAction: player.playerHand?.lastAction,
          totalBets: betSum,
        });
      });

      return Math.max(...greaterBets.map(a => a.totalBets).filter(a => a !== undefined));
    } else {
      return 0
    }
  }

  private _getTopBetCurrentTurnPlayer(): number {
    if (this.tableGame?.players) {

      let index = this.tableGame?.players.findIndex((a: any) => a?.user?._id == this.tableGame?.currentTurn);
      let result: number;
      if (this.tableGame.round == TableGameRoundStatusEnum.PRE_FLOP) {
        result = this.tableGame?.players[index]?.playerHand!.actions.filter((a: any) => a.round == TableGameRoundStatusEnum.PRE_FLOP || a.round == TableGameRoundStatusEnum.BLIND).map((a: any) => a.bet).reduce((sum: any, current: any) => sum + current, 0);
      } else {
        result = this.tableGame?.players[index]?.playerHand!.actions.filter((a: any) => a.round === this.tableGame?.round).map((a: any) => a.bet).reduce((sum: any, current: any) => sum + current, 0);
      }

      return result;
    } else {
      return 0
    }
  }

  clickShortcuts1() {
    let playerIndex = this.playersInViewOrder.findIndex((a: any) => a?.user?.email == this.authService.getAuthenticatedUser().email);
    if (this.tableGame?.round == TableGameRoundStatusEnum.PRE_FLOP) {
      
      if (+Number(this.tableGame?.bigBlindValue! * 2.5).toFixed(2) > this.playersInViewOrder[playerIndex].coinAmount! + this.betByPlayer(0)) {
        this.form.patchValue({
          bet: this.calcHigherBet() > 0 ? this.calcHigherBet() * 2.5 : this.playersInViewOrder[playerIndex].coinAmount! + this.betByPlayer(0),
          // bet: this.calcHigherBet() > 0 ? (this.playersInViewOrder[playerIndex].coinAmount! + this.betByPlayer(0))  * 2.5 : this.playersInViewOrder[playerIndex].coinAmount! + this.betByPlayer(0),
          valueRange: this.calcHigherBet() > 0 ? (this.playersInViewOrder[playerIndex].coinAmount! + this.betByPlayer(0)) * this.calcHigherBet() : this.playersInViewOrder[playerIndex].coinAmount! + this.betByPlayer(0),
          valueRangeBB: this.calcHigherBet() > 0 ? ((this.playersInViewOrder[playerIndex].coinAmount! + this.betByPlayer(0)) / this.tableGame?.bigBlindValue!) & this.calcHigherBet() : ((this.playersInViewOrder[playerIndex].coinAmount! + this.betByPlayer(0)) / this.tableGame?.bigBlindValue!) & this.calcHigherBet()
        });
      } else {
        this.form.patchValue({
          bet: this.calcHigherBet() > 0 ?  (+Number((this.calcHigherBet()) * 2.5 ).toFixed(2) ): +Number(this.tableGame?.bigBlindValue! * 2.5).toFixed(2),

          // bet: this.calcHigherBet() > 0 ?  this.calcHigherBet() * 2.5 : +Number(this.tableGame?.bigBlindValue! * 2.5).toFixed(2),

          // bet: this.calcHigherBet() > 0 ? (+Number(this.tableGame?.bigBlindValue! * 2.5).toFixed(2)) * this.calcHigherBet() : +Number(this.tableGame?.bigBlindValue! * 2.5).toFixed(2),
          valueRange: this.calcHigherBet() > 0 ? (+Number((this.calcHigherBet()) * 2.5).toFixed(2))  : +Number(this.tableGame?.bigBlindValue! * 2.5).toFixed(2),
          valueRangeBB: this.calcHigherBet() > 0 ? (+Number((+Number((this.calcHigherBet()) * 2.5).toFixed(2))* this.calcHigherBet() ))  / this.tableGame?.bigBlindValue!: +Number((+Number(this.tableGame?.bigBlindValue! * 2.5).toFixed(2)) / this.tableGame?.bigBlindValue!)
        });
      }
    } else {
      if (+Number(this.tableGame?.pot! * 0.33).toFixed(2) > this.playersInViewOrder[playerIndex].coinAmount! + this.betByPlayer(0)) {
        this.form.patchValue({
          bet: this.playersInViewOrder[playerIndex].coinAmount! + this.betByPlayer(0),
          valueRange: this.playersInViewOrder[playerIndex].coinAmount! + this.betByPlayer(0),
          valueRangeBB: (this.playersInViewOrder[playerIndex].coinAmount! + this.betByPlayer(0)) / this.tableGame?.bigBlindValue!
        });
      } else {
        this.form.patchValue({
          bet: +Number(this.tableGame?.pot! * 0.33).toFixed(2),
          valueRange: +Number(this.tableGame?.pot! * 0.33).toFixed(2),
          valueRangeBB: +Number((+Number(this.tableGame?.pot! * 0.33).toFixed(2)) / this.tableGame?.bigBlindValue!)
        });
      }
    }
  }

  clickShortcuts2() {
    let playerIndex = this.playersInViewOrder.findIndex((a: any) => a?.user?.email == this.authService.getAuthenticatedUser().email);
    if (this.tableGame?.round == TableGameRoundStatusEnum.PRE_FLOP) {
      if (+Number(this.tableGame?.bigBlindValue! * 3).toFixed(2) > this.playersInViewOrder[playerIndex].coinAmount! + this.betByPlayer(0)) {
        this.form.patchValue({
          bet: this.calcHigherBet() > 0 ?  this.calcHigherBet() * 3 : +Number(this.tableGame?.bigBlindValue! * 3).toFixed(2),
          valueRange: this.calcHigherBet() > 0 ? (this.playersInViewOrder[playerIndex].coinAmount! + this.betByPlayer(0)) * this.calcHigherBet() : this.playersInViewOrder[playerIndex].coinAmount! + this.betByPlayer(0),
          valueRangeBB: this.calcHigherBet() > 0 ? ((this.playersInViewOrder[playerIndex].coinAmount! + this.betByPlayer(0)) / this.tableGame?.bigBlindValue!) * this.calcHigherBet() : (this.playersInViewOrder[playerIndex].coinAmount! + this.betByPlayer(0)) / this.tableGame?.bigBlindValue!
        });
      } else {
        this.form.patchValue({
          bet: this.calcHigherBet() > 0 ? (+Number((this.calcHigherBet()) * 3 ).toFixed(2) ): +Number(this.tableGame?.bigBlindValue! * 3).toFixed(2),
          valueRange: this.calcHigherBet() > 0 ? (+Number((this.calcHigherBet()) * 3).toFixed(2))  : +Number(this.tableGame?.bigBlindValue! * 3).toFixed(2),
          valueRangeBB: this.calcHigherBet() > 0 ? (+Number((+Number((this.calcHigherBet()) * 3).toFixed(2))* this.calcHigherBet() ))  / this.tableGame?.bigBlindValue!: +Number((+Number(this.tableGame?.bigBlindValue! * 3).toFixed(2)) / this.tableGame?.bigBlindValue!)
        });
      }
    } else {
      if (+Number(this.tableGame?.pot! * 0.5).toFixed(2) > this.playersInViewOrder[playerIndex].coinAmount! + this.betByPlayer(0)) {
        this.form.patchValue({
          bet: this.playersInViewOrder[playerIndex].coinAmount! + this.betByPlayer(0),
          valueRange: this.playersInViewOrder[playerIndex].coinAmount! + this.betByPlayer(0),
          valueRangeBB: (this.playersInViewOrder[playerIndex].coinAmount! + this.betByPlayer(0)) / this.tableGame?.bigBlindValue!
        });
      } else {
        this.form.patchValue({
          bet: +Number(this.tableGame?.pot! * 0.5).toFixed(2),
          valueRange: +Number(this.tableGame?.pot! * 0.5).toFixed(2),
          valueRangeBB: +Number((+Number(this.tableGame?.pot! * 0.5).toFixed(2)) / this.tableGame?.bigBlindValue!)
        });
      }
    }
  }

  clickShortcuts3() {
    let playerIndex = this.playersInViewOrder.findIndex((a: any) => a?.user?.email == this.authService.getAuthenticatedUser().email);
    if (this.tableGame?.round == TableGameRoundStatusEnum.PRE_FLOP) {
      if (+Number(this.tableGame?.bigBlindValue! * 3.5).toFixed(2) > this.playersInViewOrder[playerIndex].coinAmount! + this.betByPlayer(0)) {
        this.form.patchValue({
          bet: this.calcHigherBet() > 0 ? this.calcHigherBet() * 3.5 : +Number(this.tableGame?.bigBlindValue! * 3.5).toFixed(2),
          valueRange: this.calcHigherBet() > 0 ? (this.playersInViewOrder[playerIndex].coinAmount! + this.betByPlayer(0)) * this.calcHigherBet() : this.playersInViewOrder[playerIndex].coinAmount! + this.betByPlayer(0),
          valueRangeBB: this.calcHigherBet() > 0 ? ((this.playersInViewOrder[playerIndex].coinAmount! + this.betByPlayer(0)) / this.tableGame?.bigBlindValue!) * this.calcHigherBet() : (this.playersInViewOrder[playerIndex].coinAmount! + this.betByPlayer(0)) / this.tableGame?.bigBlindValue!
        });
      } else {
        this.form.patchValue({
          bet: this.calcHigherBet() > 0 ? (+Number((this.calcHigherBet()) * 3.5 ).toFixed(2) ): +Number(this.tableGame?.bigBlindValue! * 3.5).toFixed(2),
          valueRange: this.calcHigherBet() > 0 ? (+Number((this.calcHigherBet()) * 3.5).toFixed(2))  : +Number(this.tableGame?.bigBlindValue! * 3.5).toFixed(2),
          valueRangeBB: this.calcHigherBet() > 0 ? (+Number((+Number((this.calcHigherBet()) * 3.5).toFixed(2))* this.calcHigherBet() ))  / this.tableGame?.bigBlindValue!: +Number((+Number(this.tableGame?.bigBlindValue! * 3.5).toFixed(2)) / this.tableGame?.bigBlindValue!)
        });
      }
    } else {
      if (+Number(this.tableGame?.pot! * 0.75).toFixed(2) > this.playersInViewOrder[playerIndex].coinAmount! + this.betByPlayer(0)) {
        this.form.patchValue({
          bet: this.playersInViewOrder[playerIndex].coinAmount! + this.betByPlayer(0),
          valueRange: this.playersInViewOrder[playerIndex].coinAmount! + this.betByPlayer(0),
          valueRangeBB: (this.playersInViewOrder[playerIndex].coinAmount! + this.betByPlayer(0)) / this.tableGame?.bigBlindValue!
        });
      } else {
        this.form.patchValue({
          bet: +Number(this.tableGame?.pot! * 0.75).toFixed(2),
          valueRange: +Number(this.tableGame?.pot! * 0.75).toFixed(2),
          valueRangeBB: +Number((+Number(this.tableGame?.pot! * 0.75).toFixed(2)) / this.tableGame?.bigBlindValue!)
        });
      }
    }
  }

  getMaxRange() {
    return Number((Number((this.getMaxAmount() + this.betByPlayer(0)).toFixed(2)) + 1).toFixed(2));
  }

  clickShortcuts4() {
    let playerIndex = this.playersInViewOrder.findIndex((a: any) => a?.user?.email == this.authService.getAuthenticatedUser().email);
    if (playerIndex > -1) {
      this.form.patchValue({
        bet: Number((this.playersInViewOrder[playerIndex].coinAmount! + this.betByPlayer(0)).toFixed(2)),
        valueRange: Number((this.playersInViewOrder[playerIndex].coinAmount! + this.betByPlayer(0)).toFixed(2)),
        valueRangeBB: ((Number(((this.playersInViewOrder[playerIndex].coinAmount! + this.betByPlayer(0))).toFixed(2)) / this.tableGame?.bigBlindValue!).toFixed(2))
      });
    }
  }

  openBuyIn(index: number) {
    this.sitAtTableGameIndex = index;
    this.ngxSpinnerService.show();
    this.gameCoinService.getBalance().subscribe({
      next: data => {
        this.ngxSpinnerService.hide();
        this.balanceValue = data;
        this.balanceValue.coinPokerFiValue = Number(data.coinPokerFiValue.toFixed(2));

        this.balanceValue.coinUsdValue = Number(data.coinUsdValue.toFixed(2));
        this.formBuyIn.patchValue({
          buyIn: this.tableGame?.buyInMinimum,
          valueRangeBuyIn: this.tableGame?.buyInMinimum
        });
      },
      error: err => {
        this.ngxSpinnerService.hide();
        console.error(err);
      }
    });
    if (index === 999) {
      this.joinQueue = true
    } else {
      this.joinQueue = false
    }
  }
  getByAproxBlindValue() {
    this.tableGameService.getTableByAproxBlindValue(this.tableGame._id).subscribe({
      next: data => {
        window.open(`/pages/tablegame/id/${data[0]._id}`, '_blank');

      }
    })
  }

  removeFromQueue() {
    this._commonGateway.removeFromWaitingList(this.tableGame._id, this.user._id!, 'out', 0);
    this._commonGateway.tableGameDetails.subscribe({
      next: (response: TableGameGetDetailsResponseDto) => {
        this.alreadyInQueue = false
        this.playerWaitingListPosition = response.waitingList.findIndex((item: any) => item.user === this.user?._id)
      }
    });

  }

  requestToJoinQueue() {
    const valueToFixed = Number(Number(this.formBuyIn.controls['valueRangeBuyIn'].value).toFixed(2))
    this._commonGateway.requestUpdateTableWaitingList(this.tableGame._id, this.user._id!, 'in', valueToFixed);
    this._commonGateway.tableGameDetails.subscribe({
      next: (response: TableGameGetDetailsResponseDto) => {
        this.alreadyInQueue = true
        this.playerWaitingListPosition = response.waitingList.findIndex((item: any) => item.user === this.user?._id)
      }
    });


    const modalElement = document.getElementById('closeModal');
    modalElement?.click()
  }
  onRangeScroll(event: WheelEvent) {
    event.preventDefault();
    const rangeInput = event.target as HTMLInputElement;
    let currentValue = +rangeInput.value;
    const step = 1;

    if (event.deltaY < 0) {

      currentValue = Math.min(currentValue + step, this.tableGame!.buyInMaximum);

    } else {

      currentValue = Math.max(currentValue - step, this.tableGame!.buyInMinimum);
    }


    this.formBuyIn.get('valueRangeBuyIn')?.setValue(currentValue);
    this.formBuyIn.get('buyIn')?.setValue(currentValue);
    // if(this.balanceValue!.coinUsdValue < this.formBuyIn.get('buyIn')?.value ) {
    //   this.buyInButtonDisable = true
    // } else {
    //   this.buyInButtonDisable = false
    // }
    if (this.tableGame?.coin === 'USDT') {
      if (currentValue > this.balanceValue?.coinUsdValue!) {
        this.formBuyIn.patchValue({
          buyIn: this.balanceValue?.coinUsdValue!,
          valueRangeBuyIn: this.balanceValue?.coinUsdValue!
        })
      }
    }
    if (this.tableGame?.coin === 'POKERFI') {
      if (currentValue > this.balanceValue?.coinPokerFiValue!) {
        this.formBuyIn.patchValue({
          buyCoin: this.balanceValue?.coinPokerFiValue!,
          valueRangeBuyIn: this.balanceValue?.coinPokerFiValue!
        })
      }
    }

  }

  listenedBuyInRange(event: any, type: string) {
    if (type === 'range') {
      const rangeValue = event.target.value;
      this.formBuyIn.patchValue({
        buyIn: rangeValue
      })
      if (this.tableGame?.coin === 'USDT') {
        if (rangeValue > this.balanceValue?.coinUsdValue!) {
          this.formBuyIn.patchValue({
            buyIn: this.balanceValue?.coinUsdValue!,
            valueRangeBuyIn: this.balanceValue?.coinUsdValue!
          })
        }
      }
      if (this.tableGame?.coin === 'POKERFI') {
        if (rangeValue > this.balanceValue?.coinPokerFiValue!) {
          this.formBuyIn.patchValue({
            buyCoin: this.balanceValue?.coinPokerFiValue!,
            valueRangeBuyIn: this.balanceValue?.coinPokerFiValue!
          })
        }
      }
    } else {
      const inputValue = event.target.value
      if (inputValue > this.tableGame?.buyInMinimum!) {
        this.formBuyIn.patchValue({
          valueRangeBuyIn: inputValue
        })
      }
      if (inputValue > this.tableGame?.buyInMaximum!) {
        this.formBuyIn.patchValue({
          buyIn: this.tableGame?.buyInMaximum,
          valueRangeBuyIn: this.tableGame?.buyInMaximum
        })
      }
      if (this.tableGame?.coin === 'USDT') {
        if (inputValue > this.balanceValue?.coinUsdValue!) {
          this.formBuyIn.patchValue({
            buyIn: this.balanceValue?.coinUsdValue!,
            valueRangeBuyIn: this.balanceValue?.coinUsdValue!
          })
        }
      }
      if (this.tableGame?.coin === 'POKERFI') {
        if (inputValue > this.balanceValue?.coinPokerFiValue!) {
          this.formBuyIn.patchValue({
            buyIn: this.balanceValue?.coinPokerFiValue!,
            valueRangeBuyIn: this.balanceValue?.coinPokerFiValue!
          })
        }
      }

    }
    if (this.balanceValue!.coinUsdValue < this.formBuyIn.get('buyIn')?.value || this.formBuyIn.get('buyIn')?.value < this.tableGame!.buyInMinimum) {
      this.buyInButtonDisable = true
    } else {
      this.buyInButtonDisable = false
    }

  }

  listenedRangeBuyCoin(event: any, type: string) {
    if (type === 'range') {

      const rangeValue = event.target.value;
      this.formBuyCoin.patchValue({
        buyCoin: rangeValue
      })
    } else {
      const inputValue = event.target.value
      this.formBuyCoin.patchValue({
        valueRangeBuyCoin: inputValue
      })
      if (inputValue > this.buyCoinMax) {
        
        this.formBuyCoin.patchValue({
          buyCoin: this.buyCoinMax,
          valueRangeBuyCoin: this.buyCoinMax
        });
      }
    }
  }

  openModalBuyCoin() {
    this.ngxSpinnerService.show()
    this.gameCoinService.getBalance().subscribe({
      next: (data) => {
        this.ngxSpinnerService.hide();
        this.balanceValue = data;
        this.balanceValue.coinPokerFiValue = Number(data.coinPokerFiValue.toFixed(2));
        this.balanceValue.coinUsdValue = Number(data.coinUsdValue.toFixed(2));

        let playerIndex = this.tableGame!.players.findIndex((a: any) => a?.user?.email == this.authService.getAuthenticatedUser().email);

        this.buyCoinMin = this.tableGame?.buyInMinimum! - this.tableGame?.players[playerIndex]?.coinAmount! >= 0 ? this.tableGame?.buyInMinimum! - this.tableGame?.players[playerIndex]?.coinAmount! : 0;
        this.buyCoinMax = this.tableGame?.buyInMaximum! - (this.tableGame?.players[playerIndex]?.coinAmount! + this.tableGame?.players[playerIndex]?.rebuy?.value!);

        this.formBuyCoin.patchValue({
          buyCoin: this.buyCoinMax,
          valueRangeBuyCoin: this.buyCoinMax
        });
        if (this.tableGame?.buyInMaximum! - (this.tableGame?.players[playerIndex]?.coinAmount! + this.tableGame?.players[playerIndex]?.rebuy?.value!) <= 0) {
          this.toastrService.error(`Você não pode comprar fichas nesse momento!`, '', { progressBar: true });
          document.getElementById('cancelBuyCoin')?.click();
        }

      },
      error: (err) => {
        console.error(err);
        this.ngxSpinnerService.hide();
      }
    });
  }

  async buyCoin() {

    let valueToFixed = Number(Number(this.formBuyCoin.controls['buyCoin'].value).toFixed(2))
     this._gameGateway.requestBuyCoin(this.tableGame?._id!, valueToFixed);
    this.ngxSpinnerService.show();
    this._gameGateway.tableGameBuyCoinResponse.subscribe({
      next: (response: ResponseDto) => {
        document.getElementById('cancelBuyCoin')?.click();
        this.ngxSpinnerService.hide();
        if (response.errors) {
          this.toastrService.error(`Erro ao comprar fichas!`, '', { progressBar: true });
        }
      },
      error: (err) => {
        console.error(err);
        this.ngxSpinnerService.hide();
        document.getElementById('cancelBuyCoin')?.click();
        this.toastrService.error(`Erro ao comprar fichas!`, '', { progressBar: true });
      }
    });
  }
  getTimeBankMapValueByPlayer(playerId: string) {
   const value = this.timeBankMap.find((item) => item.id === `${this.tableGame._id.toString()}/${playerId}`)?.value!

   if (value) {
    
    return value > 0 ? value : 0
   } else {
    if( value === 0) {
      return 0
    } else {
      return this.tableGame.timeBank

    }
    
   }
   
  }
  leavePokerGame() {
    this.ngxSpinnerService.show();
    if (!this.canLeavePokerGame) {
      this.ngxSpinnerService.hide();
      return;
    }

    this.canLeavePokerGame = false;
    this.timeBankMap.filter((item) => item.id === `${this.tableGame._id.toString()}/${this.user._id.toString()}`)
    this._gameGateway.leaveGame({ tableGameId: this.tableGame?._id! });

    setTimeout(() => {
      this.ngxSpinnerService.hide();
      this.canLeavePokerGame = true;
    }, 6000);
  }

  requestSittingOut() {
    this._gameGateway.requestSittingOut({ tableGameId: this.tableGame?._id! });
  }

  getPotValue(i: number) {
    if (this.tableGame && this.visualPots.length > i) {
      return this.visualPots[i].currentValue;
    }
    return 0;
  }

  animationSetChipsToPot(dto: AnimationSetChipsToPotResponseDto) {

    for (let i = 0; i < dto.playersId.length; i++) {
      let index = this.playersInViewOrder.findIndex((a: any) => a?.user?._id == dto.playersId[i]);

      if (this.betByPlayer(index) > 0) {
        let elementName = `animationChipsGoingToVisualPot${dto.visualPotIndex}Index${index}`;
        let animationName = `startAnimationChipsGoingToVisualPot${dto.visualPotIndex}Index${index}`;

        let element = document.getElementById(elementName);
        SoundUtil.play('../../assets/sounds/action-bet-raise-call-allin.m4a', 2);
        element?.classList.add(animationName);
        setTimeout(() => {
          element?.classList.remove(animationName);
        }, 1000);
      }

    }

  }

  showAnimationGiveChipsToWinners(dto: AnimationGiveChipsToWinnersResponseDto) {

    let index = this.playersInViewOrder.findIndex((a: any) => a?.user?._id == dto.playerId);

    if (index > -1) {
      let elementName = `animationChipsFromPot${dto.visualPotIndex}ToPlayerIndex${index}`;
      let animationName = `startAnimationChipsFromPot${dto.visualPotIndex}ToPlayerIndex${index}`;

      let element = document.getElementById(elementName);
      SoundUtil.play('../../assets/sounds/chips-to-winner.wav', 1);
      element?.classList.add(animationName);
      setTimeout(() => {
        element?.classList.remove(animationName);
      }, 1000);
    }
  }

  getChipColor(value: number): { [key: string]: number } {
    const fichas = [
      { name: 'FICHA-25M.png', value: 25000000 },
      { name: 'FICHA-5M.png', value: 5000000 },
      { name: 'FICHA-1M.png', value: 1000000 },
      { name: 'FICHA-100K.png', value: 100000 },
      { name: 'FICHA-25K.png', value: 25000 },
      { name: 'FICHA-5K.png', value: 5000 },
      { name: 'FICHA-1K.png', value: 1000 },
      { name: 'FICHA-500.png', value: 500 },
      { name: 'FICHA-100.png', value: 100 },
      { name: 'FICHA-25.png', value: 25 },
      { name: 'FICHA-5.png', value: 5 },
      { name: 'FICHA-1.png', value: 1 },
      { name: 'FICHA-25C.png', value: 0.25 },
      { name: 'FICHA-5C.png', value: 0.05 },
      { name: 'FICHA-1C.png', value: 0.01 },
    ];

    let remainingValue = value;
    const result: { [key: string]: number } = {};

    const fichasFiltradas = value > 10
    ? fichas.filter(ficha => ficha.value >= 1) 
    : fichas;

    for (const ficha of fichasFiltradas) {
      let count = 0;
      while (remainingValue >= ficha.value) {
        count++;
        remainingValue -= ficha.value;
      }
      if (count > 0) {
        result[`../../../assets/game/new-fichas-png/${ficha.name}`] = count;
      }
    }

    return this.combineChips(result);
  }

  combineChips(chipStacks: { [key: string]: number }): { [key: string]: number } {
    const chipKeys = Object.keys(chipStacks);
    if (chipKeys.length <= 4) {
      return chipStacks;
    }

    let combinedValue = 0;
    const combinedChips: { [key: string]: number } = {};
    for (let i = 0; i < chipKeys.length; i++) {
      const key = chipKeys[i];
      combinedValue += chipStacks[key];
      if (i < chipKeys.length - 4) {
        delete chipStacks[key];
      } else {
        combinedChips[key] = chipStacks[key];
      }
    }

    const smallestRemainingChip = chipKeys[chipKeys.length - 4];
    combinedChips[smallestRemainingChip] += combinedValue;

    return combinedChips;
  }


  getChipColorAnimation(value: number) {
    if (value > 0 && value < 101) {
      return '../../../assets/game/fichas-svg/FICHA-BRANCA.svg';
    } else if (value > 100 && value < 500) {
      return '../../../assets/game/fichas-svg/FICHA-VERMELHA.svg';
    } else if (value > 499 && value < 1000) {
      return '../../../assets/game/fichas-svg/FICHA-AZUL.svg';
    } else if (value > 999 && value < 5000) {
      return '../../../assets/game/fichas-svg/FICHA-AMARELA.svg';
    } else if (value > 4999 && value < 10000) {
      return '../../../assets/game/fichas-svg/FICHA-VERDE.svg';
    } else if (value > 9999 && value < 100000) {
      return '../../../assets/game/fichas-svg/FICHA-PRETA.svg';
    } else if (value > 99999) {
      return '../../../assets/game/fichas-svg/FICHA-ROXA.svg';
    }
    return '../../../assets/game/fichas-svg/FICHA-BRANCA.svg';
  }

  goHome() {
    const url = this.router.serializeUrl(this.router.createUrlTree(['/pages/home']));
    window.open(url, "_blank");
  }

  goWallet() {
    const url = this.router.serializeUrl(this.router.createUrlTree(['/pages/profile']));
    window.open(url, "_blank");
  }

  changeDeck(deck: 'normal' | 'colored') {
    this.selectedDeck = deck;
    localStorage.setItem('selectedDeck', deck);
  }

}
