import { AuthService } from 'src/services/auth.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/services/user.service';
import { ModalSignUpComponent } from '../modal-sign-up/modal-sign-up.component';
import { PlayerDataService } from 'src/services/player-data.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private toastrService: ToastrService,
    private router: Router,
    private ngxSpinnerService: NgxSpinnerService,
    private translateService: TranslateService,
    private modalService: NgbModal,
    private playerDataService: PlayerDataService
  ) {
    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(20)]],
      remember: [false, [Validators.required]],
    })
  }

  ngOnInit(): void { }

  exit() {
    this.modalService.dismissAll();
  }

  openModalSignUp() {
    this.modalService.dismissAll();
    this.modalService.open(ModalSignUpComponent);
  }

  onSubmit() {

    if (this.form.pristine || !this.form.valid) {
      return;
    }

    this.ngxSpinnerService.show();

    const data = Object.assign({}, this.form.value);
    this.authService.authenticate(data).subscribe(
      (data) => this.loginSuccess(data),
      (error) => this.runError(error)
    );
  }

  loginSuccess(data: any) {

    let user = JSON.stringify(data);
    localStorage.setItem('user', user);
    this.ngxSpinnerService.hide();
    this.modalService.dismissAll();

    this.playerDataService.getPlayerData().subscribe({
      next: (data) => {
        let newUser = JSON.parse(user);

        Object.assign(newUser, { nickname: data.nickname });
        if (data?.profilePicture) {
          Object.assign(newUser, { profilePicture: data.profilePicture });
        }
        if (data?.notes) {
          Object.assign(newUser, { notes: data.notes });
        }
        localStorage.setItem('user', JSON.stringify(newUser));
        this.router.navigate(['/pages/home']);

      },
      error: (error) => {
        if (error.error.errors == 'playerData not found!') {
          this.router.navigate(['/first-access']);
        }
      }
    });
  }

  runError(exception: any): void {
    console.error(exception);
    this.ngxSpinnerService.hide();
    if (exception.error.errors == 'email is invalid!') {
      this.translateService.get('TOASTR.INVALID_EMAIL').subscribe({
        next: data => {
          this.toastrService.error(data, undefined, { progressBar: true, });
        }
      });
    } else if (exception.error.errors == 'Email and/or password are invalid!') {
      this.translateService.get('TOASTR.INVALID_EMAIL_OR_PASSWORD').subscribe({
        next: data => {
          this.toastrService.error(data, undefined, { progressBar: true, });
        }
      });
    } else {
      this.translateService.get('TOASTR.ERROR_LOGIN').subscribe({
        next: data => {
          this.toastrService.error(data, undefined, { progressBar: true, });
        }
      });
    }
  }

}
