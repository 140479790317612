<section class="background-modal p-3" [formGroup]="form" (ngSubmit)="onSubmit()">
  <div (click)="exit()" class="d-flex justify-content-end cursor-pointer">
    <img src="../../../../assets/icons/icon-x.svg" />
  </div>
  <div class="d-flex flex-column justify-content-center align-items-center">
    <h6 class="heading-700-sm text-white">{{ 'PROFILE.MODAL_REQUEST.REQUEST' | translate }}</h6>
  </div>
  <label class="form-label text-white body-400-md">{{ 'PROFILE.MODAL_BUY_COIN.SELECT' | translate }}</label>
  <select formControlName="select" class="form-select mb-3" aria-label="Default select example">
    <option selected disabled>{{ 'PROFILE.MODAL_BUY_COIN.SELECT' | translate }}...</option>
    <option value="POKERFI">PKF Game</option>
    <option value="USDT">USDT Game</option>
  </select>
  <div class="d-flex flex-column my-3">
    <p class="text-start text-white body-400-md mb-1">{{ 'PROFILE.MODAL_REQUEST.ACCOUNT_BALANCE' | translate }}</p>
    <p *ngIf="form.controls['select'].value == 'POKERFI'" class="text-start heading-500-md fw-700 font-color-yellow">PKF_RBP: {{balanceValue?.pokerfiRakeBackPoints}}</p>
    <p *ngIf="form.controls['select'].value == 'USDT'" class="text-start heading-500-md fw-700 font-color-yellow">USDT_RBP: {{balanceValue?.usdRakeBackPoints}}</p>
  </div>
  <p class="mb-2 text-white body-400-md">{{ 'PROFILE.MODAL_REQUEST.VALUE' | translate }}</p>
  <div class="input-group mb-3">
    <!-- <span class="input-group-text" id="basic-addon1">USD</span> -->
    <!-- <input type="text" class="form-control" mask="separator.2" thousandSeparator="" formControlName="withdrawValue" > -->
    <input type="text" formControlName="withdrawValue" class="form-control input-other" currencyMask [options]="{ prefix: '', thousands: '', decimal: '.', precision: '2' }">
  </div>
  <div class="d-flex flex-column mt-3">
    <p class="text-end text-white body-400-md mb-1">{{ 'PROFILE.MODAL_REQUEST.TEXT' | translate }}</p>
    <p *ngIf="form.controls['select'].value == 'POKERFI'" class="text-end text-white heading-500-md">PKF: {{getPokerfiValue()}}</p>
    <p *ngIf="form.controls['select'].value == 'USDT'" class="text-end text-white heading-500-md">USDT: {{getPokerfiValue()}}</p>
  </div>
  <div class="mt-4 row align-items-center">
    <p class="text-white" *ngIf="nextDate && _translateService.currentLang !== 'en'">Saque disponível a partir de {{nextDate | date: 'dd/MM/yyyy, HH:mm:ss'}}</p>
    <p class="text-white" *ngIf="nextDate && _translateService.currentLang == 'en'">Withdrawal available from {{nextDate | date: 'MM/dd/yyyy, HH:mm:ss'}}</p>
    <div class="col d-flex justify-content-center align-items-center">
      <button class="btn-delete button-bold mx-2" (click)="exit()">{{ 'PROFILE.MODAL_REQUEST.BTN_CANCEL' | translate }}</button>
    </div>
    <div class="col align-items-center">
      <button type="submit" (click)="onSubmit()" [disabled]="!this.form.controls['withdrawValue'].value" class="btn-continue button-bold mx-2">{{ 'PROFILE.MODAL_REQUEST.BTN_REQUEST' | translate }}</button>
    </div>
  </div>
</section>
