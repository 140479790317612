<div style="background-color: #1E2129; padding-bottom: 4px;">
    <div class="chat-content">
        <div class="card">
            <div id="overflowTest">
                <ul class="list-group list-group-flush">
                    <li class="list-group-item" *ngFor="let chatMessage of _chatGateway.messages | async">
                        <strong>{{chatMessage.nickname}}:&nbsp;</strong>
                        <span>{{chatMessage.message}}</span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <form (ngSubmit)="sendMessage(sendForm)" #sendForm="ngForm">
        <div class="chat-send row mt-2">
            <div class="col-10">
                <input type="text" name="message" class="form-control position-and-zindex " id="input-message" (keydown.enter)="handleEnterKey($event)" ngModel>
            </div>
            <div class="col-2">
                <button type="submit" class="btn btn-warning position-and-zindex ">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-send" viewBox="0 0 16 16">
                        <path
                            d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576zm6.787-8.201L1.591 6.602l4.339 2.76z" />
                    </svg>
                </button>
            </div>
        </div>
    </form>
</div>