import { NgxSpinnerService } from 'ngx-spinner';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalPixPaymentComponent } from '../modal-pix-payment/modal-pix-payment.component';
import { WalletService } from 'src/services/wallet.service';
import { environment } from '../../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { BinanceService } from '../../../../services/binance.service';
import { CoinPairEnum } from '../../../dtos/enum/coin-pair.enum';
import { ObservableService } from '../../../../services/observable.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-modal-buy-coin',
  templateUrl: './modal-buy-coin.component.html',
  styleUrls: ['./modal-buy-coin.component.scss']
})
export class ModalBuyCoinComponent implements OnInit {

  sixty: boolean = false;
  oneHundredSixty: boolean = false;
  twoHundredSixty: boolean = false;
  form: FormGroup;
  amountToReceive: number = 0;

  avgPriceUsdtBrl: any;
  avgPricePokerfiUsdt: any;
  avgPricePokerfiBrl: any;
  tfa: any;

  constructor(
    private modalService: NgbModal,
    private fb: FormBuilder,
    private readonly _walletService: WalletService,
    private readonly _ngxSpinnerService: NgxSpinnerService,
    private readonly _toastrService: ToastrService,
    private readonly _ngbActiveModal: NgbActiveModal,
    private readonly _observableService: ObservableService,
    private readonly _binanceService: BinanceService,
    private readonly _translateService: TranslateService
  ) {

    this.form = this.fb.group({
      select: ['', [Validators.required]],
      payWhith: ['', [Validators.required]],
      value: ['', [Validators.required]],
      code: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.getPrices();
    this._walletService.get2fa().subscribe({
      next: response => {
        this.tfa = response;
      },
      error: error => {
        console.error(error);
      }
    });
  }

  async getPrices() {
    const response = await this._binanceService.getAvgPricePair(CoinPairEnum.USDTBRL).toPromise();
    this.avgPriceUsdtBrl = Number(response?.price);

    this._walletService.getPricePair('pokerfi', 'usdt').subscribe({
      next: data => {
        this.avgPricePokerfiUsdt = data;
        this.avgPricePokerfiBrl = this.avgPricePokerfiUsdt * this.avgPriceUsdtBrl;
      }
    })
  }

  exit() {
    this.modalService.dismissAll();
  }

  changeSelect() {
    if(this.form.controls['select'].value == 'POKERFI') {
      if(this.form.controls['payWhith'].value == 'BRL') {
        this.form.patchValue({
          payWhith: 'BRL'
        });
      } else {
        this.form.patchValue({
          payWhith: 'POKERFI'
        });
      }
    } else if(this.form.controls['select'].value == 'USDT') {
      if(this.form.controls['payWhith'].value == 'BRL') {
        this.form.patchValue({
          payWhith: 'BRL'
        });
      } else {
        this.form.patchValue({
          payWhith: 'USDT'
        });
      }
    }
  }

  calculateAmountToReceive() {

    if (this.form.controls['value'].value == '' || !this.form.controls['value'].value) {
      return this.amountToReceive = 0;
    }

    switch (this.form.controls['payWhith'].value) {
      case 'USDT':
        return this.amountToReceive = Number(this.form.controls['value'].value);

      case 'POKERFI':
        return this.amountToReceive = Number(this.form.controls['value'].value);

      case 'BRL':
        if(this.form.controls['select'].value == 'POKERFI'){
          return this.amountToReceive = Number(this.form.controls['value'].value) / this.avgPricePokerfiBrl;
        } else if(this.form.controls['select'].value == 'USDT') {
          return this.amountToReceive = Number(this.form.controls['value'].value) / this.avgPriceUsdtBrl;
        }
    }

    return this.amountToReceive = 0;
  }

  submit() {

    if(this.form.controls['value'].value == 0 || this.form.controls['value'].value == '') {
      return;
    }

    if (this.form.controls['payWhith'].value == 'USDT' || this.form.controls['payWhith'].value == 'POKERFI') {

      if(this.form.controls['code'].value == '') {
        return;
      }

      if(!this.tfa?._id) {
        this._translateService.get('PROFILE.MODAL_BUY_COIN.TOASTR_CODE').subscribe({
          next: (data: any) => {
            this._toastrService.warning(data, '', {progressBar: true});
          },
        });
        setTimeout(() => {
          document.getElementById('wallet')?.click();
        }, 3000);
        return;
      }

      this._ngxSpinnerService.show();
      let dto = {
        symbol: this.form.controls['select'].value,
        to: environment.hotWallet,
        value: this.form.controls['value'].value.toString(),
        code: Number(this.form.controls['code'].value),
      }

      this._walletService.transferToHotwallet(dto).subscribe({
        next: response => {
          this._toastrService.success('Solicitação de compra realizada com sucesso!', '', { progressBar: true });
          this._ngxSpinnerService.hide();
          this._ngbActiveModal.close(true);
        },
        error: error => {
          console.error(error);
          this._toastrService.error('Erro ao solicitar compra!', '', { progressBar: true });
          this._ngxSpinnerService.hide();
          this._ngbActiveModal.close(false);
        }
      });

    } else if (this.form.controls['payWhith'].value == 'BRL') {

      let dto = {
        expiration: 300,
        value: this.form.controls['value'].value,
        buyerName: 'name',
        buyerCpf: '12312312312',
        buyerEmail: 'email@email.com',
        buyerPhone: '12312341234',
        campaignId: this.form.controls['select'].value,
        campaignName: this.form.controls['select'].value,
        campaignStartDate: new Date(),
        campaignEndDate: new Date()
      }

      this._ngxSpinnerService.show();
      this._observableService.generateQrCode(dto).subscribe({
        next: (response: any) => {
          this._ngxSpinnerService.hide();
          this._ngbActiveModal.close(true);
          const modalRef = this.modalService.open(ModalPixPaymentComponent);
          modalRef.componentInstance.qrCode = response.pix;
          modalRef.componentInstance.pixCreatedAt = new Date();
        },
        error: error => {
          this._ngxSpinnerService.hide();
          console.error(error);
        }
      });

    }
  }
}
