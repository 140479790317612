import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CashGameResponseDto } from 'src/app/dtos/cash-game/cash-game-response.dto';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class CashGameService extends BaseService {

  url: string = `${this.gameBackoffice}cash-game`;

  constructor(
    private httpClient: HttpClient,
  ) {
    super();
  }

  list(mode: string) {
    let query = '';
    if (mode !== 'ALL') {
      query = `?mode=${mode}`
    }
    return this.httpClient
      .get<CashGameResponseDto[]>(`${this.url}/instances${query}`, this.authorizedHeader);
  }

  joinCashGame(_id: string) {
    return this.httpClient
      .post<CashGameResponseDto[]>(`${this.url}/join/id/${_id}`, undefined, this.authorizedHeader);
  }

  leaveCashGame(_id: string) {
    return this.httpClient
      .post<CashGameResponseDto[]>(`${this.url}/leave/id/${_id}`, undefined, this.authorizedHeader);
  }

  getById(id: string) {
    return this.httpClient.get<CashGameResponseDto>(`${this.url}/id/${id}`, this.authorizedHeader);
  }
}
