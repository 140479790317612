import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { PlayerDataResponseDto } from 'src/app/dtos/player-data/player-data-response.dto';
import { FinalizeRegistrationRequestDto } from 'src/app/dtos/user/finalize-registration-request.dto';
import { PlayerDataRegisterDto } from 'src/app/dtos/player-data/player-data-register.dto';
import { UpdateProfilePictureRequestDto } from 'src/app/dtos/user/update-profile-picture-request';
import PlayerDataAddNotesRequest from 'src/app/dtos/user/player-data-add-notes-request';

@Injectable()
export class PlayerDataService extends BaseService {

  userApi: string = `${this.gameBackoffice}player-data`;

  constructor(private httpClient: HttpClient) {
    super();
  }

  getPlayerData() {
    return this.httpClient.get<PlayerDataResponseDto>(`${this.userApi}/get-user`, this.authorizedHeader );
  }

  register(dto: PlayerDataRegisterDto) {
    return this.httpClient.post(`${this.userApi}/register`, dto,  this.authorizedHeader);
  }


  updateProfilePicture(dto: UpdateProfilePictureRequestDto) {
    return this.httpClient.put(`${this.userApi}/update-profile-picture`, dto, this.authorizedHeader);
  }
  addNotes(dto: PlayerDataAddNotesRequest) {
    return this.httpClient.put<PlayerDataResponseDto>(`${this.userApi}/add-notes`, dto, this.authorizedHeader);
  }

}
