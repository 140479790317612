import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-hand-image',
  templateUrl: './hand-image.component.html',
  styleUrls: ['./hand-image.component.scss'],
})
export class HandImageComponent{
  @Input() cards: string[] = [];
}
