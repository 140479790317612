export class ChatMessageDto{
    user?: string;
    table?: string;
    message?: string;
    nickname?: string;


    constructor(user:string,table:string,message:string, nickname:string){
        this.user = user;
        this.table = table;
        this.message = message;
        this.nickname = nickname;
    }
}