import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {

  constructor(private httpClient: HttpClient){

  }

  // tableList = [
  //   {
  //     date: 'Oct 23, 2022',
  //     value: 'US$ 200',
  //     return: true,
  //     game: 'NL Hold’em',
  //   },
  //   {
  //     date: 'Oct 23, 2022',
  //     value: 'US$ 200',
  //     return: false,
  //     game: 'NL Hold’em',
  //   },
  //   {
  //     date: 'Oct 23, 2022',
  //     value: 'US$ 200',
  //     return: true,
  //     game: 'NL Hold’em',
  //   },
  //   {
  //     date: 'Oct 23, 2022',
  //     value: 'US$ 200',
  //     return: false,
  //     game: 'NL Hold’em',
  //   },
  //   {
  //     date: 'Oct 23, 2022',
  //     value: 'US$ 200',
  //     return: true,
  //     game: 'NL Hold’em',
  //   },
  //   {
  //     date: 'Oct 23, 2022',
  //     value: 'US$ 200',
  //     return: false,
  //     game: 'NL Hold’em',
  //   },
  //   {
  //     date: 'Oct 23, 2022',
  //     value: 'US$ 200',
  //     return: true,
  //     game: 'NL Hold’em',
  //   },
  //   {
  //     date: 'Oct 23, 2022',
  //     value: 'US$ 200',
  //     return: false,
  //     game: 'NL Hold’em',
  //   },
  // ];

  // Code = [
  //   {
  //     qrCode: '../assets/icons/imagem_qrcode.svg',
  //   },
  // ];

  // async tableFunction() {
  //   return this.tableList;
  // }

}
