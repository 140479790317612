<div class="container-fluid">
  <div class="d-flex justify-content-center align-items-center " style="width: 100wh; height: 100vh;">
    <div id="myDiv" class="d-flex fkex-fill justify-content-center align-items-center " [ngStyle]="divStyles">
      <div class="mesa">


        <!-- ------------------- Player0 ------------------- -->
        <div>
          <div class="profilePicture0 d-flex align-items-center justify-content-center">
            <!-- <div id="firstLetter" [ngStyle]="firstLetter">W</div> -->
            <img class="img-fluid img0"
              src="https://s2-techtudo.glbimg.com/Bxr-QA4_gL25CarCCxr9JQFybt8=/0x0:1024x609/924x0/smart/filters:strip_icc()/i.s3.glbimg.com/v1/AUTH_08fbf48bc0524877943fe86e43087e7a/internal_photos/bs/2022/c/u/15eppqSmeTdHkoAKM0Uw/dall-e-2.jpg"
              alt="">
            <div class="info0">
              <div [ngStyle]="font20px" class="nickname0 d-flex w-100 justify-content-center">Wilton</div>
              <div [ngStyle]="font20px" class="balance0 d-flex w-100 justify-content-center">$ 100</div>
            </div>
          </div>
          <div class="progress-bar0">
            <span class="progress" [ngStyle]="{ 'width': progressWidth + '%' }"
              [ngClass]="{'bg-red': progressWidth <= 25 }"></span>
          </div>
          <div class="div-card-player0">
            <img class="card-player0-1" [src]="'../../../assets/game/cards/2clubs.png'">
            <img class="card-player0-2" [src]="'../../../assets/game/cards/7spades.png'">
          </div>
        </div>

        <!-- ------------------- Player1 ------------------- -->
        <div>
          <div class="profilePicture1 d-flex align-items-center justify-content-center">
            <!-- <div id="firstLetter" [ngStyle]="firstLetter">W</div> -->
            <img class="img-fluid img1"
              src="https://s2-techtudo.glbimg.com/1o2J-rf2G9qtlQlm82gaq-mFBec=/0x129:1024x952/924x0/smart/filters:strip_icc()/i.s3.glbimg.com/v1/AUTH_08fbf48bc0524877943fe86e43087e7a/internal_photos/bs/2023/7/i/ME2AxRRoygUyFPCDe0jQ/3.png"
              alt="">

            <div class="info1">
              <div [ngStyle]="font16px" class="nickname1 d-flex w-100 justify-content-center">Caio</div>
              <div [ngStyle]="font16px" class="balance1 d-flex w-100 justify-content-center">$ 200</div>
            </div>
          </div>
          <div class="progress-bar1">
            <span class="progress" [ngStyle]="{ 'width': progressWidth + '%' }"
              [ngClass]="{'bg-red': progressWidth <= 25 }"></span>
          </div>
          <div class="div-cards-face-down1">
            <img class="card-face-down1-1" [src]="'../../../assets/game-v2/mesa/Card_Back_A.png'">
            <img class="card-face-down1-2" [src]="'../../../assets/game-v2/mesa/Card_Back_A.png'">
          </div>
          <div class="bet0" [ngStyle]="font20px">
            <div class="">
              <img [src]="'../../../assets/game/fichas-svg/FICHA-VERMELHA.svg'" class="bet-img">
              <span class="ms-2">$ 500</span>
            </div>
          </div>

          <div>
            <img id="animationSetChipToTable0" [src]="'../../../assets/game/fichas-svg/FICHA-VERMELHA.svg'">
          </div>

        </div>

        <!-- ------------------- Player2 ------------------- -->
        <div>
          <div class="profilePicture2 d-flex align-items-center justify-content-center">
            <!-- <div id="firstLetter" [ngStyle]="firstLetter">W</div> -->
            <img class="img-fluid img2"
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ9Oj0GnNH6FBWX2-SvJXY3sNCCbAM9YatgPpNTkVxS5HaOUlmFCoW8s3dX67ezMkrsoFg&usqp=CAU"
              alt="">

            <div class="info2">
              <div [ngStyle]="font16px" class="nickname2 d-flex w-100 justify-content-center">Vitor</div>
              <div [ngStyle]="font16px" class="balance2 d-flex w-100 justify-content-center">$ 124</div>
            </div>
          </div>
          <div class="progress-bar2">
            <span class="progress" [ngStyle]="{ 'width': progressWidth + '%' }"
              [ngClass]="{'bg-red': progressWidth <= 25 }"></span>
          </div>
        </div>

        <!-- ------------------- ------- ------------------- -->

        <div class="btns-actions">
          <div class="d-flex align-items-center">
            <button [ngStyle]="font27px" class="btn-shortcuts">33%</button>
            <button [ngStyle]="font27px" class="btn-shortcuts">50%</button>
            <button [ngStyle]="font27px" class="btn-shortcuts">75%</button>
            <button [ngStyle]="font27px" class="btn-shortcuts">Max</button>
            <span [ngStyle]="font20px" class="input-group-text ms-1">$</span>
            <input [ngStyle]="font20px" type="number" class="form-control input-custom-value-range">
            <input type="range" class="form-range ms-2" id="buyInRange">
          </div>

          <div class="row ps-3 mt-2 row-btn-actions">
            <div class="col-12 d-flex justify-content-end">
              <div class="col-4 me-2 btn-actions d-flex justify-content-center">
                <div [ngStyle]="font27px"
                  class="btn-actions-name d-flex justify-content-center align-items-center w-100 cursor-pointer">Fold
                </div>
              </div>
              <div class="col-4 me-2 btn-actions d-flex justify-content-center">
                <div [ngStyle]="font27px"
                  class="btn-actions-name d-flex justify-content-center align-items-center w-100 cursor-pointer">Check
                </div>
              </div>
              <!-- <div class="col-4 me-2 btn-actions d-flex flex-column align-items-center">
                <div class="btn-actions-name d-flex justify-content-center align-items-center w-100 cursor-pointer">Call</div>
                <div class="btn-actions-value d-flex justify-content-center w-100">$ 50</div>
              </div> -->
              <div class="col-4 btn-actions d-flex flex-column align-items-center">
                <div [ngStyle]="font27px" (click)="animacao()"
                  class="btn-actions-name d-flex justify-content-center align-items-center w-100 cursor-pointer">Bet
                </div>
                <div [ngStyle]="font20px" class="btn-actions-value d-flex justify-content-center w-100">$ 200</div>
              </div>
            </div>
          </div>


        </div>


      </div>
    </div>
  </div>
