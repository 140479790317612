<section class="background-landing">
  <div class="container">
    <div class="d-flex flex-column justify-content-center align-items-center">
      <div class="w-75 text-center">
        <div>
          <span class="heading-700-lg">{{ 'LANDING_PAGE.PAGE_TITLE' | translate }}</span>
        </div>
        <div class="pt-4">
          <span class="heading-500-lg">{{ 'LANDING_PAGE.PAGE_SUBTITLE' | translate }}
          </span>
        </div>
      </div>
      <div class="d-flex flex-column-reverse flex-sm-row pt-4">
        <div (click)="openModalLogin()"
          class="cursor-pointer transparent-button px-5 py-3 button-700 d-flex justify-content-center me-sm-4 mb-3">
          {{
          'NAVBAR.MENU_ITEM_4' | translate }}</div>
        <div class="outlined-button px-5 py-3 button-700 d-flex justify-content-center cursor-pointer ms-sm-4 mb-3"
          (click)="openModalSignUp()">{{ 'LANDING_PAGE.CREATE_ACCOUNT_BUTTON' | translate }}
        </div>
      </div>
    </div>
  </div>
</section>

<!-- <section class="tournaments-container d-flex align-items-center flex-column px-5">
  <div class="heading-700-lg mb-5 pt-5 text-center">{{ 'LANDING_PAGE.TOURNAMENT_TITLE' | translate }}</div>

  <div *ngFor="let card of cards | slice: 0:n"
    class="row d-flex align-items-center tournament-card w-100 justify-content-between px-3 py-2 mb-5 cursor-pointer">
    <div class="flex-column flex-md-row col-lg-10 d-flex align-items-center">
      <div>
        <img class="card-icon" [src]="card.icon" />
      </div>

      <div class="ms-md-5 ms-0">
        <div class="heading-700-sm my-md-0 pt-4 my-4 text-md-start text-center">{{card.title}}</div>
        <div class="d-flex mt-3 justify-content-center justify-content-md-between">
          <div class="d-flex flex-column flex-md-row">
            <div class="me-5 mb-4">
              <div class="subtitle-700-md">{{ 'LANDING_PAGE.TOURNAMENT_CARD_LIMIT' | translate }}</div>
              <div class="body-300-md mt-2">{{card.limit}}</div>
            </div>
            <div class="me-5">
              <div class="subtitle-700-md">{{ 'LANDING_PAGE.TOURNAMENT_CARD_BUY_IN' | translate }}</div>
              <div class="body-300-md mt-2">{{card.buyIn}}</div>
            </div>
          </div>
          <div class="d-flex flex-column flex-md-row">
            <div class="me-md-5 me-0 mb-4">
              <div class="subtitle-700-md">{{ 'LANDING_PAGE.TOURNAMENT_CARD_PLAYERS' | translate }}</div>
              <div class="body-300-md mt-2">{{card.players}}</div>
            </div>
            <div>
              <div class="subtitle-700-md">{{ 'LANDING_PAGE.TOURNAMENT_CARD_START' | translate }}</div>
              <div class="body-300-md mt-2">{{card.start}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-2 mt-5">
      <div
        class="d-flex justify-content-center tournaments-subscription-button px-5 py-3 button-700 font-color-black mb-2"
        [routerLink]="['/sign-in']">
        {{ 'LANDING_PAGE.TOURNAMENT_SUBSCRIPTION' | translate }}
      </div>
    </div>
  </div>

  <div *ngIf="!showMore" (click)="onShow()"
    class="align-self-end pe-3 button-700 font-color-yellow pb-5 cursor-pointer">
    {{ 'LANDING_PAGE.SEE_MORE_BUTTON' | translate }}
  </div>

  <div *ngIf="showMore" (click)="onShow()" class="align-self-end pe-3 button-700 font-color-yellow pb-5 cursor-pointer">
    {{ 'LANDING_PAGE.SEE_LESS_BUTTON' | translate }}
  </div>
</section> -->

<!-- <section class="play-container px-3">
  <div class="d-flex flex-column align-items-center pb-5">
    <div class="heading-700-lg py-5 text-center">{{ 'LANDING_PAGE.PLAY_TITLE' | translate }}</div>
    <p class="heading-500-lg pb-3">{{ 'LANDING_PAGE.PLAY_SUBTITLE' | translate }}</p>
    <p class="heading-500-lg text-center pb-5">{{ 'LANDING_PAGE.PLAY_EXTRA_TEXT' | translate }}</p>
  </div>

  <div class="d-flex justify-content-center align-items-center flex-column flex-xl-row">
    <div class="mb-5 mb-md-5 me-xl-3 me-0 img-fluid img-game-1">
      <div class="hover-items-img-1">
        <img src="../../../assets/icons/hover-items-game-1.svg" width="180px" class="img-fluid" />
      </div>
    </div>
    <div class="mb-5 mb-md-5 ms-xl-3 ms-0 img-fluid img-game-2">
      <div class="hover-items-img-2">
        <img src="../../../assets/icons/hover-items-game-2.svg" width="180px" class="img-fluid" />
      </div>
    </div>
  </div>
</section> -->

<!-- <section class="background-tokens mt-5">
  <div class="container d-flex flex-column align-items-center">
    <div class="heading-700-sm pb-5 text-center">{{ 'LANDING_PAGE.TOKENS_TITLE' | translate }}</div>
    <div class="subtext-container">
      <p class="heading-500-lg text-center pb-5">{{ 'LANDING_PAGE.TOKENS_SUBTITLE' | translate }}</p>
    </div>
  </div>
</section> -->

<!-- <section class="email-area d-flex flex-column align-items-center py-5">
  <div class="heading-700-sm pb-5 text-center mt-5">{{ 'LANDING_PAGE.EMAIL_SUBSCRIPTION' | translate }}</div>
  <div class="subtext-container">
    <p class="heading-500-lg text-center">{{ 'LANDING_PAGE.EMAIL_SUBTITLE' | translate }}</p>
  </div>
  <div class="d-flex flex-column flex-lg-row align-items-center mt-5 mb-5">
    <div class="input-search-area d-flex justify-content-between px-4">
      <input class="custom-input body-400-md-custom" type="search"
        placeholder="{{ 'LANDING_PAGE.EMAIL_PLACEHOLDER' | translate }}" />
      <img src="../../../assets/icons/email-icon.svg" width="20px">
    </div>
    <div class="styled-button menu-400-md px-4 ms-0 ms-lg-3 mt-3 mt-lg-0 cursor-pointer">{{
      'LANDING_PAGE.EMAIL_SUBSCRIBE' | translate }}</div>
  </div>
</section> -->