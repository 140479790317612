function evaluateHand(cards: { value: string; suit: string }[]): string {
  const cardValues = ['A', 'K', 'Q', 'J', 'T', '9', '8', '7', '6', '5', '4', '3', '2'];
  const valueIndices = cards.map(card => cardValues.indexOf(card.value));
  const suitIndices = cards.map(card => card.suit);
  
  cards.sort((a, b) => cardValues.indexOf(a.value) - cardValues.indexOf(b.value));
  
  const isSameSuit = suitIndices.every(suit => suit === suitIndices[0]);

  if (isSameSuit && valueIndices.join('') === '43210') {
    return 'GAME.TIPS.ROYAL_STRAIGHT_FLUSH';
  }

  if (isSameSuit && isSequential(valueIndices)) {
    return 'GAME.TIPS.STRAIGHT_FLUSH';
  }

  if (hasSameCardCount(valueIndices, 4)) {
    return 'GAME.TIPS.FOUR_OF_A_KIND';
  }

  if (hasThreeAndPair(valueIndices)) {
    return 'GAME.TIPS.FULL_HOUSE';
  }

  if (isSameSuit && cards.length === 5) {
    return 'GAME.TIPS.FLUSH';
  }

  if (isSequential(valueIndices) && cards.length === 5) {
    return 'GAME.TIPS.STRAIGHT';
  }

  if (hasSameCardCount(valueIndices, 3)) {
    return 'GAME.TIPS.THREE_OF_A_KIND';
  }

  if (countPairs(valueIndices) === 2) {
    return 'GAME.TIPS.TWO_PAIRS';
  }

  if (countPairs(valueIndices) === 1) {
    return 'GAME.TIPS.ONE_PAIR';
  }

  const highestValueIndex = Math.min(...valueIndices);
  if (highestValueIndex <= 4) { 
    return 'GAME.TIPS.HIGH_CARD';
  }

  return 'GAME.TIPS.MIXED';
}

function isSequential(valueIndices: number[]): boolean {
  const sortedIndices = [...new Set(valueIndices)].sort((a, b) => a - b);
  for (let i = 0; i < sortedIndices.length - 1; i++) {
    if (sortedIndices[i + 1] - sortedIndices[i] !== 1) {
      return false;
    }
  }
  return sortedIndices.length === 5;
}

function hasSameCardCount(valueIndices: number[], count: number): boolean {
  const cardCounts = valueIndices.reduce((acc, val) => {
    acc[val] = (acc[val] || 0) + 1;
    return acc;
  }, {} as Record<number, number>);
  
  return Object.values(cardCounts).includes(count);
}

function hasThreeAndPair(valueIndices: number[]): boolean {
  const cardCounts = valueIndices.reduce((acc, val) => {
    acc[val] = (acc[val] || 0) + 1;
    return acc;
  }, {} as Record<number, number>);

  const values = Object.values(cardCounts);
  return values.includes(3) && values.includes(2);
}

function countPairs(valueIndices: number[]): number {
  const cardCounts = valueIndices.reduce((acc, val) => {
    acc[val] = (acc[val] || 0) + 1;
    return acc;
  }, {} as Record<number, number>);
  
  let pairs = 0;
  for (const count of Object.values(cardCounts)) {
    if (count === 2) {
      pairs++;
    }
  }
  return pairs;
}

export default function getTipForHand(hand: string[], additionalCards?: string[]): string {
  const cards = hand.map(card => ({
    value: card[0],
    suit: card.slice(1)
  }));

  if (additionalCards && additionalCards.length > 0) {
    const additional = additionalCards.map(card => ({
      value: card[0],
      suit: card.slice(1)
    }));
    cards.push(...additional);
  }

  return evaluateHand(cards);
}
