<div *ngIf="!isActive" class="background-modal">
  <div class="p-3">
    <div (click)="exit()" class="d-flex justify-content-end cursor-pointer">
      <img src="../../../../assets/icons/icon-x.svg" />
    </div>
    <h2 class="heading-700-sm text-center pt-2">{{ 'PROFILE.SECUTIRY' | translate }}</h2>
    <p class="subtitle text-center mt-4">{{ 'PROFILE.SUBTITLE_START' | translate }}</p>
    <p class="body-400-lg text-center py-3">{{ 'PROFILE.TEXT_START' | translate }}</p>
    <div class="d-flex justify-content-center" *ngIf="tfaGenerate">
      <qrcode [qrdata]="tfaGenerate.otpauthUrl" [colorDark]="'#333641'" [colorLight]="'#FFFFFF'" [width]="256"
        [errorCorrectionLevel]="'M'"></qrcode>
    </div>
    <p class="subtitle text-center mt-4">{{ 'PROFILE.SUBTITLE_END' | translate }}</p>
    <p class="body-400-lg text-center my-3">{{ 'PROFILE.TEXT_END' | translate }}</p>
    <div class="d-flex justify-content-center">
      <form [formGroup]="form">
        <div class="d-flex text-center">
          <code-input class="text-white" [isCodeHidden]="false" [codeLength]="6" (codeCompleted)="onCodeCompleted($event)">
          </code-input>
        </div>
      </form>
    </div>
    <div class="mt-4 row align-items-center">
      <div class="col d-flex justify-content-center align-items-center">
        <button class="btn-delete button-bold mx-2" (click)="exit()">{{ 'PROFILE.BUTTON_2' | translate }}</button>
      </div>
      <div class="col align-items-center">
        <button [disabled]="form.invalid || buttonRegisterTfaSubmitLoading" class="btn-continue button-bold mx-2"
          (click)="submit()">{{ 'PROFILE.BUTTON' | translate }}</button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="isActive" class="background-modal">
  <div class="p-3">
    <div (click)="exit()" class="d-flex justify-content-end cursor-pointer">
      <img src="../../../../assets/icons/icon-x.svg" />
    </div>
    <h2 class="heading-700-sm text-center pt-2">{{ 'PROFILE.SECUTIRY' | translate }}</h2>
    <p class="subtitle text-center mt-4">{{ 'PROFILE.MODAL_2FA.SUBTITLE' | translate }}</p>
    <p class="body-400-lg text-center py-3">{{ 'PROFILE.MODAL_2FA.TEXT_START' | translate }}</p>
    <form [formGroup]="form">
      <div class="position-relative w-100">
        <input type="password" formControlName="password" placeholder="{{ 'PROFILE.PASSWORD' | translate }}"
          class="form-control input-box">
        <img src="../../../assets/icons/icon-cadeado.svg" class="icon-input" width="20" height="16" />
      </div>
      <!-- <code-input class="text-white" [isCodeHidden]="false" [codeLength]="6" (codeCompleted)="onCodeCompleted($event)">
      </code-input> -->
    </form>
    <div class="mt-4 row align-items-center">
      <div class="col d-flex justify-content-center align-items-center">
        <button class="btn-delete button-bold mx-2" (click)="exit()">{{ 'PROFILE.BUTTON_2' | translate }}</button>
      </div>
      <div class="col align-items-center">
        <button class="btn-continue button-bold mx-2" (click)="remove2fa()">{{
          'PROFILE.MODAL_2FA.BTN_MODAL_DISABLED_2FA' | translate }}</button>
      </div>
    </div>
  </div>
</div>
