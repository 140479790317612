import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  form: FormGroup;
  table: any

  constructor(
    private fb: FormBuilder
  ) {
    this.form = this.fb.group({
      search: [''],
      save: [false],
    })
  }

  ngOnInit(): void {
    this.table = [
      {
        name: 'Daniela.ela',
        date: '00/00/0000'
      },
      {
        name: 'Viltu',
        date: '00/00/0000'
      }
    ]
  }

}
