<section class="background-modal p-3">
  <div (click)="exit()" class="d-flex justify-content-end cursor-pointer">
    <img src="../../../../assets/icons/icon-x.svg" />
  </div>
  <h2 class="heading-700-sm">{{ "HOME.MODAL.TITLE" | translate }}</h2>
  <div class="my-4">
    <p class="menu-400-md">{{ "HOME.MODAL.TEXT1" | translate }}</p>
    <p class="heading-500-md">{{ "HOME.MODAL.TEXT2" | translate }}</p>
  </div>
  <div class="my-4">
    <p class="menu-400-md">{{ "HOME.MODAL.TEXT3" | translate }}</p>
    <p class="heading-500-md">{{ "HOME.MODAL.TEXT4" | translate }}</p>
  </div>
  <div class="row">
    <div class="col d-flex justify-content-center align-items-center">
      <button class="btn-delete button-bold mx-2" (click)="exit()">{{ "HOME.MODAL.BTN_CANCEL" | translate }}</button>
    </div>
    <div class="col d-flex justify-content-center align-items-center">
      <button [routerLink]="['/pages/lobby-tournament']" class="btn-continue button-bold mx-2" (click)="exit()">{{ "HOME.MODAL.BTN_CONFIRM" | translate }}</button>
    </div>
  </div>
</section>
