import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import { UserAuthenticatedDto } from 'src/app/dtos/auth/user-authenticated.dto';
import { environment } from 'src/environments/environment';
import CryptoUtil from 'src/util/crypto.util';

export abstract class BaseService {
  protected gameBackoffice: string = `${environment.apis.gameBackoffice}`;
  protected wallet: string = `${environment.apis.wallet}`;
  protected observable: string = `${environment.apis.observable}`;

  protected get anonymousHeader() {
    return {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
  }

  protected get authorizedHeader() {
    const userJson = localStorage.getItem("user") as string;
    const user: UserAuthenticatedDto = JSON.parse(userJson);

    return {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `Bearer ${user?.token}`,
      }),
    };
  }


  protected get authorizedHeaderMult() {
    const userJson = localStorage.getItem("user") as string;
    const user: UserAuthenticatedDto = JSON.parse(userJson);

    return {
      headers: new HttpHeaders({
        Authorization: `Bearer ${user?.token}`,
      }),
    };
  }

  protected extractData(response: any) {
    return response.data || {};
  }

  protected encrypt(data: string) {
    return { payload: CryptoUtil.encrypt(environment.payloadKey, data) };
  }

  protected decrypt(data: any) {
    return CryptoUtil.decrypt(environment.payloadKey, data);
  }
}
