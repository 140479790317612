import { Pipe, PipeTransform } from "@angular/core";
import { TournamentStatusEnum } from "src/app/dtos/enum/tournament-status.enum";

@Pipe({ name: 'tournamentstatus' })
export class TournamentStatusPipe implements PipeTransform {
  transform(value: TournamentStatusEnum): string {
    let name = ''
    switch (value as TournamentStatusEnum) {
      case TournamentStatusEnum.ANNOUNCED:
        name = 'HOME.STATUS.ANNOUNCED';
        break;
      case TournamentStatusEnum.CANCELED:
        name = 'HOME.STATUS.CANCELED';
        break;
      case TournamentStatusEnum.ENDED:
        name = 'HOME.STATUS.ENDED';
        break;
      case TournamentStatusEnum.IN_PROGRESS:
        name = 'HOME.STATUS.IN_PROGRESS';
        break;
      case TournamentStatusEnum.LATE_INSCRIPTION:
        name = 'HOME.STATUS.LATE_INSCRIPTION';
        break;
      case TournamentStatusEnum.OPEN:
        name = 'HOME.STATUS.OPEN';
        break;
    }
    return name;
  }
}
