import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { CoinPairEnum } from '../app/dtos/enum/coin-pair.enum';
import { HttpClient } from '@angular/common/http';
import { BinanceAvgPriceResponseDto } from '../app/dtos/binance/avg-price-response.dto';

@Injectable({
  providedIn: 'root'
})
export class BinanceService {

  private binanceApi = environment.apis.binanceApi;

  constructor(
    private httpClient: HttpClient
  ) { }

  getAvgPricePair(pairSymbol: CoinPairEnum) {
    return this.httpClient.get<BinanceAvgPriceResponseDto>(`${this.binanceApi}avgPrice?&symbol=${pairSymbol}`);
  }

}
