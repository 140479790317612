import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/services/user.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  formCode: FormGroup;
  formPassword: FormGroup;
  formEmail: FormGroup;
  firstStep: boolean = true;
  showPassword: boolean = false;
  showConfirmPassword: boolean = false;
  showCodeInput: boolean = false;
  timeLeft: number = 300;
  interval: any;
  resendButton: boolean = false;

  constructor(
    private toastrService: ToastrService,
    private transletService: TranslateService,
    private userService: UserService,
    private fb: FormBuilder,
    private router: Router,
    private ngxSpinnerService: NgxSpinnerService
  ) {
    this.formEmail = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
    this.formCode = this.fb.group({
      code: ['', [Validators.required, Validators.minLength(5)]],
    });
    this.formPassword = this.fb.group(
      {
        password: [
          '',
          [
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(20),
          ],
        ],
        newPassword: ['', [Validators.required, Validators.minLength(8)]],
      },
      {
        validators: this.confirmPasswordValidator('password', 'newPassword'),
      }
    );
  }

  ngOnInit(): void {}
  submitEmail() {
    this.ngxSpinnerService.show();
    const data = Object.assign({}, this.formEmail.value);
    // this.userService.resetPasswordVerification(data).subscribe(
    //   (data) => this.runUserEmailVerification(data),
    //   (error) => this.runError(error)
    // );
  }

  submitCode() {
    this.ngxSpinnerService.show();
    // this.userService
    //   .vericationCode(this.formCode.value?.code, this.formEmail.value?.email)
    //   .subscribe(
    //     (data) => this.runUserVerifyCode(data),
    //     (error) => this.runError(error)
    //   );
  }

  runUserEmailVerification(data: any): void {
    this.ngxSpinnerService.hide();
    this.showCodeInput = true;

    this.transletService.get('TOASTR.CODE_RECEIVED').subscribe({
      next: (data) => {
        const toastr = this.toastrService.success(data, '', {
          progressBar: true,
          timeOut: 2000,
        });

        if (toastr) {
          toastr.onHidden.subscribe(() => {
            this.startTimer();
          });
        }
      },
    });
  }

  runUserVerifyCode(data: any) {
    this.ngxSpinnerService.hide();
    if (data.isValid) {
      this.firstStep = false;
      this.transletService.get('TOASTR.VALIDATED_CODE').subscribe({
        next: (data) => {
          this.toastrService.success(data, '', {
            progressBar: true,
            timeOut: 2000,
          });
        },
      });
    } else {
      this.transletService.get('TOASTR.INVALIDATED_CODE').subscribe({
        next: (data) => {
          this.toastrService.error(data, '', {
            progressBar: true,
            timeOut: 2000,
          });
        },
      });
    }
  }

  onSubmit() {
    this.ngxSpinnerService.show();
    let request = {
      code: Number(this.formCode.controls['code'].value),
      newPassword: this.formPassword.controls['newPassword'].value,
      email: this.formEmail.controls['email'].value,
    };

    // this.userService.resetPassword(request).subscribe(
    //   (data) => this.runUserChangePassword(data),
    //   (error) => this.runError(error)
    // );
  }

  runUserChangePassword(data: any): void {
    this.ngxSpinnerService.hide();

    this.router.navigate(['/sign-in']);
    this.transletService.get('TOASTR.PASSWORD_CHANGED_SUCCESSFULLY').subscribe({
      next: (data) => {
        const toastr = this.toastrService.success(data, '', {
          progressBar: true,
          timeOut: 2000,
        });

        if (toastr) {
          toastr.onHidden.subscribe(() => {});
        }
      },
    });
  }

  runError(exception: any): void {
    this.ngxSpinnerService.hide();
    console.error(exception);
    const toastr = this.toastrService.error(exception.error.errors[0], '', {
      progressBar: true,
    });

    if (toastr) {
      toastr.onHidden.subscribe(() => {});
    }
  }

  confirmPasswordValidator(password: string, confirmPassword: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[password];
      const matchingControl = formGroup.controls[confirmPassword];
      if (
        matchingControl.errors &&
        !matchingControl.errors['confirmedValidator']
      ) {
        return;
      }

      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ confirmedValidator: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  startTimer() {
    this.resendButton = false;
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;

        if (this.timeLeft <= 0) {
          clearInterval(this.interval);
          this.resendButton = true;
        }
      } else {
        this.timeLeft = 300;
      }
    }, 1000);
  }
}
