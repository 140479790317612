interface Color {
  hex: string;
  name: string;
}

const colors: Color[] = [
  { hex: '#FF0000', name: 'Red' },
  { hex: '#00FF00', name: 'Green' },
  { hex: '#0000FF', name: 'Blue' },
  { hex: '#FFFF00', name: 'Yellow' },
  { hex: '#00FFFF', name: 'Cyan' },
  { hex: '#FF00FF', name: 'Magenta' },
  { hex: '#FFFFFF', name: 'White' },
  { hex: '#000000', name: 'Black' },
  { hex: '#800000', name: 'Maroon' },
  { hex: '#808000', name: 'Olive' },
  { hex: '#008000', name: 'Dark Green' },
  { hex: '#800080', name: 'Purple' },
  { hex: '#008080', name: 'Teal' },
  { hex: '#000080', name: 'Navy' },
  { hex: '#C0C0C0', name: 'Silver' },
  { hex: '#808080', name: 'Gray' },
  { hex: '#F3C41F', name: 'Custom Yellow' },
  { hex: 'transparent', name: 'Transparent' }
];

export default colors;
