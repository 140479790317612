export enum PlayerHandActionEnum {
  PRE = 'PRE',
  FOLD = 'FOLD',
  CHECK = 'CHECK',
  CALL = 'CALL',
  RAISE = 'RAISE',
  BET = 'BET',
  ALLIN = 'ALLIN',
  NONE = 'NONE',
  ABSENCE = 'ABSENCE',
  MUCK = 'MUCK', //Na hora do showdown, o jogador esconde as cartas
}
