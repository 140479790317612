import { Injectable } from "@angular/core";
import { BaseSocketService } from "./base.socket.service";
import { BehaviorSubject, ReplaySubject } from "rxjs";
import { Socket } from "socket.io-client";
import { SocketDto } from "src/app/dtos/socket/socket.dto";
import { environment } from "src/environments/environment";
import { ResponseDto } from "src/app/dtos/response.dto";
import { ChatMessageDto } from "src/models/chatMessageDto";
import { ChatEventsEnum } from "src/app/dtos/enum/chat-events.enum";

@Injectable({
  providedIn: "root",
})
export class ChatSocketService extends BaseSocketService {

  private _socket: Socket | undefined;

  messages = new BehaviorSubject<ChatMessageDto[]>([]);
  response = new ReplaySubject<ResponseDto>();

  constructor(
  ) {
    super(
      new SocketDto(
        environment.socket.chat.host,
        environment.socket.chat.path,
      ));
  }

  initializeMain(): Socket {
    this._socket = this.initializeCommonRoutine(this._socket!);
    return this._socket;
  }




  sendMessage(dto: ChatMessageDto) {
    this._socket!.emit(
      ChatEventsEnum.SEND_MESSAGE,
      dto
    );

    this._socket!.on(ChatEventsEnum.REQUEST_MESSAGE, (response: ResponseDto) => {
      if (response.success) {
        this.messages.next(response.data);
      }

    });
  }


  requestMessageList(tableId: string) {
    this._socket!.emit(ChatEventsEnum.REQUEST_MESSAGE, tableId);
    this._socket!.on(ChatEventsEnum.RESPONSE_MESSAGE, (response: ResponseDto) => {
      if (response.success) {
        this.messages.next(response.data);
      }
    })
  }


  shutDownAll(): void {
    if (!!this._socket && this._socket.connected) {
      this._socket.offAny()
      this._socket.removeAllListeners();
      this._socket.close();
      this._socket.disconnect();
    }
  }
}