import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TournamentResponseDto } from 'src/app/dtos/tournament/tournament-response.dto';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class TournamentService extends BaseService {

  url: string = `${this.gameBackoffice}tournament`;

  constructor(
    private httpClient: HttpClient
  ) {
    super();
  }

  list(mode: string) {
    let query = '';
    if(mode !== 'ALL') {
      query = `?mode=${mode}`
    }
    return this.httpClient
      .get<TournamentResponseDto[]>(`${this.url}${query}`, this.authorizedHeader);
  }

  join(tournamentId: string) {
    return this.httpClient.post(`${this.url}/join/tournament/id/${tournamentId}`, undefined, this.authorizedHeader);
  }

  getById(tournamentId: string) {
    return this.httpClient.get<TournamentResponseDto>(`${this.url}/id/${tournamentId}`, this.authorizedHeader);
  }
}
