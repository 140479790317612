
<!-- <div class="container-fluid" style="position: absolute; top: 50;">
  <div class="container">
    <div class="d-flex flex-fill py-2 px-3 justify-content-between">
      <div class="d-flex">
        <a *ngIf="playerInTableGame() && !tableGame?.startGameLoading && !tableGame?.inResolveGame && canLeavePokerGame" [formGroup]="formCheckBoxs"
          class="cursor-pointer">
          <input id="leaveGameCheckbox" (change)="changeLeaveGame()" class="form-check-input checkbox-red cursor-pointer" type="checkbox" formControlName="leaveGameCheckbox" style="width: 12px; height: 12px; margin-top: 15px;">
        </a>
        <a *ngIf="playerInTableGame() && !tableGame?.startGameLoading && !tableGame?.inResolveGame && canLeavePokerGame" class="cursor-pointer ms-1"
          (click)="leavePokerGame()"><img class="icon-logout" src="../../../assets/icons/icon-logout.svg"
            data-bs-toggle="tooltip" title="Sair da mesa" alt=""></a>
        <div *ngIf="tableGame?.players" class="text-white table-info ms-2">{{tableGame?.coin}}</div>
        <div *ngIf="tableGame?.bigBlindValue" class="text-white table-info ms-2">{{tableGame!.bigBlindValue /
          2}} / {{tableGame?.bigBlindValue}}</div>
      </div>
      <div>
        <a *ngIf="playerInTableGame() && playersInViewOrder[0]?.status == 'playing' && !tableGame?.startGameLoading && !tableGame?.inResolveGame" [formGroup]="formCheckBoxs"
          class="cursor-pointer">
          <input id="absenceCheckbox" (change)="changeAbsence()" class="form-check-input checkbox-yellow cursor-pointer" type="checkbox" formControlName="absenceCheckbox" style="width: 12px; height: 12px; margin-top: 15px;">
        </a>
        <a *ngIf="playerInTableGame() && playersInViewOrder[0]?.status == 'playing' && !tableGame?.startGameLoading && !tableGame?.inResolveGame"
          (click)="requestSittingOut()" class="cursor-pointer me-2">
          <img src="../../../assets/game/icons-in-table/cadeira.png" data-bs-toggle="tooltip" title="Ficar ausente"
            alt="" style="height: 24px;">
        </a>
        <a *ngIf="playerInTableGame() && !tableGame?.startGameLoading && !tableGame?.inResolveGame" class="cursor-pointer" data-bs-toggle="modal"
          data-bs-target="#modalBuyCoin" title="Comprar fichas" (click)="openModalBuyCoin()">
          <img src="../../../assets/game/icons-in-table/fichas-de-cassino.png" alt="">
        </a>
      </div>
    </div>
  </div>
</div> -->

<section class="bg-table d-flex flex-column align-items-center" >
  <div class="container-scroll">


    <div class="d-flex flex-column justify-content-center">
      
      <div class="mesa mt-5" *ngIf="canStart">
        <div class="d-flex justify-content-center teste" *ngIf="canStart">
          <p>
            <span class="text-white" >{{history[this.step].coin}} &nbsp;
              {{history[this.step].bigBlindValue /2}} / {{history[this.step].bigBlindValue}} </span>
            <br>
            <!-- <span class="text-white">teste</span> -->
          </p>

        </div>

        <!-- Jogador 0 -->
        <div *ngIf="history[this.step].players[0]"
          class="player0 d-flex flex-fill flex-column align-items-center justify-content-center">
          <div class="d-flex flex-fill flex-column align-items-center justify-content-center"
            [ngClass]="history[this.step].players[0].status == 'empty' ? 'visibility-hidden' : ''">
            <div class="d-flex flex-fill flex-column align-items-center justify-content-center"
              [ngClass]="[history[this.step].players[0].status == 'requestExit' ? 'visibility-hidden' : '', history[this.step].players[0].status !== 'playing' ? 'opacity-05' : '']">
              <div [ngClass]="history[this.step].currentTurn == history[this.step].players[0].user?._id ? 'active-player-border' : ''"
                class="profilePicture0 d-flex align-items-center justify-content-center">
                <div *ngIf="history[this.step].players[0].playerData && !history[this.step].players[0].playerData?.profilePicture"
                  class="text-uppercase">
                  {{history[this.step].players[0].playerData!.nickname[0]}}
                </div>
                <img *ngIf="history[this.step].players[0]?.playerData && history[this.step].players[0]?.playerData?.profilePicture"
                  class="img-fluid img0" src="{{this.history[this.step].players[0].playerData!.profilePicture}}">
              </div>
              <div *ngIf="history[this.step].players[0].status == 'absence'"
                class="sittingOut0 d-flex w-100 justify-content-center">Sitting Out</div>
              <div [ngClass]="history[this.step].currentTurn == history[this.step].players[0].user?._id ? 'active-player-border' : ''"
                class="info0">
                <div *ngIf="!(animationPlayerIndex === 0 && showActionPlayer0 && history[this.step].players[0]!.playerHand!.actions.length !== 0 && history[this.step].players[0]!.playerHand!.actions[history[this.step].players[0].playerHand?.actions!.length - 1].action !== 'PRE')" class="nickname0 d-flex w-100 justify-content-center text-nowrap">
                  {{history[this.step].players[0].playerData?.nickname}}</div>
                <div *ngIf="animationPlayerIndex === 0 && showActionPlayer0 && history[this.step].players[0]!.playerHand!.actions.length !== 0 && history[this.step].players[0]!.playerHand!.actions[history[this.step].players[0].playerHand?.actions!.length - 1].action !== 'PRE'"
                  class="nickname0 d-flex w-100 justify-content-center">{{history[this.step].players[0]!.playerHand!.actions[history[this.step].players[0].playerHand?.actions!.length - 1].action!}}</div>
                <div (click)="changeShowValuesInBigBlindsFormat()"
                  *ngIf="showValuesInBigBlindsFormat && (history[this.step].players[0].coinAmount > 0 || history[this.step].players[0].coinAmount == 0 && history[this.step].players[0].status !== 'playing')"
                  class="balance0 cursor-pointer d-flex w-100 justify-content-center">{{history[this.step].players[0].coinAmount
                  / history[this.step].bigBlindValue! | mask: 'separator.1'}} BB</div>
                <div (click)="changeShowValuesInBigBlindsFormat()"
                  *ngIf="!showValuesInBigBlindsFormat && (history[this.step].players[0].coinAmount > 0 || history[this.step].players[0].coinAmount == 0 && history[this.step].players[0].status !== 'playing')"
                  class="balance0 cursor-pointer d-flex w-100 justify-content-center">
                  {{history[this.step].players[0].coinAmount}}</div>
                <div (click)="changeShowValuesInBigBlindsFormat()"
                  *ngIf="history[this.step].players[0].coinAmount == 0 && history[this.step].players[0].status == 'playing'"
                  class="balance0 cursor-pointer d-flex w-100 justify-content-center">All In</div>
              </div>
              <div
                [ngClass]="history[this.step].currentTurn == history[this.step].players[0].user?._id && history[this.step].round !== 'SHOWDOWN' && history[this.step].showActions ? '' : 'visibility-hidden'"
                class="progress-bar0">
                <div class="progressWidth" [ngStyle]="{ 'width': progressWidth + '%' }"
                  [ngClass]="{'bg-red': progressWidth <= 25 }"></div>
              </div>
            
              <div
                *ngIf="!arrayShowdownIndex[0] && history[this.step].players[0]?.playerHand && history[this.step].players[0]?.playerHand?.cards!.length"
                class="div-card-player0">
                <img class="card-player0-1"
                  [src]="'../../../../assets/game/new_cards/' + history[this.step].players[0].playerHand!.cards[0] + '.png'">
                <img class="card-player0-2"
                  [src]="'../../../../assets/game/new_cards/' + history[this.step].players[0].playerHand!.cards[1] + '.png'">
              </div>
              <div *ngIf="arrayShowdownIndex[0] && history[this.step].players[0]?.playerHand?.cards?.length && !isFold(0)"
                class="div-showdown-player0">
                <img class="showdown-card-player0-1" 
                  [src]="'../../../../assets/game/new_cards/' + history[this.step].players[0].playerHand!.cards[0] + '.png'">
                <img class="showdown-card-player0-2"
                  [src]="'../../../../assets/game/new_cards/' + history[this.step].players[0].playerHand!.cards[1] + '.png'">
              </div>
            </div>
            <img *ngIf="history[this.step].dealerTurn == history[this.step].players[0]?.user?._id" class="dealer0"
              src="../../../../assets/icons/poker-game/coin-dealer.svg">
            <div class="bet0">
              <div *ngIf="betByPlayer(0) > 0 && !history[this.step].inResolveGame">
                <img [src]="getChipColor(betByPlayer(0))" class="bet-img">
                <span *ngIf="showValuesInBigBlindsFormat" class="me-2"> {{betByPlayer(0) / history[this.step].bigBlindValue! |
                  mask: 'separator.1'}}
                  BB</span>
                <span *ngIf="!showValuesInBigBlindsFormat" class="ms-2">$ {{betByPlayer(0)}}</span>
              </div>
            </div>
          </div>
          <!-- <img *ngIf="lang === 'en'" data-bs-toggle="modal" data-bs-target="#modalBuyIn" (click)="openBuyIn(0)"
            [ngClass]="history[this.step].players[0] && history[this.step].players[0].status == 'empty' && !playerInTableGame() && !history[this.step].startGameLoading ? '' : 'visibility-hidden'"
            class="add-player0 cursor-pointer" src="../../../assets/icons/take-seat.svg" alt="">
          <img *ngIf="lang === 'pt'" data-bs-toggle="modal" data-bs-target="#modalBuyIn" (click)="openBuyIn(0)"
            [ngClass]="history[this.step].players[0] && history[this.step].players[0].status == 'empty' && !playerInTableGame() && !history[this.step].startGameLoading ? '' : 'visibility-hidden'"
            class="add-player0 cursor-pointer" src="../../../assets/icons/sentar.svg" alt=""> -->
        </div>



        <!-- Jogador 1 -->
        <div *ngIf="history[this.step].players[1]"
          class="player1 d-flex flex-fill flex-column align-items-center justify-content-center">
          <div class="d-flex flex-fill flex-column align-items-center justify-content-center"
            [ngClass]="history[this.step].players[1].status == 'empty' ? 'visibility-hidden' : ''">
            <div class="d-flex flex-fill flex-column align-items-center justify-content-center"
              [ngClass]="[history[this.step].players[1].status == 'requestExit' ? 'visibility-hidden' : '', history[this.step].players[1].status !== 'playing' ? 'opacity-05' : '']">
              <div [ngClass]="history[this.step].currentTurn == history[this.step].players[1].user?._id ? 'active-player-border' : ''"
                class="profilePicture1 d-flex align-items-center justify-content-center">
                <div *ngIf="history[this.step].players[1]?.playerData && !history[this.step].players[1]?.playerData?.profilePicture"
                  class="text-uppercase">
                  {{history[this.step].players[1].playerData!.nickname[0]}}
                </div>
                <img *ngIf="history[this.step].players[1]?.playerData && history[this.step].players[1]?.playerData?.profilePicture"
                  class="img-fluid img1" src="{{history[this.step].players[1].playerData!.profilePicture}}">
              </div>
              <div *ngIf="history[this.step].players[1].status == 'absence'"
                class="sittingOut1 d-flex w-100 justify-content-center">Sitting Out</div>
              <div [ngClass]="history[this.step].currentTurn == history[this.step].players[1].user?._id ? 'active-player-border' : ''"
                class="info1">
                <div *ngIf="!(animationPlayerIndex === 1 && showActionPlayer1 && history[this.step].players[1]!.playerHand!.actions.length !== 0 && history[this.step].players[1]!.playerHand!.actions[history[this.step].players[1].playerHand?.actions!.length - 1].action !== 'PRE')" class="nickname1 d-flex w-100 justify-content-center text-nowrap">
                  {{history[this.step].players[1].playerData?.nickname}}</div>
                <div *ngIf="animationPlayerIndex === 1 && showActionPlayer1 && history[this.step].players[1]!.playerHand!.actions.length !== 0 && history[this.step].players[1]!.playerHand!.actions[history[this.step].players[1].playerHand?.actions!.length - 1].action !== 'PRE'"
                  class="nickname1 d-flex w-100 justify-content-center">{{history[this.step].players[1]!.playerHand!.actions[history[this.step].players[1].playerHand?.actions!.length - 1].action!}}</div>
                <div
                  *ngIf="showValuesInBigBlindsFormat && (history[this.step].players[1].coinAmount > 0 || history[this.step].players[1].coinAmount == 0 && history[this.step].players[1].status !== 'playing')"
                  class="balance1 d-flex w-100 justify-content-center">
                  {{history[this.step].players[1].coinAmount / history[this.step].bigBlindValue! | mask: 'separator.1'}} BB</div>
                <div
                  *ngIf="!showValuesInBigBlindsFormat && (history[this.step].players[1].coinAmount > 0 || history[this.step].players[1].coinAmount == 0 && history[this.step].players[1].status !== 'playing')"
                  class="balance1 d-flex w-100 justify-content-center"> {{history[this.step].players[1].coinAmount}}</div>
                <div *ngIf="history[this.step].players[1].coinAmount == 0 && history[this.step].players[1].status == 'playing'"
                  class="balance1 d-flex w-100 justify-content-center">All In</div>
              </div>
              <div
                [ngClass]="history[this.step].currentTurn == history[this.step].players[1].user?._id && history[this.step].round !== 'SHOWDOWN' && history[this.step].showActions ? '' : 'visibility-hidden'"
                class="progress-bar1">
                <div class="progressWidth" [ngStyle]="{ 'width': progressWidth + '%' }"
                  [ngClass]="{'bg-red': progressWidth <= 25 }"></div>
              </div>
              <div
                *ngIf="!arrayShowdownIndex[1] && history[this.step].players[1]?.playerHand && history[this.step].players[1]?.playerHand?.cards!.length && !isFold(1)"
                class="div-cards-face-down1">
                <img class="card-face-down1-1" [src]="'../../../assets/game-v2/mesa/Card_Back_A.png'">
                <img class="card-face-down1-2" [src]="'../../../assets/game-v2/mesa/Card_Back_A.png'">
              </div>
              <div *ngIf="arrayShowdownIndex[1] && history[this.step].players[1]?.playerHand?.cards?.length && !isFold(1)"
                class="div-showdown-player1">
                <img class="showdown-card-player1-1"
                  [src]="'../../../assets/game/new_cards/' + history[this.step].players[1].playerHand!.cards[0] + '.png'">
                <img class="showdown-card-player1-2"
                  [src]="'../../../assets/game/new_cards/' + history[this.step].players[1].playerHand!.cards[1] + '.png'">
              </div>
            </div>
            <img *ngIf="history[this.step].dealerTurn == history[this.step].players[1]?.user?._id" class="dealer1"
              src="../../../assets/icons/poker-game/coin-dealer.svg">
            <div *ngIf="betByPlayer(1) > 0 && !history[this.step].inResolveGame" class="bet1">
              <div>
                <img [src]="getChipColor(betByPlayer(1))" class="bet-img">
                <span *ngIf="showValuesInBigBlindsFormat" class="me-2"> {{betByPlayer(1) / history[this.step].bigBlindValue! |
                  mask: 'separator.1'}}
                  BB</span>
                <span *ngIf="!showValuesInBigBlindsFormat" class="ms-2">$ {{betByPlayer(1)}}</span>
              </div>
            </div>
          </div>
          
        </div>


        <!-- Jogador 2 -->
        <div *ngIf="history[this.step].players[2]"
          class="player2 d-flex flex-fill flex-column align-items-center justify-content-center">
          <div class="d-flex flex-fill flex-column align-items-center justify-content-center"
            [ngClass]="history[this.step].players[2].status == 'empty' ? 'visibility-hidden' : ''">
            <div class="d-flex flex-fill flex-column align-items-center justify-content-center"
              [ngClass]="[history[this.step].players[2].status == 'requestExit' ? 'visibility-hidden' : '', history[this.step].players[2].status !== 'playing' ? 'opacity-05' : '']">
              <div [ngClass]="tableGame?.currentTurn == history[this.step].players[2].user?._id ? 'active-player-border' : ''"
                class="profilePicture2 d-flex align-items-center justify-content-center">
                <div *ngIf="history[this.step].players[2]?.playerData && !history[this.step].players[2]?.playerData?.profilePicture"
                  class="text-uppercase">
                  {{history[this.step].players[2].playerData!.nickname[0]}}
                </div>
                <img *ngIf="history[this.step].players[2]?.playerData && history[this.step].players[2]?.playerData?.profilePicture"
                  class="img-fluid img2" src="{{history[this.step].players[2].playerData!.profilePicture}}">
              </div>
              <div *ngIf="history[this.step].players[2].status == 'absence'"
                class="sittingOut2 d-flex w-100 justify-content-center">Sitting Out</div>
              <div [ngClass]="tableGame?.currentTurn == history[this.step].players[2].user?._id ? 'active-player-border' : ''"
                class="info2">
                <div *ngIf="!(animationPlayerIndex === 2 && showActionPlayer2 && history[this.step].players[2]!.playerHand!.actions.length !== 0 && history[this.step].players[2]!.playerHand!.actions[history[this.step].players[2].playerHand?.actions!.length - 1].action !== 'PRE')" class="nickname2 d-flex w-100 justify-content-center text-nowrap">
                  {{history[this.step].players[2].playerData?.nickname}}</div>
                <div *ngIf="animationPlayerIndex === 2 && showActionPlayer2 && history[this.step].players[2]!.playerHand!.actions.length !== 0 && history[this.step].players[2]!.playerHand!.actions[history[this.step].players[2].playerHand?.actions!.length - 1].action !== 'PRE'"
                  class="nickname2 d-flex w-100 justify-content-center">{{history[this.step].players[2]!.playerHand!.actions[history[this.step].players[2].playerHand?.actions!.length - 1].action!}}</div>
                <div
                  *ngIf="showValuesInBigBlindsFormat && (history[this.step].players[2].coinAmount > 0 || history[this.step].players[2].coinAmount == 0 && history[this.step].players[2].status !== 'playing')"
                  class="balance2 d-flex w-100 justify-content-center">
                  {{history[this.step].players[2].coinAmount / history[this.step].bigBlindValue! | mask: 'separator.1'}} BB</div>
                <div
                  *ngIf="!showValuesInBigBlindsFormat && (history[this.step].players[2].coinAmount > 0 || history[this.step].players[2].coinAmount == 0 && history[this.step].players[2].status !== 'playing')"
                  class="balance2 d-flex w-100 justify-content-center">{{history[this.step].players[2].coinAmount}}</div>
                <div *ngIf="history[this.step].players[2].coinAmount == 0 && history[this.step].players[2].status == 'playing'"
                  class="balance2 d-flex w-100 justify-content-center">All In</div>
              </div>
              <div
                [ngClass]="history[this.step].currentTurn == history[this.step].players[2].user?._id && history[this.step].round !== 'SHOWDOWN' && history[this.step].showActions ? '' : 'visibility-hidden'"
                class="progress-bar2">
                <div class="progressWidth" [ngStyle]="{ 'width': progressWidth + '%' }"
                  [ngClass]="{'bg-red': progressWidth <= 25 }"></div>
              </div>
              <div
                *ngIf="!arrayShowdownIndex[2] && history[this.step].players[2]?.playerHand && history[this.step].players[2]?.playerHand?.cards!.length && !isFold(2)"
                class="div-cards-face-down2">
                <img class="card-face-down2-1" [src]="'../../../assets/game-v2/mesa/Card_Back_A.png'">
                <img class="card-face-down2-2" [src]="'../../../assets/game-v2/mesa/Card_Back_A.png'">
              </div>
              <div *ngIf="arrayShowdownIndex[2] && history[this.step].players[2]?.playerHand?.cards?.length && !isFold(2)"
                class="div-showdown-player2">
                <img class="showdown-card-player2-1"
                  [src]="'../../../assets/game/new_cards/' + history[this.step].players[2].playerHand!.cards[0] + '.png'">
                <img class="showdown-card-player2-2"
                  [src]="'../../../assets/game/new_cards/' + history[this.step].players[2].playerHand!.cards[1] + '.png'">
              </div>
            </div>
            <img *ngIf="history[this.step]?.dealerTurn == history[this.step].players[2]?.user?._id" class="dealer2"
              src="../../../assets/icons/poker-game/coin-dealer.svg">
            <div *ngIf="betByPlayer(2) > 0 && !history[this.step]?.inResolveGame" class="bet2">
              <div>
                <img [src]="getChipColor(betByPlayer(2))" class="bet-img">
                <span *ngIf="showValuesInBigBlindsFormat" class="me-2"> {{betByPlayer(2) / history[this.step].bigBlindValue! |
                  mask: 'separator.1'}}
                  BB</span>
                <span *ngIf="!showValuesInBigBlindsFormat" class="ms-2">$ {{betByPlayer(2)}}</span>
              </div>
            </div>
          </div>
     
        </div>


        <!-- Jogador 3 -->
        <div *ngIf="history[this.step].players[3]"
          class="player3 d-flex flex-fill flex-column align-items-center justify-content-center">
          <div class="d-flex flex-fill flex-column align-items-center justify-content-center"
            [ngClass]="history[this.step].players[3].status == 'empty' ? 'visibility-hidden' : ''">
            <div class="d-flex flex-fill flex-column align-items-center justify-content-center"
              [ngClass]="[history[this.step].players[3].status == 'requestExit' ? 'visibility-hidden' : '', history[this.step].players[3].status !== 'playing' ? 'opacity-05' : '']">
              <div [ngClass]="history[this.step].currentTurn == playersInViewOrder[3].user?._id ? 'active-player-border' : ''"
                class="profilePicture3 d-flex align-items-center justify-content-center">
                <div *ngIf="history[this.step].players[3]?.playerData && !history[this.step].players[1]?.playerData?.profilePicture"
                  class="text-uppercase">
                  {{history[this.step].players[3].playerData!.nickname[0]}}
                </div>
                <img *ngIf="history[this.step].players[3]?.playerData && history[this.step].players[3]?.playerData?.profilePicture"
                  class="img-fluid img3" src="{{history[this.step].players[3].playerData!.profilePicture}}">
              </div>
              <div *ngIf="history[this.step].players[3].status == 'absence'"
                class="sittingOut3 d-flex w-100 justify-content-center">Sitting Out</div>
              <div [ngClass]="history[this.step].currentTurn == history[this.step].players[3].user?._id ? 'active-player-border' : ''"
                class="info3">
                <div *ngIf="!(animationPlayerIndex === 3 && showActionPlayer3 && history[this.step].players[3]!.playerHand!.actions.length !== 0 && history[this.step].players[3]!.playerHand!.actions[history[this.step].players[3].playerHand?.actions!.length - 1].action !== 'PRE')"  class="nickname3 d-flex w-100 justify-content-center text-nowrap">
                  {{history[this.step].players[3].playerData?.nickname}}</div>
                <div *ngIf="animationPlayerIndex === 3 && showActionPlayer3 && history[this.step].players[3]!.playerHand!.actions.length !== 0 && history[this.step].players[3]!.playerHand!.actions[history[this.step].players[3].playerHand?.actions!.length - 1].action !== 'PRE'"
                  class="nickname3 d-flex w-100 justify-content-center">{{history[this.step].players[3]!.playerHand!.actions[history[this.step].players[3].playerHand?.actions!.length - 1].action!}}</div>
                <div
                  *ngIf="showValuesInBigBlindsFormat && (history[this.step].players[3].coinAmount > 0 || history[this.step].players[3].coinAmount == 0 && history[this.step].players[3].status !== 'playing')"
                  class="balance3 d-flex w-100 justify-content-center">
                  {{history[this.step].players[3].coinAmount / history[this.step].bigBlindValue! | mask: 'separator.1'}} BB</div>
                <div
                  *ngIf="!showValuesInBigBlindsFormat && (history[this.step].players[3].coinAmount > 0 || history[this.step].players[3].coinAmount == 0 && history[this.step].players[3].status !== 'playing')"
                  class="balance3 d-flex w-100 justify-content-center"> {{history[this.step].players[3].coinAmount}}</div>
                <div *ngIf="history[this.step].players[3].coinAmount == 0 && history[this.step].players[3].status == 'playing'"
                  class="balance3 d-flex w-100 justify-content-center">All In</div>
              </div>
              <div
                [ngClass]="history[this.step].currentTurn == history[this.step].players[3].user?._id && history[this.step].round !== 'SHOWDOWN' && history[this.step].showActions ? '' : 'visibility-hidden'"
                class="progress-bar3">
                <div class="progressWidth" [ngStyle]="{ 'width': progressWidth + '%' }"
                  [ngClass]="{'bg-red': progressWidth <= 25 }"></div>
              </div>
              <div
                *ngIf="!arrayShowdownIndex[3] && history[this.step].players[3]?.playerHand && history[this.step].players[3]?.playerHand?.cards!.length && !isFold(3)"
                class="div-cards-face-down3">
                <img class="card-face-down3-1" [src]="'../../../assets/game-v2/mesa/Card_Back_A.png'">
                <img class="card-face-down3-2" [src]="'../../../assets/game-v2/mesa/Card_Back_A.png'">
              </div>
              <div *ngIf="arrayShowdownIndex[3] && history[this.step].players[3]?.playerHand?.cards?.length && !isFold(3)"
                class="div-showdown-player3">
                <img class="showdown-card-player3-1"
                  [src]="'../../../assets/game/new_cards/' + history[this.step].players[3].playerHand!.cards[0] + '.png'">
                <img class="showdown-card-player3-2"
                  [src]="'../../../assets/game/new_cards/' + history[this.step].players[3].playerHand!.cards[1] + '.png'">
              </div>
            </div>
            <img *ngIf="history[this.step]?.dealerTurn == history[this.step].players[3]?.user?._id" class="dealer3"
              src="../../../assets/icons/poker-game/coin-dealer.svg">
            <div *ngIf="betByPlayer(3) > 0 && !history[this.step]?.inResolveGame" class="bet3">
              <div>
                <img [src]="getChipColor(betByPlayer(3))" class="bet-img">
                <span *ngIf="showValuesInBigBlindsFormat" class="me-2"> {{betByPlayer(3) / history[this.step].bigBlindValue! |
                  mask: 'separator.1'}}
                  BB</span>
                <span *ngIf="!showValuesInBigBlindsFormat" class="ms-2">$ {{betByPlayer(3)}}</span>
              </div>
            </div>
          </div>
  
        </div>


        <!-- Jogador 4 -->
        <div *ngIf="history[this.step].players[4]"
          class="player4 d-flex flex-fill flex-column align-items-center justify-content-center">
          <div class="d-flex flex-fill flex-column align-items-center justify-content-center"
            [ngClass]="history[this.step].players[4].status == 'empty' ? 'visibility-hidden' : ''">
            <div class="d-flex flex-fill flex-column align-items-center justify-content-center"
              [ngClass]="[history[this.step].players[4].status == 'requestExit' ? 'visibility-hidden' : '', history[this.step].players[4].status !== 'playing' ? 'opacity-05' : '']">
              <div [ngClass]="history[this.step].currentTurn == history[this.step].players[4].user?._id ? 'active-player-border' : ''"
                class="profilePicture4 d-flex align-items-center justify-content-center">
                <div *ngIf="history[this.step].players[4]?.playerData && !history[this.step].players[4]?.playerData?.profilePicture"
                  class="text-uppercase">
                  {{history[this.step].players[4].playerData!.nickname[0]}}
                </div>
                <img *ngIf="history[this.step].players[4]?.playerData && history[this.step].players[4]?.playerData?.profilePicture"
                  class="img-fluid img4" src="{{history[this.step].players[4].playerData!.profilePicture}}">
              </div>
              <div *ngIf="history[this.step].players[4].status == 'absence'"
                class="sittingOut4 d-flex w-100 justify-content-center">Sitting Out</div>
              <div [ngClass]="history[this.step].currentTurn == history[this.step].players[4].user?._id ? 'active-player-border' : ''"
                class="info4">
                <div *ngIf="!(animationPlayerIndex === 4 && showActionPlayer4 && history[this.step].players[4]!.playerHand!.actions.length !== 0 && history[this.step].players[4]!.playerHand!.actions[history[this.step].players[4].playerHand?.actions!.length - 1].action !== 'PRE')" class="nickname4 d-flex w-100 justify-content-center text-nowrap">
                  {{history[this.step].players[4].playerData?.nickname}}</div>
                <div *ngIf="animationPlayerIndex === 4 && showActionPlayer4 && history[this.step].players[4]!.playerHand!.actions.length !== 0 && history[this.step].players[4]!.playerHand!.actions[history[this.step].players[4].playerHand?.actions!.length - 1].action !== 'PRE'"
                  class="nickname4 d-flex w-100 justify-content-center">{{history[this.step].players[1]!.playerHand!.actions[history[this.step].players[4].playerHand?.actions!.length - 1].action!}}</div>
                <div
                  *ngIf="showValuesInBigBlindsFormat && (history[this.step].players[4].coinAmount > 0 || history[this.step].players[4].coinAmount == 0 && history[this.step].players[4].status !== 'playing')"
                  class="balance4 d-flex w-100 justify-content-center">
                  {{history[this.step].players[4].coinAmount / history[this.step].bigBlindValue! | mask: 'separator.1'}} BB</div>
                <div
                  *ngIf="!showValuesInBigBlindsFormat && (history[this.step].players[4].coinAmount > 0 || history[this.step].players[4].coinAmount == 0 && history[this.step].players[4].status !== 'playing')"
                  class="balance4 d-flex w-100 justify-content-center">{{history[this.step].players[4].coinAmount}}</div>
                <div *ngIf="history[this.step].players[4].coinAmount == 0 && history[this.step].players[4].status == 'playing'"
                  class="balance4 d-flex w-100 justify-content-center">All In</div>
              </div>
              <div
                [ngClass]="history[this.step].currentTurn == history[this.step].players[4].user?._id && history[this.step].round !== 'SHOWDOWN' && history[this.step].showActions ? '' : 'visibility-hidden'"
                class="progress-bar4">
                <div class="progressWidth" [ngStyle]="{ 'width': progressWidth + '%' }"
                  [ngClass]="{'bg-red': progressWidth <= 25 }"></div>
              </div>
              <div
                *ngIf="!arrayShowdownIndex[4] && history[this.step].players[4].playerHand && history[this.step].players[4].playerHand?.cards!.length && !isFold(4)"
                class="div-cards-face-down4">
                <img class="card-face-down4-1" [src]="'../../../assets/game-v2/mesa/Card_Back_A.png'">
                <img class="card-face-down4-2" [src]="'../../../assets/game-v2/mesa/Card_Back_A.png'">
              </div>
              <div *ngIf="arrayShowdownIndex[4] && history[this.step].players[4].playerHand?.cards?.length && !isFold(4)"
                class="div-showdown-player4">
                <img class="showdown-card-player4-1"
                  [src]="'../../../assets/game/new_cards/' + history[this.step].players[4].playerHand!.cards[0] + '.png'">
                <img class="showdown-card-player4-2"
                  [src]="'../../../assets/game/new_cards/' + history[this.step].players[4].playerHand!.cards[1] + '.png'">
              </div>
            </div>
            <img *ngIf="history[this.step].dealerTurn == history[this.step].players[4].user?._id" class="dealer4"
              src="../../../assets/icons/poker-game/coin-dealer.svg">
            <div *ngIf="betByPlayer(4) > 0 && !history[this.step].inResolveGame" class="bet4">
              <div>
                <img [src]="getChipColor(betByPlayer(4))" class="bet-img">
                <span *ngIf="showValuesInBigBlindsFormat" class="me-2"> {{betByPlayer(4) / history[this.step].bigBlindValue! |
                  mask: 'separator.1'}}
                  BB</span>
                <span *ngIf="!showValuesInBigBlindsFormat" class="ms-2">$ {{betByPlayer(4)}}</span>
              </div>
            </div>
          </div>
    
        </div>


        <!-- Jogador 5 -->
        <div *ngIf="history[this.step].players[5]"
          class="player5 d-flex flex-fill flex-column align-items-center justify-content-center">
          <div class="d-flex flex-fill flex-column align-items-center justify-content-center"
            [ngClass]="history[this.step].players[5].status == 'empty' ? 'visibility-hidden' : ''">
            <div class="d-flex flex-fill flex-column align-items-center justify-content-center"
              [ngClass]="[history[this.step].players[5].status == 'requestExit' ? 'visibility-hidden' : '', history[this.step].players[5].status !== 'playing' ? 'opacity-05' : '']">
              <div [ngClass]="history[this.step].currentTurn == history[this.step].players[5].user?._id ? 'active-player-border' : ''"
                class="profilePicture5 d-flex align-items-center justify-content-center">
                <div *ngIf="history[this.step].players[5]?.playerData && !history[this.step].players[5]?.playerData?.profilePicture"
                  class="text-uppercase">
                  {{history[this.step].players[5].playerData!.nickname[0]}}
                </div>
                <img *ngIf="history[this.step].players[5]?.playerData && history[this.step].players[5]?.playerData?.profilePicture"
                  class="img-fluid img5" src="{{history[this.step].players[5].playerData!.profilePicture}}">
              </div>
              <div *ngIf="history[this.step].players[5].status == 'absence'"
                class="sittingOut5 d-flex w-100 justify-content-center">Sitting Out</div>
              <div [ngClass]="history[this.step].currentTurn == history[this.step].players[5].user?._id ? 'active-player-border' : ''"
                class="info5">
                <div *ngIf="!(animationPlayerIndex === 5 && showActionPlayer5 && history[this.step].players[5]!.playerHand!.actions.length !== 0 && history[this.step].players[5]!.playerHand!.actions[history[this.step].players[5].playerHand?.actions!.length - 1].action !== 'PRE')" class="nickname5 d-flex w-100 justify-content-center text-nowrap">
                  {{history[this.step].players[5].playerData?.nickname}}</div>
                <div *ngIf="animationPlayerIndex === 5 && showActionPlayer5 && history[this.step].players[5]!.playerHand!.actions.length !== 0 && history[this.step].players[5]!.playerHand!.actions[history[this.step].players[5].playerHand?.actions!.length - 1].action !== 'PRE'"
                  class="nickname5 d-flex w-100 justify-content-center">{{history[this.step].players[1]!.playerHand!.actions[history[this.step].players[5].playerHand?.actions!.length - 1].action!}}</div>
                <div
                  *ngIf="showValuesInBigBlindsFormat && (history[this.step].players[5].coinAmount > 0 || history[this.step].players[5].coinAmount == 0 && history[this.step].players[5].status !== 'playing')"
                  class="balance5 d-flex w-100 justify-content-center">
                  {{history[this.step].players[5].coinAmount / history[this.step].bigBlindValue! | mask: 'separator.1'}} BB</div>
                <div
                  *ngIf="!showValuesInBigBlindsFormat && (history[this.step].players[5].coinAmount > 0 || history[this.step].players[5].coinAmount == 0 && history[this.step].players[5].status !== 'playing')"
                  class="balance5 d-flex w-100 justify-content-center">
                  {{history[this.step].players[5].coinAmount}}</div>
                <div *ngIf="history[this.step].players[5].coinAmount == 0 && history[this.step].players[5].status == 'playing'"
                  class="balance5 d-flex w-100 justify-content-center">All In</div>
              </div>
              <div
                [ngClass]="history[this.step].currentTurn == history[this.step].players[5].user?._id && history[this.step].round !== 'SHOWDOWN' && history[this.step].showActions ? '' : 'visibility-hidden'"
                class="progress-bar5">
                <div class="progressWidth" [ngStyle]="{ 'width': progressWidth + '%' }"
                  [ngClass]="{'bg-red': progressWidth <= 25 }"></div>
              </div>
              <div
                *ngIf="!arrayShowdownIndex[5] && history[this.step].players[5]?.playerHand && history[this.step].players[5]?.playerHand?.cards!.length && !isFold(5)"
                class="div-cards-face-down5">
                <img class="card-face-down5-1" [src]="'../../../assets/game-v2/mesa/Card_Back_A.png'">
                <img class="card-face-down5-2" [src]="'../../../assets/game-v2/mesa/Card_Back_A.png'">
              </div>
              <div *ngIf="arrayShowdownIndex[5] && history[this.step].players[5]?.playerHand?.cards?.length && !isFold(5)"
                class="div-showdown-player5">
                <img class="showdown-card-player5-1"
                  [src]="'../../../assets/game/new_cards/' + history[this.step].players[5].playerHand!.cards[0] + '.png'">
                <img class="showdown-card-player5-2"
                  [src]="'../../../assets/game/new_cards/' + history[this.step].players[5].playerHand!.cards[1] + '.png'">
              </div>
            </div>
            <img *ngIf="history[this.step].dealerTurn == history[this.step].players[5]?.user?._id" class="dealer5"
              src="../../../assets/icons/poker-game/coin-dealer.svg">
            <div *ngIf="betByPlayer(5) > 0 && !history[this.step].inResolveGame" class="bet5">
              <div class="d-flex flex-row-reverse">
                <img [src]="getChipColor(betByPlayer(5))" class="bet-img">
                <span *ngIf="showValuesInBigBlindsFormat" class="me-2"> {{betByPlayer(5) / history[this.step].bigBlindValue! |
                  mask: 'separator.1'}}
                  BB</span>
                <span *ngIf="!showValuesInBigBlindsFormat" class="me-2">$ {{betByPlayer(5)}}</span>
              </div>
            </div>
          </div>
  
        </div>


        <!-- Jogador 6 -->
        <div *ngIf="history[this.step].players[6]"
          class="player6 d-flex flex-fill flex-column align-items-center justify-content-center">
          <div class="d-flex flex-fill flex-column align-items-center justify-content-center"
            [ngClass]="history[this.step].players[6].status == 'empty' ? 'visibility-hidden' : ''">
            <div class="d-flex flex-fill flex-column align-items-center justify-content-center"
              [ngClass]="[history[this.step].players[6].status == 'requestExit' ? 'visibility-hidden' : '', history[this.step].players[6].status !== 'playing' ? 'opacity-05' : '']">
              <div [ngClass]="history[this.step].currentTurn == history[this.step].players[6].user?._id ? 'active-player-border' : ''"
                class="profilePicture6 d-flex align-items-center justify-content-center">
                <div *ngIf="history[this.step].players[6].playerData && !history[this.step].players[6]?.playerData?.profilePicture"
                  class="text-uppercase">
                  {{history[this.step].players[6].playerData!.nickname[0]}}
                </div>
                <img *ngIf="history[this.step].players[6]?.playerData && history[this.step].players[6]?.playerData?.profilePicture"
                  class="img-fluid img6" src="{{history[this.step].players[6].playerData!.profilePicture}}">
              </div>
              <div *ngIf="history[this.step].players[6].status == 'absence'"
                class="sittingOut6 d-flex w-100 justify-content-center">Sitting Out</div>
              <div [ngClass]="history[this.step].currentTurn == history[this.step].players[6].user?._id ? 'active-player-border' : ''"
                class="info6">
                <div *ngIf="!(animationPlayerIndex === 6 && showActionPlayer6 && history[this.step].players[6]!.playerHand!.actions.length !== 0 && history[this.step].players[6]!.playerHand!.actions[history[this.step].players[6].playerHand?.actions!.length - 1].action !== 'PRE')" class="nickname6 d-flex w-100 justify-content-center text-nowrap">
                  {{history[this.step].players[6].playerData?.nickname}}</div>
                <div *ngIf="animationPlayerIndex === 6 && showActionPlayer6 && history[this.step].players[6]!.playerHand!.actions.length !== 0 && history[this.step].players[6]!.playerHand!.actions[history[this.step].players[6].playerHand?.actions!.length - 1].action !== 'PRE'"
                  class="nickname6 d-flex w-100 justify-content-center">{{history[this.step].players[6]!.playerHand!.actions[history[this.step].players[1].playerHand?.actions!.length - 1].action!}}</div>
                <div
                  *ngIf="showValuesInBigBlindsFormat && (history[this.step].players[6].coinAmount > 0 || history[this.step].players[6].coinAmount == 0 && history[this.step].players[6].status !== 'playing')"
                  class="balance6 d-flex w-100 justify-content-center">
                  {{history[this.step].players[6].coinAmount / history[this.step].bigBlindValue! | mask: 'separator.1'}} BB</div>
                <div
                  *ngIf="!showValuesInBigBlindsFormat && (history[this.step].players[6].coinAmount > 0 || history[this.step].players[6].coinAmount == 0 && history[this.step].players[6].status !== 'playing')"
                  class="balance6 d-flex w-100 justify-content-center">
                  {{history[this.step].players[6].coinAmount}}</div>
                <div *ngIf="history[this.step].players[6].coinAmount == 0 && history[this.step].players[6].status == 'playing'"
                  class="balance6 d-flex w-100 justify-content-center">All In</div>
              </div>
              <div
                [ngClass]="history[this.step].currentTurn == history[this.step].players[6].user?._id && history[this.step].round !== 'SHOWDOWN' && history[this.step].showActions ? '' : 'visibility-hidden'"
                class="progress-bar6">
                <div class="progressWidth" [ngStyle]="{ 'width': progressWidth + '%' }"
                  [ngClass]="{'bg-red': progressWidth <= 25 }"></div>
              </div>
              <div
                *ngIf="!arrayShowdownIndex[6] && history[this.step].players[6]?.playerHand && history[this.step].players[6].playerHand?.cards!.length && !isFold(6)"
                class="div-cards-face-down6">
                <img class="card-face-down6-1" [src]="'../../../assets/game-v2/mesa/Card_Back_A.png'">
                <img class="card-face-down6-2" [src]="'../../../assets/game-v2/mesa/Card_Back_A.png'">
              </div>
              <div *ngIf="arrayShowdownIndex[6] && history[this.step].players[6].playerHand?.cards?.length && !isFold(6)"
                class="div-showdown-player6">
                <img class="showdown-card-player6-1"
                  [src]="'../../../assets/game/new_cards/' + history[this.step].players[6].playerHand!.cards[0] + '.png'">
                <img class="showdown-card-player6-2"
                  [src]="'../../../assets/game/new_cards/' + history[this.step].players[6].playerHand!.cards[1] + '.png'">
              </div>
            </div>
            <img *ngIf="history[this.step].dealerTurn == history[this.step].players[6]?.user?._id" class="dealer6"
              src="../../../assets/icons/poker-game/coin-dealer.svg">
            <div *ngIf="betByPlayer(6) > 0 && !history[this.step].inResolveGame" class="bet6">
              <div class="d-flex flex-row-reverse">
                <img [src]="getChipColor(betByPlayer(6))" class="bet-img">
                <span *ngIf="showValuesInBigBlindsFormat" class="me-2"> {{betByPlayer(6) / history[this.step].bigBlindValue! |
                  mask: 'separator.1'}}
                  BB</span>
                <span *ngIf="!showValuesInBigBlindsFormat" class="me-2">$ {{betByPlayer(6)}}</span>
              </div>
            </div>
          </div>

        </div>


        <!-- Jogador 7 -->
        <div *ngIf="history[this.step].players[7]"
          class="player7 d-flex flex-fill flex-column align-items-center justify-content-center">
          <div class="d-flex flex-fill flex-column align-items-center justify-content-center"
            [ngClass]="history[this.step].players[7].status == 'empty' ? 'visibility-hidden' : ''">
            <div class="d-flex flex-fill flex-column align-items-center justify-content-center"
              [ngClass]="[history[this.step].players[7].status == 'requestExit' ? 'visibility-hidden' : '', history[this.step].players[7].status !== 'playing' ? 'opacity-05' : '']">
              <div [ngClass]="history[this.step].currentTurn == history[this.step].players[7].user?._id ? 'active-player-border' : ''"
                class="profilePicture7 d-flex align-items-center justify-content-center">
                <div *ngIf="history[this.step].players[7]?.playerData && !history[this.step].players[7]?.playerData?.profilePicture"
                  class="text-uppercase">
                  {{history[this.step].players[7].playerData!.nickname[0]}}
                </div>
                <img *ngIf="history[this.step].players[7]?.playerData && history[this.step].players[7]?.playerData?.profilePicture"
                  class="img-fluid img7" src="{{history[this.step].players[7].playerData!.profilePicture}}">
              </div>
              <div *ngIf="history[this.step].players[7].status == 'absence'"
                class="sittingOut7 d-flex w-100 justify-content-center">Sitting Out</div>
              <div [ngClass]="history[this.step].currentTurn == history[this.step].players[7].user?._id ? 'active-player-border' : ''"
                class="info7">
                <div *ngIf="!(animationPlayerIndex === 7 && showActionPlayer7 && history[this.step].players[7]!.playerHand!.actions.length !== 0 && history[this.step].players[7]!.playerHand!.actions[history[this.step].players[7].playerHand?.actions!.length - 1].action !== 'PRE')" class="nickname7 d-flex w-100 justify-content-center text-nowrap">
                  {{history[this.step].players[7].playerData?.nickname}}</div>
                <div *ngIf="animationPlayerIndex === 7 && showActionPlayer7 && history[this.step].players[7]!.playerHand!.actions.length !== 0 && history[this.step].players[7]!.playerHand!.actions[history[this.step].players[7].playerHand?.actions!.length - 1].action !== 'PRE'"
                  class="nickname7 d-flex w-100 justify-content-center">{{history[this.step].players[7]!.playerHand!.actions[history[this.step].players[7].playerHand?.actions!.length - 1].action!}}</div>
                <div
                  *ngIf="showValuesInBigBlindsFormat && (history[this.step].players[7].coinAmount > 0 || history[this.step].players[7].coinAmount == 0 && history[this.step].players[7].status !== 'playing')"
                  class="balance7 d-flex w-100 justify-content-center">
                  {{history[this.step].players[7].coinAmount / history[this.step].bigBlindValue! | mask: 'separator.1'}} BB</div>
                <div
                  *ngIf="!showValuesInBigBlindsFormat && (history[this.step].players[7].coinAmount > 0 || history[this.step].players[7].coinAmount == 0 && history[this.step].players[7].status !== 'playing')"
                  class="balance7 d-flex w-100 justify-content-center">
                  {{history[this.step].players[7].coinAmount}}</div>
                <div *ngIf="history[this.step].players[7].coinAmount == 0 && history[this.step].players[7].status == 'playing'"
                  class="balance7 d-flex w-100 justify-content-center">All In</div>
              </div>
              <div
                [ngClass]="history[this.step].currentTurn == history[this.step].players[7].user?._id && history[this.step].round !== 'SHOWDOWN' && history[this.step].showActions ? '' : 'visibility-hidden'"
                class="progress-bar7">
                <div class="progressWidth" [ngStyle]="{ 'width': progressWidth + '%' }"
                  [ngClass]="{'bg-red': progressWidth <= 25 }"></div>
              </div>
              <div
                *ngIf="!arrayShowdownIndex[7] && history[this.step].players[7]?.playerHand && history[this.step].players[7]?.playerHand?.cards!.length && !isFold(7)"
                class="div-cards-face-down7">
                <img class="card-face-down7-1" [src]="'../../../assets/game-v2/mesa/Card_Back_A.png'">
                <img class="card-face-down7-2" [src]="'../../../assets/game-v2/mesa/Card_Back_A.png'">
              </div>
              <div *ngIf="arrayShowdownIndex[7] && history[this.step].players[7]?.playerHand?.cards?.length && !isFold(7)"
                class="div-showdown-player7">
                <img class="showdown-card-player7-1"
                  [src]="'../../../assets/game/new_cards/' + history[this.step].players[7].playerHand!.cards[0] + '.png'">
                <img class="showdown-card-player7-2"
                  [src]="'../../../assets/game/new_cards/' + history[this.step].players[7].playerHand!.cards[1] + '.png'">
              </div>
            </div>
            <img *ngIf="history[this.step].dealerTurn == history[this.step].players[7]?.user?._id" class="dealer7"
              src="../../../assets/icons/poker-game/coin-dealer.svg">
            <div *ngIf="betByPlayer(7) > 0 && !history[this.step].inResolveGame" class="bet7">
              <div class="d-flex flex-row-reverse">
                <img [src]="getChipColor(betByPlayer(7))" class="bet-img">
                <span *ngIf="showValuesInBigBlindsFormat" class="me-2"> {{betByPlayer(7) / history[this.step].bigBlindValue! |
                  mask: 'separator.1'}}
                  BB</span>
                <span *ngIf="!showValuesInBigBlindsFormat" class="me-2">$ {{betByPlayer(7)}}</span>
              </div>
            </div>
          </div>
      
        </div>


        <!-- Jogador 8 -->
        <div *ngIf="history[this.step].players[8]"

          class="player8 d-flex flex-fill flex-column align-items-center justify-content-center">
          <div class="d-flex flex-fill flex-column align-items-center justify-content-center"
            [ngClass]="history[this.step].players[8].status == 'empty' ? 'visibility-hidden' : ''">
            <div class="d-flex flex-fill flex-column align-items-center justify-content-center"
              [ngClass]="[history[this.step].players[8].status == 'requestExit' ? 'visibility-hidden' : '', history[this.step].players[8].status !== 'playing' ? 'opacity-05' : '']">
              <div [ngClass]="history[this.step].currentTurn == history[this.step].players[8].user?._id ? 'active-player-border' : ''"
                class="profilePicture8 d-flex align-items-center justify-content-center">
                <div *ngIf="history[this.step].players[8]?.playerData && !history[this.step].players[8]?.playerData?.profilePicture"
                  class="text-uppercase">
                  {{history[this.step].players[8].playerData!.nickname[0]}}
                </div>
                <img *ngIf="history[this.step].players[8]?.playerData && history[this.step].players[8]?.playerData?.profilePicture"
                  class="img-fluid img8" src="{{history[this.step].players[8].playerData!.profilePicture}}">
              </div>
              <div *ngIf="history[this.step].players[8].status == 'absence'"
                class="sittingOut8 d-flex w-100 justify-content-center">Sitting Out</div>
              <div [ngClass]="history[this.step].currentTurn == history[this.step].players[8].user?._id ? 'active-player-border' : ''"
                class="info8">
                <div *ngIf="!(animationPlayerIndex === 8 && showActionPlayer8 && history[this.step].players[8]!.playerHand!.actions.length !== 0 && history[this.step].players[8]!.playerHand!.actions[history[this.step].players[8].playerHand?.actions!.length - 1].action !== 'PRE')" class="nickname8 d-flex w-100 justify-content-center text-nowrap">
                  {{history[this.step].players[8].playerData?.nickname}}</div>
                <div *ngIf="animationPlayerIndex === 8 && showActionPlayer8 && history[this.step].players[8]!.playerHand!.actions.length !== 0 && history[this.step].players[8]!.playerHand!.actions[history[this.step].players[8].playerHand?.actions!.length - 1].action !== 'PRE'"
                  class="nickname8 d-flex w-100 justify-content-center">{{history[this.step].players[8]!.playerHand!.actions[history[this.step].players[8].playerHand?.actions!.length - 1].action!}}</div>
                <div
                  *ngIf="showValuesInBigBlindsFormat && (history[this.step].players[8].coinAmount > 0 || history[this.step].players[8].coinAmount == 0 && history[this.step].players[8].status !== 'playing')"
                  class="balance8 d-flex w-100 justify-content-center">
                  {{history[this.step].players[8].coinAmount / history[this.step].bigBlindValue! | mask: 'separator.1'}} BB</div>
                <div
                  *ngIf="!showValuesInBigBlindsFormat && (history[this.step].players[8].coinAmount > 0 || history[this.step].players[8].coinAmount == 0 && history[this.step].players[8].status !== 'playing')"
                  class="balance8 d-flex w-100 justify-content-center">
                  {{history[this.step].players[8].coinAmount}}</div>
                <div *ngIf="history[this.step].players[8].coinAmount == 0 && history[this.step].players[8].status == 'playing'"
                  class="balance8 d-flex w-100 justify-content-center">All In</div>
              </div>
              <div
                [ngClass]="history[this.step].currentTurn == history[this.step].players[8].user?._id && history[this.step].round !== 'SHOWDOWN' && history[this.step].showActions ? '' : 'visibility-hidden'"
                class="progress-bar8">
                <div class="progressWidth" [ngStyle]="{ 'width': progressWidth + '%' }"
                  [ngClass]="{'bg-red': progressWidth <= 25 }"></div>
              </div>
              <div
                *ngIf="!arrayShowdownIndex[8] && history[this.step].players[8]?.playerHand && history[this.step].players[8]?.playerHand?.cards!.length && !isFold(8)"
                class="div-cards-face-down8">
                <img class="card-face-down8-1" [src]="'../../../assets/game-v2/mesa/Card_Back_A.png'">
                <img class="card-face-down8-2" [src]="'../../../assets/game-v2/mesa/Card_Back_A.png'">
              </div>
              <div *ngIf="arrayShowdownIndex[8] && history[this.step].players[8]?.playerHand?.cards?.length && !isFold(8)"
                class="div-showdown-player8">
                <img class="showdown-card-player8-1"
                  [src]="'../../../assets/game/new_cards/' + history[this.step].players[8].playerHand!.cards[0] + '.png'">
                <img class="showdown-card-player8-2"
                  [src]="'../../../assets/game/new_cards/' + history[this.step].players[8].playerHand!.cards[1] + '.png'">
              </div>
            </div>
            <img *ngIf="history[this.step].dealerTurn == history[this.step].players[8]?.user?._id" class="dealer8"
              src="../../../assets/icons/poker-game/coin-dealer.svg">
            <div *ngIf="betByPlayer(8) > 0 && !history[this.step].inResolveGame" class="bet8">
              <div class="d-flex flex-row-reverse">
                <img [src]="getChipColor(betByPlayer(8))" class="bet-img">
                <span *ngIf="showValuesInBigBlindsFormat" class="me-2"> {{betByPlayer(8) / history[this.step].bigBlindValue! |
                  mask: 'separator.1'}}
                  BB</span>
                <span *ngIf="!showValuesInBigBlindsFormat" class="me-2">$ {{betByPlayer(8)}}</span>
              </div>
            </div>
          </div>
     
        </div>

        <div class="div-pot d-flex justify-content-center align-items-center">
          <div *ngIf="history[this.step].tableStatus! == 'PLAYING' && showValuesInBigBlindsFormat" class="pot"><span>Pot:
              {{history[this.step].pot! / history[this.step].bigBlindValue! | mask: 'separator.1'}} BB</span></div>
          <div *ngIf="history[this.step].tableStatus! == 'PLAYING' && !showValuesInBigBlindsFormat" class="pot"><span>Pot: $
              {{history[this.step].pot}}</span></div>
        </div>

        <div *ngIf="this.history[this.step].visualPots.length > 0 && getPotValue(0) > 0" class="div-pot0 d-flex justify-content-center">
          <div *ngIf="showValuesInBigBlindsFormat" class="pot0 d-flex">
            <img [src]="getChipColor(getPotValue(0))" class="bet-img me-2">
            <span>{{getPotValue(0) / history[this.step].bigBlindValue! | mask: 'separator.1'}} BB</span>
          </div>
          <div *ngIf="!showValuesInBigBlindsFormat" class="pot0 d-flex">
            <img [src]="getChipColor(getPotValue(0))" class="bet-img me-2">
            <div>$ {{getPotValue(0)}}</div>
          </div>
        </div>

        <div *ngIf="this.history[this.step].visualPots.length > 1 && getPotValue(1) > 0" class="div-pot1 d-flex justify-content-center">
          <div *ngIf="showValuesInBigBlindsFormat" class="pot1 d-flex">
            <img [src]="getChipColor(getPotValue(1))" class="bet-img me-2">
            <span>{{getPotValue(1) / history[this.step].bigBlindValue! | mask: 'separator.1'}} BB</span>
          </div>
          <div *ngIf="!showValuesInBigBlindsFormat" class="pot1 d-flex">
            <img [src]="getChipColor(getPotValue(1))" class="bet-img me-2">
            <div>$ {{getPotValue(1)}}</div>
          </div>
        </div>

        <div *ngIf="this.history[this.step].visualPots.length > 2 && getPotValue(2) > 0" class="div-pot2 d-flex justify-content-center">
          <div *ngIf="showValuesInBigBlindsFormat" class="pot2 d-flex">
            <img [src]="getChipColor(getPotValue(2))" class="bet-img me-2">
            <span>{{getPotValue(2) / history[this.step].bigBlindValue! | mask: 'separator.1'}} BB</span>
          </div>
          <div *ngIf="!showValuesInBigBlindsFormat" class="pot2 d-flex">
            <img [src]="getChipColor(getPotValue(2))" class="bet-img me-2">
            <div>$ {{getPotValue(2)}}</div>
          </div>
        </div>

        <div>
          <img *ngIf="history[this.step].flopCards?.length && history[this.step].round !== 'PRE_FLOP'" class="flop-cards flop-card0"
            [src]="'../../../../assets/game/new_cards/' + history[this.step]!.flopCards[0] + '.png'">
          <img *ngIf="history[this.step].flopCards?.length && history[this.step].round !== 'PRE_FLOP'" class="flop-cards flop-card1"
            [src]="'../../../../assets/game/new_cards/' + history[this.step]!.flopCards[1] + '.png'">
          <img *ngIf="history[this.step].flopCards?.length && history[this.step].round !== 'PRE_FLOP'" class="flop-cards flop-card2"
            [src]="'../../../../assets/game/new_cards/' + history[this.step]!.flopCards[2] + '.png'">
          <img
            *ngIf="history[this.step].flopCards?.length && (history[this.step].round == 'TURN' || history[this.step].round == 'RIVER'  || history[this.step].round == 'SHOWDOWN')"
            class="flop-cards flop-card3" [src]="'../../../../assets/game/new_cards/' + history[this.step]!.flopCards[3] + '.png'">
          <img *ngIf="history[this.step].flopCards?.length && (history[this.step].round == 'RIVER'  || history[this.step].round == 'SHOWDOWN')"
            class="flop-cards flop-card4" [src]="'../../../../assets/game/new_cards/' + history[this.step]!.flopCards[4] + '.png'">
        </div>

        <!-- Animação das cartas indo para os jogadores no inicio da rodada -->
        <div id="animationDivSetCardsToPlayer0">
          <img class="animationSetCardsToPlayer-card1" [src]="'../../../../assets/game-v2/mesa/Card_Back_A.png'">
          <img class="animationSetCardsToPlayer-card2" [src]="'../../../../assets/game-v2/mesa/Card_Back_A.png'">
        </div>
        <div id="animationDivSetCardsToPlayer1">
          <img class="animationSetCardsToPlayer-card1" [src]="'../../../../assets/game-v2/mesa/Card_Back_A.png'">
          <img class="animationSetCardsToPlayer-card2" [src]="'../../../../assets/game-v2/mesa/Card_Back_A.png'">
        </div>
        <div id="animationDivSetCardsToPlayer2">
          <img class="animationSetCardsToPlayer-card1" [src]="'../../../../assets/game-v2/mesa/Card_Back_A.png'">
          <img class="animationSetCardsToPlayer-card2" [src]="'../../../../assets/game-v2/mesa/Card_Back_A.png'">
        </div>
        <div id="animationDivSetCardsToPlayer3">
          <img class="animationSetCardsToPlayer-card1" [src]="'../../../../assets/game-v2/mesa/Card_Back_A.png'">
          <img class="animationSetCardsToPlayer-card2" [src]="'../../../../assets/game-v2/mesa/Card_Back_A.png'">
        </div>
        <div id="animationDivSetCardsToPlayer4">
          <img class="animationSetCardsToPlayer-card1" [src]="'../../../../assets/game-v2/mesa/Card_Back_A.png'">
          <img class="animationSetCardsToPlayer-card2" [src]="'../../../../assets/game-v2/mesa/Card_Back_A.png'">
        </div>
        <div id="animationDivSetCardsToPlayer5">
          <img class="animationSetCardsToPlayer-card1" [src]="'../../../../assets/game-v2/mesa/Card_Back_A.png'">
          <img class="animationSetCardsToPlayer-card2" [src]="'../../../../assets/game-v2/mesa/Card_Back_A.png'">
        </div>
        <div id="animationDivSetCardsToPlayer6">
          <img class="animationSetCardsToPlayer-card1" [src]="'../../../../assets/game-v2/mesa/Card_Back_A.png'">
          <img class="animationSetCardsToPlayer-card2" [src]="'../../../../assets/game-v2/mesa/Card_Back_A.png'">
        </div>
        <div id="animationDivSetCardsToPlayer7">
          <img class="animationSetCardsToPlayer-card1" [src]="'../../../../assets/game-v2/mesa/Card_Back_A.png'">
          <img class="animationSetCardsToPlayer-card2" [src]="'../../../../assets/game-v2/mesa/Card_Back_A.png'">
        </div>
        <div id="animationDivSetCardsToPlayer8">
          <img class="animationSetCardsToPlayer-card1" [src]="'../../../../assets/game-v2/mesa/Card_Back_A.png'">
          <img class="animationSetCardsToPlayer-card2" [src]="'../../../../assets/game-v2/mesa/Card_Back_A.png'">
        </div>


        <!-- Animação das cartas quando o jogador da FOLD -->
        <div id="animationDivSetCardsToTable0">
          <img class="animationSetCardsToTable0-card1" [src]="'../../../../assets/game-v2/mesa/Card_Back_A.png'">
          <img class="animationSetCardsToTable0-card2" [src]="'../../../../assets/game-v2/mesa/Card_Back_A.png'">
        </div>
        <div id="animationDivSetCardsToTable1">
          <img class="animationSetCardsToTable1-card1" [src]="'../../../../assets/game-v2/mesa/Card_Back_A.png'">
          <img class="animationSetCardsToTable1-card2" [src]="'../../../../assets/game-v2/mesa/Card_Back_A.png'">
        </div>
        <div id="animationDivSetCardsToTable2">
          <img class="animationSetCardsToTable2-card1" [src]="'../../../../assets/game-v2/mesa/Card_Back_A.png'">
          <img class="animationSetCardsToTable2-card2" [src]="'../../../../assets/game-v2/mesa/Card_Back_A.png'">
        </div>
        <div id="animationDivSetCardsToTable3">
          <img class="animationSetCardsToTable3-card1" [src]="'../../../../assets/game-v2/mesa/Card_Back_A.png'">
          <img class="animationSetCardsToTable3-card2" [src]="'../../../../assets/game-v2/mesa/Card_Back_A.png'">
        </div>
        <div id="animationDivSetCardsToTable4">
          <img class="animationSetCardsToTable4-card1" [src]="'../../../../assets/game-v2/mesa/Card_Back_A.png'">
          <img class="animationSetCardsToTable4-card2" [src]="'../../../../assets/game-v2/mesa/Card_Back_A.png'">
        </div>
        <div id="animationDivSetCardsToTable5">
          <img class="animationSetCardsToTable5-card1" [src]="'../../../../assets/game-v2/mesa/Card_Back_A.png'">
          <img class="animationSetCardsToTable5-card2" [src]="'../../../../assets/game-v2/mesa/Card_Back_A.png'">
        </div>
        <div id="animationDivSetCardsToTable6">
          <img class="animationSetCardsToTable6-card1" [src]="'../../../../assets/game-v2/mesa/Card_Back_A.png'">
          <img class="animationSetCardsToTable6-card2" [src]="'../../../../assets/game-v2/mesa/Card_Back_A.png'">
        </div>
        <div id="animationDivSetCardsToTable7">
          <img class="animationSetCardsToTable7-card1" [src]="'../../../../assets/game-v2/mesa/Card_Back_A.png'">
          <img class="animationSetCardsToTable7-card2" [src]="'../../../../assets/game-v2/mesa/Card_Back_A.png'">
        </div>
        <div id="animationDivSetCardsToTable8">
          <img class="animationSetCardsToTable8-card1" [src]="'../../../../assets/game-v2/mesa/Card_Back_A.png'">
          <img class="animationSetCardsToTable8-card2" [src]="'../../../../assets/game-v2/mesa/Card_Back_A.png'">
        </div>

        <!-- Animação (BET CALL RAISE E ALLIN) das fichas saindo do jogador para a mesa -->
        <img id="animationChipsFromHandToTableIndex0" class="bet-img cursor-pointer"
          (click)="changeShowValuesInBigBlindsFormat()" [src]="getChipColor(animationResponse?.bet!)">
        <img id="animationChipsFromHandToTableIndex1" class="bet-img" [src]="getChipColor(animationResponse?.bet!)">
        <img id="animationChipsFromHandToTableIndex2" class="bet-img" [src]="getChipColor(animationResponse?.bet!)">
        <img id="animationChipsFromHandToTableIndex3" class="bet-img" [src]="getChipColor(animationResponse?.bet!)">
        <img id="animationChipsFromHandToTableIndex4" class="bet-img" [src]="getChipColor(animationResponse?.bet!)">
        <img id="animationChipsFromHandToTableIndex5" class="bet-img" [src]="getChipColor(animationResponse?.bet!)">
        <img id="animationChipsFromHandToTableIndex6" class="bet-img" [src]="getChipColor(animationResponse?.bet!)">
        <img id="animationChipsFromHandToTableIndex7" class="bet-img" [src]="getChipColor(animationResponse?.bet!)">
        <img id="animationChipsFromHandToTableIndex8" class="bet-img" [src]="getChipColor(animationResponse?.bet!)">


        <!-- Animação das fichas saindo da frente do jogador para a pot visual 0 -->
        <img id="animationChipsGoingToVisualPot1Index0" class="bet-img" [src]="getChipColor(betByPlayer(0))">
        <img id="animationChipsGoingToVisualPot1Index1" class="bet-img" [src]="getChipColor(betByPlayer(1))">
        <img id="animationChipsGoingToVisualPot1Index2" class="bet-img" [src]="getChipColor(betByPlayer(2))">
        <img id="animationChipsGoingToVisualPot1Index3" class="bet-img" [src]="getChipColor(betByPlayer(3))">
        <img id="animationChipsGoingToVisualPot1Index4" class="bet-img" [src]="getChipColor(betByPlayer(4))">
        <img id="animationChipsGoingToVisualPot1Index5" class="bet-img" [src]="getChipColor(betByPlayer(5))">
        <img id="animationChipsGoingToVisualPot1Index6" class="bet-img" [src]="getChipColor(betByPlayer(6))">
        <img id="animationChipsGoingToVisualPot1Index7" class="bet-img" [src]="getChipColor(betByPlayer(7))">
        <img id="animationChipsGoingToVisualPot1Index8" class="bet-img" [src]="getChipColor(betByPlayer(8))">

        <!-- Animação das fichas saindo da frente do jogador para a pot visual 1 -->
        <img id="animationChipsGoingToVisualPot0Index0" class="bet-img" [src]="getChipColor(betByPlayer(0))">
        <img id="animationChipsGoingToVisualPot0Index1" class="bet-img" [src]="getChipColor(betByPlayer(1))">
        <img id="animationChipsGoingToVisualPot0Index2" class="bet-img" [src]="getChipColor(betByPlayer(2))">
        <img id="animationChipsGoingToVisualPot0Index3" class="bet-img" [src]="getChipColor(betByPlayer(3))">
        <img id="animationChipsGoingToVisualPot0Index4" class="bet-img" [src]="getChipColor(betByPlayer(4))">
        <img id="animationChipsGoingToVisualPot0Index5" class="bet-img" [src]="getChipColor(betByPlayer(5))">
        <img id="animationChipsGoingToVisualPot0Index6" class="bet-img" [src]="getChipColor(betByPlayer(6))">
        <img id="animationChipsGoingToVisualPot0Index7" class="bet-img" [src]="getChipColor(betByPlayer(7))">
        <img id="animationChipsGoingToVisualPot0Index8" class="bet-img" [src]="getChipColor(betByPlayer(8))">

        <!-- Animação das fichas saindo da frente do jogador para a pot visual 2 -->
        <img id="animationChipsGoingToVisualPot2Index0" class="bet-img" [src]="getChipColor(betByPlayer(0))">
        <img id="animationChipsGoingToVisualPot2Index1" class="bet-img" [src]="getChipColor(betByPlayer(1))">
        <img id="animationChipsGoingToVisualPot2Index2" class="bet-img" [src]="getChipColor(betByPlayer(2))">
        <img id="animationChipsGoingToVisualPot2Index3" class="bet-img" [src]="getChipColor(betByPlayer(3))">
        <img id="animationChipsGoingToVisualPot2Index4" class="bet-img" [src]="getChipColor(betByPlayer(4))">
        <img id="animationChipsGoingToVisualPot2Index5" class="bet-img" [src]="getChipColor(betByPlayer(5))">
        <img id="animationChipsGoingToVisualPot2Index6" class="bet-img" [src]="getChipColor(betByPlayer(6))">
        <img id="animationChipsGoingToVisualPot2Index7" class="bet-img" [src]="getChipColor(betByPlayer(7))">
        <img id="animationChipsGoingToVisualPot2Index8" class="bet-img" [src]="getChipColor(betByPlayer(8))">

        <!-- Animação das fichas saindo do pot visual 0 para o vencedor -->
        <img id="animationChipsFromPot0ToPlayerIndex0" class="bet-img"
          [src]="getChipColor(animationGiveChipsToWinners?.bet!)">
        <img id="animationChipsFromPot0ToPlayerIndex1" class="bet-img"
          [src]="getChipColor(animationGiveChipsToWinners?.bet!)">
        <img id="animationChipsFromPot0ToPlayerIndex2" class="bet-img"
          [src]="getChipColor(animationGiveChipsToWinners?.bet!)">
        <img id="animationChipsFromPot0ToPlayerIndex3" class="bet-img"
          [src]="getChipColor(animationGiveChipsToWinners?.bet!)">
        <img id="animationChipsFromPot0ToPlayerIndex4" class="bet-img"
          [src]="getChipColor(animationGiveChipsToWinners?.bet!)">
        <img id="animationChipsFromPot0ToPlayerIndex5" class="bet-img"
          [src]="getChipColor(animationGiveChipsToWinners?.bet!)">
        <img id="animationChipsFromPot0ToPlayerIndex6" class="bet-img"
          [src]="getChipColor(animationGiveChipsToWinners?.bet!)">
        <img id="animationChipsFromPot0ToPlayerIndex7" class="bet-img"
          [src]="getChipColor(animationGiveChipsToWinners?.bet!)">
        <img id="animationChipsFromPot0ToPlayerIndex8" class="bet-img"
          [src]="getChipColor(animationGiveChipsToWinners?.bet!)">

        <!-- Animação das fichas saindo do pot visual 1 para o vencedor -->
        <img id="animationChipsFromPot1ToPlayerIndex0" class="bet-img"
          [src]="getChipColor(animationGiveChipsToWinners?.bet!)">
        <img id="animationChipsFromPot1ToPlayerIndex1" class="bet-img"
          [src]="getChipColor(animationGiveChipsToWinners?.bet!)">
        <img id="animationChipsFromPot1ToPlayerIndex2" class="bet-img"
          [src]="getChipColor(animationGiveChipsToWinners?.bet!)">
        <img id="animationChipsFromPot1ToPlayerIndex3" class="bet-img"
          [src]="getChipColor(animationGiveChipsToWinners?.bet!)">
        <img id="animationChipsFromPot1ToPlayerIndex4" class="bet-img"
          [src]="getChipColor(animationGiveChipsToWinners?.bet!)">
        <img id="animationChipsFromPot1ToPlayerIndex5" class="bet-img"
          [src]="getChipColor(animationGiveChipsToWinners?.bet!)">
        <img id="animationChipsFromPot1ToPlayerIndex6" class="bet-img"
          [src]="getChipColor(animationGiveChipsToWinners?.bet!)">
        <img id="animationChipsFromPot1ToPlayerIndex7" class="bet-img"
          [src]="getChipColor(animationGiveChipsToWinners?.bet!)">
        <img id="animationChipsFromPot1ToPlayerIndex8" class="bet-img"
          [src]="getChipColor(animationGiveChipsToWinners?.bet!)">

        <!-- Animação das fichas saindo do pot visual 2 para o vencedor -->
        <img id="animationChipsFromPot2ToPlayerIndex0" class="bet-img"
          [src]="getChipColor(animationGiveChipsToWinners?.bet!)">
        <img id="animationChipsFromPot2ToPlayerIndex1" class="bet-img"
          [src]="getChipColor(animationGiveChipsToWinners?.bet!)">
        <img id="animationChipsFromPot2ToPlayerIndex2" class="bet-img"
          [src]="getChipColor(animationGiveChipsToWinners?.bet!)">
        <img id="animationChipsFromPot2ToPlayerIndex3" class="bet-img"
          [src]="getChipColor(animationGiveChipsToWinners?.bet!)">
        <img id="animationChipsFromPot2ToPlayerIndex4" class="bet-img"
          [src]="getChipColor(animationGiveChipsToWinners?.bet!)">
        <img id="animationChipsFromPot2ToPlayerIndex5" class="bet-img"
          [src]="getChipColor(animationGiveChipsToWinners?.bet!)">
        <img id="animationChipsFromPot2ToPlayerIndex6" class="bet-img"
          [src]="getChipColor(animationGiveChipsToWinners?.bet!)">
        <img id="animationChipsFromPot2ToPlayerIndex7" class="bet-img"
          [src]="getChipColor(animationGiveChipsToWinners?.bet!)">
        <img id="animationChipsFromPot2ToPlayerIndex8" class="bet-img"
          [src]="getChipColor(animationGiveChipsToWinners?.bet!)">

      </div>


    </div>

    <!-- Ações -->
    <!-- <div class="row">
      <div class="col-sm-6"></div>
      <div class="col-sm-6">
        <div class="container container-actions d-flex justify-content-end">
          <div class="div-actions">
            <form [formGroup]="form">
              <div
                *ngIf="tableGame?.showActions && tableGame?.currentTurn == playersInViewOrder[0]?.user?._id && isMainPlayer()"
                class="d-flex align-items-center">
                <button type="button" *ngIf="tableGame?.round == 'PRE_FLOP'" class="btn-shortcuts"
                  (click)="clickShortcuts1()">2.5x</button>
                <button type="button" *ngIf="tableGame?.round !== 'PRE_FLOP'" class="btn-shortcuts"
                  (click)="clickShortcuts1()">33%</button>
                <button type="button" *ngIf="tableGame?.round == 'PRE_FLOP'" class="btn-shortcuts ms-1"
                  (click)="clickShortcuts2()">&nbsp;3x&nbsp;</button>
                <button type="button" *ngIf="tableGame?.round !== 'PRE_FLOP'" class="btn-shortcuts ms-1"
                  (click)="clickShortcuts2()">50%</button>
                <button type="button" *ngIf="tableGame?.round == 'PRE_FLOP'" class="btn-shortcuts ms-1"
                  (click)="clickShortcuts3()">3.5x</button>
                <button type="button" *ngIf="tableGame?.round !== 'PRE_FLOP'" class="btn-shortcuts ms-1"
                  (click)="clickShortcuts3()">75%</button>
                <button type="button" class="btn-shortcuts ms-1" (click)="clickShortcuts4()">Max</button>
                <span *ngIf="!showValuesInBigBlindsFormat" class="input-group-text ms-1">$</span>
                <input *ngIf="!showValuesInBigBlindsFormat" type="number" formControlName="valueRange"
                  class="form-control input-custom-value-range" (keyup)="listenedRange($event, 'input')">
                <input *ngIf="showValuesInBigBlindsFormat" type="number" formControlName="valueRangeBB"
                  class="form-control input-custom-value-range text-end ms-1"
                  (keyup)="listenedRange($event, 'inputBB')">
                <span *ngIf="showValuesInBigBlindsFormat" class="input-group-text"> BB</span>
                <input id="teste" type="range" formControlName="bet" class="form-range ms-2"
                  (change)="listenedRange($event, 'range')" [min]="minRaiseValue" [max]="getMaxRange()">
              </div>

              <div class="row ps-3 mt-2">
                <div class="col-12 d-flex justify-content-end">
                  <div
                    *ngIf="tableGame?.showActions && tableGame?.currentTurn == playersInViewOrder[0]?.user?._id && !canCheck() &&  isMainPlayer()"
                    class="col-4 me-2 btn-actions d-flex justify-content-center">
                    <div class="btn-actions-name d-flex justify-content-center align-items-center w-100 cursor-pointer"
                      (click)="fold()">{{'GAME.FOLD' | translate}}</div>
                  </div>
                  <div
                    *ngIf="tableGame?.showActions && tableGame?.currentTurn == playersInViewOrder[0]?.user?._id && canCheck() && isMainPlayer()"
                    class="col-4 me-2 btn-actions d-flex justify-content-center">
                    <div class="btn-actions-name d-flex justify-content-center align-items-center w-100 cursor-pointer"
                      (click)="check()">Check
                    </div>
                  </div>
                  <div
                    *ngIf="tableGame?.showActions && tableGame?.currentTurn == playersInViewOrder[0]?.user?._id && canCall() && !canCheck() &&  isMainPlayer()"
                    class="col-4 btn-actions d-flex flex-column align-items-center">
                    <div class="btn-actions-name d-flex justify-content-center align-items-center w-100 cursor-pointer"
                      (click)="call()">{{ 'GAME.CALL' | translate }}</div>
                    <div *ngIf="!showValuesInBigBlindsFormat"
                      class="btn-actions-value d-flex justify-content-center w-100">
                      $ {{(getTopBetRound() - getTopBetPlayer()).toFixed(2)}}</div>
                    <div *ngIf="showValuesInBigBlindsFormat"
                      class="btn-actions-value d-flex justify-content-center w-100">
                      {{(getTopBetRound() - getTopBetPlayer()) / tableGame?.bigBlindValue! | mask: 'separator.2'}} BB
                    </div>
                  </div>
                  <div
                    *ngIf="tableGame?.showActions && tableGame?.currentTurn == playersInViewOrder[0]?.user?._id && canRaise() && getTopBetRound() == 0 && isMainPlayer()"
                    (click)="raise()" class="col-4 me-2 btn-actions d-flex flex-column align-items-center">
                    <div class="btn-actions-name d-flex justify-content-center align-items-center w-100 cursor-pointer">
                      Bet
                    </div>
                    <div *ngIf="!showValuesInBigBlindsFormat"
                      class="btn-actions-value d-flex justify-content-center w-100">
                      $ {{(form.controls['bet'].value).toFixed(2)}}</div>
                    <div *ngIf="showValuesInBigBlindsFormat"
                      class="btn-actions-value d-flex justify-content-center w-100">
                      {{form.controls['bet'].value / tableGame?.bigBlindValue! | mask: 'separator.2'}} BB</div>
                  </div>
                  <div
                    *ngIf="tableGame?.showActions && tableGame?.currentTurn == playersInViewOrder[0]?.user?._id && canRaise() && getTopBetRound() > 0 &&  isMainPlayer()"
                    (click)="raise()" class="col-4 me-2 btn-actions d-flex flex-column  align-items-center">
                    <div class="btn-actions-name d-flex justify-content-center align-items-center w-100 cursor-pointer">
                      {{ 'GAME.RAISE' | translate }}</div>
                    <div *ngIf="!showValuesInBigBlindsFormat"
                      class="btn-actions-value d-flex justify-content-center w-100">
                      $ {{(form.controls['bet'].value).toFixed(2)}}</div>
                    <div *ngIf="showValuesInBigBlindsFormat"
                      class="btn-actions-value d-flex justify-content-center w-100">
                      {{form.controls['bet'].value / tableGame?.bigBlindValue! | mask: 'separator.2'}} BB</div>
                  </div>

                  <div
                    *ngIf="tableGame?.showActions && tableGame?.currentTurn == playersInViewOrder[0]?.user?._id && canAllIn() && isMainPlayer()"
                    (click)="allIn()" class="col-4 me-2 btn-actions d-flex flex-column  align-items-center">
                    <div class="btn-actions-name d-flex justify-content-center align-items-center w-100 cursor-pointer">
                      All
                      In</div>
                    <div *ngIf="!showValuesInBigBlindsFormat"
                      class="btn-actions-value d-flex justify-content-center w-100">
                      $ {{form.controls['bet'].value}} </div>
                    <div *ngIf="showValuesInBigBlindsFormat"
                      class="btn-actions-value d-flex justify-content-center w-100">
                      {{form.controls['bet'].value / tableGame?.bigBlindValue! | mask: 'separator.2'}} BB</div>
                  </div>

                  <div
                    *ngIf="playersInViewOrder[0]?.status == 'absence' && tableGame?.currentTurn !== playersInViewOrder[0]?.user?._id && playerInTableGame()"
                    class="d-flex mt-5 flex-fill w-100 justify-content-end">
                    <div (click)="presence()"
                      class="btn-actions-name d-flex justify-content-center align-items-center w-100 cursor-pointer"
                      style="max-width: 200px;">I'm Back</div>
                  </div>

                  <div *ngIf="playersInViewOrder[0]?.status == 'waitingForBigBlind' && playerInTableGame() "
                    class="d-flex mt-5 flex-fill w-100 justify-content-end">
                    <div (click)="payBigBlind()"
                      class="btn-actions-name d-flex justify-content-center align-items-center w-100 cursor-pointer"
                      style="max-width: 200px;">Pagar Big Blind</div>
                  </div>

                </div>
              </div>
            </form>
          </div>
      
        </div>
      </div>
    </div> -->



  </div>

</section>
