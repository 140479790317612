import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginGuard } from 'src/guards/login.guard';
import { FirstAccessComponent } from './auth/first-access/first-access.component';
import { LandingPageComponent } from './auth/landing-page/landing-page.component';
import { ErrorNotFoundComponent } from './components/error-not-found/error-not-found.component';
import { LoggedModule } from './logged/logged.module';

const routes: Routes = [
  { path: '', pathMatch: "full", component: LandingPageComponent, canActivate: [LoginGuard] },
  {
    path: 'pages',
    loadChildren: () =>
      import('./logged/logged.module').then((a) => a.LoggedModule),
  },
  // { path: 'sign-up', component: SignUpComponent },
  // { path: 'sign-in', component: SignInComponent, canActivate: [LoginGuard] },
  // { path: 'code', component: VericationCodeComponent, canActivate: [LoginGuard] },
  // { path: 'password', component: PasswordPageComponent, canActivate: [LoginGuard] },
  { path: 'first-access', component: FirstAccessComponent },
  // { path: 'reset-password', component: ResetPasswordComponent, canActivate: [LoginGuard] },
  { path: "**", component: ErrorNotFoundComponent },
];

@NgModule({
  imports: [LoggedModule, RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
