import { NgxSpinnerService } from 'ngx-spinner';
import { transition, trigger, useAnimation } from '@angular/animations';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { cardShuffle, cardShuffle2, cardSlide, cardSlideFromDealer } from 'src/animations';
import { PlayerhandStatusEnum } from 'src/interface/player-hand-status.enum';
import { TableGameRoundStatusEnum } from 'src/interface/table-game-round-status.enum';
import { AuthService } from 'src/services/auth.service';
import { TableGameService } from '../../../services/table-game.service';
import { JwtPayloadDto } from '../../dtos/auth/jwt-payload.dto';
import { GameGatewaySocketService } from 'src/services/sockets/game-gateway.socket.service';
import { TableGameJoinResponseDto } from 'src/app/dtos/socket/table-game-join-response.dto';
import { PlayerHandActionEnum } from 'src/app/dtos/enum/player-hand-action.enum';
import { ToastrService } from 'ngx-toastr';
import { AnimationActionResponseDto } from '../../dtos/table-game/animation-action-response.dto';
import SoundUtil from '../../../util/sound.util';
import { AnimationGiveChipsToWinnersResponseDto } from 'src/app/dtos/table-game/animation-give-chips-to-winners-response.dto';
import Delay from 'src/util/timer.util';
import { AnimationShowActionsDto } from 'src/app/dtos/table-game/animation-show-actions.dto';
import { AnimationTimerCurrentPlayerDto } from 'src/app/dtos/table-game/animation-timer-current-player.dto';
import { AnimationShowCardsAtShowdownDto } from 'src/app/dtos/table-game/animation-show-cards-at-showdown.dto';
import { GameCoinService } from 'src/services/game-coin.service';
import { GameCoinGetBalanceResponseDto } from 'src/app/dtos/game-coin/game-coin-get-balance-response.dto';
import { ResponseDto } from 'src/app/dtos/response.dto';
import { AnimationShowFullPotDto } from 'src/app/dtos/table-game/animation-show-full-pot.dto';
import { CommonGatewaySocketService } from 'src/services/sockets/common-gateway.socket.service';

@Component({
  selector: 'app-play-cashgame',
  templateUrl: './play-cashgame.component.html',
  styleUrls: ['./play-cashgame.component.scss'],
  animations: [
    cardShuffle,
    cardShuffle2,
    cardSlideFromDealer,

    trigger('slider', [
      transition(':enter', [
        useAnimation(cardSlide, {
          params: {
            transform: 'translateX(-300px)',
            opacity: 1
          }
        })
      ])
    ]),
    trigger('slider2', [
      transition(':enter', [
        useAnimation(cardSlide, {
          params: {
            transform: 'translateX(300px)',
            opacity: 1
          }
        })
      ])
    ]),
  ]
})
export class PlayCashgameComponent implements OnInit {

  loggedUser: JwtPayloadDto | undefined;

  showCards: boolean = true;

  roomId: string | undefined;

  loggedPlayerHand: any;
  unloggedPlayerHand: any;
  tableInfo: any;
  winner: any;
  finishedRound: boolean = false;
  ePlayerhandStatusEnum = PlayerhandStatusEnum;
  rangeCashgameValue: number = 0;
  chat: boolean = true;
  hands: boolean = false;
  grades: boolean = false;
  statistics: boolean = false;
  information: boolean = false;
  form: FormGroup;
  formBuyCoin: FormGroup;
  expand: boolean = true;
  players: { id: string, nickname: string }[] = [];
  shuffleFinished = false;
  showAnimation = false;
  slideCards: boolean = false;
  shuffleState1: 'left' | 'right' | 'pilled' = 'left';
  shuffleState2: 'left' | 'right' | 'pilled' = 'right';
  timesShuffled: number = 0;
  showCardsSlots: boolean = false;
  flip: string = 'initial';

  showActions = false;
  showFullPot = false;
  arrayPlayers: any = [];
  mainPlayerId!: string;

  animationResponse: AnimationActionResponseDto | undefined;
  animationPlayerIndex: number | undefined;
  animationGiveChipsToWinners: AnimationGiveChipsToWinnersResponseDto | undefined;
  animationGiveChipsToWinnersPlayerWinner: number | undefined;


  showActionPlayer0: boolean = false;
  showActionPlayer1: boolean = false;
  showActionPlayer2: boolean = false;
  showActionPlayer3: boolean = false;
  showActionPlayer4: boolean = false;
  showActionPlayer5: boolean = false;
  showActionPlayer6: boolean = false;
  showActionPlayer7: boolean = false;
  showActionPlayer8: boolean = false;

  showIndividualPot: boolean = true;

  interval: any;
  progressWidth!: number;

  currentTurn: string = '';
  previousRound: string = '';
  previousDealer: string = '';

  minRaiseValue = 0;

  showdownIndex0: boolean = false;
  showdownIndex1: boolean = false;
  showdownIndex2: boolean = false;
  showdownIndex3: boolean = false;
  showdownIndex4: boolean = false;
  showdownIndex5: boolean = false;
  showdownIndex6: boolean = false;
  showdownIndex7: boolean = false;
  showdownIndex8: boolean = false;

  balanceValue?: GameCoinGetBalanceResponseDto;
  buyCoinMin: Number = 0;
  buyCoinMax: Number = 0;

  constructor(
    private formBuilder: FormBuilder,
    private readonly route: ActivatedRoute,
    private readonly translateService: TranslateService,
    private readonly authService: AuthService,
    private tableGameService: TableGameService,
    private readonly _gameGateway: GameGatewaySocketService,
    private readonly _commonGateway: CommonGatewaySocketService,
    private toastrService: ToastrService,
    private gameCoinService: GameCoinService,
    private ngxSpinnerService: NgxSpinnerService,
    private router: Router,
  ) {
    this.form = this.formBuilder.group({
      bet: [''],
      valueRange: ['']
    });
    this.formBuyCoin = this.formBuilder.group({
      buyCoin: [''],
      valueRangeBuyCoin: [''],
    });
  }

  ngOnInit(): void {
    this._commonGateway.initializeMain();
    this._gameGateway.initializeMain();

    this.ngxSpinnerService.show();
    setTimeout(() => {
      this.ngxSpinnerService.hide();
    }, 3000);
    setTimeout(() => {

      this._gameGateway.requestJoin(this.route.snapshot.params['id']);
      this.loggedUser = this.authService.getPayloadFromJWT();

      this._gameGateway.tableGameJoinResponse.subscribe({
        next: (response: any) => {

          this.tableInfo = response;
          if (this.previousRound === TableGameRoundStatusEnum.PRE_FLOP && this.tableInfo.round === TableGameRoundStatusEnum.FLOP) {
            SoundUtil.play('../../assets/sounds/flop.m4a', 2);
          } else if (this.previousRound === TableGameRoundStatusEnum.FLOP && this.tableInfo.round === TableGameRoundStatusEnum.TURN ||
            this.previousRound === TableGameRoundStatusEnum.TURN && this.tableInfo.round === TableGameRoundStatusEnum.RIVER) {
            SoundUtil.play('../../assets/sounds/turn-river.m4a', 2);
          }

          if (this.currentTurn !== this.tableInfo.currentTurn || this.previousRound !== this.tableInfo.round || this.previousDealer !== this.tableInfo.dealerTurn) {
            // if (this.tableInfo.round !== TableGameRoundStatusEnum.SHOWDOWN) {
            //   this.showActions = true;
            // }
            this.currentTurn = this.tableInfo.currentTurn;
            this.showIndividualPot = true;
          }

          this.previousDealer = this.tableInfo.dealerTurn;
          this.previousRound = this.tableInfo.round;
          this.arrayPlayers = this.tableInfo.players;
          this.getMinRaise();
          this.form.patchValue({
            bet: this.minRaiseValue
          });

          for (const player of this.arrayPlayers) {
            if (player._id == this.authService.getAuthenticatedUser()._id) {
              this.mainPlayerId = player._id;
            }
          }
          if (!this.mainPlayerId) {
            for (let i = 0; i < this.tableInfo.playersQueue.length; i++) {
              if (this.tableInfo.playersQueue[i]._id == this.authService.getAuthenticatedUser()._id) {
                this.mainPlayerId = this.tableInfo.playersQueue[i]._id;
              }
            }
          }
        }
      });

      this._gameGateway.subscribeAnimationAction();
      this._gameGateway.animationActionResponse.subscribe({
        next: (response: AnimationActionResponseDto) => {
          this.animationResponse = response;
          this.showPlayerAction();
        }
      });

      this._gameGateway.subscribeAnimationGiveChipsToWinners()
      this._gameGateway.animationGiveChipsToWinners.subscribe({
        next: (response: AnimationGiveChipsToWinnersResponseDto) => {
          this.animationGiveChipsToWinners = response;
          this.showAnimationGiveChipsToWinners();
        }
      });

      this._gameGateway.subscribeAnimationSetCardsToPlayers()
      this._gameGateway.animationSetCardsToPlayers.subscribe({
        next: (response: any) => {
          this.animationSetCardsToPlayers();
        }
      });

      this._gameGateway.subscribeAnimationSetChipsToPot()
      this._gameGateway.animationSetChipsToPot.subscribe({
        next: (response: any) => {
          this.animationSetChipsToPot();
        }
      });

      this._gameGateway.subscribeAnimationTimerCurrentPlayer()
      this._gameGateway.animationTimerCurrentPlayer.subscribe({
        next: (response: AnimationTimerCurrentPlayerDto) => {
          this.progressWidth = response.timer;
        }
      });

      this._gameGateway.subscribeAnimationShowCardsAtShowdown()
      this._gameGateway.animationShowCardsAtShowdown.subscribe({
        next: (response: AnimationShowCardsAtShowdownDto) => {
          this.showdownIndex0 = response.showdownIndex0;
          this.showdownIndex1 = response.showdownIndex1;
          this.showdownIndex2 = response.showdownIndex2;
          this.showdownIndex3 = response.showdownIndex3;
          this.showdownIndex4 = response.showdownIndex4;
          this.showdownIndex5 = response.showdownIndex5;
          this.showdownIndex6 = response.showdownIndex6;
          this.showdownIndex7 = response.showdownIndex7;
          this.showdownIndex8 = response.showdownIndex8;
        }
      });

      this._gameGateway.subscribeAnimationShowActions()
      this._gameGateway.animationShowActions.subscribe({
        next: (response: AnimationShowActionsDto) => {
          this.showActions = response.showActions;
        }
      });

      this._gameGateway.subscribeAnimationShowFullPot()
      this._gameGateway.animationShowFullPot.subscribe({
        next: (response: AnimationShowFullPotDto) => {
          this.showFullPot = response.showFullPot;
        }
      });

    }, 1000);

  }

  animacao() {

  }

  showPlayerAction() {
    this.animationPlayerIndex = this.tableInfo.players.findIndex((a: any) =>
      a._id.toString() === this.animationResponse?.playerId.toString()
    );

    if (this.animationResponse?.action == PlayerHandActionEnum.FOLD) {
      this.showAnimationFoldAction();
    } else if (this.animationResponse?.action == PlayerHandActionEnum.CALL || this.animationResponse?.action == PlayerHandActionEnum.RAISE ||
      this.animationResponse?.action == PlayerHandActionEnum.ALLIN || this.animationResponse?.action == PlayerHandActionEnum.BET) {
      this.showAnimationBetAction();
    }

    this.playSound();

    switch (this.animationPlayerIndex) {
      case 0:

        this.showActionPlayer0 = true;
        this.showActionPlayer1 = false;
        this.showActionPlayer2 = false;
        this.showActionPlayer3 = false;
        this.showActionPlayer4 = false;
        this.showActionPlayer5 = false;
        this.showActionPlayer6 = false;
        this.showActionPlayer7 = false;
        this.showActionPlayer8 = false;

        setTimeout(() => {
          this.showActionPlayer0 = false;
        }, 3000);

        break;
      case 1:

        this.showActionPlayer0 = false;
        this.showActionPlayer1 = true;
        this.showActionPlayer2 = false;
        this.showActionPlayer3 = false;
        this.showActionPlayer4 = false;
        this.showActionPlayer5 = false;
        this.showActionPlayer6 = false;
        this.showActionPlayer7 = false;
        this.showActionPlayer8 = false;

        setTimeout(() => {
          this.showActionPlayer1 = false;
        }, 3000);

        break;
      case 2:

        this.showActionPlayer0 = false;
        this.showActionPlayer1 = false;
        this.showActionPlayer2 = true;
        this.showActionPlayer3 = false;
        this.showActionPlayer4 = false;
        this.showActionPlayer5 = false;
        this.showActionPlayer6 = false;
        this.showActionPlayer7 = false;
        this.showActionPlayer8 = false;

        setTimeout(() => {
          this.showActionPlayer2 = false;
        }, 3000);

        break;
      case 3:

        this.showActionPlayer0 = false;
        this.showActionPlayer1 = false;
        this.showActionPlayer2 = false;
        this.showActionPlayer3 = true;
        this.showActionPlayer4 = false;
        this.showActionPlayer5 = false;
        this.showActionPlayer6 = false;
        this.showActionPlayer7 = false;
        this.showActionPlayer8 = false;

        setTimeout(() => {
          this.showActionPlayer3 = false;
        }, 3000);

        break;
      case 4:

        this.showActionPlayer0 = false;
        this.showActionPlayer1 = false;
        this.showActionPlayer2 = false;
        this.showActionPlayer3 = false;
        this.showActionPlayer4 = true;
        this.showActionPlayer5 = false;
        this.showActionPlayer6 = false;
        this.showActionPlayer7 = false;
        this.showActionPlayer8 = false;

        setTimeout(() => {
          this.showActionPlayer4 = false;
        }, 3000);

        break;
      case 5:

        this.showActionPlayer0 = false;
        this.showActionPlayer1 = false;
        this.showActionPlayer2 = false;
        this.showActionPlayer3 = false;
        this.showActionPlayer4 = false;
        this.showActionPlayer5 = true;
        this.showActionPlayer6 = false;
        this.showActionPlayer7 = false;
        this.showActionPlayer8 = false;

        setTimeout(() => {
          this.showActionPlayer5 = false;
        }, 3000);

        break;
      case 6:

        this.showActionPlayer0 = false;
        this.showActionPlayer1 = false;
        this.showActionPlayer2 = false;
        this.showActionPlayer3 = false;
        this.showActionPlayer4 = false;
        this.showActionPlayer5 = false;
        this.showActionPlayer6 = true;
        this.showActionPlayer7 = false;
        this.showActionPlayer8 = false;

        setTimeout(() => {
          this.showActionPlayer6 = false;
        }, 3000);

        break;
      case 7:

        this.showActionPlayer0 = false;
        this.showActionPlayer1 = false;
        this.showActionPlayer2 = false;
        this.showActionPlayer3 = false;
        this.showActionPlayer4 = false;
        this.showActionPlayer5 = false;
        this.showActionPlayer6 = false;
        this.showActionPlayer7 = true;
        this.showActionPlayer8 = false;

        setTimeout(() => {
          this.showActionPlayer7 = false;
        }, 3000);

        break;
      case 8:

        this.showActionPlayer0 = false;
        this.showActionPlayer1 = false;
        this.showActionPlayer2 = false;
        this.showActionPlayer3 = false;
        this.showActionPlayer4 = false;
        this.showActionPlayer5 = false;
        this.showActionPlayer6 = false;
        this.showActionPlayer7 = false;
        this.showActionPlayer8 = true;

        setTimeout(() => {
          this.showActionPlayer8 = false;
        }, 3000);

        break;
    }
  }

  showAnimationFoldAction() {
    switch (this.animationPlayerIndex) {
      case 0:
        let cartasSobrepostasIndex0 = document.getElementById('cartasSobrepostasIndex0');
        cartasSobrepostasIndex0?.classList.add('foldIndex0');
        setTimeout(() => {
          cartasSobrepostasIndex0?.classList.remove('foldIndex0');
        }, 1000);
        break;

      case 1:
        let cartasSobrepostasIndex1 = document.getElementById('cartasSobrepostasIndex1');
        cartasSobrepostasIndex1?.classList.add('foldIndex1');
        setTimeout(() => {
          cartasSobrepostasIndex1?.classList.remove('foldIndex1');
        }, 1000);
        break;

      case 2:
        let cartasSobrepostasIndex2 = document.getElementById('cartasSobrepostasIndex2');
        cartasSobrepostasIndex2?.classList.add('foldIndex2');
        setTimeout(() => {
          cartasSobrepostasIndex2?.classList.remove('foldIndex2');
        }, 1000);
        break;

      case 3:
        let cartasSobrepostasIndex3 = document.getElementById('cartasSobrepostasIndex3');
        cartasSobrepostasIndex3?.classList.add('foldIndex3');
        setTimeout(() => {
          cartasSobrepostasIndex3?.classList.remove('foldIndex3');
        }, 1000);
        break;

      case 4:
        let cartasSobrepostasIndex4 = document.getElementById('cartasSobrepostasIndex4');
        cartasSobrepostasIndex4?.classList.add('foldIndex4');
        setTimeout(() => {
          cartasSobrepostasIndex4?.classList.remove('foldIndex4');
        }, 1000);
        break;

      case 5:
        let cartasSobrepostasIndex5 = document.getElementById('cartasSobrepostasIndex5');
        cartasSobrepostasIndex5?.classList.add('foldIndex5');
        setTimeout(() => {
          cartasSobrepostasIndex5?.classList.remove('foldIndex5');
        }, 1000);
        break;

      case 6:
        let cartasSobrepostasIndex6 = document.getElementById('cartasSobrepostasIndex6');
        cartasSobrepostasIndex6?.classList.add('foldIndex6');
        setTimeout(() => {
          cartasSobrepostasIndex6?.classList.remove('foldIndex6');
        }, 1000);
        break;

      case 7:
        let cartasSobrepostasIndex7 = document.getElementById('cartasSobrepostasIndex7');
        cartasSobrepostasIndex7?.classList.add('foldIndex7');
        setTimeout(() => {
          cartasSobrepostasIndex7?.classList.remove('foldIndex7');
        }, 1000);
        break;

      case 8:
        let cartasSobrepostasIndex8 = document.getElementById('cartasSobrepostasIndex8');
        cartasSobrepostasIndex8?.classList.add('foldIndex8');
        setTimeout(() => {
          cartasSobrepostasIndex8?.classList.remove('foldIndex8');
        }, 1000);
        break;
    }
  }

  showAnimationBetAction() {
    switch (this.animationPlayerIndex) {
      case 0:
        let ChipsFromHandToTableIndex0 = document.getElementById('ChipsFromHandToTableIndex0');
        ChipsFromHandToTableIndex0?.classList.add('fromHandToTableIndex0');
        setTimeout(() => {
          ChipsFromHandToTableIndex0?.classList.remove('fromHandToTableIndex0');
        }, 1000);
        break;

      case 1:
        let ChipsFromHandToTableIndex1 = document.getElementById('ChipsFromHandToTableIndex1');
        ChipsFromHandToTableIndex1?.classList.add('fromHandToTableIndex1');
        setTimeout(() => {
          ChipsFromHandToTableIndex1?.classList.remove('fromHandToTableIndex1');
        }, 1000);
        break;

      case 2:
        let ChipsFromHandToTableIndex2 = document.getElementById('ChipsFromHandToTableIndex2');
        ChipsFromHandToTableIndex2?.classList.add('fromHandToTableIndex2');
        setTimeout(() => {
          ChipsFromHandToTableIndex2?.classList.remove('fromHandToTableIndex2');
        }, 1000);
        break;

      case 3:
        let ChipsFromHandToTableIndex3 = document.getElementById('ChipsFromHandToTableIndex3');
        ChipsFromHandToTableIndex3?.classList.add('fromHandToTableIndex3');
        setTimeout(() => {
          ChipsFromHandToTableIndex3?.classList.remove('fromHandToTableIndex3');
        }, 1000);
        break;

      case 4:
        let ChipsFromHandToTableIndex4 = document.getElementById('ChipsFromHandToTableIndex4');
        ChipsFromHandToTableIndex4?.classList.add('fromHandToTableIndex4');
        setTimeout(() => {
          ChipsFromHandToTableIndex4?.classList.remove('fromHandToTableIndex4');
        }, 1000);
        break;

      case 5:
        let ChipsFromHandToTableIndex5 = document.getElementById('ChipsFromHandToTableIndex5');
        ChipsFromHandToTableIndex5?.classList.add('fromHandToTableIndex5');
        setTimeout(() => {
          ChipsFromHandToTableIndex5?.classList.remove('fromHandToTableIndex5');
        }, 1000);
        break;

      case 6:
        let ChipsFromHandToTableIndex6 = document.getElementById('ChipsFromHandToTableIndex6');
        ChipsFromHandToTableIndex6?.classList.add('fromHandToTableIndex6');
        setTimeout(() => {
          ChipsFromHandToTableIndex6?.classList.remove('fromHandToTableIndex6');
        }, 1000);
        break;

      case 7:
        let ChipsFromHandToTableIndex7 = document.getElementById('ChipsFromHandToTableIndex7');
        ChipsFromHandToTableIndex7?.classList.add('fromHandToTableIndex7');
        setTimeout(() => {
          ChipsFromHandToTableIndex7?.classList.remove('fromHandToTableIndex7');
        }, 1000);
        break;

      case 8:
        let ChipsFromHandToTableIndex8 = document.getElementById('ChipsFromHandToTableIndex8');
        ChipsFromHandToTableIndex8?.classList.add('fromHandToTableIndex8');
        setTimeout(() => {
          ChipsFromHandToTableIndex8?.classList.remove('fromHandToTableIndex8');
        }, 1000);
        break;
    }
  }

  showAnimationGiveChipsToWinners() {
    this.animationGiveChipsToWinnersPlayerWinner = this.tableInfo.players.findIndex((a: any) =>
      a._id.toString() === this.animationGiveChipsToWinners?.playerId.toString()
    );

    switch (this.animationGiveChipsToWinnersPlayerWinner) {
      case 0:
        let giveChipsToWinnersIndex0 = document.getElementById('giveChipsToWinnersIndex0');
        giveChipsToWinnersIndex0?.classList.add('chipsToWinnersIndex0');
        SoundUtil.play('../../assets/sounds/action-bet-raise-call-allin.m4a', 2);
        setTimeout(() => {
          giveChipsToWinnersIndex0?.classList.remove('chipsToWinnersIndex0');
        }, 1000);
        break;

      case 1:
        let giveChipsToWinnersIndex1 = document.getElementById('giveChipsToWinnersIndex1');
        giveChipsToWinnersIndex1?.classList.add('chipsToWinnersIndex1');
        SoundUtil.play('../../assets/sounds/action-bet-raise-call-allin.m4a', 2);
        setTimeout(() => {
          giveChipsToWinnersIndex1?.classList.remove('chipsToWinnersIndex1');
        }, 1000);
        break;

      case 2:
        let giveChipsToWinnersIndex2 = document.getElementById('giveChipsToWinnersIndex2');
        giveChipsToWinnersIndex2?.classList.add('chipsToWinnersIndex2');
        SoundUtil.play('../../assets/sounds/action-bet-raise-call-allin.m4a', 2);
        setTimeout(() => {
          giveChipsToWinnersIndex2?.classList.remove('chipsToWinnersIndex2');
        }, 1000);
        break;

      case 3:
        let giveChipsToWinnersIndex3 = document.getElementById('giveChipsToWinnersIndex3');
        giveChipsToWinnersIndex3?.classList.add('chipsToWinnersIndex3');
        SoundUtil.play('../../assets/sounds/action-bet-raise-call-allin.m4a', 2);
        setTimeout(() => {
          giveChipsToWinnersIndex3?.classList.remove('chipsToWinnersIndex3');
        }, 1000);
        break;

      case 4:
        let giveChipsToWinnersIndex4 = document.getElementById('giveChipsToWinnersIndex4');
        giveChipsToWinnersIndex4?.classList.add('chipsToWinnersIndex4');
        SoundUtil.play('../../assets/sounds/action-bet-raise-call-allin.m4a', 2);
        setTimeout(() => {
          giveChipsToWinnersIndex4?.classList.remove('chipsToWinnersIndex4');
        }, 1000);
        break;

      case 5:
        let giveChipsToWinnersIndex5 = document.getElementById('giveChipsToWinnersIndex5');
        giveChipsToWinnersIndex5?.classList.add('chipsToWinnersIndex5');
        SoundUtil.play('../../assets/sounds/action-bet-raise-call-allin.m4a', 2);
        setTimeout(() => {
          giveChipsToWinnersIndex5?.classList.remove('chipsToWinnersIndex5');
        }, 1000);
        break;

      case 6:
        let giveChipsToWinnersIndex6 = document.getElementById('giveChipsToWinnersIndex6');
        giveChipsToWinnersIndex6?.classList.add('chipsToWinnersIndex6');
        SoundUtil.play('../../assets/sounds/action-bet-raise-call-allin.m4a', 2);
        setTimeout(() => {
          giveChipsToWinnersIndex6?.classList.remove('chipsToWinnersIndex6');
        }, 1000);
        break;

      case 7:
        let giveChipsToWinnersIndex7 = document.getElementById('giveChipsToWinnersIndex7');
        giveChipsToWinnersIndex7?.classList.add('chipsToWinnersIndex7');
        SoundUtil.play('../../assets/sounds/action-bet-raise-call-allin.m4a', 2);
        setTimeout(() => {
          giveChipsToWinnersIndex7?.classList.remove('chipsToWinnersIndex7');
        }, 1000);
        break;

      case 8:
        let giveChipsToWinnersIndex8 = document.getElementById('giveChipsToWinnersIndex8');
        giveChipsToWinnersIndex8?.classList.add('chipsToWinnersIndex8');
        SoundUtil.play('../../assets/sounds/action-bet-raise-call-allin.m4a', 2);
        setTimeout(() => {
          giveChipsToWinnersIndex8?.classList.remove('chipsToWinnersIndex8');
        }, 1000);
        break;
    }
  }

  async animationSetCardsToPlayers() {

    if (this.arrayPlayers[0]) {
      let setCardsToPlayersIndex0 = document.getElementById('setCardsToPlayersIndex0');
      setCardsToPlayersIndex0?.classList.add('cardsToPlayersIndex0');
      SoundUtil.play('../../assets/sounds/flop.m4a', 1);
      setTimeout(() => {
        setCardsToPlayersIndex0?.classList.remove('cardsToPlayersIndex0');
      }, 1000);
    }

    if (this.arrayPlayers[1]) {
      let setCardsToPlayersIndex1 = document.getElementById('setCardsToPlayersIndex1');
      setCardsToPlayersIndex1?.classList.add('cardsToPlayersIndex1');
      setTimeout(() => {
        setCardsToPlayersIndex1?.classList.remove('cardsToPlayersIndex1');
      }, 1000);
    }

    if (this.arrayPlayers[2]) {
      let setCardsToPlayersIndex2 = document.getElementById('setCardsToPlayersIndex2');
      setCardsToPlayersIndex2?.classList.add('cardsToPlayersIndex2');
      setTimeout(() => {
        setCardsToPlayersIndex2?.classList.remove('cardsToPlayersIndex2');
      }, 1000);
    }

    if (this.arrayPlayers[3]) {
      let setCardsToPlayersIndex3 = document.getElementById('setCardsToPlayersIndex3');
      setCardsToPlayersIndex3?.classList.add('cardsToPlayersIndex3');
      setTimeout(() => {
        setCardsToPlayersIndex3?.classList.remove('cardsToPlayersIndex3');
      }, 1000);
    }

    if (this.arrayPlayers[4]) {
      let setCardsToPlayersIndex4 = document.getElementById('setCardsToPlayersIndex4');
      setCardsToPlayersIndex4?.classList.add('cardsToPlayersIndex4');
      setTimeout(() => {
        setCardsToPlayersIndex4?.classList.remove('cardsToPlayersIndex4');
      }, 1000);
    }

    if (this.arrayPlayers[5]) {
      let setCardsToPlayersIndex5 = document.getElementById('setCardsToPlayersIndex5');
      setCardsToPlayersIndex5?.classList.add('cardsToPlayersIndex5');
      setTimeout(() => {
        setCardsToPlayersIndex5?.classList.remove('cardsToPlayersIndex5');
      }, 1000);
    }

    if (this.arrayPlayers[6]) {
      let setCardsToPlayersIndex6 = document.getElementById('setCardsToPlayersIndex6');
      setCardsToPlayersIndex6?.classList.add('cardsToPlayersIndex6');
      setTimeout(() => {
        setCardsToPlayersIndex6?.classList.remove('cardsToPlayersIndex6');
      }, 1000);
    }

    if (this.arrayPlayers[7]) {
      let setCardsToPlayersIndex7 = document.getElementById('setCardsToPlayersIndex7');
      setCardsToPlayersIndex7?.classList.add('cardsToPlayersIndex7');
      setTimeout(() => {
        setCardsToPlayersIndex7?.classList.remove('cardsToPlayersIndex7');
      }, 1000);
    }

    if (this.arrayPlayers[8]) {
      let setCardsToPlayersIndex8 = document.getElementById('setCardsToPlayersIndex8');
      setCardsToPlayersIndex8?.classList.add('cardsToPlayersIndex8');
      setTimeout(() => {
        setCardsToPlayersIndex8?.classList.remove('cardsToPlayersIndex8');
      }, 1000);
    }

  }

  animationSetChipsToPot() {
    this.showIndividualPot = false;

    if (this.arrayPlayers[0] && this.betByPlayer(this.arrayPlayers[0]?._id) > 0) {
      let giveChipsToPotIndex0 = document.getElementById('giveChipsToPotIndex0');
      giveChipsToPotIndex0?.classList.add('chipsToPotIndex0');
      SoundUtil.play('../../assets/sounds/action-bet-raise-call-allin.m4a', 2);
      setTimeout(() => {
        giveChipsToPotIndex0?.classList.remove('chipsToPotIndex0');
      }, 1000);
    }

    if (this.arrayPlayers[1] && this.betByPlayer(this.arrayPlayers[1]?._id) > 0) {
      let giveChipsToPotIndex1 = document.getElementById('giveChipsToPotIndex1');
      giveChipsToPotIndex1?.classList.add('chipsToPotIndex1');
      SoundUtil.play('../../assets/sounds/action-bet-raise-call-allin.m4a', 2);
      setTimeout(() => {
        giveChipsToPotIndex1?.classList.remove('chipsToPotIndex1');
      }, 1000);
    }

    if (this.arrayPlayers[2] && this.betByPlayer(this.arrayPlayers[2]?._id) > 0) {
      let giveChipsToPotIndex2 = document.getElementById('giveChipsToPotIndex2');
      giveChipsToPotIndex2?.classList.add('chipsToPotIndex2');
      SoundUtil.play('../../assets/sounds/action-bet-raise-call-allin.m4a', 2);
      setTimeout(() => {
        giveChipsToPotIndex2?.classList.remove('chipsToPotIndex2');
      }, 1000);
    }
    if (this.arrayPlayers[3] && this.betByPlayer(this.arrayPlayers[3]?._id) > 0) {
      let giveChipsToPotIndex3 = document.getElementById('giveChipsToPotIndex3');
      giveChipsToPotIndex3?.classList.add('chipsToPotIndex3');
      SoundUtil.play('../../assets/sounds/action-bet-raise-call-allin.m4a', 2);
      setTimeout(() => {
        giveChipsToPotIndex3?.classList.remove('chipsToPotIndex3');
      }, 1000);
    }
    if (this.arrayPlayers[4] && this.betByPlayer(this.arrayPlayers[4]?._id) > 0) {
      let giveChipsToPotIndex4 = document.getElementById('giveChipsToPotIndex4');
      giveChipsToPotIndex4?.classList.add('chipsToPotIndex4');
      SoundUtil.play('../../assets/sounds/action-bet-raise-call-allin.m4a', 2);
      setTimeout(() => {
        giveChipsToPotIndex4?.classList.remove('chipsToPotIndex4');
      }, 1000);
    }
    if (this.arrayPlayers[5] && this.betByPlayer(this.arrayPlayers[5]?._id) > 0) {
      let giveChipsToPotIndex5 = document.getElementById('giveChipsToPotIndex5');
      giveChipsToPotIndex5?.classList.add('chipsToPotIndex5');
      SoundUtil.play('../../assets/sounds/action-bet-raise-call-allin.m4a', 2);
      setTimeout(() => {
        giveChipsToPotIndex5?.classList.remove('chipsToPotIndex5');
      }, 1000);
    }
    if (this.arrayPlayers[6] && this.betByPlayer(this.arrayPlayers[6]?._id) > 0) {
      let giveChipsToPotIndex6 = document.getElementById('giveChipsToPotIndex6');
      giveChipsToPotIndex6?.classList.add('chipsToPotIndex6');
      SoundUtil.play('../../assets/sounds/action-bet-raise-call-allin.m4a', 2);
      setTimeout(() => {
        giveChipsToPotIndex6?.classList.remove('chipsToPotIndex6');
      }, 1000);
    }
    if (this.arrayPlayers[7] && this.betByPlayer(this.arrayPlayers[7]?._id) > 0) {
      let giveChipsToPotIndex7 = document.getElementById('giveChipsToPotIndex7');
      giveChipsToPotIndex7?.classList.add('chipsToPotIndex7');
      SoundUtil.play('../../assets/sounds/action-bet-raise-call-allin.m4a', 2);
      setTimeout(() => {
        giveChipsToPotIndex7?.classList.remove('chipsToPotIndex7');
      }, 1000);
    }
    if (this.arrayPlayers[8] && this.betByPlayer(this.arrayPlayers[8]?._id) > 0) {
      let giveChipsToPotIndex8 = document.getElementById('giveChipsToPotIndex8');
      giveChipsToPotIndex8?.classList.add('chipsToPotIndex8');
      SoundUtil.play('../../assets/sounds/action-bet-raise-call-allin.m4a', 2);
      setTimeout(() => {
        giveChipsToPotIndex8?.classList.remove('chipsToPotIndex8');
      }, 1000);
    }

  }

  playSound() {
    if (this.animationResponse?.action === PlayerHandActionEnum.FOLD) {
      SoundUtil.play('../../assets/sounds/action-fold.m4a', 2);
    } else if (this.animationResponse?.action === PlayerHandActionEnum.CHECK) {
      SoundUtil.play('../../assets/sounds/action-check.m4a');
    } else if (this.animationResponse?.action === PlayerHandActionEnum.RAISE ||
      this.animationResponse?.action === PlayerHandActionEnum.CALL ||
      this.animationResponse?.action === PlayerHandActionEnum.ALLIN ||
      this.animationResponse?.action === PlayerHandActionEnum.BET) {
      SoundUtil.play('../../assets/sounds/action-bet-raise-call-allin.m4a', 2);
    }
  }

  betByPlayer(id: any) {
    let bet = 0;
    if (this.tableInfo?.playersHand) {
      for (let i = 0; i < this.tableInfo.playersHand.length; i++) {
        if (this.tableInfo.playersHand[i].player._id == id) {
          for (const action of this.tableInfo.playersHand[i].actions) {

            if (this.tableInfo.round == TableGameRoundStatusEnum.PRE_FLOP) {
              if (action.round == TableGameRoundStatusEnum.PRE_FLOP || action.round == TableGameRoundStatusEnum.BLIND) {
                bet += action.bet
              }
            } else {
              if (action.round == this.tableInfo.round) {
                bet += action.bet
              }
            }
          }
        }
      }
    }
    return bet;
  }

  betByPlayerWithoutBlinds(id: any) {
    let bet = 0;
    if (this.tableInfo?.playersHand) {
      for (let i = 0; i < this.tableInfo.playersHand.length; i++) {
        if (this.tableInfo.playersHand[i].player._id == id) {
          for (const action of this.tableInfo.playersHand[i].actions) {
            if (this.tableInfo.round == TableGameRoundStatusEnum.PRE_FLOP) {
              if (action.round == TableGameRoundStatusEnum.PRE_FLOP || action.round == TableGameRoundStatusEnum.BLIND) {
                if (action.round == TableGameRoundStatusEnum.BLIND && action.bet == this.tableInfo.bigBlindValue) {
                  bet += action.bet
                } else {
                  bet += action.bet
                }
              }
            } else {
              if (action.round == this.tableInfo.round) {
                bet += action.bet
              }
            }
          }
        }
      }
    }
    return bet;
  }

  isFold(i: number) {

    if (this.tableInfo?.playersHand[i]?.lastAction) {
      if (this.tableInfo.playersHand[i].lastAction == PlayerHandActionEnum.FOLD) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }

  }

  canActions() {
    if (this.tableInfo?.playersHand) {
      let playerHandIndex = 0;
      for (let i = 0; i < this.tableInfo.playersHand.length; i++) {
        if (this.tableInfo.playersHand[i].player._id == this.mainPlayerId) {
          playerHandIndex = i;
        }
      }
      if (this.tableInfo.playersHand[playerHandIndex].lastAction == PlayerHandActionEnum.FOLD) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  getBiggestBet() {
    let arrayBets = [];
    if (this.tableInfo?.playersHand) {
      for (let i = 0; i < this.tableInfo.playersHand.length; i++) {
        let bet = 0;
        for (const action of this.tableInfo.playersHand[i].actions) {
          if (action.round == this.tableInfo.round) {
            bet += action.bet;
          }
        }
        arrayBets.push(bet);
      }

      return Math.max(...arrayBets);
    } else {
      return 0;
    }

  }

  getMinRaise() {
    let minRaise = 0;
    let biggerRaiseRound = this._getBiggerRaiseRound();
    let countRaise = this._getCountRaise();

    if (this.tableInfo.round == TableGameRoundStatusEnum.PRE_FLOP) {
      if (countRaise == 0) {
        minRaise = this.tableInfo.bigBlindValue * 2;
      } else {
        let secondBiggerRaiseRound = this._getSecondBiggerRaiseRound();
        minRaise = biggerRaiseRound + biggerRaiseRound - secondBiggerRaiseRound;
      }
    } else {
      if (countRaise == 0) {
        minRaise = this.tableInfo.bigBlindValue;
      } else if (countRaise == 1) {
        minRaise = biggerRaiseRound * 2;
      } else {
        let secondBiggerRaiseRound = this._getSecondBiggerRaiseRound();
        minRaise = biggerRaiseRound + biggerRaiseRound - secondBiggerRaiseRound;
      }
    }
    this.minRaiseValue = minRaise;

    this.form.patchValue({
      bet: this.minRaiseValue,
      valueRange: this.minRaiseValue
    })
  }

  getMaxAmount(id: string) {
    if (this.tableInfo?.playersHand) {
      for (let i = 0; i < this.tableInfo.playersHand.length; i++) {
        if (this.tableInfo.playersHand[i].player._id == id) {
          return this.tableInfo.playersHand[i].coinAmount
        }
      }
    } else {
      return 0;
    }
  }

  fold() {
    let dto = {
      tableGameId: this.tableInfo._id,
      action: PlayerHandActionEnum.FOLD,
      bet: 0
    }
    this._gameGateway.playerAction(dto);
    clearInterval(this.interval);
  }

  call() {
    const topBetRound = this.getTopBetRound();
    const topBetPlayer = this._getTopBetPlayer();
    const playerHand = this._getPlayerHand();
    const playerHandIndex = this._getPlayerHandIndex();
    const bet = topBetRound - topBetPlayer;

    if (playerHand.coinAmount - bet == 0) {
      let dto = {
        tableGameId: this.tableInfo._id,
        action: PlayerHandActionEnum.ALLIN,
        bet: playerHand.coinAmount
      }
      this.tableInfo.playersHand[playerHandIndex].actions.push({
        round: this.tableInfo.round,
        action: PlayerHandActionEnum.CALL,
        bet: bet,
      });
      this._gameGateway.playerAction(dto);
    } else {
      let dto = {
        tableGameId: this.tableInfo._id,
        action: PlayerHandActionEnum.CALL,
        bet: 0
      }
      this._gameGateway.playerAction(dto);
    }
    clearInterval(this.interval);
  }

  check() {
    let dto = {
      tableGameId: this.tableInfo._id,
      action: PlayerHandActionEnum.CHECK,
      bet: 0
    }
    this._gameGateway.playerAction(dto);
    clearInterval(this.interval);
  }

  raise() {

    if (this.form.controls['bet'].value < this.minRaiseValue) {
      if (this.getTopBetRound() == 0) {
        this.toastrService.error(`Bet mínimo de ${this.minRaiseValue}`, '', { progressBar: true });
      } else {
        this.toastrService.error(`Raise mínimo de ${this.minRaiseValue}`, '', { progressBar: true });
      }
      return;
    }

    const playerHand = this._getPlayerHand();

    let sumBet = this.betByPlayer(this.mainPlayerId);

    let betValue = Number(this.form.controls['bet'].value) - sumBet;

    if (betValue == playerHand.coinAmount) {
      let dto = {
        tableGameId: this.tableInfo._id,
        action: PlayerHandActionEnum.ALLIN,
        bet: betValue
      }
      this._gameGateway.playerAction(dto);
    } else {
      let dto = {
        tableGameId: this.tableInfo._id,
        action: PlayerHandActionEnum.RAISE,
        bet: betValue
      }
      this._gameGateway.playerAction(dto);
    }
    clearInterval(this.interval);
  }

  allIn() {
    for (let i = 0; i < this.tableInfo.playersHand.length; i++) {
      if (this.tableInfo.playersHand[i].player._id == this.mainPlayerId) {
        let dto = {
          tableGameId: this.tableInfo._id,
          action: PlayerHandActionEnum.ALLIN,
          bet: this.tableInfo.playersHand[i].coinAmount
        }
        this._gameGateway.playerAction(dto);
      }
    }
    clearInterval(this.interval);
  }

  absence() {
    let dto = {
      tableGameId: this.tableInfo._id,
      action: PlayerHandActionEnum.ABSENCE,
      bet: 0
    }
    this._gameGateway.playerAction(dto);
  }

  canCheck() {
    if (this.tableInfo?.playersHand) {
      const topBetRound = this.getTopBetRound();
      const topBetPlayer = this._getTopBetPlayer();

      if (topBetRound == topBetPlayer) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  canCall() {
    if (this.tableInfo?.playersHand) {
      const topBetRound = this.getTopBetRound();
      const topBetPlayer = this._getTopBetPlayer();
      const playerHand = this._getPlayerHand();
      if (topBetPlayer + playerHand.coinAmount >= topBetRound) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  canRaise() {
    if (this.tableInfo?.playersHand) {

      if (this.tableInfo?.allinLessThanMinRaise && this.getSumBetPlayerWithoutBlinds(this.tableInfo?.currentTurn) > 0) {
        return false;
      }

      const playerHand = this._getPlayerHand();

      if (playerHand.coinAmount >= this.minRaiseValue) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  canAllIn() {
    if (this.tableInfo?.playersHand) {
      if (this.tableInfo?.allinLessThanMinRaise && this.getSumBetPlayerWithoutBlinds(this.tableInfo?.currentTurn) > 0 && this.canCall()) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  getSumBetPlayerWithoutBlinds(id: string) {
    let bet = 0;
    if (this.tableInfo?.playersHand) {
      for (let i = 0; i < this.tableInfo.playersHand.length; i++) {
        if (this.tableInfo.playersHand[i].player._id == id) {
          for (const action of this.tableInfo.playersHand[i].actions) {
            if (this.tableInfo.round == TableGameRoundStatusEnum.PRE_FLOP) {
              if (action.round !== TableGameRoundStatusEnum.BLIND) {
                bet += action.bet;
              }
            } else {
              if (action.round == this.tableInfo.round) {
                bet += action.bet;
              }
            }
          }
        }
      }
    }
    return bet;
  }



  getTopBetRound(): number {
    if (this.tableInfo?.playersHand) {
      const greaterBets: any[] = [];

      this.tableInfo.players.forEach((player: any) => {
        const playerHand = this.tableInfo.playersHand.find((a: any) => a.player._id.toString() === player._id.toString());
        let betValues = [];
        if (this.tableInfo.round == TableGameRoundStatusEnum.PRE_FLOP) {
          betValues = playerHand.actions.filter((a: any) => a.round == TableGameRoundStatusEnum.PRE_FLOP || a.round == TableGameRoundStatusEnum.BLIND).map((a: any) => a.bet);
        } else {
          betValues = playerHand.actions.filter((a: any) => a.round === this.tableInfo.round).map((a: any) => a.bet);
        }
        const betSum = betValues.reduce((sum: any, current: any) => sum + current, 0);
        greaterBets.push({
          playerId: player._id,
          lastAction: playerHand.lastAction,
          totalBets: betSum,
        });
      });
      return Math.max(...greaterBets.map(a => a.totalBets));
    } else {
      return 0
    }
  }

  getPotValue() {
    let sumBets = 0;
    let pot = 0;
    if (this.tableInfo?.playersHand) {
      pot = this.tableInfo.pot;
      let playersHandActions = this.tableInfo?.playersHand.map((a: any) => a.actions);
      let arrayActions = playersHandActions.reduce((list: any, sub: any) => list.concat(sub), []);
      if (this.tableInfo.round == TableGameRoundStatusEnum.PRE_FLOP) {
        sumBets = arrayActions.filter((a: any) => a.round == TableGameRoundStatusEnum.PRE_FLOP).map((a: any) => a.bet).reduce((sum: any, current: any) => sum + current, 0);
      } else {
        sumBets = arrayActions.filter((a: any) => a.round == this.tableInfo.round).map((a: any) => a.bet).reduce((sum: any, current: any) => sum + current, 0);
      }

      if (this.showFullPot) {
        return this.tableInfo?.pot;
      } else {
        return pot - sumBets;
      }

    }
    return 0;
  }

  private _getCountRaise() {
    let count = 0;
    if (this.tableInfo?.playersHand) {
      for (const playersHand of this.tableInfo.playersHand) {
        for (const action of playersHand.actions) {
          if (action.round == this.tableInfo.round && (action.action == PlayerHandActionEnum.RAISE || action.action == PlayerHandActionEnum.ALLIN)) {
            if (action.action == PlayerHandActionEnum.ALLIN) {
              if (action.bet > 0) {
                count++;
              }
            } else {
              count++;
            }
          }
        }
      }
    }
    return count;
  }

  private _getBiggerRaiseRound() {
    let raiseArray: number[] = [];
    if (this.tableInfo?.playersHand) {
      for (const playersHand of this.tableInfo.playersHand) {
        let playerActions;
        if (this.tableInfo.round == TableGameRoundStatusEnum.PRE_FLOP) {
          playerActions = playersHand.actions.map((a: any) => a).filter((b: any) => b.round == TableGameRoundStatusEnum.PRE_FLOP || b.round == TableGameRoundStatusEnum.BLIND);
        } else {
          playerActions = playersHand.actions.map((a: any) => a).filter((b: any) => b.round == this.tableInfo.round);
        }
        const bets = playerActions.map((a: any) => a.bet);
        const sumBet = bets.reduce((sum: any, current: any) => sum + current, 0);
        raiseArray.push(sumBet);
      }
      return Math.max(...raiseArray);
    }
    return 0;
  }

  private _getSecondBiggerRaiseRound() {
    let raiseArray: number[] = [];
    if (this.tableInfo?.playersHand) {
      for (const playersHand of this.tableInfo.playersHand) {
        let playerActions;
        if (this.tableInfo.round == TableGameRoundStatusEnum.PRE_FLOP) {
          playerActions = playersHand.actions.map((a: any) => a).filter((b: any) => b.round == TableGameRoundStatusEnum.PRE_FLOP || b.round == TableGameRoundStatusEnum.BLIND);
        } else {
          playerActions = playersHand.actions.map((a: any) => a).filter((b: any) => b.round == this.tableInfo.round);
        }
        const bets = playerActions.map((a: any) => a.bet);
        const sumBet = bets.reduce((sum: any, current: any) => sum + current, 0);
        raiseArray.push(sumBet);
      }
      raiseArray.sort((a, b) => b - a);
      let filter = raiseArray.filter((a: any) => a !== Math.max(...raiseArray));
      return Math.max(...filter);
    }
    return 0;
  }

  private _getTopBetPlayer(): number {
    if (this.tableInfo?.playersHand) {
      const playerHand = this._getPlayerHand();
      let result: number;
      if (this.tableInfo.round == TableGameRoundStatusEnum.PRE_FLOP) {
        result = playerHand.actions.filter((a: any) => a.round == TableGameRoundStatusEnum.PRE_FLOP || a.round == TableGameRoundStatusEnum.BLIND).map((a: any) => a.bet).reduce((sum: any, current: any) => sum + current, 0);
      } else {
        result = playerHand.actions.filter((a: any) => a.round === this.tableInfo.round).map((a: any) => a.bet).reduce((sum: any, current: any) => sum + current, 0);
      }

      return result;
    } else {
      return 0
    }
  }

  private _getPlayerHand() {
    if (this.tableInfo?.playersHand) {
      return this.tableInfo.playersHand.find((a: any) => a.player._id.toString() === this.mainPlayerId);
    } else {
      return 0
    }
  }

  private _getPlayerHandIndex(): number {
    if (this.tableInfo?.playersHand) {
      return this.tableInfo.playersHand.findIndex((a: any) => a.player._id.toString() === this.mainPlayerId);
    } else {
      return 0
    }
  }

  getChipColor(value: number) {
    if (value > 0 && value < 101) {
      return '../../../assets/game/fichas-svg/FICHA-BRANCA.svg';
    } else if (value > 100 && value < 500) {
      return '../../../assets/game/fichas-svg/FICHA-VERMELHA.svg';
    } else if (value > 499 && value < 1000) {
      return '../../../assets/game/fichas-svg/FICHA-AZUL.svg';
    } else if (value > 999 && value < 5000) {
      return '../../../assets/game/fichas-svg/FICHA-AMARELA.svg';
    } else if (value > 4999 && value < 10000) {
      return '../../../assets/game/fichas-svg/FICHA-VERDE.svg';
    } else if (value > 9999 && value < 100000) {
      return '../../../assets/game/fichas-svg/FICHA-PRETA.svg';
    } else if (value > 99999) {
      return '../../../assets/game/fichas-svg/FICHA-ROXA.svg';
    }
    return '../../../assets/game/fichas-svg/FICHA-BRANCA.svg';
  }

  getChipColorFromHandToTable() {
    if (this.animationResponse) {
      if (this.animationResponse.bet > 0 && this.animationResponse.bet < 101) {
        return '../../../assets/game/fichas-svg/FICHA-BRANCA.svg';
      } else if (this.animationResponse.bet > 100 && this.animationResponse.bet < 500) {
        return '../../../assets/game/fichas-svg/FICHA-VERMELHA.svg';
      } else if (this.animationResponse.bet > 499 && this.animationResponse.bet < 1000) {
        return '../../../assets/game/fichas-svg/FICHA-AZUL.svg';
      } else if (this.animationResponse.bet > 999 && this.animationResponse.bet < 5000) {
        return '../../../assets/game/fichas-svg/FICHA-AMARELA.svg';
      } else if (this.animationResponse.bet > 4999 && this.animationResponse.bet < 10000) {
        return '../../../assets/game/fichas-svg/FICHA-VERDE.svg';
      } else if (this.animationResponse.bet > 9999 && this.animationResponse.bet < 100000) {
        return '../../../assets/game/fichas-svg/FICHA-PRETA.svg';
      } else if (this.animationResponse.bet > 99999) {
        return '../../../assets/game/fichas-svg/FICHA-ROXA.svg';
      }
    }
    return '../../../assets/game/fichas-svg/FICHA-BRANCA.svg';
  }

  getChipColorToWinners() {
    if (this.animationGiveChipsToWinners) {
      if (this.animationGiveChipsToWinners.bet > 0 && this.animationGiveChipsToWinners.bet < 101) {
        return '../../../assets/game/fichas-svg/FICHA-BRANCA.svg';
      } else if (this.animationGiveChipsToWinners.bet > 100 && this.animationGiveChipsToWinners.bet < 500) {
        return '../../../assets/game/fichas-svg/FICHA-VERMELHA.svg';
      } else if (this.animationGiveChipsToWinners.bet > 499 && this.animationGiveChipsToWinners.bet < 1000) {
        return '../../../assets/game/fichas-svg/FICHA-AZUL.svg';
      } else if (this.animationGiveChipsToWinners.bet > 999 && this.animationGiveChipsToWinners.bet < 5000) {
        return '../../../assets/game/fichas-svg/FICHA-AMARELA.svg';
      } else if (this.animationGiveChipsToWinners.bet > 4999 && this.animationGiveChipsToWinners.bet < 10000) {
        return '../../../assets/game/fichas-svg/FICHA-VERDE.svg';
      } else if (this.animationGiveChipsToWinners.bet > 9999 && this.animationGiveChipsToWinners.bet < 100000) {
        return '../../../assets/game/fichas-svg/FICHA-PRETA.svg';
      } else if (this.animationGiveChipsToWinners.bet > 99999) {
        return '../../../assets/game/fichas-svg/FICHA-ROXA.svg';
      }
    }
    return '../../../assets/game/fichas-svg/FICHA-BRANCA.svg';
  }

  listenedRange(event: any, type: string) {
    if (type === 'range') {
      const rangeValue = event.target.value;
      this.form.patchValue({
        valueRange: rangeValue
      })
    } else {
      const inputValue = event.target.value
      // if (inputValue > this.minRaiseValue) {
      this.form.patchValue({
        bet: inputValue
      })
      // }
      if (inputValue > this.getMaxAmount(this.mainPlayerId)) {
        this.form.patchValue({
          bet: this.getMaxAmount(this.mainPlayerId),
          valueRange: this.getMaxAmount(this.mainPlayerId)
        })
      }
    }
  }

  listenedRangeBuyCoin(event: any, type: string) {
    if (type === 'range') {
      const rangeValue = event.target.value;
      this.formBuyCoin.patchValue({
        valueRangeBuyCoin: rangeValue
      })
    } else {
      const inputValue = event.target.value
      this.formBuyCoin.patchValue({
        buyCoin: inputValue
      })
      if (inputValue > this.buyCoinMax) {
        this.formBuyCoin.patchValue({
          buyCoin: this.buyCoinMax,
          valueRangeBuyCoin: this.buyCoinMax
        });
      }
    }
  }

  openModalBuyCoin() {
    this.ngxSpinnerService.show()
    this.gameCoinService.getBalance().subscribe({
      next: (data) => {
        this.ngxSpinnerService.hide();
        this.balanceValue = data;
        let playerHand = this.tableInfo.playersHand.findIndex((a: any) => a.player._id.toString() === this.authService.getAuthenticatedUser()._id);
        this.buyCoinMin = this.tableInfo.buyInMinimum - this.tableInfo.playersHand[playerHand].coinAmount >= 0 ? this.tableInfo.buyInMinimum - this.tableInfo.playersHand[playerHand].coinAmount : 0;
        this.buyCoinMax = this.tableInfo.buyInMaximum - this.tableInfo.playersHand[playerHand].coinAmount;

        this.formBuyCoin.patchValue({
          buyCoin: this.buyCoinMax,
          valueRangeBuyCoin: this.buyCoinMax
        });

        if (this.tableInfo.buyInMaximum - this.tableInfo.playersHand[playerHand].coinAmount <= 0) {
          this.toastrService.error(`Você não pode comprar fichas nesse momento!`, '', { progressBar: true });
          let cancelBuyCoin = document.getElementById('cancelBuyCoin');
          cancelBuyCoin?.click();
        }

      },
      error: (err) => {
        console.error(err);
        this.ngxSpinnerService.hide();
      }
    });
  }

  async buyCoin() {
    await this._gameGateway.requestBuyCoin(this.tableInfo._id, this.formBuyCoin.controls['buyCoin'].value);
    this.ngxSpinnerService.show();
    this._gameGateway.tableGameBuyCoinResponse.subscribe({
      next: (response: ResponseDto) => {
        let cancelBuyCoin = document.getElementById('cancelBuyCoin');
        cancelBuyCoin?.click();
        this.ngxSpinnerService.hide();
        if (response.errors) {
          this.toastrService.error(`Erro ao comprar fichas!`, '', { progressBar: true });
        }
      },
      error: (err) => {
        console.error(err);
        this.ngxSpinnerService.hide();
        let cancelBuyCoin = document.getElementById('cancelBuyCoin');
        cancelBuyCoin?.click();
        this.toastrService.error(`Erro ao comprar fichas!`, '', { progressBar: true });
      }
    });
  }

  canBuyCoin() {
    if (this.tableInfo?.rebuyQueue) {
      const playerRebuyQueueIndex = this.tableInfo.rebuyQueue.findIndex((a: any) => a.player._id === this.authService.getAuthenticatedUser()._id);
      if (playerRebuyQueueIndex > -1) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  playerIsNotInExitQueue(i: number) {
    if (this.tableInfo?.playersExitQueue) {
      const playerExitQueueIndex = this.tableInfo.playersExitQueue.findIndex((a: any) => a._id.toString() == this.arrayPlayers[i]._id.toString());
      if (playerExitQueueIndex > -1 && this.betByPlayer(this.arrayPlayers[i]._id.toString()) <= 0) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  leavePokerGame() {
    this._gameGateway.leaveGame({ tableGameId: this.tableInfo._id });
    window.close();
  }

}
