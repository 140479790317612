import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ChartData, ChartType } from 'chart.js';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { KycRegisterDto } from 'src/app/dtos/kyc/kyc-register.dto';
import { UserAddressInfoUpdateDto } from 'src/app/dtos/user/user-address-info-update.dto';
import { UserGetResponseDto } from 'src/app/dtos/user/user-get-response.dto';
import { AuthService } from 'src/services/auth.service';
import { KycService } from 'src/services/kyc.service';
import { PlayerDataService } from 'src/services/player-data.service';
import { UserService } from 'src/services/user.service';
import { WalletService } from 'src/services/wallet.service';
import { phoneMask } from 'src/util/langMask';
import { GameCoinService } from '../../../services/game-coin.service';
import { MovementService } from '../../../services/movement.service';
import { ModalBuyCoinComponent } from './modal-buy-coin/modal-buy-coin.component';
import { ModalDeleteAccountComponent } from './modal-delete-account/modal-delete-account.component';
import { ModalPixPaymentComponent } from './modal-pix-payment/modal-pix-payment.component';
import { ModalRequestWithdrawalComponent } from './modal-request-withdrawal/modal-request-withdrawal.component';
import { ModalSecurityComponent } from './modal-security/modal-security.component';
import { ModalWithdrawRakebackPointsComponent } from './modal-withdraw-rakeback-points/modal-withdraw-rakeback-points.component';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  @ViewChild('modalKyc') ModalKyc: any;

  formInformations: FormGroup;
  formAddress: FormGroup;
  formCurrentPassword: FormGroup;
  formPassword: FormGroup;
  balanceForm: FormGroup
  table: any = [];
  phoneMask: string = '';
  isActive: boolean = false;
  newPasswordForm: boolean = false;
  urls!: string;
  usdtToken?: string = ' 0.00';
  pkfToken?: string = ' 0.00';
  bnbToken?: string = ' 0.00';
  movements: any;

  capturedImage: string | undefined;
  selectedFile: any;

  formCompareUserInfo: boolean = false;
  formCompareAddress: boolean = false;

  response!: UserGetResponseDto;
  responseAddress!: UserAddressInfoUpdateDto;

  kycRegistered: boolean = true;
  statusKyc: string = ''
  // Doughnut
  public doughnutChartData: ChartData<'doughnut'> = {
    datasets: [
      {
        data: [650, 350],
        backgroundColor: ['rgba(63, 220, 129, 1)', 'rgba(0, 0, 0, 1)'],
        borderWidth: 0,
      },
    ],
  };

  public doughnutChartDataPokerFi: ChartData<'doughnut'> = {
    datasets: [
      {
        data: [800, 200],
        backgroundColor: ['rgba(243, 196, 31, 1)', 'rgba(0, 0, 0, 1)'],
        borderWidth: 0,
      },
    ],
  };
  public doughnutChartType: ChartType = 'doughnut';

  lineChartOptions = {
    responsive: true,
    cutout: '75%',
  };

  constructor(
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private toastrService: ToastrService,
    private userService: UserService,
    private spinner: NgxSpinnerService,
    private translateService: TranslateService,
    private router: Router,
    public authService: AuthService,
    private playerDataService: PlayerDataService,
    private readonly _walletService: WalletService,
    private readonly _ngxSpinnerService: NgxSpinnerService,
    private gameCoinService: GameCoinService,
    private readonly _movementService: MovementService,
    private kycService: KycService
  ) {
    this.formInformations = this.formBuilder.group({
      name: [''],
      phone: [''],
      email: [''],
    });
    this.formAddress = this.formBuilder.group({
      state: [''],
      postalCode: [''],
      city: [''],
    });
    this.balanceForm = this.formBuilder.group({
      pk: [''],
      bnb: [''],
      usdt: [''],
    });
    this.formCurrentPassword = this.formBuilder.group({
      email: [''],
      password: [''],
    });
    this.formPassword = this.formBuilder.group(
      {
        newPassword: [
          '',
          [
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(20),
          ],
        ],
        confirmPassword: ['', [Validators.required, Validators.minLength(8)]],
      },
      {
        validators: this.confirmPasswordValidator(
          'newPassword',
          'confirmPassword'
        ),
      }
    );

    const currentLang = translateService.currentLang;

    this.phoneMask = phoneMask(currentLang);
  }

  ngOnInit(): void {
    let user = this.authService.getAuthenticatedUser();
    this._walletService.getWalletBalance().subscribe({
      next: response => {
        this.usdtToken = response.data.balances.find((a: any) => a.symbol == 'USDT').value;
        this.pkfToken = response.data.balances.find((a: any) => a.symbol == 'POKERFI').value;
        this.bnbToken = response.data.balances.find((a: any) => a.symbol == 'BNB').value;
      },
      error: error => {
        console.error(error);
      }
    });
    this.gameCoinService.getBalance().subscribe({
      next: (data) => {
        this.gameCoinService.balance.next(data);
      }
    });

    this.listMovemetByPlayerId();
    this.getUserInfo();
    this.getTfa();
    this.getTable(); // Pega a tabela do Histórico
    this.getKyc();
  }

  getKyc() {
    this.kycService.getKyc().subscribe({
      next: (data: any) => {
        this.statusKyc = data.status
        if (!data.kycRegistered) {
          this.kycRegistered = false
        }
      }
    })
  }

  listMovemetByPlayerId() {
    this._movementService.listByPlayerId().subscribe({
      next: response => {
        this.movements = response;
      },
      error: error => {
        this.toastrService.error(error.error.message, undefined, { progressBar: true });
      }
    });
  }

  compareInformations(value: any, item: string) {
    let input = value.target.value;

    if (item === 'name') {
      this.response.name !== input
        ? (this.formCompareUserInfo = true)
        : (this.formCompareUserInfo = false);
    }

    // if (item === 'phone') {
    //   this.response.phone !== input
    //     ? (this.formCompareUserInfo = true)
    //     : (this.formCompareUserInfo = false);
    // }
  }

  compareInformationsAddress(value: any, item: string) {
    let input = value.target.value;

    if (item === 'postalCode') {
      this.responseAddress.zipCode !== input
        ? (this.formCompareAddress = true)
        : (this.formCompareAddress = false);
    }

    if (item === 'state') {
      this.responseAddress.state !== input
        ? (this.formCompareAddress = true)
        : (this.formCompareAddress = false);
    }

    if (item === 'city') {
      this.responseAddress.city !== input
        ? (this.formCompareAddress = true)
        : (this.formCompareAddress = false);
    }
  }

  // Pega a tabela do Histórico
  async getTable() {
    // this.table = await this.profileService.tableFunction();
  }

  onSelectFile(event: any) {
    if (event.target.files && event.target.files[0]) {
      if (event.target.files[0].size > 500_000) {
        this.translateService.get('TOASTR.IMAGE_SIZE').subscribe({
          next: (data) => {
            this.toastrService.error(data, '', {
              progressBar: true,
              timeOut: 2000,
            });
          },
        });
        return;
      }
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();
        reader.onload = (event: any) => {

          let uploadImage = { image: event.target.result };

          this.spinner.show();
          this.playerDataService.updateProfilePicture({ profilePicture: uploadImage.image }).subscribe(
            (data) => {

              let user = this.authService.getAuthenticatedUser();
              Object.assign(user, { profilePicture: data });
              localStorage.setItem('user', JSON.stringify(user));

              this.translateService
                .get('TOASTR.PROFILE_PICTURE_UPDATED_SUCCESSFULLY')
                .subscribe({
                  next: (data) => {
                    const toastr = this.toastrService.success(data, '', {
                      progressBar: true,
                      timeOut: 2000,
                    });
                    if (toastr) {
                      this.spinner.hide();
                    }
                  },
                });
            },
            (error) => {
              console.error(error);
              this.toastrService.error(error.error.message, undefined, { progressBar: true });
            }
          );
        };
        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }

  onSubmit() {
    let request = {
      email: this.formInformations.controls['email'].value,
      password: this.formCurrentPassword.controls['password'].value,
    };

    const data = Object.assign({}, request);
    this.authService.authenticate(data).subscribe(
      (data) => {
        this.newPasswordForm = true;
      },
      (error) => {
        console.error(error);

        if (error.error.errors[0] == 'Email and/or password are invalid!') {
          this.translateService.get('TOASTR.INVALID_PASSWORD').subscribe({
            next: (data) => {
              this.toastrService.error(data, undefined, { progressBar: true });
            },
          });
        }
      }
    );
  }


  changePassword() {
    this.spinner.show();
    let request = {
      password: this.formCurrentPassword.controls['password'].value,
      newPassword: this.formPassword.controls['newPassword'].value,
    };

    // this.userService.updatePassword(request).subscribe(
    //   (data) => this.runUserChangePassword(data),
    //   (error) => this.runError(error)
    // );
  }

  runUserChangePassword(data: any): void {
    this.spinner.hide();
    this.translateService
      .get('TOASTR.PASSWORD_CHANGED_SUCCESSFULLY')
      .subscribe({
        next: (data) => {
          this.toastrService.success(data, undefined, { progressBar: true });
        },
      });

    this.newPasswordForm = false;
    this.formCurrentPassword.controls['password'].reset();
    this.formPassword.reset();
    localStorage.removeItem('user');
    this.router.navigate(['/']);
  }

  getTfa() {
    // this.tfaService.get().subscribe(
    //   (success) => {
    //     if (success._id) {
    //       this.isActive = true;
    //     } else {
    //       this.isActive = false;
    //     }
    //   },
    //   (error) => this.runError(error)
    // );
  }

  getUserInfo() {
    this.playerDataService.getPlayerData().subscribe(
      (data) => {
        this.response = data.user;
        this.formInformations.patchValue({
          name: data.user.name,
          phone: data.user.phone,
          email: data.user.email,
        });

        if (data?.address) {
          this.responseAddress = data.address;
          this.formAddress.patchValue({
            postalCode: data.address.zipCode,
            state: data.address.state,
            city: data.address.city,
          });
        }
      },
      (error) => this.runError(error)
    );
  }

  editUser() {
    const dto = {
      name: this.formInformations.value.name,
      phone: this.formInformations.value.phone,
    };
    // this.userService.updateUser(dto).subscribe({
    //   next: () => {
    //     this.translateService.get('TOASTR.DATA_SAVED_SUCCESSFULLY').subscribe({
    //       next: (data) => {
    //         const toastr = this.toastrService.success(data, '', {
    //           progressBar: true,
    //           timeOut: 1500,
    //         });
    //       },
    //     });
    //   },
    //   error: (error) => this.runError(error),
    // });
  }

  editUserAddress() {
    const dto = {
      postalCode: this.formAddress.value.postalCode,
      state: this.formAddress.value.state,
      city: this.formAddress.value.city,
    };
    // this.userService.updateUserAddressInfo(dto).subscribe({
    //   next: () => {
    //     this.translateService.get('TOASTR.DATA_SAVED_SUCCESSFULLY').subscribe({
    //       next: (data) => {
    //         const toastr = this.toastrService.success(data, '', {
    //           progressBar: true,
    //           timeOut: 1500,
    //         });
    //       },
    //     });
    //   },
    //   error: (error) => this.runError(error),
    // });
  }

  runError(exception: any): void {
    this.spinner.hide();
    console.error(exception);

    if (exception.error.errors == 'Http Exception') {
      this.translateService.get('TOASTR.REQUIRED_FIELD').subscribe({
        next: (data) => {
          this.toastrService.error(data, undefined, { progressBar: true });
        },
      });
    } else {
      const toastr = this.toastrService.error(exception.error.errors, '', {
        progressBar: true,
      });
      if (toastr) {
        toastr.onHidden.subscribe(() => {
          this.modalService.dismissAll();
        });
      }
    }
  }

  open2FA() {
    this.modalService.open(ModalSecurityComponent);
  }

  openModalDelete() {
    this.modalService.open(ModalDeleteAccountComponent);
  }

  openModalBuyCoin() {
    if (this.statusKyc !== 'CHECKED') {
      this.modalService.open(this.ModalKyc);
    } else {
      const modalRef = this.modalService.open(ModalBuyCoinComponent);
      modalRef.result.then(
        (closeResponse) => {
          if (closeResponse) {
            this.gameCoinService.getBalance().subscribe({
              next: (data) => {
                this.gameCoinService.balance.next(data);
              }
            });
            this.listMovemetByPlayerId();
          }
        },
        (dismissAllResponse) => { }
      );
    }
  }

  openModalRequestWithdrawal() {
    if (this.statusKyc !== 'CHECKED') {
      this.modalService.open(this.ModalKyc);
    } else {
      const modalRef = this.modalService.open(ModalRequestWithdrawalComponent);
      modalRef.result.then(
        (closeResponse) => {
          if (closeResponse) {
            this.gameCoinService.getBalance().subscribe({
              next: (data) => {
                this.gameCoinService.balance.next(data);
              }
            });
            this.listMovemetByPlayerId();
          }
        },
        (dismissAllResponse) => { }
      );
    }
  }

  openModalWithdrawRakeback() {
    if (this.statusKyc !== 'CHECKED') {
      this.modalService.open(this.ModalKyc);
    } else {
      this.modalService.open(ModalWithdrawRakebackPointsComponent);
    }
  }

  confirmPasswordValidator(password: string, confirmPassword: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[password];
      const matchingControl = formGroup.controls[confirmPassword];
      if (
        matchingControl.errors &&
        !matchingControl.errors['confirmedValidator']
      ) {
        return;
      }

      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ confirmedValidator: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  cancelPasswordForm() {
    this.formCurrentPassword.controls['password'].reset();
    this.formPassword.reset();
    this.newPasswordForm = false;
  }

  openPix(item: any) {
    if (item.pix && item.pix !== '' && item.status == 'PENDING') {
      const modalRef = this.modalService.open(ModalPixPaymentComponent);
      modalRef.componentInstance.qrCode = item.pix;
      modalRef.componentInstance.pixCreatedAt = item.createdAt;
    }
  }

  handleGallerySelection(event: any) {
    this.spinner.show();
    this.selectedFile = event.target.files[0];

    if (!this.selectedFile) {
      this.spinner.hide();
      return;
    }
    if (this.selectedFile.type.startsWith('application/pdf') || this.selectedFile.type.startsWith('image/png')
      || this.selectedFile.type.startsWith('image/jpg') || this.selectedFile.type.startsWith('image/jpeg')) {
      this.capturedImage = this.selectedFile;
      this.spinner.hide();
      return;
    } else {
      this.spinner.hide();
      this.toastrService.error('Selecione um arquivo de imagem válido (PDF) ou (IMAGE).', '', { progressBar: true });
    }
  }

  uploadImage() {
    if (this.capturedImage) {
      this.spinner.show();

      const dto: KycRegisterDto = {
        documentImage: this.capturedImage
      }

      const formData = new FormData();

      formData.append("file", dto.documentImage, dto.documentImage.name)

      this.kycService.registerKyc(formData).subscribe({
        next: (response) => {
          this.spinner.hide();
          this.translateService.get('TOASTR.DOCUMENT_SEND').subscribe({
            next: (data) => {
              const toastr = this.toastrService.success(data, '', {
                progressBar: true,
                timeOut: 2000,
              });

              window.location.reload()
            },
          });
        },
        error: (error) => {
          this.spinner.hide();
          this.translateService.get('TOASTR.DOCUMENT_SEND_ERROR').subscribe({
            next: (data) => {
              const toastr = this.toastrService.error(data, '', {
                progressBar: true,
                timeOut: 2000,
              });
            },
          });
        },
      });
    } else {
      this.spinner.hide();
      this.translateService.get('TOASTR.SELECT_IMAGE').subscribe({
        next: (data) => {
          const toastr = this.toastrService.warning(data, '', {
            progressBar: true,
            timeOut: 1500,
          });
        },
      });
    }
  }

  exit() {
    this.modalService.dismissAll();
  }
}
