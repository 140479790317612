import { ToastrService } from 'ngx-toastr';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GameCoinEnum } from 'src/app/dtos/enum/game-coin.enum';
import { GameCoinGetBalanceResponseDto } from 'src/app/dtos/game-coin/game-coin-get-balance-response.dto';
import { WithdrawRakebackPointsRequestDto } from 'src/app/dtos/game-coin/withdraw-rakeback-points-request.dto';
import { GameCoinService } from 'src/services/game-coin.service';
import { RakebackMovementService } from 'src/services/rakeback-movement.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-modal-withdraw-rakeback-points',
  templateUrl: './modal-withdraw-rakeback-points.component.html',
  styleUrls: ['./modal-withdraw-rakeback-points.component.scss']
})
export class ModalWithdrawRakebackPointsComponent implements OnInit {

  balanceValue?: GameCoinGetBalanceResponseDto;
  form: FormGroup;
  nextDate?: Date;

  constructor(
    private _gameCoinService: GameCoinService,
    private _modalService: NgbModal,
    private formBuilder: FormBuilder,
    private _rakebackMovementService: RakebackMovementService,
    private _toastrService: ToastrService,
    private ngxSpinnerService: NgxSpinnerService,
    private router: Router,
    public readonly _translateService: TranslateService
  ) {
    this.form = this.formBuilder.group({
      withdrawValue: [''],
      select: ['POKERFI'],
    });
  }

  ngOnInit(): void {
    this._gameCoinService.getBalance().subscribe({
      next: data => {
        this.balanceValue = data;
      }
    })

    this._rakebackMovementService.getLastRakeBackMovement().subscribe({
      next: data => {
        if (data?.createdAt) {
          this.nextDate = new Date(data.createdAt);
          this.nextDate.setDate(this.nextDate.getDate() + 7);
        }
      }
    })
  }

  checkAvailableDate() {
    let newDate = new Date();
    if (!this.nextDate || newDate < this.nextDate!) {
      return false;
    }
    return true;
  }

  exit() {
    this._modalService.dismissAll();
  }

  getPokerfiValue() {
    return Number((this.form.controls['withdrawValue'].value * 0.4).toFixed(2));
  }

  onSubmit() {

    if(this.form.controls['select'].value == 'POKERFI' && this.balanceValue?.pokerfiRakeBackPoints! < this.form.controls['withdrawValue'].value) {
      this._translateService.get('PROFILE.MODAL_BUY_COIN.INSUFFICIENT_RAKEBACK').subscribe({
        next: (data: any) => {
          this._toastrService.error(data, '', {progressBar: true});
        },
      });
      return;
    }

    if(this.form.controls['select'].value == 'USDT' && this.balanceValue?.usdRakeBackPoints! < this.form.controls['withdrawValue'].value) {
      this._translateService.get('PROFILE.MODAL_BUY_COIN.INSUFFICIENT_RAKEBACK').subscribe({
        next: (data: any) => {
          this._toastrService.error(data, '', {progressBar: true});
        },
      });
      return;
    }

    if(this.nextDate && !this.checkAvailableDate()) {
      this._translateService.get('PROFILE.MODAL_BUY_COIN.WITHDRAW_DATE').subscribe({
        next: (data: any) => {
          this._toastrService.error(data, '', {progressBar: true});
        },
      });
      return;
    }

    this.ngxSpinnerService.show();

    let dto: WithdrawRakebackPointsRequestDto = {
      coin: this.form.controls['select'].value,
      withdrawValue: this.form.controls['withdrawValue'].value
    }
    this._gameCoinService.withdrawRakebackPoints(dto).subscribe({
      next: data => {
        this._toastrService.success('Saque de rakeback solicitado com sucesso!', '', { progressBar: true });
        this.ngxSpinnerService.hide();
        this._modalService.dismissAll();
        this.router.navigate(['/pages/home']);
      },
      error: err => {
        this.ngxSpinnerService.hide();
        this._toastrService.error('Erro ao solicitar saque!', '', { progressBar: true });
      }
    })
  }

}
