<section class="container d-flex align-items-center">
  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
  </ngx-spinner>
  <div class="col-12 col-md-10 col-lg-6 mt-5">
    <h2 class="heading-700-md">{{ "CODE_PAGE.TITLE" | translate }}</h2>
    <p class="subtitle mt-4">
      {{ "CODE_PAGE.SUBTITLE_START" | translate }}
      <span>{{email}}</span>
    </p>
    <p class="subtitle mt-1 pt-1">{{ "CODE_PAGE.SUBTITLE_END" | translate }}</p>
    <form [formGroup]="form">
    <div class="row justify-content-center align-items-center mt-4">
      <div class="col-sm col-8">
        <input type="text" formControlName="code" maxlength="5" class="form-control input-box text-capitalize"
          placeholder="{{ 'CODE_PAGE.CODE' | translate }}" >
      </div>
      <div class="col-sm">
        <a class="link-resend" (click)="resendEmail()">{{ 'CODE_PAGE.RESEND' | translate }}</a>
      </div>
    </div>
  </form>
    <div class="d-flex col-12 col-sm-6 justify-content-start align-items-center mt-4">
      <button class="btn-continue button-bold" (click)="submit()">{{ 'CODE_PAGE.BUTTON' | translate }}</button>
    </div>
  </div>
</section>
