import { Injectable } from "@angular/core";
import { BaseService } from "./base.service";
import { HttpClient } from "@angular/common/http";
import { GameCoinGetBalanceResponseDto } from "../app/dtos/game-coin/game-coin-get-balance-response.dto";
import { BehaviorSubject, Observable, Subject, firstValueFrom, map } from "rxjs";
import { WithdrawRakebackPointsRequestDto } from "src/app/dtos/game-coin/withdraw-rakeback-points-request.dto";

@Injectable({
  providedIn: 'root'
})
export class GameCoinService extends BaseService {

  url: string = `${this.gameBackoffice}coin`;
  balance = new Subject<GameCoinGetBalanceResponseDto>();

  constructor(
    private httpClient: HttpClient,
  ) {
    super();
  }

  getBalance() {
    return this.httpClient.get<GameCoinGetBalanceResponseDto>(`${this.url}/get-balance`, this.authorizedHeader);
  }

  withdrawRakebackPoints(dto: WithdrawRakebackPointsRequestDto) {
    return this.httpClient.post<number>(`${this.url}/withdraw-rakeback-points`, dto, this.authorizedHeader);
  }

}
