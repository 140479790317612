<div class="authincation vh-100">
  <div class="container h-100">
    <div class="row justify-content-center h-100 align-items-center">
      <div class="col-md-8">
        <div class="form-input-content text-center error-page text-white">
          <h1 class="error-text font-weight-bold">404</h1>
          <h4><i class="fa fa-exclamation-triangle text-"></i> Página não encontrada!</h4>
          <p>Verifique se a pagina não foi movida.</p>
          <div>
            <a class="mt-5 btn styled-button" [routerLink]="!authService.isJwtValid() ? ['/'] : ['/pages/home']">Voltar para Home</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
