<div style="background-color: #1E2129; padding-bottom: 4px;">
  <form class="form" [formGroup]="form" >
    <div class="chat-content">
      <div class="card">
        <div id="overflowTest">
          <ul class="list-group list-group-flush">
            <li class="list-group-item" *ngIf="playerToSetColor !== null">  
              <strong>{{ playerToSetColor.user?.name }}:&nbsp;</strong>
              <textarea formControlName="note" class="form-control"></textarea>
            </li>
            <li class="list-group-item" *ngIf="playerToSetColor === null">
              <strong>{{ 'GAME.NOTES_WARNING' | translate }}&nbsp;</strong>
            </li>
          </ul>
        </div>
      </div>
    </div>
 
    <div class="chat-send row mt-2">
      <div class="col-10" *ngIf="playerToSetColor !== null">
        <button type="submit" class="btn btn-warning position-and-zindex" (click)="saveNotes()">
          {{ 'GAME.SAVE_NOTES' | translate }}
        </button>
      </div>
    </div>
  </form>
</div>
