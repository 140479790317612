<section class="background-modal p-3">
  <div (click)="exit()" class="d-flex justify-content-end cursor-pointer">
    <img src="../../../../assets/icons/icon-x.svg" />
  </div>
  <div class="d-flex flex-column justify-content-center align-items-center">
    <h6 class="heading-700-sm text-white">{{ 'PROFILE.MODAL_REQUEST.REQUEST' | translate }}</h6>
  </div>
  <form [formGroup]="form">
    <label class="form-label text-white body-400-md">{{ 'PROFILE.MODAL_BUY_COIN.SELECT' | translate }}</label>
    <select formControlName="coin" class="form-select mb-3" aria-label="Default select example">
      <option selected disabled>{{ 'PROFILE.MODAL_BUY_COIN.SELECT' | translate }}...</option>
      <option value="POKERFI">PKF Game</option>
      <option value="USDT">USDT Game</option>
    </select>
    <div class="d-flex flex-column my-3">
      <p class="text-start text-white body-400-md mb-1">{{ 'PROFILE.MODAL_REQUEST.ACCOUNT_BALANCE' | translate }}</p>
      <p *ngIf="form.controls['coin'].value == 'POKERFI'" class="text-start heading-500-md fw-700 font-color-yellow">
        POKERFI Game: {{balancePokerfi}}</p>
      <p *ngIf="form.controls['coin'].value == 'USDT'" class="text-start heading-500-md fw-700 font-color-yellow">USDT
        Game: {{balanceUsdt}}</p>
    </div>
    <div class="d-flex flex-column my-3">
      <label class="form-label text-white">{{ 'PROFILE.MODAL_BUY_COIN.VALUE' | translate }}</label>
      <div *ngIf="form.controls['coin'].value == 'POKERFI'" class="d-flex align-items-center background-flex-buttons">
        <div class="d-flex broke-buttons w-100">
          <input type="text" formControlName="value" class="form-control input-other" currencyMask
            [options]="{ prefix: '', thousands: '', decimal: '.', precision: '2' }">
        </div>
      </div>
      <div *ngIf="form.controls['coin'].value == 'USDT'" class="d-flex align-items-center background-flex-buttons">
        <div class="d-flex broke-buttons w-100">
          <input type="text" formControlName="value" class="form-control input-other" currencyMask
            [options]="{ prefix: '', thousands: '', decimal: '.', precision: '2' }">
        </div>
      </div>
    </div>
  </form>
  <div class="d-flex flex-column my-3">
    <p class="text-end text-white body-400-md mb-1">{{ 'PROFILE.MODAL_REQUEST.TEXT' | translate }} {{ 'PROFILE.MODAL_BUY_COIN.APPROXIMATELY' | translate }}:</p>
    <p *ngIf="form.controls['coin'].value == 'POKERFI' && form.controls['value'].value" class="text-end text-white heading-500-md">{{form.controls['value'].value.toFixed(2)}} PKF</p>
    <p *ngIf="form.controls['coin'].value == 'USDT' && form.controls['value'].value" class="text-end text-white heading-500-md">{{form.controls['value'].value.toFixed(2)}} USDT</p>
  </div>
  <div class="mt-4 row align-items-center">
    <div class="col d-flex justify-content-center align-items-center">
      <button class="btn-delete button-bold mx-2" (click)="exit()">{{ 'PROFILE.MODAL_REQUEST.BTN_CANCEL' | translate
        }}</button>
    </div>
    <div class="col align-items-center">
      <button (click)="submit()" class="btn-continue button-bold mx-2">{{ 'PROFILE.MODAL_REQUEST.BTN_REQUEST' | translate }}</button>
    </div>
  </div>
</section>
