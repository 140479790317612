import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ChartData, ChartType } from 'chart.js';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { PlayerStatusEnum } from 'src/app/dtos/enum/player-status.enum';
import { PokerGameModeEnum } from 'src/app/dtos/enum/poker-game-mode.enum';
import { GameCoinGetBalanceResponseDto } from 'src/app/dtos/game-coin/game-coin-get-balance-response.dto';
import { TableGameGetResponseDto } from 'src/app/dtos/socket/table-game-get-response.dto';
import { AdvertisementService } from 'src/services/advertisement.service';
import { AuthService } from 'src/services/auth.service';
import { DataTransferService } from 'src/services/data/data-transfer.service';
import { GameCoinService } from 'src/services/game-coin.service';
import { CommonGatewaySocketService } from 'src/services/sockets/common-gateway.socket.service';
import { GameGatewaySocketService } from 'src/services/sockets/game-gateway.socket.service';
import { TableGameService } from 'src/services/table-game.service';
import { TournamentService } from 'src/services/tournament.service';
import { TableGameGetDetailsResponseDto } from '../../dtos/socket/table-game-get-details-response.dto';
import { CashGameService } from './../../../services/cash-game.service';
import { ModalTournamentComponent } from './modal-tournament/modal-tournament.component';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('content') Modal: any;
  @ViewChild('confirmPassword') ModalPassword: any;

  showCheckboxes: boolean = false;
  showCheckboxes2: boolean = false;
  selectbox: string[] = [];
  selectbox2: string[] = [];
  showType: string = 'table';
  sortAscending: boolean = true;
  fullRoom: boolean = false
  searchMode: boolean = false;
  alreadyInQueue: boolean = false

  public doughnutChartDataFiat: ChartData<'doughnut'> = {
    datasets: [
      {
        data: [650, 350],
        backgroundColor: ['rgba(63, 220, 129, 1)', 'rgba(0, 0, 0, 1)'],
        borderWidth: 0,
      },
    ],
  };
  public doughnutChartDataPokerFi: ChartData<'doughnut'> = {
    datasets: [
      {
        data: [800, 200],
        backgroundColor: ['rgba(243, 196, 31, 1)', 'rgba(0, 0, 0, 1)'],
        borderWidth: 0,
      },
    ],
  };
  public doughnutChartType: ChartType = 'doughnut';

  lineChartOptions = {
    responsive: true,
    cutout: '75%',
  };

  form: FormGroup;
  formGroupBy: FormGroup;
  formConfirmPassword: FormGroup;
  formJoinGame: FormGroup;
  listTournament: any[] = [];
  tournamentSelected!: any;
  listCashGame: any[] = [];
  cashGameSelected!: any;

  tournamentsToShow: any[] = [];
  cashGameToShow: any[] = [];
  secondCashGameToShow: any[] = [];
  cashGameDetails: TableGameGetDetailsResponseDto | undefined;
  gameType: string = 'pokerfi'
  balanceValue?: GameCoinGetBalanceResponseDto;
  loggedPlayerId: string = '' 
  advertisement: any;

  constructor(
    private formBuilder: FormBuilder,
    config: NgbModalConfig,
    private modalService: NgbModal,
    private tournamentService: TournamentService,
    private cashGameService: CashGameService,
    private router: Router,
    private authService: AuthService,
    private toastrService: ToastrService,
    private translateService: TranslateService,
    private spinner: NgxSpinnerService,
    private readonly _commonGateway: CommonGatewaySocketService,
    private readonly _gameGateway: GameGatewaySocketService,
    private gameCoinService: GameCoinService,
    private dataTransferService: DataTransferService,
    private advertisementService: AdvertisementService,
    private tableGameService: TableGameService
  ) {
    config.centered = true;
    this.form = this.formBuilder.group({
      modalityRadio: ['All', [Validators.required]],
      entryValueRadio: ['All', [Validators.required]],
      tableSize: ['All', [Validators.required]],
    });
    this.formGroupBy = this.formBuilder.group({
      groupBy: ['table', [Validators.required]],
    });
    this.formConfirmPassword = this.formBuilder.group({
      password: ['', [Validators.required]],
    });
    this.formJoinGame = this.formBuilder.group({
      buyIn: ['', [Validators.required]],
      valueRange: ['']
    })
  }

  ngOnDestroy(): void {
    // this.commonSocketService.homeScreenSocketShutdown();
  }

  toggleSearchMode() {
    this.searchMode = !this.searchMode;
  }

  ngOnInit(): void {
    // this.commonSocketService.homeScreenSocketInit();
    this.gameCoinService.balance.subscribe({
      next: data => {
        this.balanceValue = data;
      }
    });

    this.advertisementService.getAd().subscribe({
      next: (data) =>{
        this.advertisement = data;
      }
    })

    this.gameCoinService.getBalance().subscribe({
      next: (data) => {
        this.gameCoinService.balance.next(data);
      }
    });

    this._commonGateway.initializeMain();
    this._gameGateway.initializeMain();
    let locaStoragefilters = localStorage.getItem('filters');

    this.loggedPlayerId =  (JSON.parse(localStorage.getItem('user') || '')._id );
    let filters;
    if (locaStoragefilters) {
      filters = JSON.parse(locaStoragefilters);
      this.form.patchValue({
        modalityRadio: filters.modalityRadio,
        entryValueRadio: filters.entryValueRadio,
        tableSize: filters.tableSize,
      });
    } else {
      this.form.patchValue({
        modalityRadio: 'ALL',
        entryValueRadio: 'ALL',
        tableSize: 'ALL',
      });
    }

    this._commonGateway.requestTableGameList();

    this._commonGateway.tableGameList.subscribe({
      next: (response: TableGameGetResponseDto[]) => {
        this.cashGameToShow = response.filter(item => item.coin === 'POKERFI')
      
        this.secondCashGameToShow = response
        this.dataTransferService.setTotalPlayers(this.getTotalPlayerPlaying())

      }
    });

    this.listTournamentByFilter(this.form.controls['modalityRadio'].value);
    this.listCashGameByFilter(this.form.controls['modalityRadio'].value);
  }

  ngAfterViewInit(): void{

  }


  getTotalPlayerPlaying() {
    let count = 0
    if (this.cashGameToShow.length === 0) return count
      for(let i = 0 ; i < this.cashGameToShow.length; i++) {
        
        let playersSeated = this.cashGameToShow[i].players.filter((a: any) => a.status !== PlayerStatusEnum.empty);
        if (playersSeated.length) {
           count += playersSeated.length;
        }
      }
   
    return count;
  }

  showGameType(type: string) {
    this.cashGameSelected = null
    this.cashGameToShow  = this.secondCashGameToShow
    if (type === 'pokerfi') {
      this.cashGameToShow = this.cashGameToShow.filter(item => item.coin === 'POKERFI')
      this.gameType = type
    } else {
      this.cashGameToShow = this.cashGameToShow.filter(item => item.coin === 'USDT')
      this.gameType = type

    }
  }

  sortBy(filter: string) {
    if (filter === 'name') {
      this.cashGameToShow.sort((a, b) => {
        if (a.name < b.name) {
          return this.sortAscending ? -1 : 1;
        } else if (a.name > b.name) {
          return this.sortAscending ? 1 : -1;
        } else {
          return 0;
        }
      });

      this.sortAscending = !this.sortAscending;
    }
    if (filter === 'value') {

      this.cashGameToShow.sort((a, b) => {
        if (a.bigBlindValue < b.bigBlindValue) {
          return this.sortAscending ? -1 : 1;
        } else if (a.bigBlindValue > b.bigBlindValue) {
          return this.sortAscending ? 1 : -1;
        } else {
          return 0;
        }
      });

      this.sortAscending = !this.sortAscending;
    }
    if (filter === 'game') {
      
      this.cashGameToShow.sort((a, b) => {
        if (a.mode < b.mode) {
          return this.sortAscending ? -1 : 1;
        } else if (a.mode > b.mode) {
          return this.sortAscending ? 1 : -1;
        } else {
          return 0;
        }
      });

      this.sortAscending = !this.sortAscending;
    }
    if (filter === 'playerLimit') {
      
      this.cashGameToShow.sort((a, b) => {
        if (a.playersLimit < b.playersLimit) {
          return this.sortAscending ? -1 : 1;
        } else if (a.playersLimit > b.playersLimit) {
          return this.sortAscending ? 1 : -1;
        } else {
          return 0;
        }
      });

      this.sortAscending = !this.sortAscending;
    }

    if (filter === 'player') {
      
      this.cashGameToShow.sort((a, b) => {
        const validPlayersA = a.players.filter((player: any) => player.status !== PlayerStatusEnum.empty).length;
        const validPlayersB = b.players.filter((player: any) => player.status !== PlayerStatusEnum.empty).length;
        if (validPlayersA < validPlayersB) {
          return this.sortAscending ? -1 : 1;
        } else if (validPlayersA > validPlayersB) {
          return this.sortAscending ? 1 : -1;
        } else {
          return 0;
        }
      });

      this.sortAscending = !this.sortAscending;
    }

  }

  listTournamentByFilter(filter: string) {
    let filteredData;
    if (filter === 'ALL') {
      filteredData = this.listTournament.filter((el) =>
        Object.values(PokerGameModeEnum).includes(
          el.tournamentRoom.pokerModality
        )
      );
    } else {
      filteredData = this.listTournament.filter(
        (el) => el.tournamentRoom.pokerModality === filter
      );
    }

    let filterEntryValueRadio = [];

    if (this.form.controls['entryValueRadio'].value !== 'ALL') {
      let index = 0;
      for (const iterator of filteredData.map((el) => el.tournamentRoom)) {
        if (this.form.controls['entryValueRadio'].value == 'High') {
          if (iterator.buyInMinimum >= 1000000) {
            filterEntryValueRadio.push(filteredData[index]);
          }
        } else if (this.form.controls['entryValueRadio'].value == 'Medium') {
          if (
            iterator.buyInMinimum >= 500000 &&
            iterator.buyInMinimum < 1000000
          ) {
            filterEntryValueRadio.push(filteredData[index]);
          }
        } else if (this.form.controls['entryValueRadio'].value == 'Low') {
          if (
            iterator.buyInMinimum >= 100000 &&
            iterator.buyInMinimum < 500000
          ) {
            filterEntryValueRadio.push(filteredData[index]);
          }
        } else if (this.form.controls['entryValueRadio'].value == 'Micro') {
          if (iterator.buyInMinimum < 100000) {
            filterEntryValueRadio.push(filteredData[index]);
          }
        }
        index++;
      }
    } else {
      filterEntryValueRadio = filteredData;
    }

    let filtertableSize = [];
    if (this.form.controls['tableSize'].value !== 'ALL') {
      let index = 0;
      for (const iterator of filterEntryValueRadio.map(
        (el) => el.tournamentRoom
      )) {
        if (this.form.controls['tableSize'].value == '7/10') {
          if (
            iterator.numberOfPlayersPerTable >= 7 &&
            iterator.numberOfPlayersPerTable <= 10
          ) {
            filtertableSize.push(filterEntryValueRadio[index]);
          }
        } else if (this.form.controls['tableSize'].value == '3/6') {
          if (
            iterator.numberOfPlayersPerTable >= 3 &&
            iterator.numberOfPlayersPerTable <= 6
          ) {
            filtertableSize.push(filterEntryValueRadio[index]);
          }
        } else if (this.form.controls['tableSize'].value == '2') {
          if (iterator.numberOfPlayersPerTable == 2) {
            filtertableSize.push(filterEntryValueRadio[index]);
          }
        }
        index++;
      }
    } else {
      filtertableSize = filterEntryValueRadio;
    }

    if (
      this.form.controls['entryValueRadio'].value == 'ALL' &&
      this.form.controls['tableSize'].value == 'ALL'
    ) {
      this.tournamentsToShow = filteredData;
    } else {
      this.tournamentsToShow = filtertableSize;
    }

    this.alphabeticalOrderTournament();

    this.tournamentSelected = this.listTournament[0];
  }

  listCashGameByFilter(filter: string) {
    let filteredData;
    if (filter === 'ALL') {
      filteredData = this.listCashGame.filter((el) =>
        Object.values(PokerGameModeEnum).includes(el.gameRoom.pokerModality)
      );
    } else {
      filteredData = this.listCashGame.filter(
        (el) => el.gameRoom.pokerModality === filter
      );
    }

    let filterEntryValueRadio = [];

    if (this.form.controls['entryValueRadio'].value !== 'ALL') {
      let index = 0;
      for (const iterator of filteredData.map((el) => el.gameRoom)) {
        if (this.form.controls['entryValueRadio'].value == 'High') {
          if (iterator.buyInMinimum >= 1000000) {
            filterEntryValueRadio.push(filteredData[index]);
          }
        } else if (this.form.controls['entryValueRadio'].value == 'Medium') {
          if (
            iterator.buyInMinimum >= 500000 &&
            iterator.buyInMinimum < 1000000
          ) {
            filterEntryValueRadio.push(filteredData[index]);
          }
        } else if (this.form.controls['entryValueRadio'].value == 'Low') {
          if (
            iterator.buyInMinimum >= 100000 &&
            iterator.buyInMinimum < 500000
          ) {
            filterEntryValueRadio.push(filteredData[index]);
          }
        } else if (this.form.controls['entryValueRadio'].value == 'Micro') {
          if (iterator.buyInMinimum < 100000) {
            filterEntryValueRadio.push(filteredData[index]);
          }
        }
        index++;
      }
    } else {
      filterEntryValueRadio = filteredData;
    }

    let filtertableSize = [];
    if (this.form.controls['tableSize'].value !== 'ALL') {
      let index = 0;
      for (const iterator of filterEntryValueRadio.map((el) => el.gameRoom)) {
        if (this.form.controls['tableSize'].value == '7/10') {
          if (iterator.numberOfPlayers >= 7 && iterator.numberOfPlayers <= 10) {
            filtertableSize.push(filterEntryValueRadio[index]);
          }
        } else if (this.form.controls['tableSize'].value == '3/6') {
          if (iterator.numberOfPlayers >= 3 && iterator.numberOfPlayers <= 6) {
            filtertableSize.push(filterEntryValueRadio[index]);
          }
        } else if (this.form.controls['tableSize'].value == '2') {
          if (iterator.numberOfPlayers == 2) {
            filtertableSize.push(filterEntryValueRadio[index]);
          }
        }
        index++;
      }
    } else {
      filtertableSize = filterEntryValueRadio;
    }

    if (
      this.form.controls['entryValueRadio'].value == 'ALL' &&
      this.form.controls['tableSize'].value == 'ALL'
    ) {
      this.cashGameToShow = filteredData;
    } else {
      this.cashGameToShow = filtertableSize;
    }

    // this.alphabeticalOrderCashGame();

    this.cashGameSelected = this.listCashGame[0];
  }

  get isPlayerInCashGameAlready() {
    const userId = this.authService.getPayloadFromJWT().userId;
    return this.cashGameSelected.players.some((el: any) => el.id === userId);
  }

  rejoinCashGame() {
    sessionStorage.setItem('cashGameId', this.cashGameSelected.gameRoom._id);
    this.router.navigate([
      '/pages/cashgame/id/' + this.cashGameSelected.gameRoom._id,
    ]);
  }

  alphabeticalOrderTournament() {
    if (this.formGroupBy.controls['groupBy'].value == 'table') {
      //@ts-ignore
      this.tournamentsToShow.sort(function (x, y) {
        if (x.tournamentRoom.name < y.tournamentRoom.name) {
          return -1;
        } else {
          return true;
        }
      });
    } else {
      // @ts-ignore
      this.tournamentsToShow.sort(function (x, y) {
        if (x.tournamentRoom.pokerModality < y.tournamentRoom.pokerModality) {
          return -1;
        } else {
          return true;
        }
      });
    }
  }

  alphabeticalOrderCashGame() {
    if (this.formGroupBy.controls['groupBy'].value == 'table') {
      //@ts-ignore
      this.cashGameToShow.sort(function (x, y) {
        if (x.gameRoom.instanceName < y.gameRoom.instanceName) {
          return -1;
        } else {
          return true;
        }
      });
    } else {
      // @ts-ignore
      this.cashGameToShow.sort(function (x, y) {
        if (x.gameRoom.pokerModality < y.gameRoom.pokerModality) {
          return -1;
        } else {
          return true;
        }
      });
    }
  }

  saveFilter() {
    let filters = Object.assign({}, this.form.value);
    localStorage.setItem('filters', JSON.stringify(filters));
    this.translateService.get('TOASTR.SAVE_FILTER').subscribe({
      next: (data) => {
        const toastr = this.toastrService.success(data, '', {
          progressBar: true,
          timeOut: 3000,
        });
        if (toastr) {
          toastr.onHidden.subscribe(() => { });
        }
      },
    });
  }

  handlerCLick() {
    this.modalService.open(this.Modal);
  }
  close() {
    this.modalService.dismissAll();
  }

  openModalTournament() {
    if (!!this.tournamentSelected.tournamentRoom.password) {
      this.modalService.open(ModalTournamentComponent);
    } else {
      this.joinTournament();
    }
  }

  openModalConfirmPassword(cashGame: any) {
    // this.modalService.open(this.ModalPassword);
    // this.joinCashGame();
  }

  joinCashGame() {
    this.spinner.show();
    this.cashGameService.joinCashGame(this.cashGameSelected.gameRoom._id).subscribe({
      next: (data) => {
        sessionStorage.setItem('cashGameId', this.cashGameSelected.gameRoom._id);
        this.router.navigate(['/pages/cashgame/id/' + this.cashGameSelected.gameRoom._id]);
        this.spinner.hide();
      },
    });
    this.modalService.dismissAll();
  }

  joinTournament() {
    this.spinner.show();
    this.tournamentService.join(this.tournamentSelected.tournamentRoom._id).subscribe({
      next: () => {
        this.router.navigate(['/pages/lobby-tournament/id/' + this.tournamentSelected.tournamentRoom._id]);
        this.spinner.hide();
      },
    });
  }

  wayOfWritingGameMode(gameMode: string): string {
    switch (gameMode) {
      case 'PLO_OMAHA_5':
        return 'PL OMAHA 5';

      case 'PLO_OMAHA_4':
        return 'PL OMAHA 4';

      case 'NO_LIMIT_TEXAS_HOLDEM':
        return 'NL HOLDEM';
    }

    return '';
  }

  get hasPlayerJoinedSelectedTournament() {
    if (!!this.tournamentSelected) {
      const playerId = this.authService.getPayloadFromJWT().userId;
      return 'this.tournamentSelected.players.some(el => el.id === playerId);'
    }
    return false;
  }

  openModalJoin() {
    this.formJoinGame.patchValue({
      buyIn: this.cashGameSelected.buyInMaximum,
      valueRange: this.cashGameSelected.buyInMaximum
    });
  }

  join(tableGameId: string) {
    localStorage.setItem('tableId',tableGameId);
    window.open(`/pages/tablegame/id/${tableGameId}`, '_blank');
  }

  removeFromQueue(tableGameId: string) {
    this._commonGateway.removeFromWaitingList(tableGameId, this.loggedPlayerId, 'out', 0);
    this._commonGateway.tableGameDetails.subscribe({
      next: (response: TableGameGetDetailsResponseDto) =>{
        this.cashGameToShow.forEach((item: any) => {
          if(item._id === response._id) {
            item.waitingList = response.waitingList
          }
          this.alreadyInQueue = false
        })

      }
    });

    localStorage.setItem('pokerQueueStatus', JSON.stringify({
      tableGameId: tableGameId,
      inQueue: false,
      timestamp: new Date().getTime()
    }));
   
  }

  joinQueue(tableGameId: string) {
    const value = Number(this.formJoinGame.controls['buyIn'].value)
    this._commonGateway.requestUpdateTableWaitingList(tableGameId, this.loggedPlayerId, 'in', value);
    this._commonGateway.tableGameDetails.subscribe({
      next: (response: TableGameGetDetailsResponseDto) =>{
        this.cashGameToShow.forEach((item: any) => {
          if(item._id === response._id) {
            item.waitingList.push(response.waitingList[response.waitingList.length - 1])
          }
        })
        this.fullRoom = true
        this.alreadyInQueue = true

      }
      
    });
    window.open(`/pages/tablegame/id/${tableGameId}`, '_blank');

    const modalElement = document.getElementById('closeModal');
    modalElement?.click()

  }

  viewGame(tableGameId: string) {
  
    this._commonGateway.requestTableUpdateView(tableGameId, 'join');
    this._commonGateway.tableGameDetails.subscribe({
      next: (response: TableGameGetDetailsResponseDto) =>{
        this.cashGameSelected.viewer = response.viewer

      }
    });
    window.open(`/pages/tablegame/id/${tableGameId}/viewer`, '_blank');

  }

  getTableGameDetails(tableGameId: string) {
    this._commonGateway.requestTableGameDetails(tableGameId);
    this._commonGateway.tableGameDetails.subscribe({
      next: (response: TableGameGetDetailsResponseDto) => {
        this.cashGameDetails = response;
      }
    });
  }

  listenedRange(event: any, type: string) {
    if (type === 'range') {
      const rangeValue = event.target.value;
      this.formJoinGame.patchValue({
        valueRange: rangeValue
      })
      if (this.cashGameSelected?.coin === 'USDT') {
        if (rangeValue > this.balanceValue?.coinUsdValue!) {
          this.formJoinGame.patchValue({
            buyIn: this.balanceValue?.coinUsdValue!,
            valueRange: this.balanceValue?.coinUsdValue!
          })
        }
      }
      if (this.cashGameSelected?.coin === 'POKERFI') {
        if (rangeValue > this.balanceValue?.coinPokerFiValue!) {
          this.formJoinGame.patchValue({
            buyIn: this.balanceValue?.coinPokerFiValue!,
            valueRange: this.balanceValue?.coinPokerFiValue!
          })
        }
      }
    } else {
      const inputValue = event.target.value
      if (inputValue > this.cashGameSelected?.buyInMinimum) {
        this.formJoinGame.patchValue({
          buyIn: inputValue
        })
      } else {
        this.formJoinGame.patchValue({
          buyIn: this.cashGameSelected?.buyInMinimum
        })
      }
      if (inputValue > this.cashGameSelected?.buyInMaximum) {
        this.formJoinGame.patchValue({
          buyIn: this.cashGameSelected?.buyInMaximum,
          valueRange: this.cashGameSelected?.buyInMaximum
        })
      }
      if (this.cashGameSelected?.coin === 'USDT') {
        if (inputValue > this.balanceValue?.coinUsdValue!) {
          this.formJoinGame.patchValue({
            buyIn: this.balanceValue?.coinUsdValue!,
            valueRange: this.balanceValue?.coinUsdValue!
          })
        }
      }
      if (this.cashGameSelected?.coin === 'POKERFI') {
        if (inputValue > this.balanceValue?.coinPokerFiValue!) {
          this.formJoinGame.patchValue({
            buyIn: this.balanceValue?.coinPokerFiValue!,
            valueRange: this.balanceValue?.coinPokerFiValue!
          })
        }
      }

    }
  }
selectTable(i: number) {

  this.cashGameSelected = this.cashGameToShow[i]
  const tableTotalSit = this.cashGameSelected.players.length
  let emptySeat = 0
  this.cashGameSelected.players.forEach((item: any) => {
    item.user === null ? emptySeat += 1: emptySeat
  })
  if (emptySeat > 0) {
    this.fullRoom = false
  } else {
    if(this.cashGameSelected.players.find((item: any) => item.user?._id === this.loggedPlayerId)) {
      this.fullRoom = false
    } else {
      if(this.cashGameSelected.waitingList.find((item: any) => item?.user._id === this.loggedPlayerId || item?.user === this.loggedPlayerId)) {
        this.alreadyInQueue = true
      } else {
        this.alreadyInQueue = false
      }
      this.fullRoom = true
    }
  }

}

  getPlayersSeatedInTable(index: number) {
    if (this.cashGameToShow.length) {
      let playersSeated = this.cashGameToShow[index].players.filter((a: any) => a.status !== PlayerStatusEnum.empty);
      if (playersSeated.length) {
        return playersSeated.length;
      }
    }
    return 0;
  }
}
