import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { tap } from "rxjs/operators";
import { TournamentResponseDto } from "src/app/dtos/tournament/tournament-response.dto";
import { TournamentService } from "src/services/tournament.service";

@Injectable({
  providedIn: "root",
})
export class TournamentResolver implements Resolve<TournamentResponseDto> {
  constructor(
    private readonly tournamentService: TournamentService,
    private readonly ngxSpinnerService: NgxSpinnerService,
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    this.ngxSpinnerService.show();
    return this.tournamentService.getById(route.params['id']).pipe(
      tap(() => {
        this.ngxSpinnerService.hide();
      })
    );
  }
}
