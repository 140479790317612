import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataTransferService {
  private totalPlayersPlaying: number = 0;
  private totalPlayersSubject = new Subject<number>();

  constructor() { }

  setTotalPlayers(data: number): void {
 
    this.totalPlayersPlaying = data;
    this.totalPlayersSubject.next(data);
  }

  getTotalPlayers(): number {
    return this.totalPlayersPlaying;
  }

  getTotalPlayersObservable(): Observable<number> {
    return this.totalPlayersSubject.asObservable();
  }
}
