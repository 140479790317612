import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { AuthService } from "src/services/auth.service";

@Injectable({
    providedIn: "root",
})
export class LoginGuard implements CanActivate {
    constructor(
        private authService: AuthService,
        private router: Router
    ) { }

    async canActivate() {
        const user = this.authService.getAuthenticatedUser();
        if (!!user) {
            try {
                await this.authService.authenticated().toPromise();
                this.router.navigate(['/pages/home']);
            } catch (err) {
                return true;
            }
        }
        return true;
    }
}
