import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { JwtPayloadDto } from 'src/app/dtos/auth/jwt-payload.dto';
import { CashGameHistoryInterface } from 'src/app/dtos/cash-game-history/cash-game-history.interface';
import { PlayerHandActionEnum } from 'src/app/dtos/enum/player-hand-action.enum';
import { PlayerStatusEnum } from 'src/app/dtos/enum/player-status.enum';
import { GameCoinGetBalanceResponseDto } from 'src/app/dtos/game-coin/game-coin-get-balance-response.dto';
import { ResponseDto } from 'src/app/dtos/response.dto';
import { TableGameGetDetailsResponseDto } from 'src/app/dtos/socket/table-game-get-details-response.dto';
import { AnimationActionResponseDto } from 'src/app/dtos/table-game/animation-action-response.dto';
import { AnimationGiveChipsToWinnersResponseDto } from 'src/app/dtos/table-game/animation-give-chips-to-winners-response.dto';
import { AnimationSetChipsToPotResponseDto } from 'src/app/dtos/table-game/animation-set-chips-to-pot-response.dto';
import { AnimationShowFullPotDto } from 'src/app/dtos/table-game/animation-show-full-pot.dto';
import { AnimationTimerCurrentPlayerDto } from 'src/app/dtos/table-game/animation-timer-current-player.dto';
import { PlayerGetResponseDto } from 'src/app/dtos/table-game/player-get-response.dto';
import { TableGameRoundStatusEnum } from 'src/interface/table-game-round-status.enum';
import { TableGameStatusEnum } from 'src/interface/table-game-status.enum';
import { AuthService } from 'src/services/auth.service';
import { CashGameReplayService } from 'src/services/cash-game-replay.service';
import { GameCoinService } from 'src/services/game-coin.service';
import { CommonGatewaySocketService } from 'src/services/sockets/common-gateway.socket.service';
import { GameGatewaySocketService } from 'src/services/sockets/game-gateway.socket.service';
import { TableGameService } from 'src/services/table-game.service';
import SoundUtil from 'src/util/sound.util';

@Component({
  selector: 'app-table-game-replay',
  templateUrl: './table-game-replay.component.html',
  styleUrls: ['./table-game-replay.component.scss']
})
export class TableGameReplayComponent implements OnInit {


  loggedUser: JwtPayloadDto | undefined
  progressWidth!: number;
  form: FormGroup;
  formBuyIn: FormGroup;
  formBuyCoin: FormGroup;

  formWaitBigBlind: FormGroup;
  buyInButtonDisable: boolean = false
  tableGame?: any;
  playersInViewOrder: PlayerGetResponseDto[] = [];
  minRaiseValue = 0;
  joinQueue: boolean = false
  alreadyInQueue: boolean = false
  isUserPlaying: boolean = false
  user: any
  fullRoom: boolean = false
  showActionPlayer0: boolean = false;
  showActionPlayer1: boolean = false;
  showActionPlayer2: boolean = false;
  showActionPlayer3: boolean = false;
  showActionPlayer4: boolean = false;
  showActionPlayer5: boolean = false;
  showActionPlayer6: boolean = false;
  showActionPlayer7: boolean = false;
  showActionPlayer8: boolean = false;

  animationPlayerIndex: number | undefined;
  animationResponse: AnimationActionResponseDto | undefined;
  sitAtTableGameIndex: number = 0;

  buyCoinMin: Number = 0;
  buyCoinMax: Number = 0;

  showFullPot: boolean = false;

  arrayShowdownIndex = [false, false, false, false, false, false, false, false, false,];

  balanceValue?: GameCoinGetBalanceResponseDto;

  showValuesInBigBlindsFormat = false;

  currentTurn: string = '';
  previousRound: string = '';
  previousDealer: string = '';
  visualPots: any[] = [];
  animationGiveChipsToWinners?: AnimationGiveChipsToWinnersResponseDto;
  animationGiveChipsToWinnersPlayerWinner: number | undefined;
  AnimationGiveChipsToWinnersResponseDto?: number;
  modalRebuyIsOpen = false;
  isViewer: boolean = false
  canLeavePokerGame: boolean = true;

  lang: any;

  tableId: string = ''
  userId: string = ''
  canStart:boolean = false
  history: CashGameHistoryInterface[] = []
  step: number = 0 
  currentTableRound: number = 9999
  alreadyShowActionFoldPlayer0: boolean = false;
  playLoop: boolean = true
  constructor(
    private formBuilder: FormBuilder,
    private readonly route: ActivatedRoute,
    private readonly authService: AuthService,
    private readonly _gameGateway: GameGatewaySocketService,
    private readonly _commonGateway: CommonGatewaySocketService,
    private toastrService: ToastrService,
    private gameCoinService: GameCoinService,
    private ngxSpinnerService: NgxSpinnerService,
    public translate: TranslateService,
    private readonly router: Router,
    private tableGameService: TableGameService,
    private cashgameReplayService: CashGameReplayService
  ) {

    this.form = this.formBuilder.group({
      bet: [''],
      valueRange: [''],
      valueRangeBB: [''],
    });
    this.formBuyIn = this.formBuilder.group({
      buyIn: [''],
      valueRangeBuyIn: [''],
    });
    this.formBuyCoin = this.formBuilder.group({
      buyCoin: [''],
      valueRangeBuyCoin: [''],
    });

  
    this.formWaitBigBlind = this.formBuilder.group({
      waitForBigBlind: [''],
    });
    translate.use(localStorage.getItem('lan') || 'en')
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.tableId = params['tableId']
      this.userId = params['userId']
      const round = params['round']
      this.cashgameReplayService.listByTableAndUserId(this.tableId, this.userId, round).subscribe({
        next: data => {
         
          this.history = data
          this.canStart = true
          if (this.canStart) {
      
            this.startPlaying()
      
      
        }
        },
        error: err => {
          this.canStart = false
          console.error('err', err)
        }
      })
    })
   

  
  }
  async startPlaying() {
    if (this.playLoop) {
      if (this.canStart) {
        for (let i = 0; i < this.history.length; i++) {
          this.step = i;
          this.sitAtTable();
          await this.delay(1200);
          this.currentTableRound = this.history[this.step].tableRound;
  
          if (this.currentTableRound !== this.history[this.step].tableRound) {
            this.alreadyShowActionFoldPlayer0 = false;
          }
  
        
          if (i === this.history.length - 1) {
            this.playLoop = false; 
            this.stopAllActivity();
          }
        }
      }
    }
  }

  stopAllActivity() {
    window.close()
  }

  //---
  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }


 
 
 async sitAtTable() {
  this.playersInViewOrder = this.history[this.step].players
      
        let emptySeat = 0
        this.history[this.step].players.forEach((item: any) => {
          item.user === null ? emptySeat += 1: emptySeat
        })
        if (emptySeat > 0) {
          this.fullRoom = false
        } else {
          this.fullRoom = true
        }
      
        let found = false
        this.history[this.step].players.forEach((item: any) => {
          if(item?.user?._id === this.user?._id) {
      
            found = true
            return this.isUserPlaying = true
          } else {
            if (!found) {
            
              return this.isUserPlaying = false
            } else {
              return this.isUserPlaying = true
            }
          } 
        })

     
        if (this.previousRound === TableGameRoundStatusEnum.PRE_FLOP && this.history[this.step]?.round === TableGameRoundStatusEnum.FLOP) {
          SoundUtil.play('../../../assets/sounds/flop.m4a', 2);
        } else if (this.previousRound === TableGameRoundStatusEnum.FLOP && this.history[this.step]?.round === TableGameRoundStatusEnum.TURN ||
          this.previousRound === TableGameRoundStatusEnum.TURN && this.history[this.step]?.round === TableGameRoundStatusEnum.RIVER) {

            SoundUtil.play('../../../assets/sounds/turn-river.m4a', 2);
        }
    
        if (this.currentTurn !== this.history[this.step]?.currentTurn || this.previousRound !== this.history[this.step]?.round || this.previousDealer !== this.history[this.step].dealerTurn) {
          this.currentTurn = this.history[this.step]?.currentTurn!;
          // this.showIndividualPot = true;
          if (this.history[this.step]) {
            let indexInit = this.history[this.step].players.findIndex((a: any) => a?.user?.email == this.authService.getAuthenticatedUser().email);
            if (this.history[this.step]?.players[indexInit]?.user?._id! == this.history[this.step].currentTurn && !this.history[this.step].inResolveGame) {

              SoundUtil.play('../../../assets/sounds/your-turn.wav', 1, 0.1);
            }
          }
        }

        this.previousDealer = this.history[this.step]?.dealerTurn!;
        this.previousRound = this.history[this.step]?.round!;

        // this.playersInViewOrder = [];
        if (this.history[this.step]) {
          // this.createPots();

          this.visualPots = this.history[this.step].visualPots;

          let indexInit = this.history[this.step].players.findIndex((a: any) => a?.user?.email == this.authService.getAuthenticatedUser().email);
          if (indexInit > -1) {
            for (let i = indexInit; i < this.history[this.step].players.length; i++) {
            //  this.playersInViewOrder.push(this.history[this.step].players[i]);
            }
            for (let i = 0; i < indexInit; i++) {
            //  this.playersInViewOrder.push(this.history[this.step].players[i]);
            }

            this.getMinRaise();
            this.form.patchValue({
              bet: this.minRaiseValue,
              valueRange: this.minRaiseValue,
              valueRangeBB: this.minRaiseValue / this.history[this.step].bigBlindValue
            });

          } else {
            // this.playersInViewOrder = this.history[this.step].players;
          }
          this.showPlayerAction()
          await this.animationSetCardsToPlayers(this.playersInViewOrder)
          const playersInThisStep = this.history[this.step].players.filter((jogador) => jogador.user !== null)
          let winner;
          for( let i = 0; i < this.history[this.step].winners.length; i++) {
            winner = playersInThisStep.find((item) => item.user?._id === this.history[this.step].winners[i].player)
          } 
          if(winner && this.step > 5 && this.history[this.step].pot === 0 ) {
            this.showAnimationGiveChipsToWinners(winner)

          }

        }
    

  }



  changeShowValuesInBigBlindsFormat() {
    this.showValuesInBigBlindsFormat = !this.showValuesInBigBlindsFormat;
  }


  betByPlayer(index: number) {
    let bet = 0;
    if (this.playersInViewOrder[index]?.playerHand) {
      for (const action of this.playersInViewOrder[index]?.playerHand!.actions) {
        if (this.history[this.step]?.round == TableGameRoundStatusEnum.PRE_FLOP) {
          if (action.round == TableGameRoundStatusEnum.PRE_FLOP || action.round == TableGameRoundStatusEnum.BLIND) {
            bet += action.bet
          }
        } else {
          if (action.round == this.tableGame?.round) {
            bet += action.bet
          }
        }
      }

    }
    return Number(bet.toFixed(2));
  }


  showPlayerAction() {
    
    this.animationPlayerIndex = this.playersInViewOrder.findIndex((a: any) => a?.user._id.toString() === this.history[this.step]?.currentTurn.toString());
    if(this.animationPlayerIndex === -1) return
    if (this.history[this.step]?.players[this.animationPlayerIndex].playerHand?.lastAction == PlayerHandActionEnum.FOLD && !this.alreadyShowActionFoldPlayer0) {
      this.alreadyShowActionFoldPlayer0 = true
      this.showAnimationFoldAction();
    } else if (this.history[this.step]?.players[this.animationPlayerIndex].playerHand?.lastAction== PlayerHandActionEnum.CALL || this.history[this.step]?.players[this.animationPlayerIndex].playerHand?.lastAction== PlayerHandActionEnum.RAISE ||
      this.history[this.step]?.players[this.animationPlayerIndex].playerHand?.lastAction== PlayerHandActionEnum.ALLIN || this.history[this.step]?.players[this.animationPlayerIndex].playerHand?.lastAction== PlayerHandActionEnum.BET) {
    
      this.showAnimationBetAction();
    }
  
    this.playSound();

    switch (this.animationPlayerIndex) {
      case 0:

        this.showActionPlayer0 = true;
        this.showActionPlayer1 = false;
        this.showActionPlayer2 = false;
        this.showActionPlayer3 = false;
        this.showActionPlayer4 = false;
        this.showActionPlayer5 = false;
        this.showActionPlayer6 = false;
        this.showActionPlayer7 = false;
        this.showActionPlayer8 = false;

        setTimeout(() => {
          this.showActionPlayer0 = false;
        }, 3000);

        break;
      case 1:

        this.showActionPlayer0 = false;
        this.showActionPlayer1 = true;
        this.showActionPlayer2 = false;
        this.showActionPlayer3 = false;
        this.showActionPlayer4 = false;
        this.showActionPlayer5 = false;
        this.showActionPlayer6 = false;
        this.showActionPlayer7 = false;
        this.showActionPlayer8 = false;

        setTimeout(() => {
          this.showActionPlayer1 = false;
        }, 3000);

        break;
      case 2:

        this.showActionPlayer0 = false;
        this.showActionPlayer1 = false;
        this.showActionPlayer2 = true;
        this.showActionPlayer3 = false;
        this.showActionPlayer4 = false;
        this.showActionPlayer5 = false;
        this.showActionPlayer6 = false;
        this.showActionPlayer7 = false;
        this.showActionPlayer8 = false;

        setTimeout(() => {
          this.showActionPlayer2 = false;
        }, 3000);

        break;
      case 3:

        this.showActionPlayer0 = false;
        this.showActionPlayer1 = false;
        this.showActionPlayer2 = false;
        this.showActionPlayer3 = true;
        this.showActionPlayer4 = false;
        this.showActionPlayer5 = false;
        this.showActionPlayer6 = false;
        this.showActionPlayer7 = false;
        this.showActionPlayer8 = false;

        setTimeout(() => {
          this.showActionPlayer3 = false;
        }, 3000);

        break;
      case 4:

        this.showActionPlayer0 = false;
        this.showActionPlayer1 = false;
        this.showActionPlayer2 = false;
        this.showActionPlayer3 = false;
        this.showActionPlayer4 = true;
        this.showActionPlayer5 = false;
        this.showActionPlayer6 = false;
        this.showActionPlayer7 = false;
        this.showActionPlayer8 = false;

        setTimeout(() => {
          this.showActionPlayer4 = false;
        }, 3000);

        break;
      case 5:

        this.showActionPlayer0 = false;
        this.showActionPlayer1 = false;
        this.showActionPlayer2 = false;
        this.showActionPlayer3 = false;
        this.showActionPlayer4 = false;
        this.showActionPlayer5 = true;
        this.showActionPlayer6 = false;
        this.showActionPlayer7 = false;
        this.showActionPlayer8 = false;

        setTimeout(() => {
          this.showActionPlayer5 = false;
        }, 3000);

        break;
      case 6:

        this.showActionPlayer0 = false;
        this.showActionPlayer1 = false;
        this.showActionPlayer2 = false;
        this.showActionPlayer3 = false;
        this.showActionPlayer4 = false;
        this.showActionPlayer5 = false;
        this.showActionPlayer6 = true;
        this.showActionPlayer7 = false;
        this.showActionPlayer8 = false;

        setTimeout(() => {
          this.showActionPlayer6 = false;
        }, 3000);

        break;
      case 7:

        this.showActionPlayer0 = false;
        this.showActionPlayer1 = false;
        this.showActionPlayer2 = false;
        this.showActionPlayer3 = false;
        this.showActionPlayer4 = false;
        this.showActionPlayer5 = false;
        this.showActionPlayer6 = false;
        this.showActionPlayer7 = true;
        this.showActionPlayer8 = false;

        setTimeout(() => {
          this.showActionPlayer7 = false;
        }, 3000);

        break;
      case 8:

        this.showActionPlayer0 = false;
        this.showActionPlayer1 = false;
        this.showActionPlayer2 = false;
        this.showActionPlayer3 = false;
        this.showActionPlayer4 = false;
        this.showActionPlayer5 = false;
        this.showActionPlayer6 = false;
        this.showActionPlayer7 = false;
        this.showActionPlayer8 = true;

        setTimeout(() => {
          this.showActionPlayer8 = false;
        }, 3000);

        break;
    }

  }

  showAnimationFoldAction() {
    switch (this.animationPlayerIndex) {
      case 0:
        let animationDivSetCardsToTable0 = document.getElementById('animationDivSetCardsToTable0');
        animationDivSetCardsToTable0?.classList.add('startAnimationDivSetCardsToTable0');
        setTimeout(() => {
          animationDivSetCardsToTable0?.classList.remove('startAnimationDivSetCardsToTable0');
        }, 1000);
        break;

      case 1:
        let animationDivSetCardsToTable1 = document.getElementById('animationDivSetCardsToTable1');
        animationDivSetCardsToTable1?.classList.add('startAnimationDivSetCardsToTable1');
        setTimeout(() => {
          animationDivSetCardsToTable1?.classList.remove('startAnimationDivSetCardsToTable1');
        }, 1000);
        break;

      case 2:
        let animationDivSetCardsToTable2 = document.getElementById('animationDivSetCardsToTable2');
        animationDivSetCardsToTable2?.classList.add('startAnimationDivSetCardsToTable2');
        setTimeout(() => {
          animationDivSetCardsToTable2?.classList.remove('startAnimationDivSetCardsToTable2');
        }, 1000);
        break;

      case 3:
        let animationDivSetCardsToTable3 = document.getElementById('animationDivSetCardsToTable3');
        animationDivSetCardsToTable3?.classList.add('startAnimationDivSetCardsToTable3');
        setTimeout(() => {
          animationDivSetCardsToTable3?.classList.remove('startAnimationDivSetCardsToTable3');
        }, 1000);
        break;

      case 4:
        let animationDivSetCardsToTable4 = document.getElementById('animationDivSetCardsToTable4');
        animationDivSetCardsToTable4?.classList.add('startAnimationDivSetCardsToTable4');
        setTimeout(() => {
          animationDivSetCardsToTable4?.classList.remove('startAnimationDivSetCardsToTable4');
        }, 1000);
        break;

      case 5:
        let animationDivSetCardsToTable5 = document.getElementById('animationDivSetCardsToTable5');
        animationDivSetCardsToTable5?.classList.add('startAnimationDivSetCardsToTable5');
        setTimeout(() => {
          animationDivSetCardsToTable5?.classList.remove('startAnimationDivSetCardsToTable5');
        }, 1000);
        break;

      case 6:
        let animationDivSetCardsToTable6 = document.getElementById('animationDivSetCardsToTable6');
        animationDivSetCardsToTable6?.classList.add('startAnimationDivSetCardsToTable6');
        setTimeout(() => {
          animationDivSetCardsToTable6?.classList.remove('startAnimationDivSetCardsToTable6');
        }, 1000);
        break;

      case 7:
        let animationDivSetCardsToTable7 = document.getElementById('animationDivSetCardsToTable7');
        animationDivSetCardsToTable7?.classList.add('startAnimationDivSetCardsToTable7');
        setTimeout(() => {
          animationDivSetCardsToTable7?.classList.remove('startAnimationDivSetCardsToTable7');
        }, 1000);
        break;

      case 8:
        let animationDivSetCardsToTable8 = document.getElementById('animationDivSetCardsToTable8');
        animationDivSetCardsToTable8?.classList.add('startAnimationDivSetCardsToTable8');
        setTimeout(() => {
          animationDivSetCardsToTable8?.classList.remove('startAnimationDivSetCardsToTable8');
        }, 1000);
        break;
    }
  }

  showAnimationBetAction() {

    switch (this.animationPlayerIndex) {
      case 0:
        let animationChipsFromHandToTableIndex0 = document.getElementById('animationChipsFromHandToTableIndex0');
        animationChipsFromHandToTableIndex0?.classList.add('startAnimationChipsFromHandToTableIndex0');
        setTimeout(() => {
          animationChipsFromHandToTableIndex0?.classList.remove('startAnimationChipsFromHandToTableIndex0');
        }, 1000);
        break;

      case 1:
        let animationChipsFromHandToTableIndex1 = document.getElementById('animationChipsFromHandToTableIndex1');
        animationChipsFromHandToTableIndex1?.classList.add('startAnimationChipsFromHandToTableIndex1');
        setTimeout(() => {
          animationChipsFromHandToTableIndex1?.classList.remove('startAnimationChipsFromHandToTableIndex1');
        }, 1000);
        break;

      case 2:
        let animationChipsFromHandToTableIndex2 = document.getElementById('animationChipsFromHandToTableIndex2');
        animationChipsFromHandToTableIndex2?.classList.add('startAnimationChipsFromHandToTableIndex2');
        setTimeout(() => {
          animationChipsFromHandToTableIndex2?.classList.remove('startAnimationChipsFromHandToTableIndex2');
        }, 1000);
        break;

      case 3:
        let animationChipsFromHandToTableIndex3 = document.getElementById('animationChipsFromHandToTableIndex3');
        animationChipsFromHandToTableIndex3?.classList.add('startAnimationChipsFromHandToTableIndex3');
        setTimeout(() => {
          animationChipsFromHandToTableIndex3?.classList.remove('startAnimationChipsFromHandToTableIndex3');
        }, 1000);
        break;

      case 4:
        let animationChipsFromHandToTableIndex4 = document.getElementById('animationChipsFromHandToTableIndex4');
        animationChipsFromHandToTableIndex4?.classList.add('startAnimationChipsFromHandToTableIndex4');
        setTimeout(() => {
          animationChipsFromHandToTableIndex4?.classList.remove('startAnimationChipsFromHandToTableIndex4');
        }, 1000);
        break;

      case 5:
        let animationChipsFromHandToTableIndex5 = document.getElementById('animationChipsFromHandToTableIndex5');
        animationChipsFromHandToTableIndex5?.classList.add('startAnimationChipsFromHandToTableIndex5');
        setTimeout(() => {
          animationChipsFromHandToTableIndex5?.classList.remove('startAnimationChipsFromHandToTableIndex5');
        }, 1000);
        break;

      case 6:
        let animationChipsFromHandToTableIndex6 = document.getElementById('animationChipsFromHandToTableIndex6');
        animationChipsFromHandToTableIndex6?.classList.add('startAnimationChipsFromHandToTableIndex6');
        setTimeout(() => {
          animationChipsFromHandToTableIndex6?.classList.remove('startAnimationChipsFromHandToTableIndex6');
        }, 1000);
        break;

      case 7:
        let animationChipsFromHandToTableIndex7 = document.getElementById('animationChipsFromHandToTableIndex7');
        animationChipsFromHandToTableIndex7?.classList.add('startAnimationChipsFromHandToTableIndex7');
        setTimeout(() => {
          animationChipsFromHandToTableIndex7?.classList.remove('startAnimationChipsFromHandToTableIndex7');
        }, 1000);
        break;

      case 8:
        let animationChipsFromHandToTableIndex8 = document.getElementById('animationChipsFromHandToTableIndex8');
        animationChipsFromHandToTableIndex8?.classList.add('startAnimationChipsFromHandToTableIndex8');
        setTimeout(() => {
          animationChipsFromHandToTableIndex8?.classList.remove('startAnimationChipsFromHandToTableIndex8');
        }, 1000);
        break;
    }
  }

  async animationSetCardsToPlayers(amimationPlayersInViewOrder: PlayerGetResponseDto[]) {
    if(this.currentTableRound !== this.history[this.step].tableRound) {
      if (amimationPlayersInViewOrder[0] && amimationPlayersInViewOrder[0].status == PlayerStatusEnum.playing) {
        let animationDivSetCardsToPlayer0 = document.getElementById('animationDivSetCardsToPlayer0');
        animationDivSetCardsToPlayer0?.classList.add('startAnimationDivSetCardsToPlayer0');
        SoundUtil.play('../../assets/sounds/flop.m4a', 1);
        setTimeout(() => {
          animationDivSetCardsToPlayer0?.classList.remove('startAnimationDivSetCardsToPlayer0');
        }, 1000);
      }
  
      if (amimationPlayersInViewOrder[1] && amimationPlayersInViewOrder[1].status == PlayerStatusEnum.playing) {
        let animationDivSetCardsToPlayer1 = document.getElementById('animationDivSetCardsToPlayer1');
        animationDivSetCardsToPlayer1?.classList.add('startAnimationDivSetCardsToPlayer1');
        setTimeout(() => {
          animationDivSetCardsToPlayer1?.classList.remove('startAnimationDivSetCardsToPlayer1');
        }, 1000);
      }
  
      if (amimationPlayersInViewOrder[2] && amimationPlayersInViewOrder[2].status == PlayerStatusEnum.playing) {
        let animationDivSetCardsToPlayer2 = document.getElementById('animationDivSetCardsToPlayer2');
        animationDivSetCardsToPlayer2?.classList.add('startAnimationDivSetCardsToPlayer2');
        setTimeout(() => {
          animationDivSetCardsToPlayer2?.classList.remove('startAnimationDivSetCardsToPlayer2');
        }, 1000);
      }
  
      if (amimationPlayersInViewOrder[3] && amimationPlayersInViewOrder[3].status == PlayerStatusEnum.playing) {
        let animationDivSetCardsToPlayer3 = document.getElementById('animationDivSetCardsToPlayer3');
        animationDivSetCardsToPlayer3?.classList.add('startAnimationDivSetCardsToPlayer3');
        setTimeout(() => {
          animationDivSetCardsToPlayer3?.classList.remove('startAnimationDivSetCardsToPlayer3');
        }, 1000);
      }
  
      if (amimationPlayersInViewOrder[4] && amimationPlayersInViewOrder[4].status == PlayerStatusEnum.playing) {
        let animationDivSetCardsToPlayer4 = document.getElementById('animationDivSetCardsToPlayer4');
        animationDivSetCardsToPlayer4?.classList.add('startAnimationDivSetCardsToPlayer4');
        setTimeout(() => {
          animationDivSetCardsToPlayer4?.classList.remove('startAnimationDivSetCardsToPlayer4');
        }, 1000);
      }
  
      if (amimationPlayersInViewOrder[5] && amimationPlayersInViewOrder[5].status == PlayerStatusEnum.playing) {
        let animationDivSetCardsToPlayer5 = document.getElementById('animationDivSetCardsToPlayer5');
        animationDivSetCardsToPlayer5?.classList.add('startAnimationDivSetCardsToPlayer5');
        setTimeout(() => {
          animationDivSetCardsToPlayer5?.classList.remove('startAnimationDivSetCardsToPlayer5');
        }, 1000);
      }
  
      if (amimationPlayersInViewOrder[6] && amimationPlayersInViewOrder[6].status == PlayerStatusEnum.playing) {
        let animationDivSetCardsToPlayer6 = document.getElementById('animationDivSetCardsToPlayer6');
        animationDivSetCardsToPlayer6?.classList.add('startAnimationDivSetCardsToPlayer6');
        setTimeout(() => {
          animationDivSetCardsToPlayer6?.classList.remove('startAnimationDivSetCardsToPlayer6');
        }, 1000);
      }
  
      if (amimationPlayersInViewOrder[7] && amimationPlayersInViewOrder[7].status == PlayerStatusEnum.playing) {
        let animationDivSetCardsToPlayer7 = document.getElementById('animationDivSetCardsToPlayer7');
        animationDivSetCardsToPlayer7?.classList.add('startAnimationDivSetCardsToPlayer7');
        setTimeout(() => {
          animationDivSetCardsToPlayer7?.classList.remove('startAnimationDivSetCardsToPlayer7');
        }, 1000);
      }
  
      if (amimationPlayersInViewOrder[8] && amimationPlayersInViewOrder[8].status == PlayerStatusEnum.playing) {
        let animationDivSetCardsToPlayer8 = document.getElementById('animationDivSetCardsToPlayer8');
        animationDivSetCardsToPlayer8?.classList.add('startAnimationDivSetCardsToPlayer8');
        setTimeout(() => {
          animationDivSetCardsToPlayer8?.classList.remove('startAnimationDivSetCardsToPlayer8');
        }, 1000);
      }
    }
  


  }

  playSound() {
    if (this.animationResponse?.action === PlayerHandActionEnum.FOLD) {
      SoundUtil.play('../../assets/sounds/action-fold.m4a', 2);
    } else if (this.animationResponse?.action === PlayerHandActionEnum.CHECK) {
      SoundUtil.play('../../assets/sounds/action-check.wav', 1, 1);
    } else if (this.animationResponse?.action === PlayerHandActionEnum.RAISE ||
      this.animationResponse?.action === PlayerHandActionEnum.CALL ||
      this.animationResponse?.action === PlayerHandActionEnum.ALLIN ||
      this.animationResponse?.action === PlayerHandActionEnum.BET) {
      SoundUtil.play('../../assets/sounds/action-bet-raise-call-allin.m4a', 2);
    }
  }

  

  getMaxAmount() {
    if (this.tableGame?.players) {
      let playerIndex = this.tableGame!.players.findIndex((a: any) => a?.user?.email == this.authService.getAuthenticatedUser().email);
      if (playerIndex > -1) {
        return this.tableGame.players[playerIndex].coinAmount!;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }

  canCheck() {
    if (this.history[this.step]?.players) {
      const topBetRound = this.getTopBetRound();
      const topBetPlayer = this.getTopBetPlayer();


      if (topBetRound == topBetPlayer) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  canCall() {
    if (this.history[this.step]?.players) {
      const topBetRound = this.getTopBetRound();
      const topBetPlayer = this.getTopBetPlayer();
      let playerIndex = this.history[this.step]!.players.findIndex((a: any) => a?.user?.email == this.authService.getAuthenticatedUser().email);
      if (topBetPlayer + this.history[this.step]?.players[playerIndex]?.coinAmount! >= topBetRound) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  canRaise() {
    if (this.tableGame?.players) {

      let sumBetPlayerWithoutBlinds = this.getSumBetPlayerWithoutBlinds(this.tableGame?.currentTurn);
      let raiseArray: number[] = [];
      for (const player of this.tableGame.players) {
        if (player.playerHand?.lastAction == PlayerHandActionEnum.RAISE || player.playerHand?.lastAction == PlayerHandActionEnum.BET) {
          let playerActions;
          if (this.tableGame.round == TableGameRoundStatusEnum.PRE_FLOP) {
            playerActions = player?.playerHand?.actions.map((a: any) => a).filter((b: any) => b.round == TableGameRoundStatusEnum.PRE_FLOP || b.round == TableGameRoundStatusEnum.BLIND);
          } else {
            playerActions = player?.playerHand?.actions.map((a: any) => a).filter((b: any) => b.round == this.tableGame?.round);
          }
          const bets = playerActions?.map((a: any) => a.bet);
          const sumBet = bets?.reduce((sum: any, current: any) => sum + current, 0);
          raiseArray.push(sumBet);
        }
      }

      let biggerRaiseRound = Math.max(...raiseArray.filter(a => a !== undefined));

      if (this.tableGame?.allinLessThanMinRaise && sumBetPlayerWithoutBlinds > 0 && sumBetPlayerWithoutBlinds >= biggerRaiseRound) {
        return false;
      }

      let playerIndex = this.tableGame!.players.findIndex((a: any) => a?.user?.email == this.authService.getAuthenticatedUser().email);

      if (this.tableGame?.players[playerIndex]?.coinAmount! >= this.minRaiseValue && this.form.controls['bet'].value < Number((this.tableGame?.players[playerIndex]?.coinAmount! + this.betByPlayer(0)).toFixed(2))) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  canAllIn() {
    if (this.tableGame?.players) {

      let sumBetPlayerWithoutBlinds = this.getSumBetPlayerWithoutBlinds(this.tableGame?.currentTurn);
      let raiseArray: number[] = [];
      for (const player of this.tableGame.players) {
        if (player.playerHand?.lastAction == PlayerHandActionEnum.RAISE || player.playerHand?.lastAction == PlayerHandActionEnum.BET) {
          let playerActions;
          if (this.tableGame.round == TableGameRoundStatusEnum.PRE_FLOP) {
            playerActions = player?.playerHand?.actions.map((a: any) => a).filter((b: any) => b.round == TableGameRoundStatusEnum.PRE_FLOP || b.round == TableGameRoundStatusEnum.BLIND);
          } else {
            playerActions = player?.playerHand?.actions.map((a: any) => a).filter((b: any) => b.round == this.tableGame?.round);
          }
          const bets = playerActions?.map((a: any) => a.bet);
          const sumBet = bets?.reduce((sum: any, current: any) => sum + current, 0);
          raiseArray.push(sumBet);
        }
      }
      let biggerRaiseRound = Math.max(...raiseArray.filter(a => a !== undefined));

      if (this.tableGame?.allinLessThanMinRaise && sumBetPlayerWithoutBlinds > 0 && this.canCall() && sumBetPlayerWithoutBlinds >= biggerRaiseRound) {
        return false;
      } else {
        let playerIndex = this.playersInViewOrder.findIndex((a: any) => a?.user?.email == this.authService.getAuthenticatedUser().email);
        if (playerIndex > -1 && this.playersInViewOrder[playerIndex].coinAmount! <= this.minRaiseValue) {
          this.form.patchValue({
            bet: Number((this.playersInViewOrder[playerIndex].coinAmount! + this.betByPlayer(0)).toFixed(2)),
            valueRange: Number((this.playersInViewOrder[playerIndex].coinAmount! + this.betByPlayer(0)).toFixed(2)),
            valueRangeBB: Number(((this.playersInViewOrder[playerIndex].coinAmount! + this.betByPlayer(0)) / this.tableGame?.bigBlindValue!).toFixed(2))
          });
        }
        if (this.form.controls['bet'].value == Number((this.playersInViewOrder[playerIndex]?.coinAmount! + this.betByPlayer(0)).toFixed(2))) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      return false;
    }
  }

  isMainPlayer() {
    if (this.tableGame) {
      let indexInit = this.playersInViewOrder.findIndex((a: any) => a?.user?.email == this.authService.getAuthenticatedUser().email);
      return indexInit > -1 && indexInit == 0;
    }
    return false;
  }

  isFold(i: number) {

    if (this.playersInViewOrder[i]?.playerHand?.lastAction) {
      if (this.playersInViewOrder[i]?.playerHand?.lastAction == PlayerHandActionEnum.FOLD) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }

  }

  fold() {
    this.tableGame!.showActions = false;
    let dto = {
      tableGameId: this.tableGame!._id,
      action: PlayerHandActionEnum.FOLD,
      bet: 0
    }
    this._gameGateway.playerAction(dto);
  }

  call() {
    this.tableGame!.showActions = false;
    const topBetRound = this.getTopBetRound();
    const topBetPlayer = this._getTopBetCurrentTurnPlayer();
    let index = this.playersInViewOrder.findIndex((a: any) => a?.user?._id == this.tableGame?.currentTurn);
    const bet = topBetRound - topBetPlayer;
    if (this.playersInViewOrder[index]?.coinAmount! - bet == 0) {
      let dto = {
        tableGameId: this.tableGame!._id,
        action: PlayerHandActionEnum.ALLIN,
        bet: this.playersInViewOrder[index]?.coinAmount!
      }
      this._gameGateway.playerAction(dto);
    } else {
      let dto = {
        tableGameId: this.tableGame!._id,
        action: PlayerHandActionEnum.CALL,
        bet: 0
      }
      this._gameGateway.playerAction(dto);
    }
  }

  check() {
    this.tableGame!.showActions = false;
    let dto = {
      tableGameId: this.tableGame!._id,
      action: PlayerHandActionEnum.CHECK,
      bet: 0
    }
    this._gameGateway.playerAction(dto);
  }

  raise() {
    if (this.form.controls['bet'].value < this.minRaiseValue) {
      this.form.patchValue({
        bet: this.minRaiseValue,
        valueRange: this.minRaiseValue,
        valueRangeBB: this.minRaiseValue / this.tableGame?.bigBlindValue!
      });
      return;
    }
    this.tableGame!.showActions = false;

    let playerIndex = this.playersInViewOrder.findIndex((a: any) => a?.user?.email == this.authService.getAuthenticatedUser().email);

    let sumBet = this.betByPlayer(playerIndex);

    let betValue = +Number(Number(this.form.controls['bet'].value) - sumBet).toFixed(2);

    if (betValue == this.playersInViewOrder[playerIndex]?.playerHand?.coinAmount) {
      let dto = {
        tableGameId: this.tableGame!._id,
        action: PlayerHandActionEnum.ALLIN,
        bet: betValue
      }
      this._gameGateway.playerAction(dto);
    } else {
      let dto = {
        tableGameId: this.tableGame!._id,
        action: PlayerHandActionEnum.RAISE,
        bet: betValue
      }
      this._gameGateway.playerAction(dto);
    }
  }

  allIn() {
    this.tableGame!.showActions = false;
    let playerIndex = this.tableGame!.players.findIndex((a: any) => a?.user?.email == this.authService.getAuthenticatedUser().email);
    let dto = {
      tableGameId: this.tableGame!._id,
      action: PlayerHandActionEnum.ALLIN,
      bet: this.tableGame!.players[playerIndex]!.coinAmount as number
    }
    this._gameGateway.playerAction(dto);
  }


  presence() {
    this.playersInViewOrder[0].status = PlayerStatusEnum.presence;
    this._gameGateway.requestPlayerPresence(this.tableGame!._id);
  }

  payBigBlind() {
    this._gameGateway.requestPayBigBlind(this.tableGame!._id);
  }

  getMinRaise() {
    let minRaise = 0;
    let biggerRaiseRound = this._getBiggerRaiseRound();
    let countRaise = this._getCountRaise();


    if (this.history[this.step]?.round == TableGameRoundStatusEnum.PRE_FLOP) {
      if (countRaise == 0) {
        minRaise = this.history[this.step].bigBlindValue * 2;
      } else {
        let secondBiggerRaiseRound = this._getSecondBiggerRaiseRound();
        minRaise = biggerRaiseRound + biggerRaiseRound - secondBiggerRaiseRound;
      }
    } else {
      if (countRaise == 0) {
        minRaise = this.history[this.step]!.bigBlindValue;
      } else if (countRaise == 1) {
        minRaise = biggerRaiseRound * 2;
      } else {
        let secondBiggerRaiseRound = this._getSecondBiggerRaiseRound();
        minRaise = biggerRaiseRound + biggerRaiseRound - secondBiggerRaiseRound;
      } //this.tableGame!
    }


    this.minRaiseValue = Number(minRaise.toFixed(2));
    this.form.patchValue({
      bet: this.minRaiseValue,
      valueRange: this.minRaiseValue,
      valueRangeBB: this.minRaiseValue / this.tableGame?.bigBlindValue!
    })
  }

  getSumBetPlayerWithoutBlinds(id: string) {
    let bet = 0;
    if (this.tableGame?.players) {
      for (let i = 0; i < this.tableGame.players.length; i++) {
        if (this.tableGame?.players[i]?.user?._id == id) {
          for (const action of this.tableGame?.players[i]?.playerHand?.actions!) {
            if (this.tableGame?.round == TableGameRoundStatusEnum.PRE_FLOP) {
              if (action?.round !== TableGameRoundStatusEnum.BLIND) {
                bet += action.bet;
              }
            } else {
              if (action.round == this.tableGame?.round) {
                bet += action.bet;
              }
            }
          }
        }
      }
    }
    return Number(bet.toFixed(2));
  }

  private _getBiggerRaiseRound() {
    let raiseArray: number[] = [];
    if (this.tableGame?.players) {
      for (const player of this.tableGame.players) {
        let playerActions;
        if (this.tableGame.round == TableGameRoundStatusEnum.PRE_FLOP) {
          playerActions = player?.playerHand?.actions.map((a: any) => a).filter((b: any) => b.round == TableGameRoundStatusEnum.PRE_FLOP || b.round == TableGameRoundStatusEnum.BLIND);
        } else {
          playerActions = player?.playerHand?.actions.map((a: any) => a).filter((b: any) => b.round == this.tableGame?.round);
        }
        const bets = playerActions?.map((a: any) => a.bet);
        const sumBet = bets?.reduce((sum: any, current: any) => sum + current, 0);
        raiseArray.push(sumBet);
      }
      return Math.max(...raiseArray.filter(a => a !== undefined));
    }
    return 0;
  }

  private _getSecondBiggerRaiseRound() {
    let raiseArray: number[] = [];
    if (this.tableGame?.players) {
      for (const player of this.tableGame.players) {
        let playerActions;
        if (this.tableGame.round == TableGameRoundStatusEnum.PRE_FLOP) {
          playerActions = player?.playerHand?.actions.filter((a: any) => a.round == TableGameRoundStatusEnum.PRE_FLOP || a.round == TableGameRoundStatusEnum.BLIND);
        } else {
          playerActions = player?.playerHand?.actions.filter((a: any) => a.round == this.tableGame?.round);
        }
        const bets = playerActions?.map((a: any) => a.bet);
        const sumBet = bets?.reduce((sum: any, current: any) => sum + current, 0);
        raiseArray.push(sumBet);
      }
      raiseArray.sort((a, b) => b - a);
      let arrayFilter = raiseArray.filter(a => a !== undefined);
      let filter = arrayFilter.filter((a: any) => a !== Math.max(...arrayFilter));
      return Math.max(...filter);
    }
    return 0;
  }

  getTopBetPlayer(): number {
    if (this.tableGame?.players) {
      let playerIndex = this.tableGame!.players.findIndex((a: any) => a?.user?.email == this.authService.getAuthenticatedUser().email);
      let result: number;
      if (this.tableGame.round == TableGameRoundStatusEnum.PRE_FLOP) {
        result = this.tableGame?.players[playerIndex]?.playerHand?.actions.filter((a: any) => a.round == TableGameRoundStatusEnum.PRE_FLOP || a.round == TableGameRoundStatusEnum.BLIND).map((a: any) => a.bet).reduce((sum: any, current: any) => sum + current, 0);
      } else {
        result = this.tableGame?.players[playerIndex]?.playerHand?.actions.filter((a: any) => a.round === this.tableGame?.round).map((a: any) => a.bet).reduce((sum: any, current: any) => sum + current, 0);
      }

      return result;
    } else {
      return 0
    }
  }

  private _getCountRaise() {
    let count = 0;
    if (this.tableGame?.players) {
      for (const player of this.tableGame.players) {
        const arrayActions = player.playerHand?.actions.filter((a: any) => a);
        if (arrayActions) {
          for (const action of arrayActions!) {
            if (action.round == this.tableGame.round && (action.action == PlayerHandActionEnum.RAISE || action.action == PlayerHandActionEnum.ALLIN)) {
              if (action.action == PlayerHandActionEnum.ALLIN) {
                if (action.bet > 0) {
                  count++;
                }
              } else {
                count++;
              }
            }
          }
        }
      }
    }
    return count;
  }

  getTopBetRound(): number {
    if (this.history[this.step]?.players) {
      const greaterBets: any[] = [];

      this.history[this.step].players.forEach((player: any) => {
        let betValues = [];
        if (this.history[this.step]?.round == TableGameRoundStatusEnum.PRE_FLOP) {
          betValues = player?.playerHand?.actions.filter((a: any) => a?.round == TableGameRoundStatusEnum.PRE_FLOP || a?.round == TableGameRoundStatusEnum.BLIND).map((a: any) => a.bet);
        } else {
          betValues = player?.playerHand?.actions.filter((a: any) => a?.round === this.history[this.step]?.round).map((a: any) => a.bet);
        }
        const betSum = betValues?.reduce((sum: any, current: any) => sum + current, 0);
        greaterBets.push({
          playerId: player._id,
          lastAction: player.playerHand?.lastAction,
          totalBets: betSum,
        });
      });

      return Math.max(...greaterBets.map(a => a.totalBets).filter(a => a !== undefined));
    } else {
      return 0
    }
  }

  private _getTopBetCurrentTurnPlayer(): number {
    if (this.history[this.step]?.players) {

      let index = this.history[this.step]?.players.findIndex((a: any) => a?.user?._id == this.history[this.step]?.currentTurn);
      let result: number;
      if (this.history[this.step].round == TableGameRoundStatusEnum.PRE_FLOP) {
        result = this.history[this.step]?.players[index]?.playerHand!.actions.filter((a: any) => a.round == TableGameRoundStatusEnum.PRE_FLOP || a.round == TableGameRoundStatusEnum.BLIND).map((a: any) => a.bet).reduce((sum: any, current: any) => sum + current, 0);
      } else {
        result = this.history[this.step]?.players[index]?.playerHand!.actions.filter((a: any) => a.round === this.history[this.step]?.round).map((a: any) => a.bet).reduce((sum: any, current: any) => sum + current, 0);
      }

      return result;
    } else {
      return 0
    }
  }

  clickShortcuts1() {
    let playerIndex = this.playersInViewOrder.findIndex((a: any) => a?.user?.email == this.authService.getAuthenticatedUser().email);
    if (this.history[this.step]?.round == TableGameRoundStatusEnum.PRE_FLOP) {
      if (+Number(this.history[this.step]?.bigBlindValue! * 2.5).toFixed(2) > this.playersInViewOrder[playerIndex].coinAmount! + this.betByPlayer(0)) {
        this.form.patchValue({
          bet: this.playersInViewOrder[playerIndex].coinAmount! + this.betByPlayer(0),
          valueRange: this.playersInViewOrder[playerIndex].coinAmount! + this.betByPlayer(0),
          valueRangeBB: (this.playersInViewOrder[playerIndex].coinAmount! + this.betByPlayer(0)) / this.history[this.step]?.bigBlindValue!
        });
      } else {
        this.form.patchValue({
          bet: +Number(this.history[this.step]?.bigBlindValue! * 2.5).toFixed(2),
          valueRange: +Number(this.history[this.step]?.bigBlindValue! * 2.5).toFixed(2),
          valueRangeBB: +Number((+Number(this.history[this.step]?.bigBlindValue! * 2.5).toFixed(2)) / this.history[this.step]?.bigBlindValue!)
        });
      }
    } else {
      if (+Number(this.history[this.step]?.pot! * 0.33).toFixed(2) > this.playersInViewOrder[playerIndex].coinAmount! + this.betByPlayer(0)) {
        this.form.patchValue({
          bet: this.playersInViewOrder[playerIndex].coinAmount! + this.betByPlayer(0),
          valueRange: this.playersInViewOrder[playerIndex].coinAmount! + this.betByPlayer(0),
          valueRangeBB: (this.playersInViewOrder[playerIndex].coinAmount! + this.betByPlayer(0)) / this.history[this.step]?.bigBlindValue!
        });
      } else {
        this.form.patchValue({
          bet: +Number(this.history[this.step]?.pot! * 0.33).toFixed(2),
          valueRange: +Number(this.history[this.step]?.pot! * 0.33).toFixed(2),
          valueRangeBB: +Number((+Number(this.history[this.step]?.pot! * 0.33).toFixed(2)) / this.history[this.step]?.bigBlindValue!)
        });
      }
    }
  }

  clickShortcuts2() {
    let playerIndex = this.playersInViewOrder.findIndex((a: any) => a?.user?.email == this.authService.getAuthenticatedUser().email);
    if (this.history[this.step]?.round == TableGameRoundStatusEnum.PRE_FLOP) {
      if (+Number(this.history[this.step]?.bigBlindValue! * 3).toFixed(2) > this.playersInViewOrder[playerIndex].coinAmount! + this.betByPlayer(0)) {
        this.form.patchValue({
          bet: this.playersInViewOrder[playerIndex].coinAmount! + this.betByPlayer(0),
          valueRange: this.playersInViewOrder[playerIndex].coinAmount! + this.betByPlayer(0),
          valueRangeBB: (this.playersInViewOrder[playerIndex].coinAmount! + this.betByPlayer(0)) / this.history[this.step]?.bigBlindValue!
        });
      } else {
        this.form.patchValue({
          bet: +Number(this.history[this.step]?.bigBlindValue! * 3).toFixed(2),
          valueRange: +Number(this.history[this.step]?.bigBlindValue! * 3).toFixed(2),
          valueRangeBB: +Number((+Number(this.history[this.step]?.bigBlindValue! * 3).toFixed(2)) / this.history[this.step]?.bigBlindValue!)
        });
      }
    } else {
      if (+Number(this.history[this.step]?.pot! * 0.5).toFixed(2) > this.playersInViewOrder[playerIndex].coinAmount! + this.betByPlayer(0)) {
        this.form.patchValue({
          bet: this.playersInViewOrder[playerIndex].coinAmount! + this.betByPlayer(0),
          valueRange: this.playersInViewOrder[playerIndex].coinAmount! + this.betByPlayer(0),
          valueRangeBB: (this.playersInViewOrder[playerIndex].coinAmount! + this.betByPlayer(0)) / this.history[this.step]?.bigBlindValue!
        });
      } else {
        this.form.patchValue({
          bet: +Number(this.history[this.step]?.pot! * 0.5).toFixed(2),
          valueRange: +Number(this.history[this.step]?.pot! * 0.5).toFixed(2),
          valueRangeBB: +Number((+Number(this.history[this.step]?.pot! * 0.5).toFixed(2)) / this.history[this.step]?.bigBlindValue!)
        });
      }
    }
  }

  clickShortcuts3() {
    let playerIndex = this.playersInViewOrder.findIndex((a: any) => a?.user?.email == this.authService.getAuthenticatedUser().email);
    if (this.history[this.step]?.round == TableGameRoundStatusEnum.PRE_FLOP) {
      if (+Number(this.history[this.step]?.bigBlindValue! * 3.5).toFixed(2) > this.playersInViewOrder[playerIndex].coinAmount! + this.betByPlayer(0)) {
        this.form.patchValue({
          bet: this.playersInViewOrder[playerIndex].coinAmount! + this.betByPlayer(0),
          valueRange: this.playersInViewOrder[playerIndex].coinAmount! + this.betByPlayer(0),
          valueRangeBB: (this.playersInViewOrder[playerIndex].coinAmount! + this.betByPlayer(0)) / this.history[this.step]?.bigBlindValue!
        });
      } else {
        this.form.patchValue({
          bet: +Number(this.history[this.step]?.bigBlindValue! * 3.5).toFixed(2),
          valueRange: +Number(this.history[this.step]?.bigBlindValue! * 3.5).toFixed(2),
          valueRangeBB: +Number((+Number(this.history[this.step]?.bigBlindValue! * 3.5).toFixed(2)) / this.history[this.step]?.bigBlindValue!)
        });
      }
    } else {
      if (+Number(this.history[this.step]?.pot! * 0.75).toFixed(2) > this.playersInViewOrder[playerIndex].coinAmount! + this.betByPlayer(0)) {
        this.form.patchValue({
          bet: this.playersInViewOrder[playerIndex].coinAmount! + this.betByPlayer(0),
          valueRange: this.playersInViewOrder[playerIndex].coinAmount! + this.betByPlayer(0),
          valueRangeBB: (this.playersInViewOrder[playerIndex].coinAmount! + this.betByPlayer(0)) / this.history[this.step]?.bigBlindValue!
        });
      } else {
        this.form.patchValue({
          bet: +Number(this.history[this.step]?.pot! * 0.75).toFixed(2),
          valueRange: +Number(this.history[this.step]?.pot! * 0.75).toFixed(2),
          valueRangeBB: +Number((+Number(this.history[this.step]?.pot! * 0.75).toFixed(2)) / this.history[this.step]?.bigBlindValue!)
        });
      }
    }
  }

 

  getPotValue(i: number) {
    if (this.history[this.step] && this.history[this.step].visualPots.length > i) {
      return this.history[this.step].visualPots[i].currentValue;
    }
    return 0;
  }

  animationSetChipsToPot(dto: AnimationSetChipsToPotResponseDto) {

    for (let i = 0; i < dto.playersId.length; i++) {
      let index = this.playersInViewOrder.findIndex((a: any) => a?.user?._id == dto.playersId[i]);

      if (this.betByPlayer(index) > 0) {
        let elementName = `animationChipsGoingToVisualPot${dto.visualPotIndex}Index${index}`;
        let animationName = `startAnimationChipsGoingToVisualPot${dto.visualPotIndex}Index${index}`;

        let element = document.getElementById(elementName);
        SoundUtil.play('../../assets/sounds/action-bet-raise-call-allin.m4a', 2);
        element?.classList.add(animationName);
        setTimeout(() => {
          element?.classList.remove(animationName);
        }, 1000);
      }

    }

  }

  showAnimationGiveChipsToWinners(dto: any) {
    AnimationGiveChipsToWinnersResponseDto
    let index = this.history[this.step].players.findIndex((a: any) => a?.user?._id == dto.user._id);

    if (index > -1) {
      let elementName = `animationChipsFromPot${index}ToPlayerIndex${index}`;
      let animationName = `startAnimationChipsFromPot${index}ToPlayerIndex${index}`;

      let element = document.getElementById(elementName);
      SoundUtil.play('../../assets/sounds/chips-to-winner.wav', 1);
      element?.classList.add(animationName);
      setTimeout(() => {
        element?.classList.remove(animationName);
      }, 1000);
      setTimeout(() => {
        this.stopAllActivity()
      }, 2000);
      
    }
  }

  getChipColor(value: number) {
    if (value > 0 && value < 101) {
      return '../../../../assets/game/fichas-svg/FICHA-BRANCA.svg';
    } else if (value > 100 && value < 500) {
      return '../../../../assets/game/fichas-svg/FICHA-VERMELHA.svg';
    } else if (value > 499 && value < 1000) {
      return '../../../../assets/game/fichas-svg/FICHA-AZUL.svg';
    } else if (value > 999 && value < 5000) {
      return '../../../../assets/game/fichas-svg/FICHA-AMARELA.svg';
    } else if (value > 4999 && value < 10000) {
      return '../../../../assets/game/fichas-svg/FICHA-VERDE.svg';
    } else if (value > 9999 && value < 100000) {
      return '../../../../assets/game/fichas-svg/FICHA-PRETA.svg';
    } else if (value > 99999) {
      return '../../../../assets/game/fichas-svg/FICHA-ROXA.svg';
    }
    return '../../../../assets/game/fichas-svg/FICHA-BRANCA.svg';
  }

  goHome() {
    const url = this.router.serializeUrl(this.router.createUrlTree(['/pages/home']));
    window.open(url, "_blank");
  }

  goWallet() {
    const url = this.router.serializeUrl(this.router.createUrlTree(['/pages/profile']));
    window.open(url, "_blank");
  }

}
