<nav *ngIf="!navBarCashgame(router.url)" class="navbar navbar-expand-xl navbar-light container-fluid absolute">
  <div class="container mt-3 p-0">
    <div>
      <a class="navbar-brand ps-3" [routerLink]="handlerLoggedBackground(router.url)? '/': '/pages/home' "><img
          src="../../../assets/icons/logo-text-white.svg" height="40px" /></a>
      <div *ngIf="!handlerLoggedBackground(router.url)" class="d-flex ps-3">
        <img src="../../../assets/icons/clock-header.svg" class="me-2" />
        <div class="hour-text">{{ formattedDateTime }}</div>
      </div>
      <div *ngIf="!handlerLoggedBackground(router.url)" class="d-flex ps-3">
        <img src="../../../assets/icons/people-header.svg" class="me-2" />
        <div class="hour-text">{{totalPlayersPlaying}}</div>
      </div>
    </div>


    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>


    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto d-flex justify-content-end mb-2 mb-lg-0 px-2">

        <li *ngIf="!handlerLoggedBackground(router.url) && this.authService.getAuthenticatedUser()?.nickname"
          class="nav-item"></li>

        <li class="nav-item dropdown d-flex  ms-3">
          <img *ngIf="selectLang == 'EN-US'" class="me-1" src="../../../assets/icons/en-us-icon.png" height="21px" />
          <img *ngIf="selectLang == 'PT-BR'" class="me-1" src="../../../assets/icons/pt-br-icon.svg" height="21px" />

          <div class="nav-link dropdown-toggle body-400-md" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
            aria-expanded="false">
            {{selectLang}}
          </div>
          <ul class="dropdown-menu position-absolute" aria-labelledby="navbarDropdown">
            <li class="cursor-pointer">
              <div class="dropdown-item body-400-md" (click)="changeLanguage('pt')">
                <img class="me-1" src="../../../assets/icons/pt-br-icon.svg" height="21px" />
                PT-BR
              </div>
            </li>
            <li class="cursor-pointer">
              <div class="dropdown-item body-400-md" (click)="changeLanguage('en')">
                <img class="me-1" src="../../../assets/icons/en-us-icon.png" height="21px" />
                EN-US
              </div>
            </li>

          </ul>
        </li>
        <li *ngIf="handlerLoggedBackground(router.url)" class="nav-item ms-3">
          <a class="nav-link menu-400-md cursor-pointer" (click)="openModalLogin()">{{ 'NAVBAR.MENU_ITEM_4' | translate
            }}</a>
        </li>
        <li *ngIf="handlerLoggedBackground(router.url)" class="nav-item ms-3">
          <a class="nav-link menu-400-md styled-button cursor-pointer px-4"
            [ngClass]="selectLang === 'PT-BR' ? 'buttonSingInPt': 'buttonSingInEn' " (click)="openModalSignUp()">{{
            'NAVBAR.MENU_ITEM_5' |
            translate }}</a>
        </li>
        <!-- NavBar Desktop -->
        <div class="d-flex align-items-center navbar-desktop">
          <li *ngIf="!handlerLoggedBackground(router.url)" class="nav-item pb-3 ms-3 pb-xl-0 mt-xl-0">
            <p class="text-start subtitle-700-md color-darkgray">Rakeback</p>
            <p class="text-start subtitle-700-md color-darkgray mt-2 mb-1"><img
                src="../../../assets/icons/coin-pokerfi.svg" class="me-2"
                alt="">{{balanceValue?.pokerfiRakeBackPoints?.toFixed(2)}}</p>
            <p class="text-start subtitle-700-md color-darkgray"><img src="../../../assets/icons/usdt-logo.svg"
                class="me-2" alt="">{{balanceValue?.usdRakeBackPoints?.toFixed(2)}}</p>
          </li>

          <li *ngIf="!handlerLoggedBackground(router.url)" class="nav-item pb-3 ms-3 pb-xl-0 mt-xl-0">
            <div class="d-flex justify-content-center align-items-center">
              <div (click)="showBalance()" class="cursor-pointer mt-3">
                <span class="heading-500-sm fw-700 text-white cursor-pointer">Cashier</span>
                <p *ngIf="balance && this.authService.getAuthenticatedUser()?.nickname"
                  class="text-start body-300-md text-white mt-2">●●●●●●●●</p>
                <p *ngIf="myBalance && this.authService.getAuthenticatedUser()?.nickname"
                  class="text-start subtitle-700-md mt-2 mb-1" style="color: #F7C100;">PKF:
                  {{balanceValue?.coinPokerFiValue?.toFixed(2)}}</p>
                <p *ngIf="balance && this.authService.getAuthenticatedUser()?.nickname"
                  class="text-start body-300-md text-white">●●●●●●●●</p>
                <p *ngIf="myBalance && this.authService.getAuthenticatedUser()?.nickname"
                  class="text-start subtitle-700-md" style="color: #00ca54;">USDT:
                  {{balanceValue?.coinUsdValue?.toFixed(2)}}</p>

              </div>
              <div class="btn-group dropstart ps-2">
                <div class="profile-pictures btn btn-secondary dropdown-toggle image-profile cursor-pointer"
                  data-bs-toggle="dropdown" aria-expanded="false">
                  <div *ngIf="authService.getAuthenticatedUser()?.profilePicture">
                    <img [src]="authService.getAuthenticatedUser()?.profilePicture" class="image-profile-navbar">
                  </div>
                  <div
                    *ngIf="!authService.getAuthenticatedUser()?.profilePicture && authService.getAuthenticatedUser()?.nickname"
                    class="without-profile-navbar text-capitalize">
                    {{authService.getAuthenticatedUser()?.nickname[0]}}
                  </div>
                </div>
                <ul class="dropdown-menu cursor-pointer">
                  <div *ngIf="this.authService.getAuthenticatedUser()?.nickname" [routerLink]="['/pages/home']"
                    class="d-flex justify-content-between">
                    <li><a class="dropdown-item text-white"><img src="../../../assets/icons/lobby.svg"
                          class="me-2">{{ 'NAVBAR.LOBBY' | translate }}
                      </a></li>
                    <img src="../../../assets/icons/icon-arrow-right.svg" class="me-3">
                  </div>
                  <li>
                    <hr class="dropdown-divider background-divider-dropdown">
                  </li>
                  <div *ngIf="this.authService.getAuthenticatedUser()?.nickname" [routerLink]="['/pages/profile']"
                    class="d-flex justify-content-between">
                    <li><a class="dropdown-item text-white"><img src="../../../assets/icons/icon-profile.svg"
                          class="me-2">{{ 'NAVBAR.WALLET' | translate }}
                      </a></li>
                    <img src="../../../assets/icons/icon-arrow-right.svg" class="me-3">
                  </div>
                  <li>
                    <hr class="dropdown-divider background-divider-dropdown">
                  </li>
                  <div *ngIf="this.authService.getAuthenticatedUser()?.nickname" (click)="openModalBuyCoin()"
                    class="d-flex justify-content-between">
                    <li><a class="dropdown-item text-white"><img src="../../../assets/icons/coins.svg" class="me-2">{{
                        'NAVBAR.BUY_COIN' | translate }}
                      </a></li>
                    <img src="../../../assets/icons/icon-arrow-right.svg" class="me-3">
                  </div>
                  <li>
                    <hr class="dropdown-divider background-divider-dropdown">
                  </li>
                  <!-- <div *ngIf="this.authService.getAuthenticatedUser()?.nickname" [routerLink]="['/pages/settings']"
                    class="d-flex justify-content-between">
                    <li><a class="dropdown-item text-white"><img src="../../../assets/icons/config.svg" class="me-2">{{
                        'NAVBAR.CONFIG' | translate }}
                      </a></li>
                    <img src="../../../assets/icons/icon-arrow-right.svg" class="me-3">
                  </div>
                  <li>
                    <hr class="dropdown-divider background-divider-dropdown">
                  </li> -->
                  <div class="d-flex justify-content-between" (click)="logOut()">
                    <li ><a class="dropdown-item text-white"><img
                          src="../../../assets/icons/icon-logout.svg" class="me-2">{{ 'NAVBAR.LOGOUT' | translate }}
                      </a></li>
                    <img src="../../../assets/icons/icon-arrow-right.svg" class="me-3">
                  </div>
                </ul>
              </div>
            </div>
            <div class="text-white">
              <p *ngIf="this.authService.getAuthenticatedUser()?.nickname" class="text-center nickname-navbar"
                style="margin-right: 15px;">{{this.authService.getAuthenticatedUser()?.nickname}}</p>
            </div>
          </li>
        </div>

        <!-- NavBar mobile -->
        <div *ngIf="!handlerLoggedBackground(router.url)" class="d-flex align-items-center navbar-mobile">
          <div>
            <div class="btn-group dropend ps-2">
              <div
                class="profile-pictures btn btn-secondary dropdown-toggle dropdown-toggle-split image-profile cursor-pointer"
                data-bs-toggle="dropdown" aria-expanded="false">
                <div *ngIf="authService.getAuthenticatedUser()?.profilePicture">
                  <img [src]="authService.getAuthenticatedUser()?.profilePicture" class="image-profile-navbar">
                </div>
                <div
                  *ngIf="!authService.getAuthenticatedUser()?.profilePicture && authService.getAuthenticatedUser()?.nickname"
                  class="without-profile-navbar text-capitalize">
                  {{authService.getAuthenticatedUser()?.nickname[0]}}
                </div>
              </div>
              <ul class="dropdown-menu cursor-pointer">
                <div *ngIf="this.authService.getAuthenticatedUser()?.nickname" [routerLink]="['/pages/home']"
                  class="d-flex justify-content-between">
                  <li><a class="dropdown-item text-white"><img src="../../../assets/icons/lobby.svg" class="me-2">Lobby
                    </a></li>
                  <img src="../../../assets/icons/icon-arrow-right.svg" class="me-3">
                </div>
                <li>
                  <hr class="dropdown-divider background-divider-dropdown">
                </li>
                <div *ngIf="this.authService.getAuthenticatedUser()?.nickname" [routerLink]="['/pages/profile']"
                  class="d-flex justify-content-between">
                  <li><a class="dropdown-item text-white"><img src="../../../assets/icons/icon-profile.svg"
                        class="me-2">{{ 'NAVBAR.WALLET' | translate }}
                    </a></li>
                  <img src="../../../assets/icons/icon-arrow-right.svg" class="me-3">
                </div>
                <li>
                  <hr class="dropdown-divider background-divider-dropdown">
                </li>
                <div *ngIf="this.authService.getAuthenticatedUser()?.nickname" (click)="openModalBuyCoin()"
                  class="d-flex justify-content-between">
                  <li><a class="dropdown-item text-white"><img src="../../../assets/icons/coins.svg" class="me-2">{{
                      'NAVBAR.BUY_COIN' | translate }}
                    </a></li>
                  <img src="../../../assets/icons/icon-arrow-right.svg" class="me-3">
                </div>
                <li>
                  <hr class="dropdown-divider background-divider-dropdown">
                </li>
                <div *ngIf="this.authService.getAuthenticatedUser()?.nickname" [routerLink]="['/pages/settings']"
                  class="d-flex justify-content-between">
                  <li><a class="dropdown-item text-white"><img src="../../../assets/icons/config.svg" class="me-2">{{
                      'NAVBAR.CONFIG' | translate }}
                    </a></li>
                  <img src="../../../assets/icons/icon-arrow-right.svg" class="me-3">
                </div>
                <li>
                  <hr class="dropdown-divider background-divider-dropdown">
                </li>
                <div class="d-flex justify-content-between">
                  <li (click)="logOut()"><a class="dropdown-item text-white"><img
                        src="../../../assets/icons/icon-logout.svg" class="me-2">{{ 'NAVBAR.LOGOUT' | translate }}
                    </a></li>
                  <img src="../../../assets/icons/icon-arrow-right.svg" class="me-3">
                </div>
              </ul>
            </div>
            <div class="d-flex justify-content-center text-white">
              <p *ngIf="this.authService.getAuthenticatedUser()?.nickname" class="nickname-navbar">
                {{this.authService.getAuthenticatedUser()?.nickname}}</p>
            </div>
          </div>

          <li *ngIf="!handlerLoggedBackground(router.url)" class="nav-item pb-3 ms-3 pb-xl-0 mt-xl-0">
            <div class="d-flex justify-content-center align-items-center">
              <div (click)="showBalance()" class="cursor-pointer mt-2">
                <span class="heading-500-sm fw-700 text-white cursor-pointer">Cashier</span>
                <p *ngIf="balance && this.authService.getAuthenticatedUser()?.nickname"
                  class="text-start body-300-md text-white mt-2">●●●●●●●●</p>
                <p *ngIf="myBalance && this.authService.getAuthenticatedUser()?.nickname"
                  class="text-start subtitle-700-md mt-2 mb-1" style="color: #F7C100;">PKF:
                  {{balanceValue?.coinPokerFiValue?.toFixed(2)}}</p>
                <p *ngIf="balance && this.authService.getAuthenticatedUser()?.nickname"
                  class="text-start body-300-md text-white">●●●●●●●●</p>
                <p *ngIf="myBalance && this.authService.getAuthenticatedUser()?.nickname"
                  class="text-start subtitle-700-md" style="color: #00ca54;">USDT:
                  {{balanceValue?.coinUsdValue?.toFixed(2)}}</p>

              </div>
            </div>
          </li>

          <li *ngIf="!handlerLoggedBackground(router.url)" class="nav-item pb-3 ms-3 pb-xl-0 mt-3 mt-xl-0">
            <p class="text-start subtitle-700-md color-darkgray">Rakeback</p>
            <p class="text-start subtitle-700-md color-darkgray mt-2 mb-1"><img
                src="../../../assets/icons/coin-pokerfi.svg" class="me-2"
                alt="">{{balanceValue?.pokerfiRakeBackPoints?.toFixed(2)}}</p>
            <p class="text-start subtitle-700-md color-darkgray"><img src="../../../assets/icons/usdt-logo.svg"
                class="me-2" alt="">{{balanceValue?.usdRakeBackPoints?.toFixed(2)}}</p>
          </li>
        </div>
      </ul>
    </div>
  </div>



</nav>

<!-- MODAL Sem KYC -->
<ng-template #modalKyc>
  <section class="background-modal p-3">
    <div (click)="exit()" class="d-flex justify-content-end cursor-pointer">
      <img src="../../../../assets/icons/icon-x.svg" />
    </div>
    <div class="d-flex flex-column justify-content-center align-items-center">
      <h6 class="heading-700-sm text-white">{{ 'PROFILE.MODAL_REQUEST.KYC' | translate }}</h6>
      <h2 class="heading-500-sm text-white text-center">{{ 'PROFILE.MODAL_REQUEST.TEXT_MODAL_KYC' | translate }}</h2>
    </div>
  </section>
</ng-template>

<!-- NavBar do Cashgame -->
<!-- <nav *ngIf="navBarCashgame(router.url)" class="navbar navbar-expand-lg background-navbar-cashgame">
  <div class="container-fluid">
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <a (click)="changeStar()" class="nav-link cursor-pointer">
            <svg *ngIf="fillStar" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#F3C41F"
              class="bi bi-star" viewBox="0 0 16 16">
              <path
                d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z" />
            </svg>
            <svg *ngIf="!fillStar" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#F3C41F"
              class="bi bi-star-fill" viewBox="0 0 16 16">
              <path
                d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
            </svg>
          </a>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle button-700" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            {{'NAVBAR.TABLE' | translate}}
          </a>
          <ul class="dropdown-menu">
            <li><a class="dropdown-item button-700 cursor-pointer" (click)="leavePokerGame()">{{'NAVBAR.LEAVE_GAME' |
                translate}}</a></li>
          </ul>
        </li>
        <li class="nav-item">
          <a class="nav-link"><img src="../../../assets/icons/icon-reload.svg"></a>
        </li>
        <li class="nav-item">
        </li>
        <li class="nav-item">
        </li> -->
<!-- <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle button-700" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Layout
          </a>
          <ul class="dropdown-menu">
            <li>
              <div class="d-flex justify-content-between cursor-pointer background-hover px-2">
                <p class="body-400-lg">{{'NAVBAR.RESET_DEFAULT_SIZE' | translate}}</p>
                <p class="overline-700">Ctrl+B</p>
              </div>
            </li>
            <hr class="dropdown-divider background-divider-dropdown">
            <li>
              <div class="d-flex justify-content-between cursor-pointer background-hover px-2">
                <p class="body-400-lg">{{'NAVBAR.FIT_TABLES' | translate}}</p>
                <p class="overline-700">Ctrl+9</p>
              </div>
            </li>
            <hr class="dropdown-divider background-divider-dropdown">
            <li>
              <div class="d-flex justify-content-between cursor-pointer background-hover px-2">
                <p class="body-400-lg">{{'NAVBAR.OVERLAP_TABLES' | translate}}</p>
                <p class="overline-700">Ctrl+0</p>
              </div>
            </li>
            <hr class="dropdown-divider background-divider-dropdown">
            <li>
              <div class="d-flex justify-content-between cursor-pointer background-hover px-2">
                <p class="body-400-lg">{{'NAVBAR.GROUP_TABLES' | translate}}</p>
                <p class="overline-700">Ctrl+8</p>
              </div>
            </li>
            <hr class="dropdown-divider background-divider-dropdown">
            <li>
              <div class="d-flex justify-content-between cursor-pointer background-hover px-2">
                <p class="body-400-lg">{{'NAVBAR.APPLY_ACTIVE_LAYOUT' | translate}}</p>
                <p class="overline-700">Ctrl+3</p>
              </div>
            </li>
            <hr class="dropdown-divider background-divider-dropdown">
            <li>
              <p class="body-400-lg px-2 cursor-pointer background-hover">{{'NAVBAR.MANAGE_LAYOUT' | translate}}</p>
            </li>
            <hr class="dropdown-divider background-divider-dropdown">
            <li>
              <p class="body-400-lg px-2 cursor-pointer background-hover">{{'NAVBAR.REMEMBER_TABLE_POSITIONING' |
                translate}}</p>
            </li>
          </ul>
        </li> -->
<!-- <li></li>
      </ul>
    </div>
  </div>
</nav> -->