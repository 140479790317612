import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MovementService extends BaseService {

  url: string = `${this.gameBackoffice}movement`;

  constructor(
    private httpClient: HttpClient,
  ) {
    super();
  }

  listByPlayerId() {
    return this.httpClient.get(`${this.url}/list-by-playerId`, this.authorizedHeader);
  }

}
