import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { CodeInputModule } from 'angular-code-input';
import { QRCodeModule } from 'angularx-qrcode';
import { NgChartsModule } from 'ng2-charts';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { NgxSpinnerModule } from 'ngx-spinner';
import { TfaService } from 'src/services/tfa.service';
import { AppComponent } from '../app.component';
import { ChatComponent } from '../components/chat/chat.component';
import { HandImageComponent } from '../components/hands/hand-image/hand-image.component';
import { HandsComponent } from '../components/hands/hands.component';
import { NotesComponent } from '../components/notes/notes.component';
import { SharedModule } from '../shared/shared.module';
import { HomePageComponent } from './home-page/home-page.component';
import { ModalTournamentComponent } from './home-page/modal-tournament/modal-tournament.component';
import { TableCashGameComponent } from './home-page/table-cash-game/table-cash-game.component';
import { TableGameReplayComponent } from './home-page/table-game-replay/table-game-replay.component';
import { LobbyTournamentComponent } from './lobby-tournament/lobby-tournament.component';
import { LoggedComponent } from './logged.component';
import { LoggedRouteModule } from './logged.route';
import { PlayCashgameComponent } from './play-cashgame/play-cashgame.component';
import { ModalBuyCoinComponent } from './profile/modal-buy-coin/modal-buy-coin.component';
import { ModalDeleteAccountComponent } from './profile/modal-delete-account/modal-delete-account.component';
import { ModalPixPaymentComponent } from './profile/modal-pix-payment/modal-pix-payment.component';
import { ModalRequestWithdrawalComponent } from './profile/modal-request-withdrawal/modal-request-withdrawal.component';
import { ModalSecurityComponent } from './profile/modal-security/modal-security.component';
import { ModalWithdrawRakebackPointsComponent } from './profile/modal-withdraw-rakeback-points/modal-withdraw-rakeback-points.component';
import { ProfileComponent } from './profile/profile.component';
import { SettingsComponent } from './settings/settings.component';
import { TableGameComponent } from './table-game/table-game.component';
import { TesteComponent } from './teste/teste.component';
import { TournamentInfoComponent } from './tournament-info/tournament-info.component';

export const options: Partial<null | IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [
    LoggedComponent,
    ProfileComponent,
    HomePageComponent,
    LobbyTournamentComponent,
    ModalSecurityComponent,
    ModalDeleteAccountComponent,
    ModalTournamentComponent,
    ModalBuyCoinComponent,
    ModalPixPaymentComponent,
    ModalRequestWithdrawalComponent,
    TournamentInfoComponent,
    PlayCashgameComponent,
    TableCashGameComponent,
    TableGameComponent,
    TesteComponent,
    ModalWithdrawRakebackPointsComponent,
    SettingsComponent,
    ChatComponent,
    TableGameReplayComponent,
    HandsComponent,
    HandImageComponent,
    NotesComponent

  ],
  imports: [
    CommonModule,
    LoggedRouteModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    NgChartsModule,
    NgxMaskModule.forRoot(),
    QRCodeModule,
    CodeInputModule,
    NgxSpinnerModule,
    SharedModule,
    CurrencyMaskModule
  ],
  providers: [TfaService],
  schemas: [],
  bootstrap: [AppComponent],
  exports: [],
})
export class LoggedModule { }
