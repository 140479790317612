import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/services/user.service';

@Component({
  selector: 'app-verication-code',
  templateUrl: './verication-code.component.html',
  styleUrls: ['./verication-code.component.scss'],
})
export class VericationCodeComponent implements OnInit {
  form: FormGroup;

  email: string = '';

  constructor(
    public translateService: TranslateService,
    private toastrService: ToastrService,
    private userService: UserService,
    private fb: FormBuilder,
    private router: Router,
    private ngxSpinnerService: NgxSpinnerService
  ) {
    this.form = this.fb.group({
      code: ['', [Validators.required, Validators.minLength(4)]],
    });

    // translate.addLangs(['en', 'pt', 'es']);
    // translate.setDefaultLang('en');
    // translate.use('en');
  }

  ngOnInit(): void {
    this.email = localStorage.getItem('email') || '';
  }

  resendEmail() {
    // this.userService.resendEmail(this.email).subscribe(
    //   () => {},
    //   (error) => this.runError(error)
    // );
  }

  submit() {
    if (this.form.pristine || !this.form.valid) {
      return;
    }
    this.ngxSpinnerService.show();
    // this.userService
    //   .vericationCode(this.form.value?.code, this.email)
    //   .subscribe(
    //     (data) => this.runUserRegisterSuccess(data.isValid),
    //     (error) => this.runError(error)
    //   );
  }

  // this.translateService.get('TOASTR.USER_REGISTERED_SUCCESSFULLY').subscribe({
  //   next: (data) => {
  //     const toastr = this.toastrService.success(data, '', {
  //       progressBar: true,
  //       timeOut: 3000,
  //     });
  //     localStorage.setItem('email', this.form.value.email);

  //     if (toastr) {
  //       toastr.onHidden.subscribe(() => {});
  //     }
  //   },
  // });

  runUserRegisterSuccess(data: boolean): void {
    this.ngxSpinnerService.hide();
    if (data) {
      localStorage.setItem('code', this.form.value?.code);
      localStorage.setItem('email', this.email);
      this.router.navigate(['/password']);

      this.translateService.get('TOASTR.CODE_VERIFIED_SUCCESSFULLY').subscribe({
        next: (data) => {
          const toastr = this.toastrService.success(data, '', {
            progressBar: true,
            timeOut: 2500,
          });
          if (toastr) {
            toastr.onHidden.subscribe(() => {});
          }
        },
      });
    } else {
      this.translateService.get('TOASTR.CODE_INVALID').subscribe({
        next: (data) => {
          const toastr = this.toastrService.error(data, '', {
            progressBar: true,
          });
          if (toastr) {
            toastr.onHidden.subscribe(() => {});
          }
        },
      });
    }
  }

  runError(exception: any): void {
    this.ngxSpinnerService.hide();
    const toastr = this.toastrService.error(exception.error.errors[0], '', {
      progressBar: true,
    });

    if (toastr) {
      toastr.onHidden.subscribe(() => {});
    }
  }
}
