import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { AuthService } from "src/services/auth.service";

@Injectable({
  providedIn: "root",
})
export class TwofaGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private readonly router: Router
  ) { }

  async canActivate() {
    const user = this.authService.getAuthenticatedUser();

    if (!!user) {
      try {

        if(!user?.nickname) {
          this.router.navigate(['/first-access']);
          return false;
        }

        if (!this.authService.isJwtValid()) {
          localStorage.removeItem('user');
          this.router.navigate(['/']);
          return false;
        }
        return true;
      } catch (err) {
        return false;
      }
    }
    return false;
  }
}
