import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { TransferToHotWalletRequestDto } from 'src/app/dtos/wallet/transfer-to-hotwallet-request-dto';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WalletService extends BaseService {

  url: string = `${this.wallet}`;

  constructor(
    private httpClient: HttpClient,
  ) {
    super();
  }

  getWalletBalance() {
    return this.httpClient.get<any>(`${this.url}user/balance`, this.authorizedHeader);
  }

  transferToHotwallet(dto: TransferToHotWalletRequestDto) {
    return this.httpClient.post(`${this.wallet}token/transfer-to`, dto, this.authorizedHeader);
  }

  getPricePair(from: string, to: string) {
    return this.httpClient.get<any>(`${this.url}coin-gecko/get-zicoin-market-data/from/${from}/to/${to}`, this.authorizedHeader).pipe(map(this.extractData));
  }

  get2fa() {
    return this.httpClient.get<any>(`${this.url}2fa`, this.authorizedHeader).pipe(map(this.extractData));
  }


}
