<!-- Mesa para 2 jogadores -->
<div *ngIf="players.length <= 2" class="background-table-game">
  <div class="text-center">
    <div class="row players-2">
      <div class="col d-flex justify-content-start">
        <div class="text-white radius-player">{{(!!players[0] ? players[0].nickname! : '') | initialcapitalletter}}</div>
      </div>
      <div class="col d-flex justify-content-end">
        <div class="text-white radius-player">{{ (!!players[1] ? players[1].nickname! : '') | initialcapitalletter }}</div>
      </div>
    </div>
  </div>
</div>

<!-- Mesa para 4 jogadores -->
<div *ngIf="players.length > 2 && players.length <= 4" class="background-table-game mt-5">
  <div class="text-center">
    <div class="row players-4-row-1">
      <div class="col d-flex justify-content-start">
        <div class="text-white radius-player">{{players[0].nickname! | initialcapitalletter}}</div>
      </div>
      <div class="col d-flex justify-content-end">
        <div class="text-white radius-player">{{players[1].nickname! | initialcapitalletter}}</div>
      </div>
    </div>

    <div class="row players-4-row-2">
      <div class="col d-flex justify-content-start">
        <div class="text-white radius-player">{{players[2].nickname! | initialcapitalletter}}</div>
      </div>
      <div class="col d-flex justify-content-end">
        <div class="text-white radius-player">{{(!!players[3] ? players[3].nickname! : '') | initialcapitalletter}}</div>
      </div>
    </div>
  </div>
</div>

<!-- Mesa para 6 jogadores -->
<div *ngIf="players.length > 4 && players.length <= 6" class="background-table-game mt-5">
  <div class="text-center">
    <div class="row">
      <div class="col d-flex justify-content-center">
        <div class="text-white radius-player">{{players[0].nickname! | initialcapitalletter}}</div>
      </div>
      <div class="col d-flex justify-content-center">
        <div class="text-white radius-player">{{players[1].nickname! | initialcapitalletter}}</div>
      </div>
    </div>

    <div class="row py-2">
      <div class="col d-flex justify-content-center me-5">
        <div class="text-white radius-player">{{players[2].nickname! | initialcapitalletter}}</div>
      </div>
      <div class="col"></div>
      <div class="col"></div>
      <div class="col d-flex justify-content-center ms-5">
        <div class="text-white radius-player">{{players[3].nickname! | initialcapitalletter}}</div>
      </div>
    </div>

    <div class="row">
      <div class="col d-flex justify-content-center">
        <div class="text-white radius-player">{{players[4].nickname! | initialcapitalletter}}</div>
      </div>
      <div class="col d-flex justify-content-center">
        <div class="text-white radius-player">{{(!!players[5] ? players[5].nickname! : '') | initialcapitalletter}}</div>
      </div>
    </div>
  </div>
</div>

<!-- Mesa para 7 jogadores -->
<div *ngIf="players.length === 7" class="background-table-game mt-5">
  <div class="text-center">
    <div class="row">
      <div class="col d-flex justify-content-center">
        <div class="text-white radius-player" style="margin-top: -10px;">{{players[0].nickname! | initialcapitalletter}}</div>
      </div>
      <div class="col d-flex justify-content-center">
        <div class="text-white radius-player" style="margin-top: -10px;">{{players[1].nickname! | initialcapitalletter}}</div>
      </div>
    </div>

    <div class="row pt-3">
      <div class="col d-flex justify-content-center me-5">
        <div class="text-white radius-player">{{players[2].nickname! | initialcapitalletter}}</div>
      </div>
      <div class="col"></div>
      <div class="col"></div>
      <div class="col d-flex justify-content-center ms-5">
        <div class="text-white radius-player">{{players[3].nickname! | initialcapitalletter}}</div>
      </div>
    </div>

    <div class="row pt-3">
      <div class="col d-flex justify-content-center">
        <div class="text-white radius-player">{{players[4].nickname! | initialcapitalletter}}</div>
      </div>
      <div class="col d-flex justify-content-center pt-2">
        <div class="text-white radius-player">{{players[5].nickname! | initialcapitalletter}}</div>
      </div>
      <div class="col d-flex justify-content-center">
        <div class="text-white radius-player">{{players[6].nickname! | initialcapitalletter}}</div>
      </div>
    </div>
  </div>
</div>

<!-- Mesa para 8 jogadores -->
<div *ngIf="players.length === 8" class="background-table-game mt-5">
  <div class="text-center">
    <div class="row">
      <div class="col d-flex justify-content-center">
        <div class="text-white radius-player" style="margin-top: -10px;">{{players[0].nickname! | initialcapitalletter}}</div>
      </div>
      <div class="col d-flex justify-content-center">
        <div class="text-white radius-player" style="margin-top: -10px;">{{players[1].nickname! | initialcapitalletter}}</div>
      </div>
    </div>

    <div class="row">
      <div class="col d-flex justify-content-center me-5">
        <div class="text-white radius-player">{{players[2].nickname! | initialcapitalletter}}</div>
      </div>
      <div class="col"></div>
      <div class="col"></div>
      <div class="col d-flex justify-content-center ms-5">
        <div class="text-white radius-player">{{players[3].nickname! | initialcapitalletter}}</div>
      </div>
    </div>

    <div class="row pt-2">
      <div class="col d-flex justify-content-center me-5">
        <div class="text-white radius-player">{{players[4].nickname! | initialcapitalletter}}</div>
      </div>
      <div class="col"></div>
      <div class="col"></div>
      <div class="col d-flex justify-content-center ms-5">
        <div class="text-white radius-player">{{players[5].nickname! | initialcapitalletter}}</div>
      </div>
    </div>

    <div class="row">
      <div class="col d-flex justify-content-center">
        <div class="text-white radius-player">{{players[6].nickname! | initialcapitalletter}}</div>
      </div>
      <div class="col d-flex justify-content-center">
        <div class="text-white radius-player">{{players[7].nickname! | initialcapitalletter}}</div>
      </div>
    </div>
  </div>
</div>

<!-- Mesa para 9 jogadores -->
<div *ngIf="players.length === 9" class="background-table-game mt-5">
  <div class="text-center">
    <div class="row">
      <div class="col d-flex justify-content-center">
        <div class="text-white radius-player" style="margin-top: -10px;">{{players[0].nickname! | initialcapitalletter}}</div>
      </div>
      <div class="col d-flex justify-content-center">
        <div class="text-white radius-player" style="margin-top: -10px;">{{players[1].nickname! | initialcapitalletter}}</div>
      </div>
    </div>

    <div class="row">
      <div class="col d-flex justify-content-center me-5">
        <div class="text-white radius-player">{{players[2].nickname! | initialcapitalletter}}</div>
      </div>
      <div class="col"></div>
      <div class="col"></div>
      <div class="col d-flex justify-content-center ms-5">
        <div class="text-white radius-player">{{players[3].nickname! | initialcapitalletter}}</div>
      </div>
    </div>

    <div class="row pt-2">
      <div class="col d-flex justify-content-center me-5">
        <div class="text-white radius-player">{{players[4].nickname! | initialcapitalletter}}</div>
      </div>
      <div class="col"></div>
      <div class="col"></div>
      <div class="col d-flex justify-content-center ms-5">
        <div class="text-white radius-player">{{players[5].nickname! | initialcapitalletter}}</div>
      </div>
    </div>

    <div class="row">
      <div class="col d-flex justify-content-end">
        <div class="text-white radius-player">{{players[6].nickname! | initialcapitalletter}}</div>
      </div>
      <div class="col d-flex justify-content-center pt-2">
        <div class="text-white radius-player">{{players[7].nickname! | initialcapitalletter}}</div>
      </div>
      <div class="col d-flex justify-content-start">
        <div class="text-white radius-player">{{players[8].nickname! | initialcapitalletter}}</div>
      </div>
    </div>
  </div>
</div>
