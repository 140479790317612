<div *ngIf="!navBarCashgame(router.url)"
  class="container d-flex flex-lg-row flex-column justify-content-between align-items-center background-footer px-0">
  <div class="font-color-highlight heading-500-md pt-2"> PokerFi Serviços Digitais LTDA<span class="heading-500-md font-color">
      {{ 'FOOTER.TITLE' | translate }}</span>
  </div>

  <div class="d-flex pt-2">
    <!-- Telegram -->
    <a href="https://t.me/PokerFiToken" target="_blank">
      <img target class="cursor-pointer" src="../../../assets/icons/telegram-icon.svg" alt="telegram icon" />
    </a>
    <a href="https://discord.com/invite/SbrPXj4Hdw" target="_blank">
      <img target class="cursor-pointer" src="../../../assets/icons/discord-icon.svg" alt="discord icon" />
    </a>
    <a href="https://twitter.com/FinancePoker" target="_blank">
      <img class="cursor-pointer" src="../../../assets/icons/twitter-icon.svg" alt="twitter icon" />
    </a>
    <a href="https://www.instagram.com/pokerfinance/" target="_blank">
      <img class="cursor-pointer" src="../../../assets/icons/instagram-icon.svg" alt="instagram icon" />
    </a>
    <a href="https://www.facebook.com/profile.php?id=100059933972119" target="_blank">
      <img class="cursor-pointer" src="../../../assets/icons/facebook-icon.svg" alt="facebook icon" />
    </a>
 
  </div>
</div>
