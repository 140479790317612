<section class="bg-dark">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 mt-3">
        <div class="card h-368">
          <div class="d-flex justify-content-between align-items-center p-3">
            <p class="title-tournament-info">{{ 'TOURNAMENT_INFO.TITLE1' | translate }}</p>
            <p class="text-white subtitle-700-lg">{{ 'TOURNAMENT_INFO.SUBTITLE' | translate }}</p>
          </div>
          <div class="p-0 m-0 mt-1 div-table">
            <table class="table table-dark table-striped text-white">
              <thead>
                <tr>
                  <th scope="col">Level</th>
                  <th scope="col">Blinds</th>
                  <th scope="col">Ante</th>
                  <th scope="col">Time Bank</th>
                  <th scope="col">{{ 'TOURNAMENT_INFO.TABLE5' | translate }}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let blind of blinds; let i = index">
                  <td class="py-2">{{i+1}}</td>
                  <td>{{i*5}}/{{i*10}}</td>
                  <td>{{i*2}}</td>
                  <td>45sec</td>
                  <td>10</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="card p-3 mt-3">
          <div class="align-items-center mb-4">
            <p class="title-tournament-info">{{ 'TOURNAMENT_INFO.TITLE2' | translate }}</p>
          </div>
          <div class="row text-white">
            <div class="col-md-3">
              <p class="subtitle-700-lg">{{ 'TOURNAMENT_INFO.TEXT_LEFT1' | translate }}</p>
            </div>
            <div class="col-md-9">
              <p class="body-400-lg">{{ 'TOURNAMENT_INFO.TEXT_RIGHT1' | translate }}</p>
            </div>
          </div>
          <div class="row text-white my-3">
            <div class="col-md-3">
              <p class="subtitle-700-lg">{{ 'TOURNAMENT_INFO.TEXT_LEFT2' | translate }}</p>
            </div>
            <div class="col-md-9">
              <p class="body-400-lg">{{ 'TOURNAMENT_INFO.TEXT_RIGHT2' | translate }}</p>
            </div>
          </div>
          <div class="row text-white">
            <div class="col-md-3">
              <p class="subtitle-700-lg">{{ 'TOURNAMENT_INFO.TEXT_LEFT3' | translate }}</p>
            </div>
            <div class="col-md-9">
              <p class="body-400-lg">{{ 'TOURNAMENT_INFO.TEXT_RIGHT3' | translate }}</p>
            </div>
          </div>
          <div class="row text-white my-3">
            <div class="col-md-3">
              <p class="subtitle-700-lg">{{ 'TOURNAMENT_INFO.TEXT_LEFT4' | translate }}</p>
            </div>
            <div class="col-md-9">
              <p class="body-400-lg">{{ 'TOURNAMENT_INFO.TEXT_RIGHT4' | translate }}</p>
            </div>
          </div>
          <div class="row text-white">
            <div class="col-md-3">
              <p class="subtitle-700-lg">{{ 'TOURNAMENT_INFO.TEXT_LEFT5' | translate }}</p>
            </div>
            <div class="col-md-9">
              <p class="body-400-lg">{{ 'TOURNAMENT_INFO.TEXT_RIGHT5' | translate }}</p>
            </div>
          </div>
          <div class="row text-white mt-3">
            <div class="col-md-3">
              <p class="subtitle-700-lg">{{ 'TOURNAMENT_INFO.TEXT_LEFT6' | translate }}</p>
            </div>
            <div class="col-md-9">
              <p class="body-400-lg">{{ 'TOURNAMENT_INFO.TEXT_RIGHT6' | translate }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 mt-3">
        <div class="card">
          <div class="align-items-center p-3">
            <p class="title-tournament-info">{{ 'TOURNAMENT_INFO.TITLE3' | translate }}</p>
          </div>
          <div class="p-0 m-0 mt-1 div-table h-308">
            <table class="table table-dark table-striped text-white scroll-x">
              <thead>
                <tr>
                  <th class="text-center" scope="col">{{ 'TOURNAMENT_INFO.TABLE6' | translate }}</th>
                  <th class="text-center" scope="col">{{ 'TOURNAMENT_INFO.TABLE7' | translate }}</th>
                  <th class="text-center" scope="col">{{ 'TOURNAMENT_INFO.TABLE8' | translate }}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let award of awards; let i = index">
                  <td class="py-2 text-center">{{i+476}} - {{i+539}}</td>
                  <td class="text-center">{{63+i*5}}</td>
                  <td class="text-center">null</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="card mt-3">
          <div class="p-0 m-0 div-table table-radius-top h-308">
            <table class="table table-dark table-striped text-white scroll-x">
              <thead>
                <tr>
                  <th class="text-center" scope="col">{{ 'TOURNAMENT_INFO.TABLE9' | translate }}</th>
                  <th class="text-center" scope="col">{{ 'TOURNAMENT_INFO.TABLE10' | translate }}</th>
                  <th class="text-center" scope="col">{{ 'TOURNAMENT_INFO.TABLE11' | translate }}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let award of awards; let i = index">
                  <td class="py-2 text-center">{{i+1}}</td>
                  <td class="text-center">{{18+i*5.32}}%</td>
                  <td class="text-center">null</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="card p-3 mt-3 text-white">
          <p class="title-tournament-info pb-3">{{ 'TOURNAMENT_INFO.TITLE4' | translate }}</p>
          <p class="heading-500-sm">{{ 'TOURNAMENT_INFO.TEXT' | translate }}</p>
        </div>
      </div>
    </div>
  </div>
</section>
