import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { LoginComponent } from 'src/app/auth/login/login.component';
import { ModalSignUpComponent } from 'src/app/auth/modal-sign-up/modal-sign-up.component';
import { GameCoinGetBalanceResponseDto } from 'src/app/dtos/game-coin/game-coin-get-balance-response.dto';
import { ModalBuyCoinComponent } from 'src/app/logged/profile/modal-buy-coin/modal-buy-coin.component';
import { AuthService } from 'src/services/auth.service';
import { CashGameService } from 'src/services/cash-game.service';
import { DataTransferService } from 'src/services/data/data-transfer.service';
import { GameCoinService } from 'src/services/game-coin.service';
import { KycService } from 'src/services/kyc.service';
import { MovementService } from 'src/services/movement.service';
import { GameGatewaySocketService } from 'src/services/sockets/game-gateway.socket.service';
import { UserService } from 'src/services/user.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  @ViewChild('modalKyc') ModalKyc: any;

  selectLang: string = 'PT-BR';
  balance: boolean = false;
  myBalance: boolean = true;
  urls!: string;

  name!: string;

  fillStar: boolean = true;

  nickname!: string;

  balanceValue?: GameCoinGetBalanceResponseDto;
  formattedDateTime: string = ''
  private intervalId: any;
  kycRegistered: boolean = true;
  movements: any;
  statusKyc: string = ''
  timezone: string = ''
  totalPlayersPlaying: number = 0
  constructor(
    public translate: TranslateService,
    public router: Router,
    private userService: UserService,
    private toastrService: ToastrService,
    public authService: AuthService,
    private cashGameService: CashGameService,
    private ngxSpinnerService: NgxSpinnerService,
    private modalService: NgbModal,
    private readonly _gameGateway: GameGatewaySocketService,
    private gameCoinService: GameCoinService,
    private readonly _movementService: MovementService,
    private kycService: KycService,
    private dataTransferService: DataTransferService
  ) {
    translate.addLangs(['en', 'pt', 'es']);
    translate.setDefaultLang('en');

    // const browserLang = translate.getBrowserLang();
    const locStorageValue = localStorage.getItem('lan')
    const objKeys: { [key: string]: string } = {
      en: 'EN-US',
      es: 'ES-ES',
      pt: 'PT-BR'
    }
    if (locStorageValue) {
      translate.use(locStorageValue)
      this.selectLang = objKeys[locStorageValue]
    } else {
      this.handlerSelectedLanguage('en');
      translate.use('en');
      // this.handlerSelectedLanguage(
      //   browserLang?.match(/en|pt|es/) ? browserLang || '' : 'en'
      // );
    }

  }

  ngOnInit(): void {
    this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone.replace(/_/g, ' ')
    this.formatDate();
    this.intervalId = setInterval(() => {
      this.formatDate();
    }, 30000);
    const showBalance = localStorage.getItem('showBalance') || '';
    if (showBalance == '' || showBalance == 'false') {
      this.balance = true;
      this.myBalance = false;
    } else {
      this.balance = false;
      this.myBalance = true;
    }

    if (this.authService.isJwtValid()) {
      this.getUserInfo();
    };

    this.gameCoinService.balance.subscribe({
      next: data => {
        this.balanceValue = data;
      }
    })

    this.getKyc();
    this.getTotalPlayers();
  }

  ngOnDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  getTotalPlayers() {
    this.dataTransferService.getTotalPlayersObservable().subscribe({
      next: data => {
        this.totalPlayersPlaying = data
      }
    })
  }

  formatDate() {
    const currentDate = new Date();

    const hours = ('0' + currentDate.getHours()).slice(-2);
    const minutes = ('0' + currentDate.getMinutes()).slice(-2);
    const time = hours + ':' + minutes;

    const day = ('0' + currentDate.getDate()).slice(-2);
    const month = ('0' + (currentDate.getMonth() + 1)).slice(-2);
    const year = currentDate.getFullYear().toString().slice(-2);
    const date = day + '/' + month + '/' + year;

    this.formattedDateTime = this.timezone + ' - ' + time + ' - ' + date;

  }
  openModalLogin() {
    this.modalService.open(LoginComponent);
  }

  openModalSignUp() {
    this.modalService.open(ModalSignUpComponent);
  }

  changeLanguage(lang: string) {
    this.translate.use(lang);
    localStorage.setItem('lan', lang)
    this.handlerSelectedLanguage(lang);
  }

  handlerSelectedLanguage(lang: string) {
    if (lang === 'en') {
      this.selectLang = 'EN-US';
      return;
    }
    if (lang === 'es') {
      this.selectLang = 'ES-ES';
      return;
    }
    this.selectLang = 'PT-BR';
  }

  handlerLoggedBackground(url: string): boolean {
    if (this.authService.isJwtValid()) {
      return false;
    }
    if (url.indexOf('pages') !== -1) {
      return false;
    }
    return true;
  }

  navBarCashgame(url: string): boolean {
    if (url.indexOf('pages/cashgame') !== -1 || url.indexOf('pages/tablegame') !== -1 || url.indexOf('pages/teste') !== -1) {
      return true;
    }
    return false;
  }

  showBalance() {
    //       this.balance = false;
    // this.myBalance = true;
    if (this.balance === true) {
      localStorage.setItem('showBalance', 'true');
      this.balance = false;
      this.myBalance = true;
    } else {
      localStorage.setItem('showBalance', 'false');
      this.balance = true;
      this.myBalance = false;
    }
  }

  getUserInfo() {
    this.userService.getUser().subscribe(
      (data) => this.runGetUserInfo(data),
      (error) => this.runError(error)
    );
  }

  runGetUserInfo(data: any): void {
    this.name = data.name;
  };

  runError(exception: any): void {
    const toastr = this.toastrService.error(exception.error.errors, '', {
      progressBar: true,
    });
  };

  changeStar() {
    if (this.fillStar == true) {
      this.fillStar = false
    } else {
      this.fillStar = true
    }
  }

  leavePokerGame() {
    let cashGameId = sessionStorage.getItem('cashGameId') || '';
    this._gameGateway.leaveGame({ tableGameId: cashGameId });
    this.router.navigate(['/pages/home']);
  }

  getKyc() {
    this.kycService.getKyc().subscribe({
      next: (data: any) => {
        this.statusKyc = data.status
        if (!data.kycRegistered) {
          this.kycRegistered = false
        }
      }
    })
  }

  exit() {
    this.modalService.dismissAll();
  }

  openModalBuyCoin() {
    if(this.statusKyc == '') {
      this.ngxSpinnerService.show();
      this.kycService.getKyc().subscribe({
        next: (data: any) => {
          this.ngxSpinnerService.hide();
          this.statusKyc = data.status
          if (!data.kycRegistered) {
            this.kycRegistered = false
          }
          if (this.statusKyc !== 'CHECKED') {
            this.modalService.open(this.ModalKyc);
          } else {
            const modalRef = this.modalService.open(ModalBuyCoinComponent);
            modalRef.result.then(
              (closeResponse) => {
                if (closeResponse) {
                  this.gameCoinService.getBalance().subscribe({
                    next: (data) => {
                      this.gameCoinService.balance.next(data);
                    }
                  });
                  this.listMovemetByPlayerId();
                }
              },
              (dismissAllResponse) => { }
            );
          }
        },
        error: (error: any) => {
          this.ngxSpinnerService.hide();
        }
      })
    } else {
      if (this.statusKyc !== 'CHECKED') {
        this.modalService.open(this.ModalKyc);
      } else {
        const modalRef = this.modalService.open(ModalBuyCoinComponent);
        modalRef.result.then(
          (closeResponse) => {
            if (closeResponse) {
              this.gameCoinService.getBalance().subscribe({
                next: (data) => {
                  this.gameCoinService.balance.next(data);
                }
              });
              this.listMovemetByPlayerId();
            }
          },
          (dismissAllResponse) => { }
        );
      }
    }
  }

  listMovemetByPlayerId() {
    this._movementService.listByPlayerId().subscribe({
      next: response => {
        this.movements = response;
      },
      error: error => {
        this.toastrService.error(error.error.message, undefined, { progressBar: true });
      }
    });
  }

  logOut() {
    localStorage.removeItem('user');
    this.router.navigate(['/']);
  }
}
