<section class="container-fluid pt-0 pt-xl-5 px-0 bg-dark">
  <div
    class="container bg-dark d-flex flex-row justify-content-xl-between justify-content-start align-items-center px-lg-2 py-3 mt-0 mt-xl-5">
  
  </div>
  <div class="container card-home my-3 p-3">
    <div class="row">
      <div class="col-lg-8 col-12">
        <div class="row d-flex flex-row justify-content-between align-items-center">
          <div class="col-sm-12" >
            <div class="d-flex flex-row" >
     

              <div class="col d-flex" >
                <button class="btn-continue button-bold mx-2 double-button-measure"(click)="showGameType('pokerfi')" [ngClass]="gameType === 'pokerfi' ? 'pkf-button-active' : 'pkf-button-inactive' ">
                  <img *ngIf="gameType === 'pokerfi'" src="../../../assets/icons/poker-fi-icon-home.svg"  width="30" height="29" />
                  <img  *ngIf="gameType != 'pokerfi'" src="../../../assets/icons/pkf-icon-white.svg"  width="30" height="29" />

                  <span class="nameLogo">{{ "HOME.HOME-POKERFI" | translate}}</span>
                </button>
        
                <button class="btn-continue button-bold mx-2 double-button-measure " (click)="showGameType('usdt')"  [ngClass]="gameType === 'usdt' ? 'usdt-button-active' : 'usdt-button-inactive' ">
                  <img *ngIf="gameType != 'usdt'" src="../../../assets/icons/usdt-icon-home.svg"  width="30" height="29" />
                  <img *ngIf="gameType === 'usdt'" src="../../../assets/icons/tether-icon-black.svg"  width="30" height="29" />

                  <span class="nameLogo">{{ "HOME.HOME-USDT" | translate}}</span> 
                </button>
              </div>
            </div>
          </div>
   
        </div>

       <!-- Quando o torneio voltar, mudar para *ngIf="searchMode"-->
       <div *ngIf="!searchMode" class="table-responsive">
        <table class="table table-striped  mt-3" >
          <thead style="background-color: #f3c41f;" class="text-center">
            <tr class="text-center w-100">
              <th (click)="sortBy('name')" class="cursor-pointer" scope="col th-config" style="background-color: #f3c41f; border-top-left-radius: 10px;">
                &nbsp; {{ "HOME.TABLE_TITLES2.TITLE1" | translate }} &nbsp;
                <br class="br">
                <img src="../../../assets/icons/arrow-down.svg" class="ml-2 cursor-pointer img-point" width="14" height="14" />
              </th>
              <th (click)="sortBy('value')" scope="col" class="cursor-pointer" style="background-color: #f3c41f;width: 140px;">
                {{ "HOME.TABLE_TITLES2.TITLE2" | translate }} &nbsp;
                <img src="../../../assets/icons/arrow-down.svg" class="ml-2" width="14" height="14"/>
              </th>
              <th (click)="sortBy('game')" scope="col" class="cursor-pointer" style="background-color: #f3c41f; width: 140px;">
                {{ "HOME.TABLE_TITLES2.TITLE3" | translate }} &nbsp;
                <img src="../../../assets/icons/arrow-down.svg" class="ml-2" width="14" height="14"/>
              </th>
              <th (click)="sortBy('playerLimit')" scope="col" class="cursor-pointer" style="background-color: #f3c41f;width: 140px;">
                {{ "HOME.TABLE_TITLES2.TITLE4" | translate }} &nbsp;
                <img src="../../../assets/icons/arrow-down.svg" class="ml-2" width="14" height="14" />
              </th>
              <th (click)="sortBy('player')" scope="col" class="cursor-pointer" style="background-color: #f3c41f; border-top-right-radius: 10px;width: 170px;">
                {{ "HOME.TABLE_TITLES2.TITLE5" | translate }} &nbsp;
                <img src="../../../assets/icons/arrow-down.svg" class="ml-2" width="14" height="14"/>
              </th>
            </tr>
          </thead>
          <tbody class="table-dark" >
            <tr class="cursor-pointer" *ngFor="let item of cashGameToShow; let i = index"
                (click)="selectTable(i)"
                [ngClass]="cashGameSelected?._id == item?._id ? 'table-row-selected' : ''">
              <td class="py-2 text-capitalize title-cashgame text-center"   [ngStyle]="{'color': gameType === 'usdt' ? '#27C69A' : '#F3C41F'}">{{item.name}}</td>
              <td class="text-center" *ngIf="item.bigBlindValue > 1000 && item.bigBlindValue < 1000000">{{(item?.bigBlindValue / 1000 )  / 2}}K / {{(item?.bigBlindValue / 1000)}}K</td>
              <td class="text-center" *ngIf="item.bigBlindValue > 1000000">{{(item?.bigBlindValue / 1000000 )  / 2}}KK / {{(item?.bigBlindValue / 1000000)}}KK</td>
              <td class="text-center" *ngIf="item.bigBlindValue < 1000">{{item?.bigBlindValue / 2}} / {{item?.bigBlindValue}}</td>
              <td class="text-center">{{wayOfWritingGameMode(item.mode)}}</td>
              <td class="text-center align-items-center" >
                <div class="type"  [ngStyle]="{'background-color': gameType === 'usdt' ? '#27C69A' : '#F3C41F'}">{{item?.playersLimit}}</div>
              </td>
              <td class="text-center" > {{getPlayersSeatedInTable(i)}}</td>
            </tr>
          </tbody>
        </table >
        <div  class="card mt-lg-3 p-2 advertising ">
          <div class="advertising-text" >
            <!-- {{ "HOME.ADVERTISING" | translate }} -->

            <!-- <img src="{{advertisement[0].image}}" width="726px" height="150px" alt=""> -->

            <div id="carouselExampleSlidesOnly" class="carousel slide" data-bs-ride="carousel">
              <div class="carousel-inner">
                <div *ngFor="let ad of advertisement; let i = index" [class]="i === 0 ? 'carousel-item active' : 'carousel-item'">
                  <img [src]="ad.image" width="726px" height="150px" class="d-block" alt="Slide {{i + 1}}">
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
      
      

      </div>
      <div class="col-lg-4 col-12 pt-1 mt-1 mb-3">
        <!-- Quando o torneio voltar, mudar para *ngIf="searchMode"-->
        <div *ngIf="!searchMode" class="card mt-lg-5 mt-3 p-4">
          <div class="header-content">
            <h3 class="title-cashgame text-white profile-700 align-self-start text-capitalize">
              {{cashGameSelected?.name}}
            </h3>
            <div class="d-flex mt-0 align-items-center viewer-text" >
              {{cashGameSelected?.viewer}}
              <img *ngIf="cashGameSelected?.name" src="../../../assets/icons/white-eye-icon.svg" width="20" height="16"  class="ms-2"/>
            </div>

          </div>
      
          <h4 *ngIf="cashGameSelected && cashGameSelected.players" class="subtitle-cashgame text-white align-self-start mt-4">{{ "HOME.GOUP-OPTIONS2" | translate }}:</h4>
    
          <div *ngIf="showType=='game'" class="d-flex justify-content-center align-items-center mt-0">
            <app-table-cash-game [players]="cashGameSelected.players"></app-table-cash-game>
          </div>

          <div *ngIf="showType=='table'" class="d-flex flex-column justify-content-center align-items-center mt-2">
            <div *ngIf="cashGameSelected && cashGameSelected.players && this.gameType === 'pokerfi'" class="w-100">
              <div *ngFor="let player of cashGameSelected.players; let i = index"
                class="card-player w-100 mt-2 px-2 py-1" >
                <p class="body-400-md text-white" ><span class="subtitle-700-lg me-2" >{{i+1}}.</span> {{player?.playerData?.nickname}}</p>
              </div>
            </div>

            <div *ngIf="cashGameSelected && cashGameSelected.players && this.gameType === 'usdt'" class="w-100">
              <div *ngFor="let player of cashGameSelected.players; let i = index"
                class="card-player w-100 mt-2 px-2 py-1" >
                <p class="body-400-md text-white" ><span class="subtitle-700-lg me-2" style="color: #27C69A !important " >{{i+1}}.</span> {{player?.playerData?.nickname}}</p>
              </div>
            </div>
          </div>
          <!-- Botão Olho Observe -->
   
          <div style="flex:1"></div>
          <div class="px-2 pt-3" *ngIf="cashGameSelected && !isPlayerInCashGameAlready">
            <button class="btn-continue button-bold"  (click)="viewGame(cashGameSelected._id)">{{"HOME.OBSERVE" | translate }}</button>
          </div>
          <div class="px-2 pt-3" *ngIf="cashGameSelected && !isPlayerInCashGameAlready">

            <button class="btn-continue button-bold" *ngIf="!fullRoom" (click)="join(cashGameSelected._id)">{{"HOME.PLAY-NOW" | translate }}</button>
            <button class="btn-continue button-bold" *ngIf="fullRoom && !alreadyInQueue" data-bs-toggle="modal" data-bs-target="#modalBuyIn">{{"HOME.QUEUE" | translate }}</button>
            <button class="btn-continue button-bold" *ngIf="fullRoom && alreadyInQueue"  (click)="removeFromQueue(cashGameSelected._id)">Sair da fila</button>

            <!-- Botão Entre na fila -->
            <!-- <button class="btn-black button-bold text-white mt-2" (click)="joinCashGame()"
              [disabled]="cashGameSelected && cashGameSelected.players.length !== cashGameSelected.gameRoom.numberOfPlayers">{{
              "HOME.QUEUE" | translate }}</button> -->
          </div>
          <div class="px-2 pt-3" *ngIf="cashGameSelected && isPlayerInCashGameAlready">
            <button class="btn-continue button-bold" (click)="rejoinCashGame()">{{ "HOME.RETURN_GAME" | translate
              }}</button>
          </div>
        </div>
     
      </div>
    </div>
    
  </div>
</section>

<!-- Modal informações do header responsivo -->
<ng-template #content>
  <div class="background-modal d-flex flex-column align-items-center justify-content-center">
    <div class="px-2 my-3" [formGroup]="form">
      <p class="modality-title ps-2">{{ "HOME.MODALITY-TITLE" | translate }}</p>
      <div class="row mt-1">
        <div class="col-sm-3 mt-1">
          <input type="radio" name="modalityRadio" formControlName="modalityRadio" class="input-radio mx-3"
            value="ALL" />
          <span class="body-400-lg text-white">TODOS</span>
        </div>
        <div class="col-sm-3 mt-1">
          <input type="radio" name="modalityRadio" formControlName="modalityRadio" class="input-radio mx-3"
            value="NO_LIMIT_TEXAS_HOLDEM" />
          <span class="body-400-lg text-white">NL HOLDEM</span>
        </div>
        <div class="col-sm-3 mt-1">
          <input type="radio" name="modalityRadio" formControlName="modalityRadio" class="input-radio mx-3"
            value="PLO_OMAHA_4" />
          <span class="body-400-lg text-white">PL OMAHA 4</span>
        </div>
        <div class="col-sm-3 mt-1">
          <input type="radio" name="modalityRadio" formControlName="modalityRadio" class="input-radio mx-3"
            value="PLO_OMAHA_5" />
          <span class="body-400-lg text-white">PL OMAHA 5</span>
        </div>
      </div>
    </div>
    <div class="row px-2 my-3">
      <div class="col-sm-4 mt-2">
        <p class="modality-title mb-1">{{ "HOME.MODALITY-OPTIONS1" | translate }}</p>
        <div class="multipleSelection">
          <div class="selectBox" (click)="showCheckboxes=!showCheckboxes;showCheckboxes2=false ">
            <select>
              <option *ngIf="form.controls['entryValueRadio'].value == 'ALL'" selected>{{
                "HOME.MODALITY-OPTIONS1-CHECKBOX.OPTIONS1" | translate }}</option>
              <option *ngIf="form.controls['entryValueRadio'].value == 'High'" selected>{{
                "HOME.MODALITY-OPTIONS1-CHECKBOX.OPTIONS2" | translate }} (+ 1m)</option>
              <option *ngIf="form.controls['entryValueRadio'].value == 'Medium'" selected>{{
                "HOME.MODALITY-OPTIONS1-CHECKBOX.OPTIONS3" | translate }} (500k - 900k)</option>
              <option *ngIf="form.controls['entryValueRadio'].value == 'Low'" selected>{{
                "HOME.MODALITY-OPTIONS1-CHECKBOX.OPTIONS4" | translate }} (100k - 499k)</option>
              <option *ngIf="form.controls['entryValueRadio'].value == 'Micro'" selected>{{
                "HOME.MODALITY-OPTIONS1-CHECKBOX.OPTIONS5" | translate }}{{" (<100k)"}} </option>
            </select>
            <div class="overSelect"></div>
          </div>

          <div [formGroup]="form" [ngClass]="showCheckboxes?'checkBoxes':'checkBoxes-none'">
            <label for="first pt-2">
              <input type="radio" name="entryValueRadio" formControlName="entryValueRadio" value="ALL" />
              {{ "HOME.MODALITY-OPTIONS1-CHECKBOX.OPTIONS1" | translate }}
            </label>
            <label for="second pt-2">
              <input type="radio" name="entryValueRadio" formControlName="entryValueRadio" value="High" />
              {{ "HOME.MODALITY-OPTIONS1-CHECKBOX.OPTIONS2" | translate }} (+ 1m)
            </label>
            <label for="third pt-2">
              <input type="radio" name="entryValueRadio" formControlName="entryValueRadio" value="Medium" />
              {{ "HOME.MODALITY-OPTIONS1-CHECKBOX.OPTIONS3" | translate }} (500k - 900k)
            </label>
            <label for="fourth pt-2">
              <input type="radio" name="entryValueRadio" formControlName="entryValueRadio" value="Low" />
              {{ "HOME.MODALITY-OPTIONS1-CHECKBOX.OPTIONS4" | translate }} (100k - 499k)
            </label>
            <label for="fifth pt-2">
              <input type="radio" name="entryValueRadio" formControlName="entryValueRadio" value="Micro" />
              {{ "HOME.MODALITY-OPTIONS1-CHECKBOX.OPTIONS5" | translate }}{{" (<100k)"}} </label>
          </div>
        </div>
      </div>
      <div class="col-sm-4 mt-2">
        <p class="modality-title mb-1">{{ "HOME.MODALITY-OPTIONS2" | translate }}</p>
        <div class="multipleSelection">
          <div class="selectBox" (click)="showCheckboxes2=!showCheckboxes2;showCheckboxes=false">
            <select>
              <option *ngIf="form.controls['tableSize'].value == 'ALL'" selected>{{
                "HOME.MODALITY-OPTIONS2-CHECKBOX.OPTIONS1" | translate }}</option>
              <option *ngIf="form.controls['tableSize'].value == '7/10'" selected>7 - 10 {{
                "HOME.MODALITY-OPTIONS2-CHECKBOX.OPTIONS2" | translate }}</option>
              <option *ngIf="form.controls['tableSize'].value == '3/6'" selected>3 - 6 {{
                "HOME.MODALITY-OPTIONS2-CHECKBOX.OPTIONS2" | translate }}</option>
              <option *ngIf="form.controls['tableSize'].value == '2'" selected>2 {{
                "HOME.MODALITY-OPTIONS2-CHECKBOX.OPTIONS2" | translate }}</option>
            </select>
            <div class="overSelect"></div>
          </div>

          <div [formGroup]="form" [ngClass]="showCheckboxes2?'checkBoxes':'checkBoxes-none'">
            <label for="first pt-2">
              <input type="radio" name="tableSize" formControlName="tableSize" value="ALL" />
              {{ "HOME.MODALITY-OPTIONS2-CHECKBOX.OPTIONS1" | translate }}
            </label>
            <label for="second pt-2">
              <input type="radio" name="tableSize" formControlName="tableSize" value="7/10" />
              7 - 10 {{ "HOME.MODALITY-OPTIONS2-CHECKBOX.OPTIONS2" | translate }}
            </label>
            <label for="third pt-2">
              <input type="radio" name="tableSize" formControlName="tableSize" value="3/6" />
              3 - 6 {{ "HOME.MODALITY-OPTIONS2-CHECKBOX.OPTIONS2" | translate }}
            </label>
            <label for="fourth pt-2">
              <input type="radio" name="tableSize" formControlName="tableSize" value="2" />
              2 {{ "HOME.MODALITY-OPTIONS2-CHECKBOX.OPTIONS2" | translate }}
            </label>
          </div>

        </div>
      </div>
      <div class="col-sm-4 mt-2">
        <a class="link-400 " (click)="saveFilter()">{{ "HOME.FILTER-TEXT" | translate }}</a>
      </div>
    </div>
    <button (click)="listTournamentByFilter(this.form.controls['modalityRadio'].value); listCashGameByFilter(this.form.controls['modalityRadio'].value);
      close()" class="btn-continue d-flex button-bold w-auto my-3 px-5">
      {{ "HOME.FILTER-BTN" | translate }}</button>
  </div>
</ng-template>

<!-- Modal confirmar senha para entrar na sala -->
<ng-template #confirmPassword>
  <section class="background-modal p-3">
    <div (click)="close()" class="d-flex justify-content-end cursor-pointer">
      <img src="../../../../assets/icons/icon-x.svg" />
    </div>
    <div class="d-flex flex-column justify-content-center align-items-center">
      <h6 class="heading-700-sm text-white">{{ "HOME.MODAL_PASSWORD.TITLE" | translate }}</h6>
      <p class="heading-500-md text-white text-center mt-3">{{ "HOME.MODAL_PASSWORD.TEXT1" | translate }}</p>
    </div>
    <form class="form" [formGroup]="formConfirmPassword">
      <div class="my-4 position-relative">
        <input type="password" formControlName="password" class="form-control input-box"
          placeholder="{{ 'SIGN_IN.PASSWORD' | translate }}">
        <img src="../../../assets/icons/password-icon.svg" class="icon-input" width="20" height="16" />
      </div>
      <div class="row">
        <div class="col d-flex justify-content-center align-items-center">
          <button [disabled]="form.invalid" class="btn-delete button-bold mx-2" (click)="close()">{{
            "HOME.MODAL_PASSWORD.BTN_CANCEL" | translate }}</button>
        </div>
        <div class="col d-flex justify-content-center align-items-center">
          <button class="btn-continue button-bold mx-2" (click)="joinCashGame()">{{ "HOME.MODAL_PASSWORD.BTN_CONFIRM" |
            translate
            }}</button>
        </div>
      </div>
    </form>
  </section>

</ng-template>

<!-- Modal -->
<div class="modal fade" id="modalBuyIn" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="modalBuyInLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content bg-dark text-white p-3">
      <div class="modal-header">
        <h2 class="heading-700-sm ">Buy-in</h2>
        <button type="button" id="closeModal" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="d-flex justify-content-end">
          Saldo&nbsp;<span *ngIf="cashGameSelected?.coin === 'USDT'"> US $ {{balanceValue?.coinUsdValue}}</span>
          <span *ngIf="cashGameSelected?.coin === 'POKERFI'"> PKF {{balanceValue?.coinPokerFiValue}}</span>
        </div>
        <form [formGroup]="formJoinGame">
          <div class="d-flex flex-fill flex-column">

            <div class="d-flex align-items-center mb-3">
              <label for="customRange2" class="form-label text-white mb-0">Quantia de Buy-in:
                <!-- {{formJoinGame.controls['buyIn'].value}} -->
              </label>
              <input type="text" class="form-control input-custom-value-range" formControlName="valueRange"
                id="customRange2" (keyup)="listenedRange($event, 'input')">

              <!-- <input type="number" formControlName="buyIn" class="form-control input-custom" style="border:none;border-bottom: 1px solid #C1D34E; border-radius: 0;"> -->
            </div>
            <div class="d-flex flex-fill">
              <div class="px-2 d-flex flex-column">
                <div>Mín</div>
              </div>
              <input type="range" class="form-range" formControlName="buyIn" [min]="cashGameSelected?.buyInMinimum"
                [max]="cashGameSelected?.buyInMaximum" id="buyInRange" (change)="listenedRange($event, 'range')">
              <div class="px-2 d-flex flex-column">
                <div>Max</div>
              </div>
            </div>

            <div class="d-flex justify-content-between">
              <span><span *ngIf="cashGameSelected?.coin === 'USDT'">US $ </span><span
                  *ngIf="cashGameSelected?.coin === 'POKERFI'">PKF </span>{{cashGameSelected?.buyInMinimum}}</span>
              <span><span *ngIf="cashGameSelected?.coin === 'USDT'">US $ </span><span
                  *ngIf="cashGameSelected?.coin === 'POKERFI'">PKF </span>{{cashGameSelected?.buyInMaximum}}</span>
            </div>


          </div>
        </form>
      </div>
      <div class="modal-footer">
        <div class="d-flex flex-fill justify-content-between">
          <button id="cancelBuyIn" class="btn-delete button-bold w-50" data-bs-dismiss="modal">Cancelar</button>
          <button [disabled]="formJoinGame.controls['valueRange'].value < cashGameSelected?.buyInMinimum"
            class="btn-continue button-bold w-50" (click)="joinQueue(cashGameSelected._id)">Entrar</button>
        </div>
      </div>
    </div>
  </div>
</div>
