<section class="background-modal p-3">
  <div (click)="exit()" class="d-flex justify-content-end cursor-pointer">
    <img src="../../../../assets/icons/icon-x.svg" />
  </div>
  <div class="d-flex flex-column justify-content-center align-items-center">
    <h6 class="heading-700-sm text-white">{{ 'PROFILE.MODAL_BUY_COIN.TITLE' | translate }}</h6>
  </div>
  <form [formGroup]="form">
    <label class="form-label text-white body-400-md">{{ 'PROFILE.MODAL_BUY_COIN.SELECT' | translate }}</label>
    <select formControlName="select" (change)="changeSelect(); calculateAmountToReceive()" class="form-select mb-3" aria-label="Default select example">
      <option selected disabled>{{ 'PROFILE.MODAL_BUY_COIN.SELECT' | translate }}...</option>
      <option value="POKERFI">PKF Game</option>
      <option value="USDT">USDT Game</option>
    </select>
    <label class="form-label text-white body-400-md">{{ 'PROFILE.MODAL_BUY_COIN.LABEL' | translate }}</label>
    <select formControlName="payWhith" (change)="calculateAmountToReceive()" class="form-select mb-3" aria-label="Default select example">
      <option selected disabled>{{ 'PROFILE.MODAL_BUY_COIN.LABEL' | translate }}...</option>
      <option *ngIf="form.controls['select'].value == 'USDT'" value="USDT">USDT</option>
      <option *ngIf="form.controls['select'].value == 'POKERFI'" value="POKERFI">PKF</option>
      <option *ngIf="form.controls['select'].value == 'USDT'" value="BRL">BRL</option>
    </select>
    <p *ngIf="form.controls['payWhith'].value == 'POKERFI' || form.controls['payWhith'].value == 'USDT'" class="text-white body-300-md mt-3">{{ 'PROFILE.MODAL_BUY_COIN.HAVE_BNB' | translate }}</p>
    <label class="form-label text-white body-400-md">{{ 'PROFILE.MODAL_BUY_COIN.VALUE' | translate }}</label>
    <div class="d-flex align-items-center background-flex-buttons">
      <div class="d-flex broke-buttons w-100">
        <input *ngIf="form.controls['payWhith'].value == 'BRL'" type="text" (keyup)="calculateAmountToReceive()" formControlName="value" class="form-control input-other" currencyMask [options]="{ prefix: '', thousands: '', decimal: ',', precision: '2' }">
        <input *ngIf="form.controls['payWhith'].value !== 'BRL'" type="text" (keyup)="calculateAmountToReceive()" formControlName="value" class="form-control input-other" currencyMask [options]="{ prefix: '', thousands: '', decimal: '.', precision: '2' }">
      </div>
    </div>
    <label *ngIf="form.controls['payWhith'].value == 'POKERFI' || form.controls['payWhith'].value == 'USDT'" class="form-label text-white body-400-md mt-4">{{ 'PROFILE.MODAL_BUY_COIN.CODE' | translate }}</label>
    <div *ngIf="form.controls['payWhith'].value == 'POKERFI' || form.controls['payWhith'].value == 'USDT'" class="d-flex align-items-center background-flex-buttons">
      <div class="d-flex broke-buttons w-100">
        <input type="text" placeholder="000000" mask="000000" formControlName="code" class="form-control input-other">
      </div>
    </div>
  </form>
  <div class="d-flex flex-column my-3">
    <p class="text-end text-white body-400-md mb-1" *ngIf="form.controls['payWhith'].value == 'POKERFI' && form.controls['value'].value">Valor à pagar: {{form.controls['value'].value.toFixed(2)}} PKF</p>
    <p class="text-end text-white body-400-md mb-1" *ngIf="form.controls['payWhith'].value == 'USDT' && form.controls['value'].value">Valor à pagar: {{form.controls['value'].value.toFixed(2)}} USDT</p>
    <p class="text-end text-white body-400-md mb-1" *ngIf="form.controls['payWhith'].value == 'BRL'">Valor à pagar: {{form.controls['value'].value || '0.00'}} BRL</p>
  </div>
  <div class="d-flex flex-column my-3">
    <p class="text-end text-white body-400-md mb-1">{{ 'PROFILE.MODAL_BUY_COIN.TEXT' | translate }} <span *ngIf="this.form.controls['payWhith'].value == 'BRL'">{{ 'PROFILE.MODAL_BUY_COIN.APPROXIMATELY' | translate }}</span>:</p>
    <p *ngIf="form.controls['select'].value == 'POKERFI'" class="text-end text-white heading-500-md">{{amountToReceive.toFixed(2)}} PKF Game</p>
    <p *ngIf="form.controls['select'].value == 'USDT'" class="text-end text-white heading-500-md">{{amountToReceive.toFixed(2)}} USDT Game</p>
  </div>
  <div class="mt-4 row align-items-center">
    <div class="col d-flex justify-content-center align-items-center">
      <button class="btn-delete button-bold mx-2" (click)="exit()">{{ 'PROFILE.MODAL_BUY_COIN.BTN_CANCEL' | translate
        }}</button>
    </div>
    <div class="col align-items-center">
      <button class="btn-continue button-bold mx-2" (click)="submit()">{{
        'PROFILE.MODAL_BUY_COIN.BTN_CONFIRM' | translate }}</button>
    </div>
  </div>
</section>

<a id="wallet" href="https://wallet.pokerfi.app/auth/login" target="_blank" class="d-none"></a>
