<section class="container section-auth d-flex align-items-center">
  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-scale-multiple" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
  </ngx-spinner>
  <div class="col-12 col-md-10 col-lg-5 mt-5">
    <h2 class="heading-700-md">{{ 'SIGN_IN.TITLE' | translate }}</h2>
    <p class="subtitle my-3">{{ 'SIGN_IN.SUBTITLE_START' | translate }} <span [routerLink]="'/sign-up'">{{
        'SIGN_IN.SUBTITLE_END' | translate
        }}</span></p>
    <form class="form" [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="d-flex flex-column mt-4 justify-content-center">
        <div class="position-relative">
          <input type="email" formControlName="email" class="form-control input-box"
            placeholder="{{ 'SIGN_IN.EMAIL' | translate }}">
          <img src="../../../assets/icons/email-icon.svg" class="icon-input" width="20" height="16" />
        </div>
        <div class="my-4 position-relative">
          <input type="password" formControlName="password" class="form-control input-box"
            placeholder="{{ 'SIGN_IN.PASSWORD' | translate }}">
          <img src="../../../assets/icons/password-icon.svg" class="icon-input" width="20" height="16" />
        </div>
      </div>
      <div class="d-flex justify-content-center align-items-center">
        <button [ngClass]="!form.valid?'btn-continue-disable':'btn-continue'" class="button-bold">{{ 'SIGN_IN.BUTTON' |
          translate }}</button>
      </div>
    </form>
    <p class="subtitle my-4" [routerLink]="'/reset-password'">
      <span>
        {{ 'SIGN_IN.FORGOT' | translate }}
      </span>
    </p>
  </div>
</section>
