import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { RakebackMovementResponseDto } from 'src/app/dtos/rakeback-movement/rakeback-movement-response.dto';

@Injectable({
  providedIn: 'root'
})
export class RakebackMovementService extends BaseService {

  url: string = `${this.gameBackoffice}rakeback`;

  constructor(
    private httpClient: HttpClient,
  ) {
    super();
  }

  getLastRakeBackMovement() {
    return this.httpClient.get<RakebackMovementResponseDto>(`${this.url}/last-rakeBack-movement`, this.authorizedHeader);
  }

}
