<div class="container-fluid" style="position: absolute; top: 0;">
  <div class="container">
    <div class="d-flex flex-fill py-2 px-3 justify-content-between">
      <div class="d-flex">
        <a class="cursor-pointer" (click)="leavePokerGame()"><img src="../../../assets/icons/icon-logout.svg" style="width: 24px" alt=""></a>
        <div *ngIf="tableInfo?.bigBlindValue" class="text-white ms-2">Valores: {{tableInfo?.bigBlindValue / 2 }}/{{tableInfo?.bigBlindValue}}</div>
        <div *ngIf="tableInfo?.playersLimit" class="text-white ms-2">Tipo: {{tableInfo?.playersLimit}}</div>
      </div>
      <div>
        <a *ngIf="canBuyCoin()" class="cursor-pointer" data-bs-toggle="modal" data-bs-target="#modalBuyCoin" (click)="openModalBuyCoin()">
          <img src="../../../assets/game/icons-in-table/fichas-de-cassino.png" alt="">
        </a>
      </div>
    </div>
  </div>
</div>

<section class="bg-table d-flex flex-column align-items-center justify-content-center">

  <div class="d-flex flex-column justify-content-center ms-2">

    <div class="mt-2" style="text-align: center;">
      <h3 class="text-color-winner-cashgame">
        <!-- mainPlayerId: {{mainPlayerId}} -->
      </h3>
    </div>

    <div class="mesa">

      <!-- Jogador 1 -->
      <div [ngClass]="arrayPlayers[4] && playerIsNotInExitQueue(4) ? '' : 'visibility-hidden'">
        <div class="player1" [ngClass]="tableInfo?.currentTurn == arrayPlayers[4]?._id ? 'player-border-yellow' : ''">
          <div class="circulo1"
            [ngClass]="tableInfo?.currentTurn == arrayPlayers[4]?._id ? 'circulo-border-yellow' : ''">
            <div *ngFor="let item of tableInfo?.playersData">
              <img *ngIf="item?.user?._id == arrayPlayers[4]?._id && item?.profilePicture" class="img-fluid img-circulo"
                [src]="item?.profilePicture" alt="">
              <div class="first-letter-nickname text-capitalize"
                *ngIf="item?.user?._id == arrayPlayers[4]?._id && !item?.profilePicture">
                {{item?.nickname[0]}}</div>
            </div>
          </div>
          <div *ngFor="let item of tableInfo?.playersData">
            <div *ngIf="item?.user?._id == arrayPlayers[4]?._id" class="nickname1 text-capitalize">
              <span *ngIf="!showActionPlayer4">{{item?.nickname || ''}}</span>
              <span *ngIf="animationPlayerIndex === 4 && showActionPlayer4">{{animationResponse?.action || ''}}</span>
            </div>
          </div>
          <img [ngClass]="tableInfo?.dealerTurn == arrayPlayers[4]?._id ? '' : 'visibility-hidden'" class="dealer1"
            src="../../../assets/icons/poker-game/coin-dealer.svg">
          <div>
            <div *ngIf="arrayPlayers[4]" class="balance1">$ {{tableInfo?.playersHand[4]?.coinAmount}}</div>
          </div>
          <div class="bet1">
            <div [ngClass]="betByPlayer(arrayPlayers[4]?._id) == 0 || !showIndividualPot ? 'visibility-hidden' : ''">
              <img [src]="getChipColor(betByPlayer(arrayPlayers[4]?._id))" class="bet-img"><span class="bet-span1">$
                {{betByPlayer(arrayPlayers[4]?._id)}}</span>
            </div>
          </div>
          <div class="progress-bar1"
            [ngClass]="tableInfo?.currentTurn == arrayPlayers[4]?._id && tableInfo?.round !== 'SHOWDOWN' && showActions ? '' : 'visibility-hidden'">
            <span class="progress" [ngStyle]="{ 'width': progressWidth + '%' }"
              [ngClass]="{'bg-red': progressWidth <= 25 }"></span>
          </div>
          <div *ngIf="!showdownIndex4">
            <img
              *ngIf="tableInfo?.playersHand[4]?.cards.length > 0 && tableInfo?.playersHand[4]?.player?._id !== mainPlayerId && !isFold(4)"
              class="card1" src="../../../assets/icons/cartas-sobrepostas.png">
            <div
              [ngClass]="tableInfo?.playersHand[4]?.cards.length > 0 && tableInfo?.playersHand[4]?.player?._id !== mainPlayerId ? 'd-none': ''">
              <div class="div-card-player1" *ngIf="!isFold(4)">
                <img
                  *ngIf="tableInfo?.playersHand[4]?.cards.length > 0 && tableInfo?.playersHand[4]?.player?._id == mainPlayerId"
                  class="card-player1"
                  [src]="'../../../assets/game/cards/'  + tableInfo?.playersHand[4]?.cards[0] + '.png' ">
                <img
                  *ngIf="tableInfo?.playersHand[4]?.cards.length > 0 && tableInfo?.playersHand[4]?.player?._id == mainPlayerId"
                  class="card-player1 ms-1"
                  [src]="'../../../assets/game/cards/'  + tableInfo?.playersHand[4]?.cards[1] + '.png' ">
              </div>
            </div>
          </div>
          <div *ngIf="showdownIndex4">
            <div *ngIf="!isFold(4)">
              <div *ngFor="let item of tableInfo?.playersHand">
                <img *ngIf="tableInfo?.playersHand[0]?.cards.length > 0 && item?.player?._id == arrayPlayers[4]?._id"
                  class="card-showdown1" [src]="'../../../assets/game/cards/' + item?.cards[0] + '.png'">
                <img *ngIf="tableInfo?.playersHand[0]?.cards.length > 0 && item?.player?._id == arrayPlayers[4]?._id"
                  class="card-showdown1 ms-1" [src]="'../../../assets/game/cards/' + item?.cards[1] + '.png'">
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Jogador 2 -->
      <div [ngClass]="arrayPlayers[5] && playerIsNotInExitQueue(5) ? '' : 'visibility-hidden'">
        <div class="player2" [ngClass]="tableInfo?.currentTurn == arrayPlayers[5]?._id ? 'player-border-yellow' : ''">
          <div class="circulo2"
            [ngClass]="tableInfo?.currentTurn == arrayPlayers[5]?._id ? 'circulo-border-yellow' : ''">
            <div *ngFor="let item of tableInfo?.playersData">
              <img *ngIf="item?.user?._id == arrayPlayers[5]?._id && item?.profilePicture" class="img-fluid img-circulo"
                [src]="item?.profilePicture" alt="">
              <div class="first-letter-nickname text-capitalize"
                *ngIf="item?.user?._id == arrayPlayers[5]?._id && !item?.profilePicture">
                {{item?.nickname[0]}}</div>
            </div>
          </div>
          <div *ngFor="let item of tableInfo?.playersData">
            <div *ngIf="item?.user?._id == arrayPlayers[5]?._id" class="nickname2 text-capitalize">
              <span *ngIf="!showActionPlayer5">{{item?.nickname || ''}}</span>
              <span *ngIf="animationPlayerIndex === 5 && showActionPlayer5">{{animationResponse?.action || ''}}</span>
            </div>
          </div>
          <img [ngClass]="tableInfo?.dealerTurn == arrayPlayers[5]?._id ? '' : 'visibility-hidden'" class="dealer2"
            src="../../../assets/icons/poker-game/coin-dealer.svg">
          <div>
            <div *ngIf="arrayPlayers[5]" class="balance2">$ {{tableInfo?.playersHand[5]?.coinAmount}}</div>
          </div>
          <div class="bet2">
            <div [ngClass]="betByPlayer(arrayPlayers[5]?._id) == 0 || !showIndividualPot ? 'visibility-hidden' : ''">
              <span class="bet-span2">$ {{betByPlayer(arrayPlayers[5]?._id)}}</span><img
                [src]="getChipColor(betByPlayer(arrayPlayers[5]?._id))" class="bet-img">
            </div>
          </div>
          <div class="progress-bar2"
            [ngClass]="tableInfo?.currentTurn == arrayPlayers[5]?._id && tableInfo?.round !== 'SHOWDOWN' && showActions ? '' : 'visibility-hidden'">
            <span class="progress" [ngStyle]="{ 'width': progressWidth + '%' }"
              [ngClass]="{'bg-red': progressWidth <= 25 }"></span>
          </div>
          <div *ngIf="!showdownIndex5">
            <img
              *ngIf="tableInfo?.playersHand[5]?.cards.length > 0 && tableInfo?.playersHand[5]?.player?._id !== mainPlayerId && !isFold(5)"
              class="card2" src="../../../assets/icons/cartas-sobrepostas.png">
            <div
              [ngClass]="tableInfo?.playersHand[5]?.cards.length > 0 && tableInfo?.playersHand[5]?.player?._id !== mainPlayerId ? 'd-none': ''">
              <div class="div-card-player1" *ngIf="!isFold(5)">
                <img
                  *ngIf="tableInfo?.playersHand[5]?.cards.length > 0 && tableInfo?.playersHand[5]?.player?._id == mainPlayerId"
                  class="card-player1"
                  [src]="'../../../assets/game/cards/'  + tableInfo?.playersHand[5]?.cards[0] + '.png' ">
                <img
                  *ngIf="tableInfo?.playersHand[5]?.cards.length > 0 && tableInfo?.playersHand[5]?.player?._id == mainPlayerId"
                  class="card-player1 ms-1"
                  [src]="'../../../assets/game/cards/'  + tableInfo?.playersHand[5]?.cards[1] + '.png' ">
              </div>
            </div>
          </div>
          <div *ngIf="showdownIndex5">
            <div *ngIf="!isFold(5)">
              <div *ngFor="let item of tableInfo?.playersHand">
                <img *ngIf="tableInfo?.playersHand[0]?.cards.length > 0 && item?.player?._id == arrayPlayers[5]?._id"
                  class="card-showdown2" [src]="'../../../assets/game/cards/' + item?.cards[0] + '.png'">
                <img *ngIf="tableInfo?.playersHand[0]?.cards.length > 0 && item?.player?._id == arrayPlayers[5]?._id"
                  class="card-showdown2 ms-1" [src]="'../../../assets/game/cards/' + item?.cards[1] + '.png'">
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Jogador 3 -->
      <div [ngClass]="arrayPlayers[3] && playerIsNotInExitQueue(3) ? '' : 'visibility-hidden'">
        <div class="player3" [ngClass]="tableInfo?.currentTurn == arrayPlayers[3]?._id ? 'player-border-yellow' : ''">
          <div class="circulo1"
            [ngClass]="tableInfo?.currentTurn == arrayPlayers[3]?._id ? 'circulo-border-yellow' : ''">
            <div *ngFor="let item of tableInfo?.playersData">
              <img *ngIf="item?.user?._id == arrayPlayers[3]?._id && item?.profilePicture" class="img-fluid img-circulo"
                [src]="item?.profilePicture" alt="">
              <div class="first-letter-nickname text-capitalize"
                *ngIf="item?.user?._id == arrayPlayers[3]?._id && !item?.profilePicture">
                {{item?.nickname[0]}}</div>
            </div>
          </div>
          <div *ngFor="let item of tableInfo?.playersData">
            <div *ngIf="item?.user?._id == arrayPlayers[3]?._id" class="nickname1 text-capitalize">
              <span *ngIf="!showActionPlayer3">{{item?.nickname || ''}}</span>
              <span *ngIf="animationPlayerIndex === 3 && showActionPlayer3">{{animationResponse?.action || ''}}</span>
            </div>
          </div>
          <img [ngClass]="tableInfo?.dealerTurn == arrayPlayers[3]?._id ? '' : 'visibility-hidden'" class="dealer3"
            src="../../../assets/icons/poker-game/coin-dealer.svg">
          <div>
            <div *ngIf="arrayPlayers[3]" class="balance1">$ {{tableInfo?.playersHand[3]?.coinAmount}}</div>
          </div>
          <div class="bet3">
            <div [ngClass]="betByPlayer(arrayPlayers[3]?._id) == 0 || !showIndividualPot ? 'visibility-hidden' : ''">
              <img [src]="getChipColor(betByPlayer(arrayPlayers[3]?._id))" class="bet-img"><span class="bet-span1">$
                {{betByPlayer(arrayPlayers[3]?._id)}}</span>
            </div>
          </div>
          <div class="progress-bar1"
            [ngClass]="tableInfo?.currentTurn == arrayPlayers[3]?._id && tableInfo?.round !== 'SHOWDOWN' && showActions ? '' : 'visibility-hidden'">
            <span class="progress" [ngStyle]="{ 'width': progressWidth + '%' }"
              [ngClass]="{'bg-red': progressWidth <= 25 }"></span>
          </div>
          <div *ngIf="!showdownIndex3">
            <img
              *ngIf="tableInfo?.playersHand[3]?.cards.length > 0 && tableInfo?.playersHand[3]?.player?._id !== mainPlayerId && !isFold(3)"
              class="card1" src="../../../assets/icons/cartas-sobrepostas.png">
            <div
              [ngClass]="tableInfo?.playersHand[3]?.cards.length > 0 && tableInfo?.playersHand[3]?.player?._id !== mainPlayerId ? 'd-none': ''">
              <div class="div-card-player1" *ngIf="!isFold(3)">
                <img
                  *ngIf="tableInfo?.playersHand[3]?.cards.length > 0 && tableInfo?.playersHand[3]?.player?._id == mainPlayerId"
                  class="card-player1"
                  [src]="'../../../assets/game/cards/'  + tableInfo?.playersHand[3]?.cards[0] + '.png' ">
                <img
                  *ngIf="tableInfo?.playersHand[3]?.cards.length > 0 && tableInfo?.playersHand[3]?.player?._id == mainPlayerId"
                  class="card-player1 ms-1"
                  [src]="'../../../assets/game/cards/'  + tableInfo?.playersHand[3]?.cards[1] + '.png' ">
              </div>
            </div>
          </div>
          <div *ngIf="showdownIndex3">
            <div *ngIf="!isFold(3)">
              <div *ngFor="let item of tableInfo?.playersHand">
                <img *ngIf="tableInfo?.playersHand[0]?.cards.length > 0 && item?.player?._id == arrayPlayers[3]?._id"
                  class="card-showdown1" [src]="'../../../assets/game/cards/' + item?.cards[0] + '.png'">
                <img *ngIf="tableInfo?.playersHand[0]?.cards.length > 0 && item?.player?._id == arrayPlayers[3]?._id"
                  class="card-showdown1 ms-1" [src]="'../../../assets/game/cards/' + item?.cards[1] + '.png'">
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Jogador 4 -->
      <div [ngClass]="arrayPlayers[6] && playerIsNotInExitQueue(6) ? '' : 'visibility-hidden'">
        <div class="player4" [ngClass]="tableInfo?.currentTurn == arrayPlayers[6]?._id ? 'player-border-yellow' : ''">
          <div class="circulo2"
            [ngClass]="tableInfo?.currentTurn == arrayPlayers[6]?._id ? 'circulo-border-yellow' : ''">
            <div *ngFor="let item of tableInfo?.playersData">
              <img *ngIf="item?.user?._id == arrayPlayers[6]?._id && item?.profilePicture" class="img-fluid img-circulo"
                [src]="item?.profilePicture" alt="">
              <div class="first-letter-nickname text-capitalize"
                *ngIf="item?.user?._id == arrayPlayers[6]?._id && !item?.profilePicture">
                {{item?.nickname[0]}}</div>
            </div>
          </div>
          <div *ngFor="let item of tableInfo?.playersData">
            <div *ngIf="item?.user?._id == arrayPlayers[6]?._id" class="nickname2 text-capitalize">
              <span *ngIf="!showActionPlayer6">{{item?.nickname || ''}}</span>
              <span *ngIf="animationPlayerIndex === 6 && showActionPlayer6">{{animationResponse?.action || ''}}</span>
            </div>
          </div>
          <img [ngClass]="tableInfo?.dealerTurn == arrayPlayers[6]?._id ? '' : 'visibility-hidden'" class="dealer4"
            src="../../../assets/icons/poker-game/coin-dealer.svg">
          <div>
            <div *ngIf="arrayPlayers[6]" class="balance2">$ {{tableInfo?.playersHand[6]?.coinAmount}}</div>
          </div>
          <div class="bet4">
            <div [ngClass]="betByPlayer(arrayPlayers[6]?._id) == 0 || !showIndividualPot ? 'visibility-hidden' : ''">
              <span class="bet-span2">$ {{betByPlayer(arrayPlayers[6]?._id)}}</span><img
                [src]="getChipColor(betByPlayer(arrayPlayers[6]?._id))" class="bet-img">
            </div>
          </div>
          <div class="progress-bar2"
            [ngClass]="tableInfo?.currentTurn == arrayPlayers[6]?._id && tableInfo?.round !== 'SHOWDOWN' && showActions ? '' : 'visibility-hidden'">
            <span class="progress" [ngStyle]="{ 'width': progressWidth + '%' }"
              [ngClass]="{'bg-red': progressWidth <= 25 }"></span>
          </div>
          <div *ngIf="!showdownIndex6">
            <img
              *ngIf="tableInfo?.playersHand[6]?.cards.length > 0 && tableInfo?.playersHand[6]?.player?._id !== mainPlayerId && !isFold(6)"
              class="card2" src="../../../assets/icons/cartas-sobrepostas.png">
            <div
              [ngClass]="tableInfo?.playersHand[6]?.cards.length > 0 && tableInfo?.playersHand[6]?.player?._id !== mainPlayerId ? 'd-none': ''">
              <div class="div-card-player1" *ngIf="!isFold(6)">
                <img
                  *ngIf="tableInfo?.playersHand[6]?.cards.length > 0 && tableInfo?.playersHand[6]?.player?._id == mainPlayerId"
                  class="card-player1"
                  [src]="'../../../assets/game/cards/'  + tableInfo?.playersHand[6]?.cards[0] + '.png' ">
                <img
                  *ngIf="tableInfo?.playersHand[6]?.cards.length > 0 && tableInfo?.playersHand[6]?.player?._id == mainPlayerId"
                  class="card-player1 ms-1"
                  [src]="'../../../assets/game/cards/'  + tableInfo?.playersHand[6]?.cards[1] + '.png' ">
              </div>
            </div>
          </div>
          <div *ngIf="showdownIndex6">
            <div *ngIf="!isFold(6)">
              <div *ngFor="let item of tableInfo?.playersHand">
                <img *ngIf="tableInfo?.playersHand[0]?.cards.length > 0 && item?.player?._id == arrayPlayers[6]?._id"
                  class="card-showdown2" [src]="'../../../assets/game/cards/' + item?.cards[0] + '.png'">
                <img *ngIf="tableInfo?.playersHand[0]?.cards.length > 0 && item?.player?._id == arrayPlayers[6]?._id"
                  class="card-showdown2 ms-1" [src]="'../../../assets/game/cards/' + item?.cards[1] + '.png'">
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Jogador 5 -->
      <div [ngClass]="arrayPlayers[2] && playerIsNotInExitQueue(2) ? '' : 'visibility-hidden'">
        <div class="player5" [ngClass]="tableInfo?.currentTurn == arrayPlayers[2]?._id ? 'player-border-yellow' : ''">
          <div class="circulo1"
            [ngClass]="tableInfo?.currentTurn == arrayPlayers[2]?._id ? 'circulo-border-yellow' : ''">
            <div *ngFor="let item of tableInfo?.playersData">
              <img *ngIf="item?.user?._id == arrayPlayers[2]?._id && item?.profilePicture" class="img-fluid img-circulo"
                [src]="item?.profilePicture" alt="">
              <div class="first-letter-nickname text-capitalize"
                *ngIf="item?.user?._id == arrayPlayers[2]?._id && !item?.profilePicture">
                {{item?.nickname[0]}}</div>
            </div>
          </div>
          <div *ngFor="let item of tableInfo?.playersData">
            <div *ngIf="item?.user?._id == arrayPlayers[2]?._id" class="nickname1 text-capitalize">
              <span *ngIf="!showActionPlayer2">{{item?.nickname || ''}}</span>
              <span *ngIf="animationPlayerIndex === 2 && showActionPlayer2">{{animationResponse?.action || ''}}</span>
            </div>
          </div>
          <img [ngClass]="tableInfo?.dealerTurn == arrayPlayers[2]?._id ? '' : 'visibility-hidden'" class="dealer5"
            src="../../../assets/icons/poker-game/coin-dealer.svg">
          <div>
            <div *ngIf="arrayPlayers[2]" class="balance1">$ {{tableInfo?.playersHand[2]?.coinAmount}}</div>
          </div>
          <div class="bet5">
            <div [ngClass]="betByPlayer(arrayPlayers[2]?._id) == 0 || !showIndividualPot ? 'visibility-hidden' : ''">
              <img [src]="getChipColor(betByPlayer(arrayPlayers[2]?._id))" class="bet-img"><span class="bet-span1">$
                {{betByPlayer(arrayPlayers[2]?._id)}}</span>
            </div>
          </div>
          <div class="progress-bar1"
            [ngClass]="tableInfo?.currentTurn == arrayPlayers[2]?._id && tableInfo?.round !== 'SHOWDOWN' && showActions ? '' : 'visibility-hidden'">
            <span class="progress" [ngStyle]="{ 'width': progressWidth + '%' }"
              [ngClass]="{'bg-red': progressWidth <= 25 }"></span>
          </div>
          <div *ngIf="!showdownIndex2">
            <img
              *ngIf="tableInfo?.playersHand[2]?.cards.length > 0 && tableInfo?.playersHand[2]?.player?._id !== mainPlayerId && !isFold(2)"
              class="card1" src="../../../assets/icons/cartas-sobrepostas.png">
            <div
              [ngClass]="tableInfo?.playersHand[2]?.cards.length > 0 && tableInfo?.playersHand[2]?.player?._id !== mainPlayerId ? 'd-none': ''">
              <div class="div-card-player1" *ngIf="!isFold(2)">
                <img
                  *ngIf="tableInfo?.playersHand[2]?.cards.length > 0 && tableInfo?.playersHand[2]?.player?._id == mainPlayerId"
                  class="card-player1"
                  [src]="'../../../assets/game/cards/'  + tableInfo?.playersHand[2]?.cards[0] + '.png' ">
                <img
                  *ngIf="tableInfo?.playersHand[2]?.cards.length > 0 && tableInfo?.playersHand[2]?.player?._id == mainPlayerId"
                  class="card-player1 ms-1"
                  [src]="'../../../assets/game/cards/'  + tableInfo?.playersHand[2]?.cards[1] + '.png' ">
              </div>
            </div>
          </div>
          <div *ngIf="showdownIndex2">
            <div *ngIf="!isFold(2)">
              <div *ngFor="let item of tableInfo?.playersHand">
                <img *ngIf="tableInfo?.playersHand[0]?.cards.length > 0 && item?.player?._id == arrayPlayers[2]?._id"
                  class="card-showdown1" [src]="'../../../assets/game/cards/' + item?.cards[0] + '.png'">
                <img *ngIf="tableInfo?.playersHand[0]?.cards.length > 0 && item?.player?._id == arrayPlayers[2]?._id"
                  class="card-showdown1 ms-1" [src]="'../../../assets/game/cards/' + item?.cards[1] + '.png'">
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Jogador 6 -->
      <div [ngClass]="arrayPlayers[7] && playerIsNotInExitQueue(7) ? '' : 'visibility-hidden'">
        <div class="player6" [ngClass]="tableInfo?.currentTurn == arrayPlayers[7]?._id ? 'player-border-yellow' : ''">
          <div class="circulo2"
            [ngClass]="tableInfo?.currentTurn == arrayPlayers[7]?._id ? 'circulo-border-yellow' : ''">
            <div *ngFor="let item of tableInfo?.playersData">
              <img *ngIf="item?.user?._id == arrayPlayers[7]?._id && item?.profilePicture" class="img-fluid img-circulo"
                [src]="item?.profilePicture" alt="">
              <div class="first-letter-nickname text-capitalize"
                *ngIf="item?.user?._id == arrayPlayers[7]?._id && !item?.profilePicture">
                {{item?.nickname[0]}}</div>
            </div>
          </div>
          <div *ngFor="let item of tableInfo?.playersData">
            <div *ngIf="item?.user?._id == arrayPlayers[7]?._id" class="nickname2 text-capitalize">
              <span *ngIf="!showActionPlayer7">{{item?.nickname || ''}}</span>
              <span *ngIf="animationPlayerIndex === 7 && showActionPlayer7">{{animationResponse?.action || ''}}</span>
            </div>
          </div>
          <img [ngClass]="tableInfo?.dealerTurn == arrayPlayers[7]?._id ? '' : 'visibility-hidden'" class="dealer6"
            src="../../../assets/icons/poker-game/coin-dealer.svg">
          <div>
            <div *ngIf="arrayPlayers[7]" class="balance2">$ {{tableInfo?.playersHand[7]?.coinAmount}}</div>
          </div>
          <div class="bet6">
            <div [ngClass]="betByPlayer(arrayPlayers[7]?._id) == 0 || !showIndividualPot ? 'visibility-hidden' : ''">
              <span class="bet-span2">$ {{betByPlayer(arrayPlayers[7]?._id)}}</span><img
                [src]="getChipColor(betByPlayer(arrayPlayers[7]?._id))" class="bet-img">
            </div>
          </div>
          <div class="progress-bar2"
            [ngClass]="tableInfo?.currentTurn == arrayPlayers[7]?._id && tableInfo?.round !== 'SHOWDOWN' && showActions ? '' : 'visibility-hidden'">
            <span class="progress" [ngStyle]="{ 'width': progressWidth + '%' }"
              [ngClass]="{'bg-red': progressWidth <= 25 }"></span>
          </div>
          <div *ngIf="!showdownIndex7">
            <img
              *ngIf="tableInfo?.playersHand[7]?.cards.length > 0 && tableInfo?.playersHand[7]?.player?._id !== mainPlayerId  && !isFold(7)"
              class="card2" src="../../../assets/icons/cartas-sobrepostas.png">
            <div
              [ngClass]="tableInfo?.playersHand[7]?.cards.length > 0 && tableInfo?.playersHand[7]?.player?._id !== mainPlayerId ? 'd-none': ''">
              <div class="div-card-player1" *ngIf="!isFold(7)">
                <img
                  *ngIf="tableInfo?.playersHand[7]?.cards.length > 0 && tableInfo?.playersHand[7]?.player?._id == mainPlayerId"
                  class="card-player1"
                  [src]="'../../../assets/game/cards/'  + tableInfo?.playersHand[7]?.cards[0] + '.png' ">
                <img
                  *ngIf="tableInfo?.playersHand[7]?.cards.length > 0 && tableInfo?.playersHand[7]?.player?._id == mainPlayerId"
                  class="card-player1 ms-1"
                  [src]="'../../../assets/game/cards/'  + tableInfo?.playersHand[7]?.cards[1] + '.png' ">
              </div>
            </div>
          </div>
          <div *ngIf="showdownIndex7">
            <div *ngIf="!isFold(7)">
              <div *ngFor="let item of tableInfo?.playersHand">
                <img *ngIf="tableInfo?.playersHand[0]?.cards.length > 0 && item?.player?._id == arrayPlayers[7]?._id"
                  class="card-showdown2" [src]="'../../../assets/game/cards/' + item?.cards[0] + '.png'">
                <img *ngIf="tableInfo?.playersHand[0]?.cards.length > 0 && item?.player?._id == arrayPlayers[7]?._id"
                  class="card-showdown2 ms-1" [src]="'../../../assets/game/cards/' + item?.cards[1] + '.png'">
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Jogador 7 -->
      <div [ngClass]="arrayPlayers[1] && playerIsNotInExitQueue(1) ? '' : 'visibility-hidden'">
        <div class="player7" [ngClass]="tableInfo?.currentTurn == arrayPlayers[1]?._id ? 'player-border-yellow' : ''">
          <div class="circulo1"
            [ngClass]="tableInfo?.currentTurn == arrayPlayers[1]?._id ? 'circulo-border-yellow' : ''">
            <div *ngFor="let item of tableInfo?.playersData">
              <img *ngIf="item?.user?._id == arrayPlayers[1]?._id && item?.profilePicture" class="img-fluid img-circulo"
                [src]="item?.profilePicture" alt="">
              <div class="first-letter-nickname text-capitalize"
                *ngIf="item?.user?._id == arrayPlayers[1]?._id && !item?.profilePicture">
                {{item?.nickname[0]}}</div>
            </div>
          </div>
          <div *ngFor="let item of tableInfo?.playersData">
            <div *ngIf="item?.user?._id == arrayPlayers[1]?._id" class="nickname1 text-capitalize">
              <span *ngIf="!showActionPlayer1">{{item?.nickname || ''}}</span>
              <span *ngIf="animationPlayerIndex === 1 && showActionPlayer1">{{animationResponse?.action || ''}}</span>
            </div>
          </div>
          <img [ngClass]="tableInfo?.dealerTurn == arrayPlayers[1]?._id ? '' : 'visibility-hidden'" class="dealer7"
            src="../../../assets/icons/poker-game/coin-dealer.svg">
          <div>
            <div *ngIf="arrayPlayers[1]" class="balance1">$ {{tableInfo?.playersHand[1]?.coinAmount}}</div>
          </div>
          <div class="bet7">
            <div [ngClass]="betByPlayer(arrayPlayers[1]?._id) == 0 || !showIndividualPot ? 'visibility-hidden' : ''">
              <img [src]="getChipColor(betByPlayer(arrayPlayers[1]?._id))" class="bet-img"><span class="bet-span1">$
                {{betByPlayer(arrayPlayers[1]?._id)}}</span>
            </div>
          </div>
          <div class="progress-bar1"
            [ngClass]="tableInfo?.currentTurn == arrayPlayers[1]?._id && tableInfo?.round !== 'SHOWDOWN' && showActions ? '' : 'visibility-hidden'">
            <span class="progress" [ngStyle]="{ 'width': progressWidth + '%' }"
              [ngClass]="{'bg-red': progressWidth <= 25 }"></span>
          </div>
          <div *ngIf="!showdownIndex1">
            <img
              *ngIf="tableInfo?.playersHand[1]?.cards.length > 0 && tableInfo?.playersHand[1]?.player?._id !== mainPlayerId && !isFold(1)"
              class="card1" src="../../../assets/icons/cartas-sobrepostas.png">
            <div
              [ngClass]="tableInfo?.playersHand[1]?.cards.length > 0 && tableInfo?.playersHand[1]?.player?._id !== mainPlayerId ? 'd-none': ''">
              <div class="div-card-player1" *ngIf="!isFold(1)">
                <img
                  *ngIf="tableInfo?.playersHand[1]?.cards.length > 0 && tableInfo?.playersHand[1]?.player?._id == mainPlayerId"
                  class="card-player1"
                  [src]="'../../../assets/game/cards/'  + tableInfo?.playersHand[1]?.cards[0] + '.png' ">
                <img
                  *ngIf="tableInfo?.playersHand[1]?.cards.length > 0 && tableInfo?.playersHand[1]?.player?._id == mainPlayerId"
                  class="card-player1 ms-1"
                  [src]="'../../../assets/game/cards/'  + tableInfo?.playersHand[1]?.cards[1] + '.png' ">
              </div>
            </div>
          </div>
          <div *ngIf="showdownIndex1">
            <div *ngIf="!isFold(1)">
              <div *ngFor="let item of tableInfo?.playersHand">
                <img *ngIf="tableInfo?.playersHand[0]?.cards.length > 0 && item?.player?._id == arrayPlayers[1]?._id"
                  class="card-showdown1" [src]="'../../../assets/game/cards/' + item?.cards[0] + '.png'">
                <img *ngIf="tableInfo?.playersHand[0]?.cards.length > 0 && item?.player?._id == arrayPlayers[1]?._id"
                  class="card-showdown1 ms-1" [src]="'../../../assets/game/cards/' + item?.cards[1] + '.png'">
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Jogador 8 -->
      <div [ngClass]="arrayPlayers[0] && playerIsNotInExitQueue(0) ? '' : 'visibility-hidden'">
        <div class="player8" [ngClass]="tableInfo?.currentTurn == arrayPlayers[0]?._id ? 'player-border-yellow' : ''">
          <div class="circulo1"
            [ngClass]="tableInfo?.currentTurn == arrayPlayers[0]?._id ? 'circulo-border-yellow' : ''">
            <div *ngFor="let item of tableInfo?.playersData">
              <img *ngIf="item?.user?._id == arrayPlayers[0]?._id && item?.profilePicture" class="img-fluid img-circulo"
                [src]="item?.profilePicture" alt="">
              <div class="first-letter-nickname text-capitalize"
                *ngIf="item?.user?._id == arrayPlayers[0]?._id && !item?.profilePicture">
                {{item?.nickname[0]}}</div>
            </div>
          </div>
          <div *ngFor="let item of tableInfo?.playersData">
            <div *ngIf="item?.user?._id == arrayPlayers[0]?._id" class="nickname1 text-capitalize">
              <span *ngIf="!showActionPlayer0">{{item?.nickname || ''}}</span>
              <span *ngIf="animationPlayerIndex === 0 && showActionPlayer0">{{animationResponse?.action || ''}}</span>
            </div>
          </div>
          <img [ngClass]="tableInfo?.dealerTurn == arrayPlayers[0]?._id ? '' : 'visibility-hidden'" class="dealer8"
            src="../../../assets/icons/poker-game/coin-dealer.svg">
          <div>
            <div *ngIf="arrayPlayers[0]" class="balance1">$ {{tableInfo?.playersHand[0]?.coinAmount}}</div>
          </div>
          <div class="bet8">
            <div [ngClass]="betByPlayer(arrayPlayers[0]?._id) == 0 || !showIndividualPot ? 'visibility-hidden' : ''">
              <img [src]="getChipColor(betByPlayer(arrayPlayers[0]?._id))" class="bet-img"> <span class="bet-span1">$
                {{betByPlayer(arrayPlayers[0]?._id)}}</span>
            </div>
          </div>
          <div class="progress-bar1"
            [ngClass]="tableInfo?.currentTurn == arrayPlayers[0]?._id && tableInfo?.round !== 'SHOWDOWN' && showActions ? '' : 'visibility-hidden'">
            <span class="progress" [ngStyle]="{ 'width': progressWidth + '%' }"
              [ngClass]="{'bg-red': progressWidth <= 25 }"></span>
          </div>
          <div *ngIf="!showdownIndex0">
            <img
              *ngIf="tableInfo?.playersHand[0]?.cards.length > 0 && tableInfo?.playersHand[0]?.player?._id !== mainPlayerId && !isFold(0)"
              class="card1" src="../../../assets/icons/cartas-sobrepostas.png">
            <div
              [ngClass]="tableInfo?.playersHand[0]?.cards.length > 0 && tableInfo?.playersHand[0]?.player?._id !== mainPlayerId ? 'd-none': ''">
              <div class="div-card-player1" *ngIf="!isFold(0)">
                <img
                  *ngIf="tableInfo?.playersHand[0]?.cards.length > 0 && tableInfo?.playersHand[0]?.player?._id == mainPlayerId"
                  class="card-player1"
                  [src]="'../../../assets/game/cards/'  + tableInfo?.playersHand[0]?.cards[0] + '.png' ">
                <img
                  *ngIf="tableInfo?.playersHand[0]?.cards.length > 0 && tableInfo?.playersHand[0]?.player?._id == mainPlayerId"
                  class="card-player1 ms-1"
                  [src]="'../../../assets/game/cards/'  + tableInfo?.playersHand[0]?.cards[1] + '.png' ">
              </div>
            </div>
          </div>
          <div *ngIf="showdownIndex0">
            <div *ngIf="!isFold(0)">
              <img
                *ngIf="tableInfo?.playersHand[0]?.cards.length > 0 && tableInfo?.playersHand[0]?.player?._id == arrayPlayers[0]?._id"
                class="card-showdown1"
                [src]="'../../../assets/game/cards/' + tableInfo?.playersHand[0]?.cards[0] + '.png'">
              <img
                *ngIf="tableInfo?.playersHand[0]?.cards.length > 0 && tableInfo?.playersHand[0]?.player?._id == arrayPlayers[0]?._id"
                class="card-showdown1 ms-1"
                [src]="'../../../assets/game/cards/' + tableInfo?.playersHand[0]?.cards[1] + '.png'">
            </div>
          </div>
        </div>
      </div>

      <!-- Jogador 9 -->
      <div [ngClass]="arrayPlayers[8] && playerIsNotInExitQueue(8) ? '' : 'visibility-hidden'">
        <div class="player9" [ngClass]="tableInfo?.currentTurn == arrayPlayers[8]?._id ? 'player-border-yellow' : ''">
          <div class="circulo2"
            [ngClass]="tableInfo?.currentTurn == arrayPlayers[8]?._id ? 'circulo-border-yellow' : ''">
            <div *ngFor="let item of tableInfo?.playersData">
              <img *ngIf="item?.user?._id == arrayPlayers[8]?._id && item?.profilePicture" class="img-fluid img-circulo"
                [src]="item?.profilePicture" alt="">
              <div class="first-letter-nickname text-capitalize"
                *ngIf="item?.user?._id == arrayPlayers[8]?._id && !item?.profilePicture">
                {{item?.nickname[0]}}</div>
            </div>
          </div>
          <div *ngFor="let item of tableInfo?.playersData">
            <div *ngIf="item?.user?._id == arrayPlayers[8]?._id" class="nickname2 text-capitalize">
              <span *ngIf="!showActionPlayer8">{{item?.nickname || ''}}</span>
              <span *ngIf="animationPlayerIndex === 8 && showActionPlayer8">{{animationResponse?.action || ''}}</span>
            </div>
          </div>
          <img [ngClass]="tableInfo?.dealerTurn == arrayPlayers[8]?._id ? '' : 'visibility-hidden'" class="dealer9"
            src="../../../assets/icons/poker-game/coin-dealer.svg">
          <div>
            <div *ngIf="arrayPlayers[8]" class="balance2">$ {{tableInfo?.playersHand[8]?.coinAmount}}</div>
          </div>
          <div class="bet9">
            <div [ngClass]="betByPlayer(arrayPlayers[8]?._id) == 0 || !showIndividualPot ? 'visibility-hidden' : ''">
              <span class="bet-span2">$ {{betByPlayer(arrayPlayers[8]?._id)}}</span><img
                [src]="getChipColor(betByPlayer(arrayPlayers[8]?._id))" class="bet-img">
            </div>
          </div>
          <div class="progress-bar2"
            [ngClass]="tableInfo?.currentTurn == arrayPlayers[8]?._id && tableInfo?.round !== 'SHOWDOWN' && showActions ? '' : 'visibility-hidden'">
            <span class="progress" [ngStyle]="{ 'width': progressWidth + '%' }"
              [ngClass]="{'bg-red': progressWidth <= 25 }"></span>
          </div>
          <div *ngIf="!showdownIndex8">
            <img
              *ngIf="tableInfo?.playersHand[8]?.cards.length > 0 && tableInfo?.playersHand[8]?.player?._id !== mainPlayerId && !isFold(8)"
              class="card2" src="../../../assets/icons/cartas-sobrepostas.png">
            <div
              [ngClass]="tableInfo?.playersHand[8]?.cards.length > 0 && tableInfo?.playersHand[8]?.player?._id !== mainPlayerId ? 'd-none': ''">
              <div class="div-card-player1" *ngIf="!isFold(8)">
                <img
                  *ngIf="tableInfo?.playersHand[8]?.cards.length > 0 && tableInfo?.playersHand[8]?.player?._id == mainPlayerId"
                  class="card-player1"
                  [src]="'../../../assets/game/cards/'  + tableInfo?.playersHand[8]?.cards[0] + '.png' ">
                <img
                  *ngIf="tableInfo?.playersHand[8]?.cards.length > 0 && tableInfo?.playersHand[8]?.player?._id == mainPlayerId"
                  class="card-player1 ms-1"
                  [src]="'../../../assets/game/cards/'  + tableInfo?.playersHand[8]?.cards[1] + '.png' ">
              </div>
            </div>
          </div>
          <div *ngIf="showdownIndex8">
            <div *ngIf="!isFold(8)">
              <div *ngFor="let item of tableInfo?.playersHand">
                <img *ngIf="tableInfo?.playersHand[0]?.cards.length > 0 && item?.player?._id == arrayPlayers[8]?._id"
                  class="card-showdown2" [src]="'../../../assets/game/cards/' + item?.cards[0] + '.png'">
                <img *ngIf="tableInfo?.playersHand[0]?.cards.length > 0 && item?.player?._id == arrayPlayers[8]?._id"
                  class="card-showdown2 ms-1" [src]="'../../../assets/game/cards/' + item?.cards[1] + '.png'">
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div class="pot">
          Pot: <span class="bet-span1">$ {{getPotValue()}}</span>
        </div>
      </div>

      <!-- Animação (FOLD) das cartas saindo do jogador para a mesa -->
      <img id="cartasSobrepostasIndex0" class="card1" src="../../../assets/icons/cartas-sobrepostas.png">
      <img id="cartasSobrepostasIndex1" class="card1" src="../../../assets/icons/cartas-sobrepostas.png">
      <img id="cartasSobrepostasIndex2" class="card1" src="../../../assets/icons/cartas-sobrepostas.png">
      <img id="cartasSobrepostasIndex3" class="card1" src="../../../assets/icons/cartas-sobrepostas.png">
      <img id="cartasSobrepostasIndex4" class="card1" src="../../../assets/icons/cartas-sobrepostas.png">
      <img id="cartasSobrepostasIndex5" class="card1" src="../../../assets/icons/cartas-sobrepostas.png">
      <img id="cartasSobrepostasIndex6" class="card1" src="../../../assets/icons/cartas-sobrepostas.png">
      <img id="cartasSobrepostasIndex7" class="card1" src="../../../assets/icons/cartas-sobrepostas.png">
      <img id="cartasSobrepostasIndex8" class="card1" src="../../../assets/icons/cartas-sobrepostas.png">

      <!-- Animação (BET CALL RAISE E ALLIN) das fichas saindo do jogador para a mesa -->
      <img id="ChipsFromHandToTableIndex0" [src]="getChipColorFromHandToTable()">
      <img id="ChipsFromHandToTableIndex1" [src]="getChipColorFromHandToTable()">
      <img id="ChipsFromHandToTableIndex2" [src]="getChipColorFromHandToTable()">
      <img id="ChipsFromHandToTableIndex3" [src]="getChipColorFromHandToTable()">
      <img id="ChipsFromHandToTableIndex4" [src]="getChipColorFromHandToTable()">
      <img id="ChipsFromHandToTableIndex5" [src]="getChipColorFromHandToTable()">
      <img id="ChipsFromHandToTableIndex6" [src]="getChipColorFromHandToTable()">
      <img id="ChipsFromHandToTableIndex7" [src]="getChipColorFromHandToTable()">
      <img id="ChipsFromHandToTableIndex8" [src]="getChipColorFromHandToTable()">

      <!-- Animação das fichas saindo do pote para os vencedores -->
      <img id="giveChipsToWinnersIndex0" [src]="getChipColorToWinners()">
      <img id="giveChipsToWinnersIndex1" [src]="getChipColorToWinners()">
      <img id="giveChipsToWinnersIndex2" [src]="getChipColorToWinners()">
      <img id="giveChipsToWinnersIndex3" [src]="getChipColorToWinners()">
      <img id="giveChipsToWinnersIndex4" [src]="getChipColorToWinners()">
      <img id="giveChipsToWinnersIndex5" [src]="getChipColorToWinners()">
      <img id="giveChipsToWinnersIndex6" [src]="getChipColorToWinners()">
      <img id="giveChipsToWinnersIndex7" [src]="getChipColorToWinners()">
      <img id="giveChipsToWinnersIndex8" [src]="getChipColorToWinners()">

      <!-- Animação das cartas indo para os jogadores quando antes do jogo começar -->
      <img id="setCardsToPlayersIndex0" class="card1" src="../../../assets/icons/cartas-sobrepostas.png">
      <img id="setCardsToPlayersIndex1" class="card1" src="../../../assets/icons/cartas-sobrepostas.png">
      <img id="setCardsToPlayersIndex2" class="card1" src="../../../assets/icons/cartas-sobrepostas.png">
      <img id="setCardsToPlayersIndex3" class="card1" src="../../../assets/icons/cartas-sobrepostas.png">
      <img id="setCardsToPlayersIndex4" class="card1" src="../../../assets/icons/cartas-sobrepostas.png">
      <img id="setCardsToPlayersIndex5" class="card1" src="../../../assets/icons/cartas-sobrepostas.png">
      <img id="setCardsToPlayersIndex6" class="card1" src="../../../assets/icons/cartas-sobrepostas.png">
      <img id="setCardsToPlayersIndex7" class="card1" src="../../../assets/icons/cartas-sobrepostas.png">
      <img id="setCardsToPlayersIndex8" class="card1" src="../../../assets/icons/cartas-sobrepostas.png">

      <!-- Animação das fichas saindo do pote de cada jogador e indo para o pote principal -->
      <img id="giveChipsToPotIndex0" [src]="getChipColor(betByPlayer(arrayPlayers[0]?._id))">
      <img id="giveChipsToPotIndex1" [src]="getChipColor(betByPlayer(arrayPlayers[1]?._id))">
      <img id="giveChipsToPotIndex2" [src]="getChipColor(betByPlayer(arrayPlayers[2]?._id))">
      <img id="giveChipsToPotIndex3" [src]="getChipColor(betByPlayer(arrayPlayers[3]?._id))">
      <img id="giveChipsToPotIndex4" [src]="getChipColor(betByPlayer(arrayPlayers[4]?._id))">
      <img id="giveChipsToPotIndex5" [src]="getChipColor(betByPlayer(arrayPlayers[5]?._id))">
      <img id="giveChipsToPotIndex6" [src]="getChipColor(betByPlayer(arrayPlayers[6]?._id))">
      <img id="giveChipsToPotIndex7" [src]="getChipColor(betByPlayer(arrayPlayers[7]?._id))">
      <img id="giveChipsToPotIndex8" [src]="getChipColor(betByPlayer(arrayPlayers[8]?._id))">

      <div>
        <img *ngIf="tableInfo?.flopCards[0] && tableInfo?.round !== 'PRE_FLOP'" class="flop-cards"
          [src]="'../../../assets/game/cards/' + tableInfo?.flopCards[0] + '.png'">
        <img *ngIf="tableInfo?.flopCards[1] && tableInfo?.round !== 'PRE_FLOP'" class="flop-cards ms-1"
          [src]="'../../../assets/game/cards/' + tableInfo?.flopCards[1] + '.png'">
        <img *ngIf="tableInfo?.flopCards[2] && tableInfo?.round !== 'PRE_FLOP'" class="flop-cards ms-1"
          [src]="'../../../assets/game/cards/' + tableInfo?.flopCards[2] + '.png'">
        <img
          *ngIf="tableInfo?.flopCards[3] && (tableInfo?.round == 'TURN' || tableInfo?.round == 'RIVER'  || tableInfo?.round == 'SHOWDOWN')"
          class="flop-cards ms-1" [src]="'../../../assets/game/cards/' + tableInfo?.flopCards[3] + '.png'">
        <img *ngIf="tableInfo?.flopCards[4] && (tableInfo?.round == 'RIVER'  || tableInfo?.round == 'SHOWDOWN')"
          class="flop-cards ms-1" [src]="'../../../assets/game/cards/' + tableInfo?.flopCards[4] + '.png'">
      </div>

      <!-- <button class="btn-background-active-div subtitle-700-lg text-white p-2" style="position: absolute; top: 0;" (click)="animacao()">ANIMACAO</button> -->

    </div>

  </div>

  <div class="d-flex flex-column p-5 div-actions" style="max-width: 1000px;">
    <div
      *ngIf="tableInfo?.currentTurn == mainPlayerId && showActions && tableInfo?.round !== 'SHOWDOWN' && canActions()"
      class="d-flex flex-fill justify-content-end" style="height: 30px;">
      <button class="btn-background-active-div subtitle-700-lg text-white p-2" (click)="fold()">FOLD</button>
      <button *ngIf="canCheck()" class="btn-background-active-div subtitle-700-lg text-white p-2"
        (click)="check()">CHECK</button>
      <button *ngIf="canCall() && !canCheck()" class="btn-background-active-div subtitle-700-lg text-white p-2"
        (click)="call()">CALL</button>
      <button *ngIf="canRaise() && this.getTopBetRound() == 0"
        class="btn-background-active-div subtitle-700-lg text-white p-2" (click)="raise()">BET</button>
      <button *ngIf="canRaise() && this.getTopBetRound() > 0"
        class="btn-background-active-div subtitle-700-lg text-white p-2" (click)="raise()">RAISE</button>
      <button *ngIf="canAllIn()" class="btn-background-active-div subtitle-700-lg text-white p-2" (click)="allIn()">ALLIN</button>
    </div>
    <div
      *ngIf="tableInfo?.currentTurn == mainPlayerId && showActions && tableInfo?.round !== 'SHOWDOWN' && canActions()"
      class="d-flex flex-fill justify-content-end">
      <form [formGroup]="form">
        <div *ngIf="canRaise()" class="d-flex flex-fill">
          <div class="px-2 d-flex flex-column">
            <div class="text-white">Mín</div>
          </div>
          <input type="range" class="form-range" formControlName="bet" [min]="minRaiseValue"
            [max]="getMaxAmount(mainPlayerId) + betByPlayer(mainPlayerId)" id="buyInRange"
            (change)="listenedRange($event, 'range')">
          <div class="px-2 d-flex flex-column">
            <div class="text-white">Max</div>
          </div>
        </div>
        <div *ngIf="canRaise()" class="d-flex">
          <label for="customRange2" class="form-label text-white">Apostar:</label>
          <input type="text" class="form-control input-custom-value-range" formControlName="valueRange"
            id="customRange2" (keyup)="listenedRange($event, 'input')">
        </div>
      </form>
    </div>
  </div>

</section>


<!-- Modal -->
<div class="modal fade" id="modalBuyCoin" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="modalBuyCoinLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content bg-dark text-white p-3">
      <div class="modal-header">
        <h2 class="heading-700-sm ">Comprar Fichas</h2>
        <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="d-flex justify-content-end">
          Saldo&nbsp;<span *ngIf="tableInfo?.coin === 'USDT'"> US $ {{balanceValue?.coinUsdValue}}</span>
          <span *ngIf="tableInfo?.coin === 'POKERFI'"> PKF {{balanceValue?.coinPokerFiValue}}</span>
        </div>
        <form [formGroup]="formBuyCoin">
          <div class="d-flex flex-fill flex-column">

            <div class="d-flex align-items-center mb-3">
              <label for="customRange2" class="form-label text-white mb-0">Quantia de Buy-in:
              </label>
              <input type="text" class="form-control input-custom-value-range" formControlName="valueRangeBuyCoin"
                id="customRange2" (keyup)="listenedRangeBuyCoin($event, 'input')">
            </div>
            <div class="d-flex flex-fill">
              <div class="px-2 d-flex flex-column">
                <div>Mín</div>
              </div>
              <input type="range" class="form-range" formControlName="buyCoin" [min]="buyCoinMin" [max]="buyCoinMax"
                id="buyInRange" (change)="listenedRangeBuyCoin($event, 'range')">
              <div class="px-2 d-flex flex-column">
                <div>Max</div>
              </div>
            </div>

            <div class="d-flex justify-content-between">
              <span><span *ngIf="tableInfo?.coin === 'USDT'">US $ </span><span *ngIf="tableInfo?.coin === 'POKERFI'">PKF
                </span>{{buyCoinMin}}</span>
              <span><span *ngIf="tableInfo?.coin === 'USDT'">US $ </span><span *ngIf="tableInfo?.coin === 'POKERFI'">PKF
                </span>{{buyCoinMax}}</span>
            </div>


          </div>
        </form>
      </div>
      <div class="modal-footer">
        <div class="d-flex flex-fill justify-content-between">
          <button id="cancelBuyCoin" class="btn-delete button-bold w-50" data-bs-dismiss="modal">Cancelar</button>
          <button class="btn-continue button-bold w-50" (click)="buyCoin()">Comprar</button>
        </div>
      </div>
    </div>
  </div>
</div>
