<section class="container section-auth d-flex align-items-center">
  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
  </ngx-spinner>
  <div class="col-12 col-md-10 col-lg-6">
    <h2 class="heading-700-md">{{ 'PASSWORD_PAGE.TITLE' | translate }}</h2>
    <p class="subtitle my-3">{{ 'PASSWORD_PAGE.SUBTITLE_START' | translate }}
      <span>{{ 'PASSWORD_PAGE.SUBTITLE_END' | translate}}</span>
    </p>
    <form class="form" [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="row d-flex flex-row mt-3">
        <div class="col-sm position-relative">
          <input [type]="showPassword?'text':'password'" formControlName="password" class="form-control input-box"
            placeholder="{{ 'PASSWORD_PAGE.PASSWORD' | translate }}">
          <img src="../../../assets/icons/password-icon.svg" class="icon-input cursor-pointer" width="20" height="16" (click)="showPassword=!showPassword" />
        </div>
        <div class="col-sm mt-3 mt-sm-0 position-relative">
          <input [type]="showConfirmPassword?'text':'password'" formControlName="confirmPassword"  class="form-control input-box"
            placeholder="{{ 'PASSWORD_PAGE.CONFIRMPASSWORD' | translate }}">
          <img src="../../../assets/icons/password-icon.svg" class="icon-input cursor-pointer" width="20" height="16" (click)="showConfirmPassword=!showConfirmPassword" />
        </div>
      </div>
      <div class="d-flex justify-content-center align-items-center mt-4">
        <button [ngClass]="!form.valid?'btn-continue-disable':'btn-continue'" class="button-bold">{{ 'PASSWORD_PAGE.BUTTON' | translate }}</button>
      </div>
      <div class="d-flex flex-row justify-content-start align-items-center mt-4">
        <input type="checkbox" formControlName="confirm" [value]="true" />
        <span class="checkbox-label ms-3">{{ 'PASSWORD_PAGE.TEXT' | translate }}</span>
      </div>
    </form>
  </div>
</section>
