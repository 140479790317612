import { Component, HostListener, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-logged',
  templateUrl: './logged.component.html',
  styleUrls: ['./logged.component.scss']
})
export class LoggedComponent implements OnInit {

  constructor(
    public translate: TranslateService,
  ) {
    translate.addLangs(['en', 'pt', 'es']);
    translate.setDefaultLang('en');

    const locStorageValue = localStorage.getItem('lan');
    if (locStorageValue) {
      translate.use(locStorageValue);
    } else {
      translate.use('en');
      localStorage.setItem('lan', 'en');
    }
  }

  ngOnInit(): void {
    this.initializeGameSockets();
  }

  initializeGameSockets() {
    // this.commonSocketService.initializeMain();
    // this.tableGameSocketService.initializeMain();
  }

  @HostListener('window:beforeunload', ['$event'])
  handleShutDownSocketRoutine() {
    // this.commonSocketService.shutDownAll();
    // this.tableGameSocketService.shutDownAll();
  }
}
