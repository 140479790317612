<section class="bg-dark">
  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="default" type="square-jelly-box" [fullScreen]="false">
    <p style="color: white"> Loading... </p>
  </ngx-spinner>
  <div class="container">
    <div class="row">
      <div class="col-lg-3 pb-5">
        <div class="card h-746 card-scroll p-3">
          <div class="d-flex justify-content-center mb-3">
            <div class="profile-picture position-relative">
              <label for="pictureProfile" class="btn-change-picture"><img
                  src="../../../assets/icons/icon-changeProfile.svg" width="15">
              </label>
              <div *ngIf="authService.getAuthenticatedUser()?.profilePicture">
                <img [src]="authService.getAuthenticatedUser()?.profilePicture" class="foto-perfil">
              </div>
              <div *ngIf="!authService.getAuthenticatedUser()?.profilePicture"
                class="without-profile-picture text-capitalize">
                {{authService.getAuthenticatedUser()?.nickname[0]}}
              </div>
            </div>
            <input class="d-none" id='pictureProfile' type='file' (change)="onSelectFile($event)"
              accept="image/png, image/jpeg">
          </div>

          <div class="accordion" id="accordionProfile">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingInformations">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseInformations" aria-expanded="false" aria-controls="collapseInformations">
                  <img src="../../../assets/icons/icon-card-profile.svg">
                  <p class="body-400-lg text-white mb-0 ms-3">{{ 'PROFILE.INFORMATIONS' | translate }}</p>
                </button>
              </h2>
              <div id="collapseInformations" class="accordion-collapse collapse" aria-labelledby="headingInformations"
                data-bs-parent="#accordionProfile">
                <div class="accordion-body p-0">
                  <form [formGroup]="formInformations">
                    <div class="position-relative">
                      <input type="text" formControlName="name" class="form-control input-box body-300-md" readonly
                        (keyup)="compareInformations($event, 'name')">
                      <img src="../../../assets/icons/user-icon.svg" class="icon-input" width="20" height="16" />
                    </div>
                    <div class="position-relative my-3">
                      <input type="text" formControlName="phone" [mask]="phoneMask"
                        class="form-control input-box body-300-md" readonly
                        (keyup)="compareInformations($event, 'phone')">
                      <img src="../../../assets/icons/phone-icon.svg" class="icon-input" width="20" height="16" />
                    </div>
                    <div class="position-relative pb-2">
                      <input type="text" formControlName="email" readonly class="form-control input-box body-300-md"
                        readonly>
                      <img src="../../../assets/icons/email-icon.svg" class="icon-input" width="20" height="16" />
                    </div>
                  </form>
                </div>
                <div *ngIf="formCompareUserInfo" class="row justify-content-end align-item-center">
                  <div class="col-3"><img src="../../../assets/icons/icon-x-red.svg" class="cursor-pointer"></div>
                  <div class="col-3"><img src="../../../assets/icons/icon-check.svg" class="cursor-pointer"
                      (click)="editUser()"></div>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingAddress">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseAddress" aria-expanded="false" aria-controls="collapseAddress">
                  <img src="../../../assets/icons/state-icon-white.svg">
                  <p class="body-400-lg text-white mb-0 ms-3">{{ 'PROFILE.ADDRESS' | translate }}</p>
                </button>
              </h2>
              <div id="collapseAddress" class="accordion-collapse collapse" aria-labelledby="headingAddress"
                data-bs-parent="#accordionProfile">
                <div class="accordion-body p-0">
                  <form [formGroup]="formAddress">
                    <div class="position-relative">
                      <input type="text" formControlName="postalCode" class="form-control input-box body-300-md"
                        readonly placeholder="{{ 'PROFILE.POSTAL_CODE' | translate }}"
                        (keyup)="compareInformationsAddress($event, 'postalCode')">
                      <img src="../../../assets/icons/cep-icon.svg" class="icon-input" width="20" height="16" />
                    </div>
                    <div class="position-relative my-3">
                      <input type="text" formControlName="state" class="form-control input-box body-300-md"
                        placeholder="{{ 'PROFILE.STATE' | translate }}" readonly
                        (keyup)="compareInformationsAddress($event, 'state')">
                      <img src="../../../assets/icons/state-icon.svg" class="icon-input" width="20" height="16" />
                    </div>
                    <div class="position-relative pb-2">
                      <input type="text" formControlName="city" class="form-control input-box body-300-md"
                        placeholder="{{ 'PROFILE.CITY' | translate }}" readonly
                        (keyup)="compareInformationsAddress($event, 'city')">
                      <img src="../../../assets/icons/city-icon.svg" class="icon-input" width="20" height="16" />
                    </div>
                  </form>
                </div>
                <div *ngIf="formCompareAddress" class="row justify-content-end align-item-center">
                  <div class="col-3"><img src="../../../assets/icons/icon-x-red.svg" class="cursor-pointer"></div>
                  <div class="col-3"><img src="../../../assets/icons/icon-check.svg" class="cursor-pointer"
                      (click)="editUserAddress()"></div>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingDocument">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseDocument" aria-expanded="false" aria-controls="collapseDocument">
                  <img src="../../../assets/icons/icon-card-profile.svg">
                  <p class="body-400-lg text-white mb-0 ms-3">{{ 'PROFILE.DOCUMENT' | translate }}</p>
                </button>
              </h2>
              <div id="collapseDocument" class="accordion-collapse collapse" aria-labelledby="headingThree"
                data-bs-parent="#accordionProfile">
                <div class="accordion-body p-0">
                  <p *ngIf="this.kycRegistered === false" class="body-400-lg text-white mb-2 ms-3">{{
                    'PROFILE.TEXT_DOCUMENT' | translate }}</p>
                  <p *ngIf="this.kycRegistered === true" class="body-400-lg text-white mb-2 ms-3">{{
                    'PROFILE.STATUS_DOCUMENT' | translate }} <br>
                    <span *ngIf="statusKyc === 'PENDING'">{{'PROFILE.PENDING' | translate }}</span>
                    <span *ngIf="statusKyc === 'UNCHECKED'">{{'PROFILE.UNCHECKED' | translate }}</span>
                    <span *ngIf="statusKyc === 'CHECKED'">{{'PROFILE.CHECKED' | translate }}</span>
                  </p>
                  <div class="card-add-money cursor-pointer">
                    <div class="d-flex justify-content-between align-items-center custom-file-upload">
                      <label *ngIf="this.kycRegistered === false" class="d-flex align-items-center w-100 p-2">
                        <i class="bi bi-images" style="color: #4A35E8;"></i>
                        <input type="file" accept="application/pdf, image/*" (change)="handleGallerySelection($event)">
                      </label>
                    </div>
                  </div>
                  <!-- <img class="img-fluid mt-3" [src]="capturedImage"> -->

                  <button *ngIf="this.kycRegistered === false" [disabled]="!capturedImage" class="btn-activate-2fa mt-3"
                    (click)="uploadImage()">{{ 'PROFILE.SEND' | translate }}</button>
                </div>
              </div>
            </div>
          </div>
          <div style="flex:1"></div>
          <!-- <div class="d-flex justify-content-center mt-2">
            <button class="btn-activate-2fa" (click)="open2FA()">{{!isActive ?( 'PROFILE.BUTTON' | translate
              ):('PROFILE.BUTTON_ACTIVE'| translate)}}</button>
          </div> -->
          <!-- <div class="d-flex justify-content-center mt-2">
            <button class="btn-delete-account" (click)="openModalDelete()">{{ 'PROFILE.BUTTON_DELETE' | translate
              }}</button>
          </div> -->
        </div>
      </div>
      <div class="col-lg-3 pb-5">

        <div class="card h-746 card-scroll p-3">
          <h2 class="heading-700-sm text-white py-2 ps-1">{{ 'PROFILE.WALLET' | translate }}</h2>
          <div class="row">
            <div class="col-md-6">
              <div class="card px-2 py-3 mt-2">
                <div class="row">
                  <div class="col d-flex flex-column">
                    <p class="subtitle-700-md text-white mb-1">{{ 'PROFILE.BALANCE' | translate }} <span
                        class="font-color-yellow">PKF</span></p>
                    <p class="heading-500-sm text-white mb-0">{{pkfToken | currency}}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="card px-2 py-3 mt-2">
                <div class="row">
                  <div class="col d-flex flex-column">
                    <p class="subtitle-700-md text-white mb-1">{{ 'PROFILE.BALANCE' | translate }} <span
                        class="font-color-lightBlue">BNB</span></p>
                    <p class="heading-500-sm text-white mb-0">{{bnbToken | currency}}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="card px-2 py-3 mt-2">
                <div class="row">
                  <div class="col d-flex flex-column">
                    <p class="subtitle-700-md text-white mb-1">{{ 'PROFILE.BALANCE' | translate }} <span
                        class="font-color-lightGreen">USDT</span></p>
                    <p class="heading-500-sm text-white mb-0">{{usdtToken | currency}}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6"></div>
          </div>
          <div class="mt-5 mb-3">
            <h2 class="heading-500-md fw-700 text-white">{{ 'PROFILE.BUY_COIN' | translate }}</h2>
            <div class="d-flex justify-content-center mt-2">
              <button class="btn-activate-2fa" (click)="openModalBuyCoin()">{{ 'PROFILE.BUY' | translate }}</button>
            </div>
          </div>
          <hr>
          <div class="my-3">
            <h2 class="heading-500-md fw-700 text-white">{{ 'PROFILE.WITHDRAWAL_REQUEST' | translate }}</h2>
            <div class="d-flex justify-content-center mt-2">
              <button class="btn-activate-2fa" (click)="openModalRequestWithdrawal()">{{ 'PROFILE.REQUEST' | translate
                }}</button>
            </div>
          </div>
          <hr>
          <div class="my-3">
            <h2 class="heading-500-md fw-700 text-white">{{ 'PROFILE.WITHDRAWAL_RAKEBACK' | translate }}</h2>
            <div class="d-flex justify-content-center mt-2">
              <button class="btn-activate-2fa" (click)="openModalWithdrawRakeback()">{{ 'PROFILE.REQUEST' | translate
                }}</button>
            </div>
          </div>
        </div>

      </div>
      <div class="col-lg-6 pb-5">
        <div class="card h-746">
          <h2 class="heading-700-sm text-white pt-4 ps-3">{{ 'PROFILE.HISTORY' | translate }}</h2>
          <div class="table-responsive">
            <table class="table table-dark table-striped mt-3">
              <thead>
                <tr class="body-400-lg text-white">
                  <th class="color-yellow text-center">{{'PROFILE.TYPE' | translate}}</th>
                  <th class="color-yellow text-center">{{'PROFILE.DATE' | translate}}</th>
                  <th class="color-yellow text-center">{{'PROFILE.COIN' | translate}}</th>
                  <th class="color-yellow text-center">Status</th>
                  <th class="color-yellow text-center">{{'PROFILE.VALUES' | translate}}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of movements;" (click)="openPix(item)"
                  [ngClass]="item.pix && item.pix !== '' && item.status == 'PENDING' ? 'cursor-pointer' : ''">
                  <td class="pt-0" [ngClass]="item.type == 'OUT'? 'text-danger': 'text-success'">{{item.type}}</td>
                  <td class="pt-0">{{item.createdAt | date: 'dd/MM/yy HH:mm'}}</td>
                  <td class="pt-0">{{item.coin}}</td>
                  <td class="pt-0"
                    [ngClass]="[item.status == 'CANCELED' ? 'text-danger': '', item.status == 'PENDING' ? 'text-warning': '']">
                    {{item.status}}</td>
                  <td class="pt-0">{{item.value}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- MODAL Sem KYC -->
<ng-template #modalKyc>
  <section class="background-modal p-3">
    <div (click)="exit()" class="d-flex justify-content-end cursor-pointer">
      <img src="../../../../assets/icons/icon-x.svg" />
    </div>
    <div class="d-flex flex-column justify-content-center align-items-center">
      <h6 class="heading-700-sm text-white">{{ 'PROFILE.MODAL_REQUEST.KYC' | translate }}</h6>
      <h2 class="heading-500-sm text-white text-center">{{ 'PROFILE.MODAL_REQUEST.TEXT_MODAL_KYC' | translate }}</h2>
    </div>
  </section>
</ng-template>