export class SitAtTableGameRequestDto {

  constructor(
    public tableGameId: string,
    public positionInArrayOfPlayers: number,
    public buyIn: number,
  ) {

  }

}
