export const environment = {
  production: false,
  payloadKey: 'a0d2f9de-cf38-4a1a-9f5f-86ead3e6864f',
  hotWallet: '0x9c6Af501c99FEfCBCfA24f2ea05CE3b8F98903b3',
  apis: {
    gameBackoffice: 'https://pokergame.codefabrik.dev/',
    wallet: 'https://pokerfiwallet.codefabrik.dev/',
    observable: 'https://pokergame.codefabrik.dev/observable/',
    binanceApi: 'https://www.binance.com/api/v3/',
  },
  socket: {
    common: {
      host: 'https://pokergame.codefabrik.dev/',
      path: '/socket/general'
    },
    tableGame: {
      host: 'https://pokergame.codefabrik.dev/',
      path: '/socket/game'
    },
    chat: {
      host: 'https://pokergame.codefabrik.dev/',
      path: '/socket/chat'
    }
  }

};
