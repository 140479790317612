import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler, HttpInterceptor, HttpRequest,
  HttpResponse
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from "@angular/router";
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { AuthService } from "../services/auth.service";

@Injectable()
export class BackofficeRequestInterceptor implements HttpInterceptor {
  constructor(
    private readonly authService: AuthService,
    private readonly ngxSpinnerService: NgxSpinnerService,
    private readonly router: Router,
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      map((resp) => {
        if (request.url.includes(environment.apis.gameBackoffice)) {
          if (resp instanceof HttpResponse) {
            return resp.clone({
              body: resp.body.data ? resp.body.data : null,
            });
          }
        }
        return resp;
      }),
      catchError((err: HttpErrorResponse) => {
        if (request.url.includes(environment.apis.gameBackoffice)) {
          if (err.status === 401) {
            this.authService.removeAuthenticatedUser();
            if (!this.router.url.includes('sign-in')) {
              this.router.navigate(['/']);
            }
            this.ngxSpinnerService.hide();
            throw err;
          }
        }
        this.ngxSpinnerService.hide();
        throw err;
      })
    );
  }
}
