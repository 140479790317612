import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AuthResponseDTO } from 'src/app/dtos/user/auth-response.dto';
import { UserChangePassword } from 'src/app/dtos/user/user-change-password.dto';
import { UserConfirmationDeleteRequestDto } from 'src/app/dtos/user/user-confirmation-delete-request.dto';
import { UserConfirmationRequestDto } from 'src/app/dtos/user/user-confirmation-register-request.dto';
import { UserDeleteAccountDto } from 'src/app/dtos/user/user-delete-account.dto';
import { UserPersonalInfo } from 'src/app/dtos/user/user-personal-info-register-request.dto';
import { UserRegisterRequestDto } from 'src/app/dtos/user/user-register-request.dto';
import { UserRegisterResponseDto } from 'src/app/dtos/user/user-register-response.dto';
import { UserResetPasswordVerification } from 'src/app/dtos/user/user-reset-password-verification.dto';
import { UserUpdateImageRequestDto } from 'src/app/dtos/user/user-update-image-request.dto';
import { UserUpdatePassword } from 'src/app/dtos/user/user-update-password.dto';
import { UserInterface } from 'src/app/dtos/user/user.interface';
import { UserAddressInfoUpdateDto } from './../app/dtos/user/user-address-info-update.dto';
import { UserPersonalInfoUpdateDto } from './../app/dtos/user/user-personal-info-update.dto';
import { BaseService } from './base.service';
import { FinalizeRegistrationRequestDto } from 'src/app/dtos/user/finalize-registration-request.dto';
import { UpdateProfilePictureRequestDto } from 'src/app/dtos/user/update-profile-picture-request';

@Injectable({
  providedIn: 'root',
})
export class UserService extends BaseService {
  userApi: string = `${this.gameBackoffice}user`;

  constructor(private httpClient: HttpClient) {
    super();
  }

  getUser(): Observable<UserInterface> {
    return this.httpClient.get<UserInterface>(`${this.userApi}/get-user`, this.authorizedHeader );
  }

  // checkIfUsernameExists(username: string) {
  //   return this.httpClient.get(
  //     `${this.userApi}/nickname-available?nickname=${username}`,
  //     this.anonymousHeader
  //   );
  // }

  // register(dto: UserRegisterRequestDto): Observable<UserRegisterResponseDto> {
  //   return this.httpClient.post<UserRegisterResponseDto>(
  //     `${this.userApi}/register-user`,
  //     dto,
  //     this.anonymousHeader
  //   );
  // }

  // resendEmail(email: string): Observable<UserRegisterResponseDto> {
  //   return this.httpClient.post<UserRegisterResponseDto>(
  //     `${this.userApi}/resend-email/${email}`,
  //     {},
  //     this.anonymousHeader
  //   );
  // }

  // registerAddress(
  //   email: string,
  //   dto: UserPersonalInfo
  // ): Observable<UserRegisterResponseDto> {
  //   return this.httpClient.put<UserRegisterResponseDto>(
  //     `${this.userApi}/register-address/email/${email}`,
  //     dto,
  //     this.anonymousHeader
  //   );
  // }

  // vericationCode(
  //   code: number,
  //   email: string
  // ): Observable<{ isValid: boolean }> {
  //   return this.httpClient.get<{ isValid: boolean }>(
  //     `${this.userApi}/verify-code/${email}/${code}`,
  //     this.anonymousHeader
  //   );
  // }

  // registerPassword(
  //   dto: UserConfirmationRequestDto
  // ): Observable<UserRegisterResponseDto> {
  //   return this.httpClient.post<UserRegisterResponseDto>(
  //     `${this.userApi}/register-password`,
  //     this.encrypt(JSON.stringify(dto)),
  //     this.anonymousHeader
  //   );
  // }

  // auth(dto: { email: string; password: string }): Observable<AuthResponseDTO> {
  //   return this.httpClient.post<AuthResponseDTO>(
  //     `${this.authApi}/authenticate`,
  //     this.encrypt(JSON.stringify(dto)),
  //     this.anonymousHeader
  //   );
  // }

  // passwordConfirmToDelete(dto: UserConfirmationDeleteRequestDto) {
  //   return this.httpClient.post<UserConfirmationDeleteRequestDto>(
  //     `${this.userApi}/password-to-delete`,
  //     dto,
  //     this.authorizedHeader
  //   );
  // }

  // deleteAccount(): Observable<UserDeleteAccountDto> {
  //   return this.httpClient.delete<UserDeleteAccountDto>(
  //     `${this.userApi}/delete-user`,
  //     this.authorizedHeader
  //   );
  // }

  // resetPassword(dto: UserChangePassword): Observable<UserRegisterResponseDto> {
  //   return this.httpClient.put<UserRegisterResponseDto>(
  //     `${this.userApi}/reset-password-confirmation`,
  //     this.encrypt(JSON.stringify(dto)),
  //     this.anonymousHeader
  //   );
  // }

  // updatePassword(dto: UserUpdatePassword): Observable<any> {
  //   return this.httpClient.put(
  //     `${this.userApi}/update-password`,
  //     this.encrypt(JSON.stringify(dto)),
  //     this.authorizedHeader
  //   );
  // }

  // resetPasswordVerification(
  //   dto: UserResetPasswordVerification
  // ): Observable<UserRegisterResponseDto> {
  //   return this.httpClient.post<UserRegisterResponseDto>(
  //     `${this.userApi}/reset-password`,
  //     dto,
  //     this.anonymousHeader
  //   );
  // }


  // updateUser(
  //   dto: UserPersonalInfoUpdateDto
  // ): Observable<UserPersonalInfoUpdateDto> {
  //   return this.httpClient.put<UserPersonalInfoUpdateDto>(
  //     `${this.userApi}/update`,
  //     dto,
  //     this.authorizedHeader
  //   );
  // }

  // updateUserAddressInfo(
  //   dto: UserAddressInfoUpdateDto
  // ): Observable<UserAddressInfoUpdateDto> {
  //   return this.httpClient.put<UserAddressInfoUpdateDto>(
  //     `${this.userApi}/update-address`,
  //     dto,
  //     this.authorizedHeader
  //   );
  // }

  // uploadImage(
  //   dto: UserUpdateImageRequestDto
  // ): Observable<UserUpdateImageRequestDto> {
  //   return this.httpClient.put<UserUpdateImageRequestDto>(
  //     `${this.userApi}/upload-image`,
  //     dto,
  //     this.authorizedHeader
  //   );
  // }

}
