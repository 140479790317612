export enum PlayerStatusEnum {
    playing = 'playing',
    waitingForBigBlind = 'waitingForBigBlind',
    rebuy = 'rebuy',
    empty = 'empty',
    seated = 'seated',
    requestExit = 'requestExit',
    absence = 'absence',
    presence = 'presence',
    payBigBlind = 'payBigBlind'
}
