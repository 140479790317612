<div class="hand-content p-2 justify-content-center align-items-center" id="overflowTest"
  style="background-color: #1E2129; padding-bottom: 4px;">
  <table class="table table-dark table-custom">
    <thead>
      <tr>
        <th scope="col text-center" style="font-size: 14px;">{{ 'GAME_SELECT.MY_CARDS' | translate }}</th>
        <th scope="col text-center" style="font-size: 14px;">{{ 'GAME_SELECT.BORDO' | translate }}</th>
        <th scope="col text-center" style="font-size: 14px;">{{ 'GAME_SELECT.POT' | translate }}</th>
        <th scope="col text-center" style="font-size: 14px;">{{ 'GAME_SELECT.OPTIONS' | translate }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let hand of handHistory">
        <td>
          <app-hand-image [cards]="hand.hand"></app-hand-image>
        </td>
        <td>
          <app-hand-image [cards]="hand.flopCards"></app-hand-image>
        </td>
        <td>{{ hand.pot }}</td>
        <td>
          <img (click)="playTableReplay(hand.round)" src="../../../assets/icons/control-icon.svg" width="23px"
            height="28px" alt="">
        </td>
      </tr>
    </tbody>
  </table>
</div>