<section class="background-lobby-tournament pb-4">
  <form [formGroup]="form">
    <div class="container">
      <div class="col-md-6 tournament-name d-flex justify-content-start align-items-center">
        <h2 class="heading-700-sm ps-4 pt-3">{{ "LOBBY_TOURNAMENT.PAGE_TITLE" | translate }}</h2>
      </div>
      <div class="row card-row p-3">
        <div class="col-lg-4 mb-3">
          <div class="card p-3">
            <div class="rectangle"></div>
            <p class="heading-500-sm pt-4">{{ "LOBBY_TOURNAMENT.PAGE_SUBTITLE" | translate }}</p>
            <div class="d-flex justify-content-between pt-4">
              <p class="heading-300-sm">{{ "LOBBY_TOURNAMENT.CURRENT_LEVEL" | translate }}</p>
              <p class="heading-500-lg">{{0}}</p>
            </div>
            <div class="d-flex justify-content-between py-3">
              <p class="heading-300-sm">{{ "LOBBY_TOURNAMENT.BLINDS" | translate }}</p>
              <p class="heading-500-lg">{{0}}</p>
            </div>
            <div class="d-flex justify-content-between">
              <p class="heading-300-sm">{{ "LOBBY_TOURNAMENT.NEXT_BLINDS" | translate }}</p>
              <p class="heading-500-lg">{{0}}</p>
            </div>
            <div class="d-flex justify-content-between py-3">
              <p class="heading-300-sm">{{ "LOBBY_TOURNAMENT.LARGEST" | translate }}</p>
              <p class="heading-500-lg">{{0}}</p>
            </div>
            <div class="d-flex justify-content-between">
              <p class="heading-300-sm">{{ "LOBBY_TOURNAMENT.AVERAGE" | translate }}</p>
              <p class="heading-500-lg">{{0}}</p>
            </div>
            <div class="d-flex justify-content-between py-3">
              <p class="heading-300-sm">{{ "LOBBY_TOURNAMENT.SMALLEST_STACKS" | translate }}</p>
              <p class="heading-500-lg">{{0}}</p>
            </div>
            <!-- <div class="d-flex justify-content-center align-items-center py-4">
              <button class="w-100 button-700 btn-continue">{{ "LOBBY_TOURNAMENT.SUBSCRIBE" | translate }}</button>
            </div> -->
            <div class="d-flex justify-content-center align-items-center py-4">
              <!-- <button [routerLink]="['/pages/cashgame/id', usersPokerGame?.gameRoom?._id]" class="w-100 button-700 btn-continue">{{ "LOBBY_TOURNAMENT.PLAY_NOW" | translate }}</button> -->
            </div>
            <div class="d-flex justify-content-center align-items-center pb-5">
              <!-- <button [routerLink]="['/pages/tournament-info']" class="w-100 button-700 btn-black">{{ "LOBBY_TOURNAMENT.BTN_STRUCTURE" | translate }}</button> -->
            </div>
          </div>
        </div>
        <div class="col-lg-5">
          <div class="card" style="height: 300px;">
            <table class="table table-dark table-striped">
              <thead>
                <tr>
                  <th scope="col">{{ "LOBBY_TOURNAMENT.TABLE" | translate }}</th>
                  <th scope="col">{{ "LOBBY_TOURNAMENT.PLAYERS" | translate }}</th>
                  <th scope="col">{{ "LOBBY_TOURNAMENT.SATELITES" | translate }}</th>
                  <th scope="col">{{ "LOBBY_TOURNAMENT.TABLE_STRUCTURE" | translate }}</th>
                </tr>
              </thead>
              <tbody *ngFor="let table of tables; let i = index">
                <tr (click)="onClickTableRow(table)" style="cursor: pointer;">
                  <th>{{i+1}}</th>
                  <td>{{table.players.length}}</td>
                  <td>{{table.gameRoom.status}}</td>
                  <td>{{table.gameRoom.numberOfPlayers}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="card-game my-3">
            <div class="d-flex justify-content-center align-items-center table-card py-3">
              <h2 class="heading-500-lg">{{ "LOBBY_TOURNAMENT.TABLE" | translate }}</h2>
            </div>
            <div class="d-flex justify-content-center align-items-center py-3">
              <!-- <img src="../../../assets/icons/icon-lobby-tournament.svg" class="img-fluid"> -->
              <app-table-cash-game [players]="!!selectedTable ? selectedTable.players : []"></app-table-cash-game>
            </div>
            <div class="d-flex flex-row justify-content-center align-items-center cursor-pointer my-4">
              <img src="../../../assets/icons/eye-icon.svg" width="20" height="16" />
              <h4 class="text-button-color-1 ps-3">{{ "LOBBY_TOURNAMENT.OBSERVE" | translate }}</h4>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="card">
            <div class="my-4 position-relative mx-2">
              <input type="text" formControlName="search" class="form-control input-box" placeholder="Search..." (keyup)="onTypeSearchPlayer()">
              <img src="../../../assets/icons/icon-search.svg" class="icon-input end-0 me-2" width="20" height="16" />
            </div>
            <div class="d-flex flex-column justify-content-center align-items-center px-3">
              <div *ngFor="let player of formattedPlayers; let i = index" class="card-player w-100 mt-2 px-2 py-1">
                <p class="body-400-md"><span class="subtitle-700-lg">{{i+1}}.</span> {{player}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</section>
