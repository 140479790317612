import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseService } from "./base.service";
import jwt_decode from "jwt-decode";
import { Router } from "@angular/router";
import { AuthenticateRequestDto } from "src/app/dtos/auth/authenticate-request.dto";
import { JwtPayloadDto } from "src/app/dtos/auth/jwt-payload.dto";
import { UserAuthenticatedDto } from "src/app/dtos/auth/user-authenticated.dto";
import { LocalStorageKeysEnum } from "src/app/dtos/enum/local-storage-keys.enum";

@Injectable({
  providedIn: "root",
})
export class AuthService extends BaseService {
  private baseUrl = this.gameBackoffice + 'game';

  constructor(
    private readonly httpClient: HttpClient,
    private readonly router: Router
  ) {
    super();
  }

  authenticate(dto: AuthenticateRequestDto) {
    return this.httpClient.post<UserAuthenticatedDto>(
      `${this.baseUrl}/authenticate`,
      dto,
      this.anonymousHeader
    );
  }

  authenticated() {
    return this.httpClient.get(
      `${this.baseUrl}/authenticated`,
      this.authorizedHeader
    );
  }

  setAuthenticatedUser(dto: UserAuthenticatedDto) {
    localStorage.setItem(LocalStorageKeysEnum.user, JSON.stringify(dto));
  }

  getAuthenticatedUser() {
    const user = localStorage.getItem(LocalStorageKeysEnum.user);
    return user === null ? undefined : JSON.parse(user);
  }

  removeAuthenticatedUser() {
    localStorage.removeItem(LocalStorageKeysEnum.user);
  }

  getPayloadFromJWT() {
    return jwt_decode<JwtPayloadDto>(
      (this.getAuthenticatedUser() as UserAuthenticatedDto).token
    );
  }

  isJwtValid() {
    const user = this.getAuthenticatedUser();

    if (!user) {
      return false;
    }

    const payload = this.getPayloadFromJWT();

    if (payload.exp < new Date().getTime()/1_000) {
      return false;
    }

    if (payload.tfaRegistered && !payload.tfaAuthenticate) {
      return false;
    }

    return true;
  }
}
