import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: 'initialcapitalletter' })
export class InitialCapitalLetterPipe implements PipeTransform {
  transform(value: string): string {
    if (!!value && value.length > 0) {
      return value[0].toUpperCase();
    }else {
      return value;
    }
  }
}
