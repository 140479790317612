import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'pokerfi-game';

  constructor(
    public router: Router,
    public route: ActivatedRoute
  ) {
    router.events.pipe(filter(ev => ev instanceof NavigationStart)).subscribe({
      next: data => {
        sessionStorage.setItem('lastNavigatedRoute', (data as NavigationStart).url);
      }
    });
    this.listenToRouteChanges();
  }

  listenToRouteChanges() {
    this.router.events.pipe(filter(ev => ev instanceof NavigationEnd || ev instanceof NavigationStart)).subscribe({
      next: data => {
        if (data instanceof NavigationEnd) {
          if (!(data as NavigationEnd).url.includes('pages')) {
            // this.commonSocketService.shutDownAll();
            // this.tableGameSocketService.shutDownAll();
          }
        }
      }
    });
  }

  handlerLoggedBackground(url: string): boolean {
    if (url.length <= 1) {
      return false;
    }

    if (url.indexOf('pages') !== -1) {
      return false;
    }
    return true;
  }
}
