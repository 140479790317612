import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ObservableService extends BaseService {

  url: string = `${this.observable}payment`;

  constructor(
    private httpClient: HttpClient,
  ) {
    super();
  }

  generateQrCode(dto: any) {
    return this.httpClient.post(`${this.url}/generate-qrcode`, dto, this.authorizedHeader);
  }

  withdraw(dto: any) {
    return this.httpClient.post(`${this.url}/withdraw`, dto, this.authorizedHeader);
  }

}
