import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TableGameDto } from "../app/dtos/table-game/table-game.dto";
import { BaseService } from "./base.service";

@Injectable({
    providedIn: 'root'
})
export class TableGameService extends BaseService {

     url: string = `${this.gameBackoffice}table-game`;

    constructor(
        private httpClient: HttpClient,
    ) {
        super();
    }

  
    getTableByAproxBlindValue(tableGameId: string) {
        return this.httpClient.get<TableGameDto[]>(`${this.gameBackoffice}table-game/by-aprox-blind-value/${tableGameId}`, this.authorizedHeader );
      }
   
}
