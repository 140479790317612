import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CashGameResponseDto } from 'src/app/dtos/cash-game/cash-game-response.dto';
import { BaseService } from './base.service';
import { CashGameHistoryInterface } from 'src/app/dtos/cash-game-history/cash-game-history.interface';
import { CashGameHandHisotry } from 'src/app/dtos/cash-game-history/cash-game-hand-history.interface';

@Injectable({
  providedIn: 'root'
})
export class CashGameReplayService extends BaseService {

  url: string = `${this.gameBackoffice}table-game-history`;

  constructor(
    private httpClient: HttpClient,
  ) {
    super();
  }

  listByTableAndUserId(id: string, userId:string, round:string) {
    return this.httpClient.get<CashGameHistoryInterface[]>(`${this.url}/list-by-table-id/${id}/${userId}/${round}`, this.authorizedHeader);
  }

  getHandhistory(id: string, userId:string) {
    return this.httpClient.get<CashGameHandHisotry[]>(`${this.url}/list-hand-by-table-id/${id}/${userId}`, this.authorizedHeader);
  }
}
