<section class="mb-5">
  <div (click)="exit()" class="d-flex justify-content-end cursor-pointer mt-3 pe-3">
    <img src="../../../assets/icons/icon-x-black.svg">
  </div>
  <div class="d-flex flex-column align-items-center">
    <img src="../../../assets/icons/icon-pokerfi-grey.svg" class="img-fluid" style="margin-top: 24px !important;">
  </div>
  <div class="d-flex flex-column px-lg-5 mt-2">
    <h6 class="font-montserrat-bold font-size-24 text-center text-dark mt-3">{{ 'MODAL_LOGIN.REGISTER_TEXT' | translate }}</h6>

    <div class="d-flex justify-content-center mt-3">
      <button type="button" class="btn btn-register" (click)="register()" style="width: 80%;">
        {{ 'MODAL_LOGIN.CREATE_ACCOUNT' | translate }}
      </button>
    </div>

    <span class="body-400-lg  text-center text-dark">{{ 'MODAL_LOGIN.ACCOUNT' | translate }} <a
        class="link-400 cursor-pointer" (click)="openModalLogin()">
        Login</a>.</span>

    <!-- <h4 class="text-center text-dark mt-3">{{ 'MODAL_LOGIN.REGISTER_TEXT' | translate }}</h4> -->
    <!-- <form class="w-100 mt-3" [formGroup]="form">
            <div class="d-flex flex-column">
                <strong><label class="label-custom">{{ 'MODAL_LOGIN.FULL_NAME' | translate }}</label></strong>
                <input type="text" formControlName="name" class="form-control input-custom text-capitalize"
                    placeholder="{{ 'MODAL_LOGIN.FULL_NAME' | translate }}">
            </div>
            <div class="form-group mt-2">
                <strong><label class="label-custom" for="phone">{{ 'MODAL_LOGIN.TEL' | translate }}</label></strong>
                <div>
                    <ngx-intl-tel-input class="custom" [preferredCountries]="preferredCountries"
                        [enableAutoCountrySelect]="true" [enablePlaceholder]="false" [searchCountryFlag]="true"
                        [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                        [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.Brazil" [maxLength]="15"
                        [phoneValidation]="true" [separateDialCode]="separateDialCode" name="phone" required
                        formControlName="phone">
                    </ngx-intl-tel-input>
                    <div class="div-line-register"></div>
                    <p *ngIf="form.controls['phone'].invalid && form.controls['phone'].value !== null"
                        class="text-danger mt-2">{{'MODAL_LOGIN.INCORRET_NUMBER' | translate}}</p>
                </div>
            </div>
            <div class="d-flex flex-column mt-2">
                <strong><label class="label-custom" for="email">E-mail</label></strong>
                <input type="email" formControlName="email" class="form-control input-custom" placeholder="Email"
                    id="email">
            </div>

            <label>
                <div class="mt-3">
                    <div class="d-flex align-items-center pb-2">
                        <div class="checkbox-round d-flex justify-content-center align-items-center"
                            style="min-height: 24px; min-width: 24px;">
                            <input type="checkbox" id="1" class="checkbox-round-center" (click)="term=!term" />
                        </div>
                        <div class="form-check-label ps-2" for="defaultCheck1">
                            <span class="openModal" style="cursor:pointer;">{{ 'MODAL_LOGIN.TERM' |
                                translate }}</span>.
                        </div>
                    </div>
                </div>
            </label>
            <div class="d-flex flex-column mt-2 align-items-center">

            </div>
        </form> -->
  </div>
</section>
