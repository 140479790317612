import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable } from 'rxjs';
import { TfaDeleteRequestDto } from 'src/app/dtos/tfa/tfa-delete-request.dto';
import { TfaGenerateResponseDto } from 'src/app/dtos/tfa/tfa-generate-response.dto';
import { TfaGetResponseDto } from 'src/app/dtos/tfa/tfa-get-response.dto';
import { TfaRegisterRequestDto } from 'src/app/dtos/tfa/tfa-register-request.dto';
import { TfaRegisterResponseDto } from 'src/app/dtos/tfa/tfa-register-response.dto';
import { TfaVerifyRequestDto } from 'src/app/dtos/tfa/tfa-verify-request.dto';
import { TfaVerifyResponseDto } from 'src/app/dtos/tfa/tfa-verify-response.dto';
import { BaseService } from './base.service';

@Injectable()
export class TfaService extends BaseService {
  // tfaApi: string = `${this.gameBackoffice}2fa`;

  // constructor(private httpClient: HttpClient) {
  //   super();
  // }

  // get(): Observable<TfaGetResponseDto> {
  //   return this.httpClient
  //     .get<TfaGetResponseDto>(`${this.tfaApi}`, this.authorizedHeader)

  // }

  // generate(): Observable<TfaGenerateResponseDto> {
  //   return this.httpClient
  //     .post<TfaGenerateResponseDto>(`${this.tfaApi}/generate`, {}, this.authorizedHeader)

  // }

  // register(dto: TfaRegisterRequestDto): Observable<TfaRegisterResponseDto> {
  //   return this.httpClient
  //     .post<TfaRegisterResponseDto>(`${this.tfaApi}/register`, dto, this.authorizedHeader)
  // }

  // verify(dto: TfaVerifyRequestDto): Observable<TfaVerifyResponseDto> {
  //   return this.httpClient
  //     .post<TfaVerifyResponseDto>(`${this.tfaApi}/verify`, dto, this.authorizedHeader)

  // }

  // delete(dto: TfaDeleteRequestDto) {
  //   return this.httpClient
  //     .post(`${this.tfaApi}/delete`, dto, this.authorizedHeader)
  // }

}
