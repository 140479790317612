export enum GameGatewayEventsEnum {
  PATH = '/socket/game',
  REQUEST_JOIN_TABLE_GAME = 'REQUEST_JOIN_TABLE_GAME',
  RESPONSE_JOIN_TABLE_GAME = 'RESPONSE_JOIN_TABLE_GAME',
  REQUEST_JOIN_CONFIRMATION_TABLE_GAME = 'REQUEST_JOIN_CONFIRMATION_TABLE_GAME',
  RESPONSE_START_TABLE_GAME = 'RESPONSE_START_TABLE_GAME',
  REQUEST_PLAYER_ACTION = 'REQUEST_PLAYER_ACTION',
  RESPONSE_PLAYER_ACTION = 'RESPONSE_PLAYER_ACTION',
  REQUEST_LEAVE_GAME = 'REQUEST_LEAVE_GAME',
  RESPONSE_LEAVE_GAME = 'RESPONSE_LEAVE_GAME',
  RESPONSE_ANIMATION_ACTION = 'RESPONSE_ANIMATION_ACTION',
  RESPONSE_ANIMATION_GIVE_CHIPS_TO_WINNERS = 'RESPONSE_ANIMATION_GIVE_CHIPS_TO_WINNERS',
  RESPONSE_ANIMATION_SET_CARDS_TO_PLAYERS = 'RESPONSE_ANIMATION_SET_CARDS_TO_PLAYERS',
  RESPONSE_ANIMATION_SET_CHIPS_TO_POT = 'RESPONSE_ANIMATION_SET_CARDS_TO_POT',
  RESPONSE_ANIMATION_SHOW_CARDS_AT_SHOWDOWN = 'RESPONSE_ANIMATION_SHOW_CARDS_AT_SHOWDOWN',
  RESPONSE_ANIMATION_TIMER_CURRENT_PLAYER = 'RESPONSE_ANIMATION_TIMER_CURRENT_PLAYER',
  RESPONSE_ANIMATION_SHOW_ACTIONS = 'RESPONSE_ANIMATION_SHOW_ACTIONS',
  REQUEST_BUY_COIN = 'REQUEST_BUY_COIN',
  RESPONSE_ANIMATION_SHOW_FULL_POT = 'RESPONSE_ANIMATION_SHOW_FULL_POT',
  REQUEST_SIT_AT_TABLE_GAME = 'REQUEST_SIT_AT_TABLE_GAME',
  REQUEST_PLAYER_ABSENCE = 'REQUEST_PLAYER_ABSENCE',
  REQUEST_PLAYER_PRESENCE = 'REQUEST_PLAYER_PRESENCE',
  REQUEST_PAY_BIG_BLIND = 'REQUEST_PAY_BIG_BLIND',
  RESPONSE_ANIMATION_TIMEBANK_CURRENT_PLAYER = 'RESPONSE_ANIMATION_TIMEBANK_CURRENT_PLAYER'
}
