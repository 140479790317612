import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserInterface } from 'src/app/dtos/user/user.interface';
import { BaseService } from './base.service';
import { KycRegisterDto } from 'src/app/dtos/kyc/kyc-register.dto';

@Injectable({
    providedIn: 'root',
})
export class KycService extends BaseService {
    userApi: string = `${this.gameBackoffice}kyc`;

    constructor(private httpClient: HttpClient) {
        super();
    }

    getKyc() {
        return this.httpClient.get(`${this.userApi}`, this.authorizedHeader);
    }

    registerKyc(dto: any) {
        return this.httpClient.post<any>(
            `${this.userApi}/register`,
            dto,
            this.authorizedHeaderMult
        );
    }

}
