import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CashGameHandHisotry } from 'src/app/dtos/cash-game-history/cash-game-hand-history.interface';
import { CashGameReplayService } from 'src/services/cash-game-replay.service';

@Component({
  selector: 'app-hands',
  templateUrl: './hands.component.html',
  styleUrls: ['./hands.component.scss']
})
export class HandsComponent implements OnInit, OnChanges {
  @Input() tableRound: number = 0;
  handHistory: CashGameHandHisotry[] = [];

  user: any;
  tableId: string = ''

  constructor(
    private cashGameReplayService: CashGameReplayService
  ) { }

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('user') || '')
    this.tableId = localStorage.getItem('tableId') || ''

    this.getHandHistory()

  }

  getHandHistory() {
    this.cashGameReplayService.getHandhistory(this.tableId, this.user._id.toString()).subscribe({
      next: data => {  
        this.handHistory = data;
        this.handHistory.reverse();
      }, error: err => {
        console.error(err)
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['tableRound'] && this.tableRound !== 0) {
      this.getHandHistory()
    }
  }

  playTableReplay(round: any) {
    window.open(`/pages/tablegame-replay/id/${this.tableId}/${this.user._id}/${round}`, '_blank');
  }

}
