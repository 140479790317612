import { Component, ElementRef, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-teste',
  templateUrl: './teste.component.html',
  styleUrls: ['./teste.component.scss']
})
export class TesteComponent implements OnInit {

  divStyles: any = {
    width: '1000px',
    height: '570px',
    backgroundColor: '#212529'
  };

  firstLetter: any = {
    'font-size': '60px'
  }

  font16px: any = {
    'font-size': '16px'
  }

  font20px: any = {
    'font-size': '20px'
  }

  font27px: any = {
    'font-size': '27px'
  }

  @HostListener('window:resize')
  onWindowResize() {
    this.resizeDiv();
  }

  resizeDiv() {
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const divWidth = 1000;
    const divHeight = 570;

    const widthRatio = windowWidth / divWidth;
    const heightRatio = windowHeight / divHeight;
    const scale = Math.min(widthRatio, heightRatio);

    this.divStyles.width = divWidth * scale + 'px';
    this.divStyles.height = divHeight * scale + 'px';

    this.firstLetter['font-size'] = 37 * scale + 'px';
    this.font16px['font-size'] = 10 * scale + 'px';
    this.font20px['font-size'] = 12.5 * scale + 'px';
    this.font27px['font-size'] = 17 * scale + 'px';

  }

  progressWidth!: number;

  // Chame a função resizeDiv() uma vez para inicializar a div com as proporções corretas
  ngOnInit() {
    this.resizeDiv();
  }

  animacao() {
    let animationSetChipToTable0 = document.getElementById('animationSetChipToTable0');
    animationSetChipToTable0?.classList.add('startAnimationSetChipToTable0');
    setTimeout(() => {
      animationSetChipToTable0?.classList.remove('startAnimationSetChipToTable0');
    }, 1000);
  }

}
