<section class="background-modal p-3">
    <div (click)="exit()" class="d-flex justify-content-end cursor-pointer">
      <img src="../../../../assets/icons/icon-x.svg" />
    </div>
    <div class="d-flex flex-column justify-content-center align-items-center">
      <h6 class="heading-700-sm">{{ "MODAL_DELETE.TITLE" | translate }}</h6>
      <p class="heading-500-md text-center mt-3">{{ "MODAL_DELETE.TEXT_1" | translate }}</p>
      <p class="heading-500-md text-center">{{ "MODAL_DELETE.TEXT_2" | translate }}</p>
    </div>
  <form class="form" [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="my-4 position-relative">
      <input type="password" formControlName="password" class="form-control input-box"
        placeholder="{{ 'SIGN_IN.PASSWORD' | translate }}">
      <img src="../../../assets/icons/password-icon.svg" class="icon-input" width="20" height="16" />
    </div>
    <div class="row">
      <div class="col d-flex justify-content-center align-items-center">
        <button [disabled]="form.invalid" class="btn-delete button-bold mx-2"
        >{{ "MODAL_DELETE.BTN_CONFIRM" | translate }}</button>
      </div>
      <div class="col d-flex justify-content-center align-items-center">
        <button class="btn-continue button-bold mx-2" (click)="exit()">{{ "MODAL_DELETE.BTN_CANCEL" | translate
          }}</button>
      </div>
    </div>
  </form>
</section>
