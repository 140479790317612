import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GameCoinService } from '../../../../services/game-coin.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ObservableService } from '../../../../services/observable.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-modal-request-withdrawal',
  templateUrl: './modal-request-withdrawal.component.html',
  styleUrls: ['./modal-request-withdrawal.component.scss']
})
export class ModalRequestWithdrawalComponent implements OnInit {

  form: FormGroup;
  balanceUsdt: number = 0;
  balancePokerfi: number = 0;

  constructor(
    private modalService: NgbModal,
    private readonly _gameCoinService: GameCoinService,
    private readonly _formBuilder: FormBuilder,
    private readonly _observableService: ObservableService,
    private readonly _toastrService: ToastrService,
    private readonly _ngxSpinnerService: NgxSpinnerService,
    private readonly _ngbActiveModal: NgbActiveModal,
    private readonly _translateService: TranslateService

  ) {
    this.form = this._formBuilder.group({
      coin: ['POKERFI', [Validators.required]],
      value: [0, [Validators.required]],
    });
  }

  ngOnInit(): void {
    this._gameCoinService.getBalance().subscribe({
      next: response => {
        this.balancePokerfi = response.coinPokerFiValue;
        this.balanceUsdt = response.coinUsdValue;
      },
      error: error => {
        console.error(error);
      }
    });
  }

  submit() {

    if(this.form.invalid || this.form.controls['value'].value == 0) {
      return;
    }

    let dto;

    if(this.form.controls['coin'].value == 'USDT') {

      if(this.form.controls['value'].value > this.balanceUsdt) {
        this._toastrService.error('Saldo insuficiente!', '', { progressBar: true });
        return;
      }

      dto = {
        coin: this.form.controls['coin'].value,
        value: this.form.controls['value'].value.toString()
      }
    } else {

      if(this.form.controls['value'].value > this.balancePokerfi) {
        this._toastrService.error('Saldo insuficiente!', '', { progressBar: true });
        return;
      }

      dto = {
        coin: this.form.controls['coin'].value,
        value: this.form.controls['value'].value.toString()
      }
    }
    this._ngxSpinnerService.show();
    this._observableService.withdraw(dto).subscribe({
      next: response => {
        this._translateService.get('PROFILE.MODAL_REQUEST.WHITHDRAW_REQUEST').subscribe({
          next: (data: any) => {
            this._toastrService.success(data, '', {progressBar: true});
          },
        });
        this._ngxSpinnerService.hide();
        this._ngbActiveModal.close(true);
      },
      error: error => {
        this._translateService.get('PROFILE.MODAL_REQUEST.WHITHDRAW_REQUEST_ERROR').subscribe({
          next: (data: any) => {
            this._toastrService.error(data, '', {progressBar: true});
          },
        });
        this._ngxSpinnerService.hide();
        console.error(error);
      }
    });

  }

  exit() {
    this.modalService.dismissAll();
  }

}
