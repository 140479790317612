import { Subject } from 'rxjs';
import { Component, HostListener, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProfileService } from 'src/services/profile.service';
import { ClipboardService } from 'ngx-clipboard';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-modal-pix-payment',
  templateUrl: './modal-pix-payment.component.html',
  styleUrls: ['./modal-pix-payment.component.scss']
})
export class ModalPixPaymentComponent implements OnInit {

  @Input() public qrCode!: string;
  @Input() public pixCreatedAt!: Date;

  interval!: any;
  minutos?:number;
  segundos?: number;

  constructor(
    private modalService: NgbModal,
    private profileService: ProfileService,
    private clipboardApi: ClipboardService,
    private readonly _toastrService: ToastrService,
    private readonly _translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.interval = setInterval(() => {
      let dataAtualEmMilissegundos = new Date().getTime();
      let dataFuturaEmMilissegundos =  new Date(this.pixCreatedAt).getTime() + 5 * 60000;
      let tempoRestanteEmMilissegundos = dataFuturaEmMilissegundos - dataAtualEmMilissegundos;
      this.minutos = Math.floor(tempoRestanteEmMilissegundos / 60000);
      this.segundos = Math.floor((tempoRestanteEmMilissegundos % 60000) / 1000);

      if(this.minutos <= 0 && this.segundos <= 0) {
        this.minutos = 0;
        this.segundos = 0;
        clearInterval(this.interval);
      }
    }, 1000);
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }

  exit() {
    this.modalService.dismissAll();
  }

  copyText() {
    this.clipboardApi.copyFromContent(this.qrCode);
    this._translateService.get('PIX_MODAL.COPIED_PIX').subscribe({
      next: (data: any) => {
        this._toastrService.success(data, '', {progressBar: true});
      },
    });
  }

}
