import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { GlobalErrorHandler } from 'src/errors/global-error-handler';
import { BackofficeRequestInterceptor } from 'src/interceptors/backoffice.interceptor';
import { HttpLoaderFactory } from 'src/services/http-load.service';
import { TfaService } from 'src/services/tfa.service';
import { UserService } from 'src/services/user.service';
import { CepService } from './../services/cep.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FirstAccessComponent } from './auth/first-access/first-access.component';
import { LandingPageComponent } from './auth/landing-page/landing-page.component';
import { LoginComponent } from './auth/login/login.component';
import { ModalSignUpComponent } from './auth/modal-sign-up/modal-sign-up.component';
import { PasswordPageComponent } from './auth/password-page/password-page.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { SignInComponent } from './auth/sign-in/sign-in.component';
import { SignUpComponent } from './auth/sign-up/sign-up.component';
import { VericationCodeComponent } from './auth/verication-code/verication-code.component';
import { ErrorNotFoundComponent } from './components/error-not-found/error-not-found.component';
import { FooterComponent } from './components/footer/footer.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { CurrencyMaskModule } from "ng2-currency-mask";
import { PlayerDataService } from 'src/services/player-data.service';

export const options: Partial<null | IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [
    AppComponent,
    SignInComponent,
    SignUpComponent,
    NavbarComponent,
    VericationCodeComponent,
    FooterComponent,
    PasswordPageComponent,
    FirstAccessComponent,
    LandingPageComponent,
    ResetPasswordComponent,
    ErrorNotFoundComponent,
    LoginComponent,
    ModalSignUpComponent,

  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    NgxSpinnerModule,
    CurrencyMaskModule
  ],
  providers: [
    UserService,
    PlayerDataService,
    CepService,
    TfaService,
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BackofficeRequestInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
