import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from 'src/services/user.service';
import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-modal-delete-account',
  templateUrl: './modal-delete-account.component.html',
  styleUrls: ['./modal-delete-account.component.scss'],
})
export class ModalDeleteAccountComponent implements OnInit {
  form: FormGroup;

  constructor(
    private modalService: NgbModal,
    private userService: UserService,
    private router: Router,
    private toastrService: ToastrService,
    private fb: FormBuilder,
    private translateService: TranslateService
  ) {
    this.form = this.fb.group({
      password: ['', [Validators.required, Validators.minLength(8)]],
    });
  }

  ngOnInit(): void {}

  exit() {
    this.modalService.dismissAll();
  }

  onSubmit() {
    if (this.form.pristine || !this.form.valid) {
      return;
    }
    const data = Object.assign({}, this.form.value);

    // this.userService.passwordConfirmToDelete(data).subscribe((success) => {
    //   if ((success as any)) {
    //     this.deletAccount();
    //     this.modalService.dismissAll();
    //   } else {
    //     this.translateService.get('TOASTR.INCORRECT_PASSWORD').subscribe({
    //       next: (data) => {
    //         this.toastrService.error(data, '', {
    //           progressBar: true,
    //           timeOut: 3000,
    //         });
    //       },
    //     });
    //   }
    // });
  }

  deletAccount() {
    // this.userService.deleteAccount().subscribe({
    //   next: (data) => {
    //     localStorage.removeItem('user');
    //     this.translateService.get('TOASTR.ACCOUNT_DELETED').subscribe({
    //       next: (data) => {
    //         const toastr = this.toastrService.success(data, '', {
    //           progressBar: true,
    //           timeOut: 3000,
    //         });
    //       },
    //     });

    //     this.modalService.dismissAll();
    //     this.router.navigate(['/']);
    //   },
    //   error: (err) => {
    //     console.error(err);
    //   },
    // });
  }

  runError(exception: any): void {
    const toastr = this.toastrService.error(exception.error.errors[0], '', {
      progressBar: true,
    });
    if (toastr) {
      toastr.onHidden.subscribe(() => {});
    }
  }
}
function deleteAccount(): UserService {
  throw new Error('Function not implemented.');
}
