<section class="bg-dark">
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="default" type="square-jelly-box" [fullScreen]="false">
        <p style="color: white"> Loading... </p>
    </ngx-spinner>
    <div class="container">
        <form [formGroup]="form">
            <div class="row">
                <div class="col-lg-6 pb-5">
                    <div class="card card-scroll p-4">
                        <h2 class="heading-700-sm text-white py-2 ps-1">{{ 'SETTINGS.TITLE_1' | translate }}</h2>
                        <div class="my-4 position-relative">
                            <input type="text" formControlName="search" class="form-control input-box"
                                placeholder="{{ 'SETTINGS.SEARCH' | translate }}">
                            <img src="../../../assets/icons/icon-search.svg" class="icon-input end-0 me-2" width="20"
                                height="16" />
                        </div>
                        <div class="table-responsive">
                            <table class="table table-dark table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col" style="border-top-left-radius: 10px;">{{ 'SETTINGS.PLAYER' |
                                            translate }} &nbsp;
                                            <img src="../../../assets/icons/arrow-down-white.svg" class="ml-2"
                                                width="14" height="14" />
                                        </th>
                                        <th class="text-center" scope="col"
                                            style="border-top-right-radius: 10px;width: 170px;">{{ 'SETTINGS.MODIFIED' |
                                            translate }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of table">
                                        <td>{{item.name}}</td>
                                        <td class="text-center">{{item.date}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="row">
                            <div class="col-md-5">
                                <button class="btn-outline-red w-100 mt-2">{{ 'SETTINGS.DELETE' | translate }}</button>
                            </div>
                            <div class="col-md-7">
                                <button class="btn-activate-2fa mt-2">{{ 'SETTINGS.ADD' | translate }}</button>
                            </div>
                        </div>

                        <p class="subtitle-700-lg text-white mt-4">Daniela.ela</p>
                        <textarea class="form-control class-textarea mt-2" placeholder="Nota"
                            style="height: 86px"></textarea>

                        <div class="d-flex justify-content-between mt-4 mb-2">
                            <p class="body-400-md mb-0">{{ 'SETTINGS.SAVE_NOTES' | translate }}</p>
                            <img src="../../../assets/icons/icon-compartilhas.svg">
                        </div>
                        <div class="border-span p-2">
                            <span>c://users/ricksz/appdata/local</span>
                        </div>

                        <div class="row mt-2">
                            <div class="col-md-5">
                                <button class="btn-outline-white body-400-md w-100 mt-2">{{ 'SETTINGS.CHANGE_FOLDER' |
                                    translate }}</button>
                            </div>
                            <div class="col-md-7">
                                <button class="btn-outline-white body-400-md w-100 mt-2">{{ 'SETTINGS.OPEN_FOLDER' |
                                    translate }}</button>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="col-lg-6 pb-5">
                    <div class="card card-scroll p-4">
                        <h2 class="heading-700-sm text-white py-2 ps-1">{{ 'SETTINGS.TITLE_2' | translate }}</h2>
                        <label>
                            <div class="mt-3">
                                <div class="d-flex align-items-center pb-2">
                                    <div
                                        class="checkbox-round d-flex justify-content-center align-items-center cursor-pointer">
                                        <input type="checkbox" id="1" value="save" class="checkbox-round-center"
                                            formControlName="save" />
                                    </div>
                                    <p class="subtitle-700-md text-white mb-0 ps-2">{{ 'SETTINGS.SAVE_HISTORIC' |
                                        translate }}</p>
                                </div>
                            </div>
                        </label>

                        <div class="border-span p-2">
                            <div class="d-flex justify-content-between align-items-center">
                                <p class="body-400-md mb-0">{{ 'SETTINGS.SAVE_HAND_HISTORIC' | translate }}</p>
                                <div class="bg-dark-days">
                                    <span class="subtitle-700-md px-4">00</span>
                                    <span class="body-400-md font-color-5 pe-4">{{ 'SETTINGS.DAYS' | translate }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="mt-4 mb-2">
                            <p class="body-400-md mb-0">{{ 'SETTINGS.SAVE' | translate }}</p>
                        </div>
                        <div class="border-span p-2">
                            <span>c://users/ricksz/appdata/local</span>
                        </div>

                        <div class="row mt-2">
                            <div class="col-md-5">
                                <button class="btn-outline-white body-400-md w-100 mt-2">{{ 'SETTINGS.CHANGE_FOLDER' |
                                    translate }}</button>
                            </div>
                            <div class="col-md-7">
                                <button class="btn-outline-white body-400-md w-100 mt-2">{{ 'SETTINGS.OPEN_FOLDER' |
                                    translate }}</button>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </form>
    </div>
</section>