export enum CoinPairEnum {
    BUSDUSDT = 'BUSDUSDT',
    BUSDBRL = 'BUSDBRL',
    ETHUSDT = 'ETHUSDT',
    ETHBRL = 'ETHBRL',
    MATICBRL = 'MATICBRL',
    MATICUSDT = 'MATICUSDT',
    BNBUSDT = 'BNBUSDT',
    BNBBRL = 'BNBBRL',
    USDTUSDT = 'USDTUSDT',
    USDTBRL = 'USDTBRL',
}
