<section class="container section-auth d-flex align-items-center">
  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
  </ngx-spinner>
  <div class="col-12 col-md-10 col-lg-6 pt-3">
    <h2 class="heading-700-md">{{ 'FIRST_ACCESS.TITLE' | translate }} </h2>
    <p class="subtitle-700-lg my-3">{{ 'FIRST_ACCESS.SUBTITLE' | translate }}</p>
    <!-- <div class="row d-flex flex-row mt-3">
        <div class="col-sm">
          <a href="/" class="link-yellow">{{ 'FIRST_ACCESS.LINK_1' | translate }}</a>
        </div>
        <div class="col-sm">
          <a href="/" class="link-yellow">{{ 'FIRST_ACCESS.LINK_2' | translate }}</a>
        </div>
      </div> -->
    <form class="form" [formGroup]="form">

      <div class="row d-flex flex-row mt-4">
        <div class="col-sm-6 mt-3 mt-sm-0 d-flex flex-column">
          <div class="d-flex position-relative">
            <input type="text" formControlName="nickname" class="form-control input-box" maxlength="14"
              placeholder="{{ 'SIGN-UP.NICKNAME' | translate }}">
            <img src="../../../assets/icons/icon-nickname.svg" class="icon-input" width="20" height="16" />
          </div>
          <div *ngIf="form.controls['nickname']?.errors?.['required'] && isSubmit" class="d-flex msg-error mt-1">
            {{'FORM.REQUIRED_FIELD' | translate}}</div>
          <div *ngIf="form.controls['nickname']?.errors?.['minlength'] && isSubmit" class="d-flex msg-error mt-1">
            {{'FORM.MIN_LENGTH_2' | translate}}</div>
        </div>

        <div class="col-sm-6 mt-3 mt-sm-0 d-flex flex-column">
          <div class="d-flex position-relative">
            <input type="date" formControlName="birthdate" class="form-control input-box justify-content-start">
            <img src="../../../assets/icons/date-icon.svg" class="icon-input" width="20" height="16" />
          </div>
          <div *ngIf="form.controls['birthdate']?.errors?.['required'] && isSubmit" class="d-flex msg-error mt-1">
            {{'FORM.REQUIRED_FIELD' | translate}}</div>
          <div *ngIf="form.controls['birthdate']?.errors?.['minlength'] && isSubmit" class="d-flex msg-error mt-1">
            {{'FORM.INVALID_BIRTHDATE' | translate}}</div>
        </div>

      </div>

      <div class="row d-flex flex-row mt-4">
        <div class="col-sm position-relative">
          <div class="d-flex position-relative">
            <input type="text" formControlName="zipCode" class="form-control input-box"
              placeholder="{{ 'FIRST_ACCESS.POSTAL_CODE' | translate }}" (keyup)="buscarCep($event)">
            <img src="../../../assets/icons/cep-icon.svg" class="icon-input" width="20" height="16" />
          </div>
          <div *ngIf="form.controls['zipCode']?.errors?.['required'] && isSubmit" class="d-flex msg-error mt-1">
            {{'FORM.REQUIRED_FIELD' | translate}}</div>
        </div>
        <div class="col-sm mt-4 mt-sm-0 position-relative">
          <div class="d-flex position-relative">
            <input type="text" formControlName="state" class="form-control input-box text-capitalize"
              placeholder="{{ 'FIRST_ACCESS.STATE' | translate }}">
            <img src="../../../assets/icons/state-icon.svg" class="icon-input" width="20" height="16" />
          </div>
          <div *ngIf="form.controls['state']?.errors?.['required'] && isSubmit" class="d-flex msg-error mt-1">
            {{'FORM.REQUIRED_FIELD' | translate}}</div>
        </div>
      </div>
      <div class="mt-4 position-relative">
        <div class="d-flex position-relative">
          <input type="text" formControlName="city" class="form-control input-box text-capitalize"
            placeholder="{{ 'FIRST_ACCESS.CITY' | translate }}">
          <img src="../../../assets/icons/city-icon.svg" class="icon-input end-0 me-2" width="20" height="16" />
        </div>
        <div *ngIf="form.controls['city']?.errors?.['required'] && isSubmit" class="d-flex msg-error mt-1">
          {{'FORM.REQUIRED_FIELD' | translate}}</div>
      </div>
      <div class="mt-4 position-relative">
        <div class="d-flex position-relative">
        <input type="text" formControlName="publicPlace" class="form-control input-box text-capitalize"
          placeholder="{{ 'FIRST_ACCESS.ADDRESS' | translate }}">
        <img src="../../../assets/icons/address-icon.svg" class="icon-input end-0 me-2" width="20" height="16" />
      </div>
      <div *ngIf="form.controls['publicPlace']?.errors?.['required'] && isSubmit" class="d-flex msg-error mt-1">
        {{'FORM.REQUIRED_FIELD' | translate}}</div>
    </div>
      <div class="row mt-4">
        <div class="col-sm position-relative">
          <div class="d-flex position-relative">
          <input type="text" formControlName="country" class="form-control input-box text-capitalize"
            placeholder="{{ 'FIRST_ACCESS.COUNTRY' | translate }}">
          <img src="../../../assets/icons/country-icon.svg" class="icon-input" width="20" height="16" />
        </div>
        <div *ngIf="form.controls['country']?.errors?.['required'] && isSubmit" class="d-flex msg-error mt-1">
          {{'FORM.REQUIRED_FIELD' | translate}}</div>
      </div>
        <div class="col-sm mt-4 mt-sm-0">
          <button class="btn-continue button-bold" (click)="onSubmit()">{{ 'FIRST_ACCESS.CONFIRM' | translate
            }}</button>
        </div>
      </div>
    </form>
  </div>
</section>
