import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, NgForm } from '@angular/forms';
import { LocalStorageKeysEnum } from 'src/app/dtos/enum/local-storage-keys.enum';
import { PlayerDataResponseDto } from 'src/app/dtos/player-data/player-data-response.dto';
import PlayerNotesInterface from 'src/app/dtos/player-notes/player-notes.interface';
import { PlayerGetResponseDto } from 'src/app/dtos/table-game/player-get-response.dto';
import PlayerDataAddNotesRequest from 'src/app/dtos/user/player-data-add-notes-request';
import { PlayerDataService } from 'src/services/player-data.service';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent implements OnInit, OnChanges {
  @Input() playerToSetColor: PlayerGetResponseDto | null = null;
  form: FormGroup;
  tableId: string = ''
  user!: PlayerDataResponseDto;
  constructor(
    private fb: FormBuilder,
    private playerDataService: PlayerDataService
  ) {
    this.form = this.fb.group({
      userId: [''],
      note: [''],
      color: [''],
    });
   }

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('user') || '')
    this.tableId = localStorage.getItem('tableId') || ''

    if (this.playerToSetColor) {
      const note = this.findNoteForPlayer();
      if (note) {
        this.form.patchValue({
          userId: note.user,
          note: note.notes,
          color: note.color
        });
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['playerToSetColor']) {
      this.updateForm();
    }
  }

  updateForm(): void {
    if (this.playerToSetColor) {
      const note = this.findNoteForPlayer();
      if (note) {
        this.form.patchValue({
          userId: note.user,
          note: note.notes,
          color: note.color
        });
      } else {
        this.form.reset({
          userId: this.playerToSetColor.user?._id,
          note: '',
          color: this.playerToSetColor.color || ''
        });
      }
    }
  }

  findNoteForPlayer(): PlayerNotesInterface | undefined {
    if (!this.playerToSetColor || !this.user || !this.user.notes) {
      return undefined;
    }

    return this.user.notes.find(note => note.user === this.playerToSetColor?.user?._id);
  }

  saveNotes(){
    if (this.playerToSetColor === null) return
    const dto: PlayerDataAddNotesRequest = {
      user: this.playerToSetColor?.user?._id!,
      notes: this.form.controls['note'].value,
      color: this.playerToSetColor.color ? this.playerToSetColor.color : ''
    }
    this.playerDataService.addNotes(dto).subscribe({
      next: data => {

        this.user.notes = data.notes
        localStorage.setItem(LocalStorageKeysEnum.user, JSON.stringify(this.user));
      }, error: err => {console.error(err)}
    })
  }

}
