import {
  animate, animation, state,
  style,
  transition,
  trigger
} from '@angular/animations';

export const cardShuffle = trigger('shuffler', [
  state('left',
    style({
      transform: 'translateX(-15px)'
    })
  ),
  state('pilled',
    style({
      transform: 'translateX(0px)'
    })
  ),
  state('right',
    style({
      transform: 'translateX(15px)'
    })
  ),
  transition('* <=> pilled', [animate('0.1s 0s cubic-bezier(0.455, 0.030, 0.515, 0.955)')]),
  transition('left <=> right', [animate('0.1s 0s cubic-bezier(0.455, 0.030, 0.515, 0.955)')]),
]);

export const cardShuffle2 = trigger('shuffler2', [
  state('left',
    style({
      transform: 'translateX(15px)'
    })
  ),
  state('pilled',
    style({
      transform: 'translateX(0px)'
    })
  ),
  state('right',
    style({
      transform: 'translateX(-15px)'
    })
  ),
  transition('* <=> pilled', [animate('0.1s 0s cubic-bezier(0.455, 0.030, 0.515, 0.955)')]),
  transition('left <=> right', [animate('0.1s 0s cubic-bezier(0.455, 0.030, 0.515, 0.955)')]),
]);

export const cardSlide = animation([
  animate('0.4s 0s cubic-bezier(0.250, 0.460, 0.450, 0.940)', style({
          transform: '{{ transform }}',
          opacity: '{{ opacity }}'
        })),
]);

export const cardSlideFromDealer = trigger('dealerCard', [
  state('initial',
    style({
      transform: 'translateY(-150px) translateX(156px)'
    })
  ),
  state('final',
    style({
      transform: 'translateX(91px)'
    })
  ),
  // state('flip',
  //   style({
  //     transform: 'rotateY(180deg)'
  //   })
  // ),
  transition('initial => final', [animate('200ms ease-out')]),
  transition('final => flip', [animate('200ms ease-in')])
]);

