import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { TfaDeleteRequestDto } from 'src/app/dtos/tfa/tfa-delete-request.dto';
import { TfaGenerateResponseDto } from 'src/app/dtos/tfa/tfa-generate-response.dto';
import { TfaGetResponseDto } from 'src/app/dtos/tfa/tfa-get-response.dto';
import { TfaRegisterRequestDto } from 'src/app/dtos/tfa/tfa-register-request.dto';
import { TfaRegisterResponseDto } from 'src/app/dtos/tfa/tfa-register-response.dto';
import { TfaVerifyRequestDto } from 'src/app/dtos/tfa/tfa-verify-request.dto';
import { TfaVerifyResponseDto } from 'src/app/dtos/tfa/tfa-verify-response.dto';
import { ProfileService } from 'src/services/profile.service';
import { TfaService } from 'src/services/tfa.service';

@Component({
  selector: 'app-modal-security',
  templateUrl: './modal-security.component.html',
  styleUrls: ['./modal-security.component.scss'],
})
export class ModalSecurityComponent implements OnInit {
  qrCode: any = [];
  isActive: boolean = false;
  tafGet!: TfaGetResponseDto;
  tfaGenerate!: TfaGenerateResponseDto;
  requestRegisterTfa!: TfaRegisterRequestDto;
  requestRemoveTfa!: TfaDeleteRequestDto;
  form: FormGroup;
  wallet!: string;
  buttonRemoveTfaSubmitLoading: boolean = false;
  buttonRegisterTfaSubmitLoading: boolean = false;

  constructor(
    private profileService: ProfileService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private tfaService: TfaService,
    private translateService: TranslateService,
    private ngxSpinnerService: NgxSpinnerService
  ) {
    this.form = this.formBuilder.group({
      code: ['', Validators.required],
      password: [''],
    });
  }

  ngOnInit(): void {
    // this.qrCode = this.profileService.Code;
    this.isActive = !!localStorage.getItem('2fa');
    this.wallet = '304F52C66e2A80e78501662887698A1BBef2B8';
    this.getTfa();
  }

  onCodeCompleted(code: string) {
    this.form.patchValue({ code: code });
  }

  getTfa() {
    // this.tfaService.get().subscribe(
    //   (success) => this.runTfaGetSuccess(success),
    //   (error) => this.runError(error)
    // );
  }

  runTfaGetSuccess(success: TfaGetResponseDto): void {
    if (success._id) {
      this.isActive = true;
      this.tafGet = success;
    } else this.generateTfa();
  }

  generateTfa() {
    // this.tfaService.generate().subscribe(
    //   (success) => this.runGenerateTfaSuccess(success),
    //   (error) => this.runError(error)
    // );
  }

  runGenerateTfaSuccess(success: TfaGenerateResponseDto): void {
    this.tfaGenerate = success;
  }

  register2Fa() {
    // this.buttonRegisterTfaSubmitLoading = true;
    // this.ngxSpinnerService.hide();
    // this.tfaService.verify(
    //   new TfaVerifyRequestDto(this.tfaGenerate.secret, this.form.value.code)
    // )
    //   .subscribe(
    //     (success) => this.runRegister2FaSuccess(success),
    //     (error) => this.runError(error)
    //   );
  }

  runRegister2FaSuccess(success: TfaVerifyResponseDto): void {
    // if (success.isValid) {
    //   this.tfaService.register(new TfaRegisterRequestDto(
    //     this.tfaGenerate.secret,
    //     this.tfaGenerate.otpauthUrl
    //   )
    //   )
    //     .subscribe(
    //       (success) => this.runTfaRegisterSuccess(success),
    //       (error) => this.runError(error)
    //     );
    // } else {
    //   this.translateService.get('TOASTR.CODE_INVALID').subscribe({
    //     next: (data) => {
    //       this.toastrService.error(data, '', {
    //         progressBar: true,
    //         timeOut: 2000,
    //       });
    //     },
    //   });
    // }
  }

  runTfaRegisterSuccess(success: TfaRegisterResponseDto): void {
    this.translateService.get('TOASTR.2FA_REGISTERED_SUCCESSFULLY').subscribe({
      next: (data) => {
        const toastr = this.toastrService.success(data, '', {
          progressBar: true,
          timeOut: 2000,
        });

        if (toastr) {
          toastr.onHidden.subscribe(() => {
            this.isActive = true;
            this.form.reset();
            this.modalService.dismissAll();
          });
        }
      },
    });
  }

  remove2Fa() {
    // this.buttonRemoveTfaSubmitLoading = true;
    // this.ngxSpinnerService.show();
    // this.tfaService
    //   .delete(
    //     new TfaDeleteRequestDto(
    //       // this.form.value.code,
    //       // this.tafGet.secret,
    //       this.form.value.password
    //     )
    //   )
    //   .subscribe(
    //     (success) => this.runDeleteTfaSuccess(success),
    //     (error) => this.runError(error)
    //   );
  }

  runDeleteTfaSuccess(success: any): void {
    this.ngxSpinnerService.hide();
    this.translateService.get('TOASTR.2FA_REMOVED_SUCCESSFULLY').subscribe({
      next: (data) => {
        const toastr = this.toastrService.success(data, '', {
          progressBar: true,
          timeOut: 2000,
        });

        if (toastr) {
          toastr.onHidden.subscribe(() => {
            this.isActive = false;
            this.generateTfa();
            this.form.reset();
            this.buttonRemoveTfaSubmitLoading = false;
            this.modalService.dismissAll();
          });
        }
      },
    });
  }

  exit() {
    this.modalService.dismissAll();
  }

  submit() {
    this.register2Fa();
  }

  remove2fa() {
    if (!this.form.value.code && !this.form.value.password) {
      return;
    }
    this.remove2Fa();
  }

  runError(exception: any): void {
    const toastr = this.toastrService.error(exception.error.errors, '', {
      progressBar: true,
      timeOut: 2000,
    });

    if (toastr) {
      toastr.onHidden.subscribe(() => { });
    }
  }
}
