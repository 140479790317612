import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/services/user.service';

@Component({
  selector: 'app-password-page',
  templateUrl: './password-page.component.html',
  styleUrls: ['./password-page.component.scss'],
})
export class PasswordPageComponent implements OnInit {
  form: FormGroup;
  email: string = '';

  showPassword: boolean = false;
  showConfirmPassword: boolean = false;

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private toastrService: ToastrService,
    private router: Router,
    private ngxSpinnerService: NgxSpinnerService,
    public translateService: TranslateService
  ) {
    this.form = this.fb.group(
      {
        password: [
          '',
          [
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(20),
          ],
        ],
        confirmPassword: ['', [Validators.required, Validators.minLength(8)]],
        confirm: [''],
      },
      {
        validators: this.confirmPasswordValidator(
          'password',
          'confirmPassword'
        ),
      }
    );
  }

  ngOnInit(): void {
    this.email = localStorage.getItem('email') || '';
  }

  onSubmit() {
    if (this.form.pristine || !this.form.valid) {
      return;
    }
    this.ngxSpinnerService.show();
    const email = localStorage.getItem('email') || '';
    const password: string = this.form.value.password || '';

    // this.userService.registerPassword({ email, password }).subscribe(
    //   (data) => this.runUserRegisterSuccess(data),
    //   (error) => this.runError(error)
    // );
  }

  runUserRegisterSuccess(data: any): void {
    this.ngxSpinnerService.hide();
    this.router.navigate(['/first-access']);

    this.translateService
      .get('TOASTR.PASSWORD_REGISTERED_SUCCESSFULLY')
      .subscribe({
        next: (data) => {
          const toastr = this.toastrService.success(data, '', {
            progressBar: true,
            timeOut: 3000,
          });

          if (toastr) {
            toastr.onHidden.subscribe(() => {});
          }
        },
      });
  }

  runError(exception: any): void {
    this.ngxSpinnerService.hide();
    console.error(exception);
    const toastr = this.toastrService.error(exception.error.errors[0], '', {
      progressBar: true,
    });

    if (toastr) {
      toastr.onHidden.subscribe(() => {});
    }
  }

  confirmPasswordValidator(password: string, confirmPassword: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[password];
      const matchingControl = formGroup.controls[confirmPassword];
      if (
        matchingControl.errors &&
        !matchingControl.errors['confirmedValidator']
      ) {
        return;
      }

      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ confirmedValidator: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }
}
