import { NgxSpinnerService } from 'ngx-spinner';
import { CepService } from './../../../services/cep.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Cep } from 'src/models/cep';
import { UserService } from 'src/services/user.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { birthdate } from 'src/util/langMask';
import { AuthService } from 'src/services/auth.service';
import { LocalStorageKeysEnum } from 'src/app/dtos/enum/local-storage-keys.enum';
import { PlayerDataService } from 'src/services/player-data.service';

@Component({
  selector: 'app-first-access',
  templateUrl: './first-access.component.html',
  styleUrls: ['./first-access.component.scss'],
})
export class FirstAccessComponent implements OnInit {
  form: FormGroup;
  birthdateMask: string = '';
  isSubmit = false;

  constructor(
    private fb: FormBuilder,
    public translateService: TranslateService,
    private playerDataService: PlayerDataService,
    private toastrService: ToastrService,
    private router: Router,
    private cepService: CepService,
    private ngxSpinnerService: NgxSpinnerService,
    private authService: AuthService
  ) {
    this.form = this.fb.group({
      zipCode: ['', [Validators.required]],
      publicPlace: ['', [Validators.required]],
      country: ['', [Validators.required]],
      city: ['', [Validators.required]],
      state: ['', [Validators.required]],
      nickname: ['', [Validators.required, Validators.minLength(2)]],
      birthdate: ['', [Validators.required, Validators.minLength(8)]],
    });
  }

  ngOnInit(): void {
    this.birthdateMask = birthdate(this.translateService.currentLang);
  }

  buscarCep(event: any) {
    if (event.target.value.length === 8) {
      const cep = this.form.controls['zipCode'].value;

      if (cep)
        this.cepService.buscarCep(cep).then((cep: Cep) => {
          this.form.patchValue({
            district: cep.bairro,
            city: cep.cidade,
            publicPlace: cep.logradouro,
            state: cep.uf,
          });
        });
    }
  }

  onSubmit() {

    this.isSubmit = true;

    if (this.form.pristine || !this.form.valid) {
      return;
    }
    this.ngxSpinnerService.show();
    const email = localStorage.getItem('email') || '';
    let dto = {
      nickname: this.form.controls['nickname'].value,
      birthday: this.form.controls['birthdate'].value,
      address: {
        zipCode: this.form.controls['zipCode'].value,
        publicPlace: this.form.controls['publicPlace'].value,
        country: this.form.controls['country'].value,
        city: this.form.controls['city'].value,
        state: this.form.controls['state'].value,
      }


    }
    this.playerDataService.register(dto).subscribe(
      (data) => this.runUserRegisterSuccess(data),
      (error) => this.runError(error)
    );

  }

  runUserRegisterSuccess(data: any): void {
    this.ngxSpinnerService.hide();

    let user = this.authService.getAuthenticatedUser();
    Object.assign(user, { nickname: this.form.controls['nickname'].value});
    localStorage.setItem(LocalStorageKeysEnum.user, JSON.stringify(user));

    this.router.navigate(['/pages/home']);

    this.translateService
      .get('TOASTR.ADDRESS_REGISTERED_SUCCESSFULLY')
      .subscribe({
        next: (data) => {
          const toastr = this.toastrService.success(data, '', {
            progressBar: true,
            timeOut: 3000,
          });
          if (toastr) {
            toastr.onHidden.subscribe(() => { });
          }
        },
      });
  }

  runError(exception: any): void {
    this.ngxSpinnerService.hide();
    const toastr = this.toastrService.error(exception.error.errors, '', {
      progressBar: true,
    });

    if (toastr) {
      toastr.onHidden.subscribe(() => { });
    }
  }

}
