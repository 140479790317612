import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CurrencyFormatPipe } from 'src/pipe/currency-format.pipe';
import { InitialCapitalLetterPipe } from 'src/pipe/initial-capital-letter.pipe';
import { TournamentStatusPipe } from 'src/pipe/tournament-status.pipe';

@NgModule({
  declarations: [
    InitialCapitalLetterPipe,
    TournamentStatusPipe,
    CurrencyFormatPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    InitialCapitalLetterPipe,
    TournamentStatusPipe,
    CurrencyFormatPipe
  ]
})
export class SharedModule { }
