<section class="background-modal p-3">
  <div (click)="exit()" class="d-flex justify-content-end cursor-pointer">
    <img src="../../../../assets/icons/icon-x.svg" />
  </div>
  <h2 class="heading-700-sm text-center text-white pt-2">{{ 'PROFILE.MODAL_PIX.TITLE' | translate }}</h2>
  <p class="subtitle text-center text-white mt-4 mb-0">{{ 'PIX_MODAL.TIME_LEFT' | translate }}: </p>
  <p class="subtitle text-center text-white">
    <span *ngIf="minutos !== undefined && minutos < 10">0{{minutos}}</span>
    <span *ngIf="minutos !== undefined && minutos >= 10">{{minutos}}</span>
    :
    <span *ngIf="segundos !== undefined && segundos < 10">0{{segundos}}</span>
    <span *ngIf="segundos !== undefined && segundos >= 10">{{segundos}}</span>
  </p>
  <p class="subtitle text-center text-white mt-4">{{ 'PROFILE.MODAL_PIX.SUBTITLE' | translate }}</p>
  <div class="d-flex justify-content-center">
    <qrcode [qrdata]="qrCode" [colorDark]="'#333641'" [colorLight]="'#FFFFFF'" [width]="256"
        [errorCorrectionLevel]="'M'"></qrcode>
  </div>

  <p class="subtitle text-center text-white mt-4">{{ 'PIX_MODAL.AFTER_PAYMENT' | translate }}!</p>

  <div class="d-flex justify-content-end cursor-pointer">
    <img (click)="copyText()" src="../../../../assets/icons/icon-btn-modal.svg">
  </div>
</section>
