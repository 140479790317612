import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/services/user.service';
import { birthdate, phoneMask } from 'src/util/langMask';
// import { usernameAsyncValidator } from 'src/validators/username-async.validator';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
})
export class SignUpComponent implements OnInit {
  form: FormGroup;
  phoneMask: string = '';
  birthdateMask: string = '';

  isSubmit: boolean = false;

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    public translateService: TranslateService,
    private toastrService: ToastrService,
    private router: Router,
    private ngxSpinnerService: NgxSpinnerService
  ) {
    this.form = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(3)]],
      nickname: [
        '',
        {
          validators: [Validators.required, Validators.minLength(2)],
          updateOn: 'blur',
        },
      ],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required, Validators.minLength(11)]],
      birthdate: ['', [Validators.required, Validators.minLength(8)]],
      country: ['', [Validators.required, Validators.minLength(3)]],
      age: ['', [Validators.requiredTrue]],
      term: ['', [Validators.requiredTrue]],
    })
  };

  ngOnInit(): void {
    this.birthdateMask = birthdate(this.translateService.currentLang);
  }

  componentsPhoneMask(lang:string){
    return phoneMask(lang);
  }

  onSubmit() {
    this.isSubmit = true;

    if (this.form.pristine || !this.form.valid) {
      return;
    }
    this.ngxSpinnerService.show();
    const data = Object.assign({}, this.form.value);

    data.birthdate = `${this.form.controls['birthdate'].value[4]}${this.form.controls['birthdate'].value[5]}${this.form.controls['birthdate'].value[6]}${this.form.controls['birthdate'].value[7]}-${this.form.controls['birthdate'].value[2]}${this.form.controls['birthdate'].value[3]}-${this.form.controls['birthdate'].value[0]}${this.form.controls['birthdate'].value[1]}`;

    // this.userService.register(data).subscribe(
    //   (data) => this.runUserRegisterSuccess(data),
    //   (error) => this.runError(error)
    // );
  }

  runUserRegisterSuccess(data: any): void {
    this.ngxSpinnerService.hide();
    this.router.navigate(['/code']);

    this.translateService.get('TOASTR.USER_REGISTERED_SUCCESSFULLY').subscribe({
      next: (data) => {
        const toastr = this.toastrService.success(data, '', {
          progressBar: true,
          timeOut: 3000,
        });
        localStorage.setItem('email', this.form.value.email);

        if (toastr) {
          toastr.onHidden.subscribe(() => { });
        }
      },
    });
  }

  runError(exception: any): void {
    this.ngxSpinnerService.hide();
    const toastr = this.toastrService.error(exception.error.errors, '', {
      progressBar: true,
    });

    if (toastr) {
      toastr.onHidden.subscribe(() => { });
    }
  }

  unblur() {
    // console.log(
    //   this.form.controls['nickname'].errors,
    //   this.form.controls['nickname'].valid
    // );
  }
}
