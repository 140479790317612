import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tournament-info',
  templateUrl: './tournament-info.component.html',
  styleUrls: ['./tournament-info.component.scss']
})
export class TournamentInfoComponent implements OnInit {

  blinds = new Array(11);
  awards = new Array(11);

  constructor() { }

  ngOnInit(): void {
  }

}
