export enum CommonGatewayEventsEnum {
    PATH = '/socket/commom',
    REQUEST_LIST_TABLE_GAMES = 'REQUEST_LIST_TABLE_GAMES',
    RESPONSE_LIST_TABLE_GAMES = 'RESPONSE_LIST_TABLE_GAMES',
    REQUEST_LIST_TABLE_GAMES_DETAILS = 'REQUEST_LIST_TABLE_GAMES_DETAILS',
    RESPONSE_LIST_TABLE_GAMES_DETAILS = 'RESPONSE_LIST_TABLE_GAMES_DETAILS',
    REQUEST_UPDATE_TABLE_VIEWS = 'REQUEST_UPDATE_TABLE_VIEWS',
    REQUEST_UPDATE_WAITING_LIST = 'REQUEST_UPDATE_WAITING_LIST',
    REMOVE_FROM_WAITING_LIST = 'REMOVE_FROM_WAITING_LIST',
    UPDATE_PLAYER_LEAVE_REQUEST = 'UPDATE_PLAYER_LEAVE_REQUEST',

}