import { Component, OnInit } from '@angular/core';
import { ModalSignUpComponent } from '../modal-sign-up/modal-sign-up.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginComponent } from '../login/login.component';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {
  showMore = false;
  n = 3;
  cards = [
    {
      icon: '../../../assets/icons/crown-icon.svg',
      title: 'Bounty Hunter $410',
      limit: 'No Limit',
      buyIn: '$20 + $2',
      players: '0',
      start: '0',
    },
    {
      icon: '../../../assets/icons/trophy-icon.svg',
      title: 'Bounty Hunter $410',
      limit: 'No Limit',
      buyIn: '$20 + $2',
      players: '0',
      start: '0',
    },
    {
      icon: '../../../assets/icons/coin-icon.svg',
      title: 'Bounty Hunter $410',
      limit: 'No Limit',
      buyIn: '$20 + $2',
      players: '0',
      start: '0',
    },
    {
      icon: '../../../assets/icons/crown-icon.svg',
      title: 'Bounty Hunter $410',
      limit: 'No Limit',
      buyIn: '$20 + $2',
      players: '0',
      start: '0',
    },
    {
      icon: '../../../assets/icons/trophy-icon.svg',
      title: 'Bounty Hunter $410',
      limit: 'No Limit',
      buyIn: '$20 + $2',
      players: '0',
      start: '0',
    },
    {
      icon: '../../../assets/icons/coin-icon.svg',
      title: 'Bounty Hunter $410',
      limit: 'No Limit',
      buyIn: '$20 + $2',
      players: '0',
      start: '0',
    },
  ]

  onShow() {
    if (this.n == 3) {
      this.n += 3;
      this.showMore = true;
    } else {
      this.n -= 3;
      this.showMore = false;
    }
  }

  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  openModalLogin() {
    this.modalService.open(LoginComponent);
  }

  openModalSignUp(){
    this.modalService.open(ModalSignUpComponent)
  }

}
