import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TournamentResponseDto } from 'src/app/dtos/tournament/tournament-response.dto';
import { ModalTournamentComponent } from '../home-page/modal-tournament/modal-tournament.component';
import { AuthService } from './../../../services/auth.service';

@Component({
  selector: 'app-lobby-tournament',
  templateUrl: './lobby-tournament.component.html',
  styleUrls: ['./lobby-tournament.component.scss']
})
export class LobbyTournamentComponent implements OnInit {

  form: FormGroup;

  tables: any[] = [];
  tournament!: TournamentResponseDto;

  selectedTable!: any;

  formattedPlayers: string[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    // private readonly commonSocketService: CommonSocketService,
    private readonly route: ActivatedRoute,
    private readonly authService: AuthService
  ) {
    this.form = this.formBuilder.group({
      search: [''],
    });
  }

  ngOnDestroy(): void {
    // this.commonSocketService.tournamentLobbyScreenShutown(this.route.snapshot.params['id']);
  }

  ngOnInit(): void {
    // this.commonSocketService.tournamentLobbyScreenInit(this.route.snapshot.params['id']);

    // this.commonSocketService.tournamentTablesInfo.subscribe({
    //   next: data => {
    //     this.tables = data.tables;
    //     this.selectedTable = data.tables[0];
    //     this.formattedPlayers = this.tables.map(el => el.players.map(el => el.nickname)).flat();
    //   }
    // });

    this.route.data.subscribe({
      next: data => {
        this.tournament = data['data'];
      }
    });
  }

  openModalTournament() {
    this.modalService.open(ModalTournamentComponent)
  }

  onClickTableRow(table: any) {
    this.selectedTable = table;
  }

  onTypeSearchPlayer() {
    // this.formattedPlayers = this.tables.map(el => el.players.map(el => el.nickname)).flat().filter(el => el.toLowerCase().includes(this.form.controls['search'].value.toLowerCase()));
  }

  get usersPokerGame() {
    const usersId = this.authService.getPayloadFromJWT().userId;
    return 'this.tables.find(el => el.players.some(el2 => el2.id === usersId));'
  }
}
