import { Component, Input, OnInit } from '@angular/core';
import { UserGetResponseDto } from '../../../dtos/user/user-get-response.dto';
import { PlayerDataGetResponseDto } from '../../../dtos/player-data/player-data-get.response.dto';

@Component({
  selector: 'app-table-cash-game',
  templateUrl: './table-cash-game.component.html',
  styleUrls: ['./table-cash-game.component.scss']
})
export class TableCashGameComponent implements OnInit {

  @Input() players: PlayerDataGetResponseDto[] = [];

  constructor() { }

  ngOnInit(): void {
  }

}
